var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var DL,EL,xaa,GL,yaa,zaa,Aaa,HL,FL;$CLJS.BL=function(a,b){return $CLJS.fc($CLJS.bb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.hg.j(c,e,$CLJS.ae.g($CLJS.I.j(c,e,$CLJS.Bf),d))},$CLJS.cc($CLJS.N),b))};$CLJS.CL=function(a,b){return $CLJS.bb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
DL=function(a,b){$CLJS.H(a,0,null);$CLJS.H(a,1,null);$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);b=$CLJS.PF(b,$CLJS.vG)?$CLJS.nL:$CLJS.PF(b,$CLJS.TH)?$CLJS.iL:$CLJS.PF(b,$CLJS.dH)?$CLJS.kL:null;return $CLJS.n(b)?$CLJS.sJ(b,a):!0};
EL=function(a){a=$CLJS.A(a);$CLJS.B(a);a=$CLJS.C(a);$CLJS.B(a);a=$CLJS.C(a);a=$CLJS.BL(function(d){return $CLJS.PF($CLJS.KI(d),$CLJS.OG)},a);var b=$CLJS.Ge(a);a=$CLJS.I.g(b,!1);b=$CLJS.I.g(b,!0);if($CLJS.ah.g($CLJS.E(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.E(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.KI($CLJS.B(a));return $CLJS.Ne(function(d){$CLJS.H(d,0,null);$CLJS.H(d,1,null);$CLJS.H(d,2,null);
var e=$CLJS.H(d,3,null);return $CLJS.n(DL(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
xaa=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.st,new $CLJS.k(null,1,[$CLJS.Au,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FL],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FL],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Cu,function(b){b=$CLJS.Ge(b);b=$CLJS.I.g(b,$CLJS.zj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(EL(b))].join("")}],null),$CLJS.Oe(EL)],null)],null)};
GL=function(a){var b=$CLJS.Q,c=xaa(a);a=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ys,new $CLJS.k(null,1,[$CLJS.Au,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ws,new $CLJS.k(null,1,[$CLJS.On,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lJ],null)],null)],null)],null);
return new $CLJS.P(null,3,5,b,[$CLJS.lt,c,a],null)};yaa=function(a){return $CLJS.Qd($CLJS.vJ,$CLJS.ff.g(function(b){var c=$CLJS.KI(b),d=$CLJS.PF(c,$CLJS.ZI);b=d?$CLJS.sJ($CLJS.lK,b):d;return $CLJS.n(b)?$CLJS.Jj:c},a))};zaa=function(a){a=$CLJS.CL(function(b){return!$CLJS.PF(b,$CLJS.OG)},$CLJS.ff.g($CLJS.KI,a));return $CLJS.PF(a,$CLJS.ZI)?$CLJS.Qj:a};Aaa=function(a){return $CLJS.n($CLJS.Ne(function(b){return $CLJS.PF($CLJS.KI(b),$CLJS.OG)},a))?zaa(a):yaa(a)};
HL=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.IL=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);FL=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.at,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,FL],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.A(a);a=$CLJS.B(b);b=$CLJS.C(b);var c=$CLJS.KI(a);return $CLJS.Me(function(d){return DL(d,c)},b)}],null)],null));
$CLJS.X(HL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ws,new $CLJS.k(null,1,[$CLJS.On,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lJ],null)],null)],null));$CLJS.IK.g($CLJS.Os,GL($CLJS.Os));$CLJS.IK.g($CLJS.qu,GL($CLJS.qu));$CLJS.CJ($CLJS.Ps,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,HL],null)]));$CLJS.CJ($CLJS.DK,$CLJS.G([$CLJS.qu,$CLJS.NH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,HL],null)]));
for(var JL=$CLJS.A(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,$CLJS.qu,$CLJS.Ps],null)),KL=null,LL=0,ML=0;;)if(ML<LL){var Baa=KL.X(null,ML);$CLJS.tJ(Baa,$CLJS.IL);ML+=1}else{var NL=$CLJS.A(JL);if(NL){var OL=NL;if($CLJS.Bd(OL)){var PL=$CLJS.kc(OL),Caa=$CLJS.lc(OL),Daa=PL,Eaa=$CLJS.E(PL);JL=Caa;KL=Daa;LL=Eaa}else{var Faa=$CLJS.B(OL);$CLJS.tJ(Faa,$CLJS.IL);JL=$CLJS.C(OL);KL=null;LL=0}ML=0}else break}$CLJS.JI.m(null,$CLJS.IL,function(a){a=$CLJS.A(a);$CLJS.B(a);a=$CLJS.C(a);$CLJS.B(a);a=$CLJS.C(a);return Aaa(a)});
$CLJS.AJ($CLJS.KJ,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lJ],null)],null)]));$CLJS.tJ($CLJS.KJ,$CLJS.eJ);
for(var QL=$CLJS.A(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FK,$CLJS.DJ,$CLJS.WJ],null)),RL=null,SL=0,TL=0;;)if(TL<SL){var Gaa=RL.X(null,TL);$CLJS.AJ(Gaa,$CLJS.G([$CLJS.qu,$CLJS.NH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lJ],null)],null)]));TL+=1}else{var UL=$CLJS.A(QL);if(UL){var VL=UL;if($CLJS.Bd(VL)){var WL=$CLJS.kc(VL),Haa=$CLJS.lc(VL),Iaa=WL,Jaa=$CLJS.E(WL);QL=Haa;RL=Iaa;SL=Jaa}else{var Kaa=$CLJS.B(VL);$CLJS.AJ(Kaa,$CLJS.G([$CLJS.qu,$CLJS.NH,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lJ],null)],null)]));QL=$CLJS.C(VL);RL=null;SL=0}TL=0}else break}
for(var XL=$CLJS.A(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GJ,$CLJS.fK,$CLJS.mK],null)),YL=null,ZL=0,$L=0;;)if($L<ZL){var Laa=YL.X(null,$L);$CLJS.AJ(Laa,$CLJS.G([$CLJS.qu,$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lJ],null)],null)]));$L+=1}else{var aM=$CLJS.A(XL);if(aM){var bM=aM;if($CLJS.Bd(bM)){var cM=$CLJS.kc(bM),Maa=$CLJS.lc(bM),Naa=cM,Oaa=$CLJS.E(cM);XL=Maa;YL=Naa;ZL=Oaa}else{var Paa=$CLJS.B(bM);$CLJS.AJ(Paa,$CLJS.G([$CLJS.qu,$CLJS.sj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lJ],null)],null)]));XL=$CLJS.C(bM);YL=null;ZL=0}$L=0}else break}$CLJS.AJ($CLJS.HJ,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lJ],null)],null)]));
$CLJS.JI.m(null,$CLJS.HJ,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.PF($CLJS.KI(b),$CLJS.sj)&&$CLJS.PF($CLJS.KI(a),$CLJS.sj)?$CLJS.sj:$CLJS.NH});
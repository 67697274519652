var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var D_=function(a,b){var c=$CLJS.A(b);for(a=$CLJS.A($CLJS.Ye(a,b));;)if(a)c=$CLJS.C(c),a=$CLJS.C(a);else return c},Hfa=function(){return function(){function a(g,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij.j?$CLJS.ij.j(g,l,m):$CLJS.ij.call(null,g,l,m),$CLJS.el.j?$CLJS.el.j(g,l,m):$CLJS.el.call(null,g,l,m)],null)}function b(g,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij.g?$CLJS.ij.g(g,l):$CLJS.ij.call(null,g,l),$CLJS.el.g?$CLJS.el.g(g,l):$CLJS.el.call(null,g,l)],null)}function c(g){return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ij.h?$CLJS.ij.h(g):$CLJS.ij.call(null,g),$CLJS.el.h?$CLJS.el.h(g):$CLJS.el.call(null,g)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij.o?$CLJS.ij.o():$CLJS.ij.call(null),$CLJS.el.o?$CLJS.el.o():$CLJS.el.call(null)],null)}var e=null,f=function(){function g(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var z=Array(arguments.length-3);x<z.length;)z[x]=arguments[x+3],++x;x=new $CLJS.y(z,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.O.N($CLJS.ij,m,t,u,v),$CLJS.O.N($CLJS.el,m,t,u,v)],null)}g.A=3;g.B=function(m){var t=$CLJS.B(m);m=$CLJS.C(m);var u=$CLJS.B(m);m=$CLJS.C(m);var v=$CLJS.B(m);m=$CLJS.Lc(m);return l(t,u,v,m)};g.l=l;return g}();e=function(g,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,g);case 2:return b.call(this,g,l);case 3:return a.call(this,g,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+
3],++u;u=new $CLJS.y(v,0,null)}return f.l(g,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()},E_=function(a){return $CLJS.pP($CLJS.SZ)(a,$CLJS.Bf,$CLJS.Bf)},Ifa=function(a,b,c){return $CLJS.bb(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.XJ,b),$CLJS.ju(2,2,c))},Jfa=function(a){return $CLJS.cf.g($CLJS.wZ(a),$CLJS.xZ(a))},F_=function(a,b,c){var d=new $CLJS.dh(null,
new $CLJS.k(null,2,[$CLJS.Ot,null,$CLJS.kl,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.fw(2,c):$CLJS.fw(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?D_(2,c):D_(1,c);var f=$CLJS.H(b,0,null);return $CLJS.A(e)?$CLJS.lZ.j(a,e,function(g){var l=$CLJS.R.j(g,f,null);return $CLJS.Ad(g)?$CLJS.eg.j($CLJS.Bf,$CLJS.Dk.h($CLJS.Ra),l):$CLJS.yd(g)?$CLJS.dE($CLJS.Ua,l):l}):$CLJS.vk.g(a,f)},Lfa=function(a){return $CLJS.bb(function(b,c){var d=$CLJS.H(c,0,null);$CLJS.H(c,1,null);return F_(b,Kfa,d)},a,
Jfa(a))},Mfa=function(a){for(var b=0;;){var c=$CLJS.cd($CLJS.aP.h(a),b);a:{var d=c;for(var e=$CLJS.Bf;;){var f=$CLJS.B($CLJS.ff.g(Hfa(),$CLJS.jf($CLJS.Ck.j(G_,$CLJS.B,$CLJS.el),$CLJS.St.h(E_(d)))));if($CLJS.n(f)){var g=f;f=$CLJS.H(g,0,null);g=$CLJS.H(g,1,null);var l=F_(d,f,g),m=$CLJS.iE($CLJS.oC);if($CLJS.n($CLJS.hE("metabase.lib.convert",m))){var t=$CLJS.LE.l($CLJS.G([$CLJS.jD,$CLJS.Ph.l($CLJS.G([g]))])),u=$CLJS.LE,v=u.l,x=$CLJS.Ph,z=x.l;var D=f;D=$CLJS.n(D)?D:$CLJS.uP(E_(d));$CLJS.gE("metabase.lib.convert",
m,$CLJS.f_("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.G([t,v.call(u,$CLJS.G([$CLJS.jD,z.call(x,$CLJS.G([D]))])),$CLJS.LE.l($CLJS.G([$CLJS.ZC,$CLJS.g_($CLJS.B($CLJS.Lv(d,l)))]))])),null)}if($CLJS.F.g(l,d))break a;d=l;e=$CLJS.ae.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,g],null))}else break a}}d=Lfa(d);if($CLJS.F.g(c,d)){if($CLJS.F.g(b,$CLJS.E($CLJS.aP.h(a))-1))return a;b+=1}else a=$CLJS.Fk.N(a,$CLJS.aP,$CLJS.R,b,d)}},Nfa=function(a){var b=$CLJS.s_();return $CLJS.Ek.g(function(c){return $CLJS.F.g($CLJS.WI.h(c),
"__join")?$CLJS.Fk.j(c,$CLJS.WI,b):c},a)},Ofa=function(a){return"string"===typeof $CLJS.LS.h(a)?$CLJS.vk.g($CLJS.R.j(a,$CLJS.yZ,$CLJS.q_($CLJS.LS.h(a))),$CLJS.LS):a},I_=function(a,b){var c=$CLJS.I.j(a,b,H_);if($CLJS.F.g(c,H_))throw $CLJS.li(["Unable to find ",$CLJS.Ph.l($CLJS.G([b]))," in map."].join(""),new $CLJS.k(null,2,[Pfa,a,$CLJS.Vy,b],null));return c},J_=function(){return $CLJS.Dk.h(function(a){var b=$CLJS.H(a,0,null);$CLJS.H(a,1,null);(a=$CLJS.le(b))?(b=$CLJS.he(b),b=$CLJS.n(b)?$CLJS.F.g(b,
"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})},K_=function(a){return $CLJS.eg.j($CLJS.N,J_(),a)},L_=function(a){return $CLJS.He($CLJS.eg.j($CLJS.N,$CLJS.Ck.g(J_(),$CLJS.Dk.h(function(b){var c=$CLJS.H(b,0,null);$CLJS.H(b,1,null);return $CLJS.F.g(c,$CLJS.Hi)})),a))},N_=function(a){var b=$CLJS.A(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);var d=$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.ff.h($CLJS.M_),c);a=$CLJS.F.g(a,$CLJS.SJ)&&$CLJS.C(c)?$CLJS.ae.g($CLJS.td(d),
new $CLJS.k(null,1,[$CLJS.gi,$CLJS.sd(d)],null)):d;b=$CLJS.He(L_(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WP,a,b],null):a},Qfa=function(a){return $CLJS.eg.j($CLJS.Bf,$CLJS.Ck.g($CLJS.ff.h(function(b){return $CLJS.YD(b,$CLJS.PE)}),$CLJS.ff.h($CLJS.M_)),$CLJS.n_.h(a))},O_=function(a){a=$CLJS.Ge(a);a=$CLJS.I.g(a,$CLJS.aP);a=$CLJS.B($CLJS.bb(function(b,c){var d=$CLJS.H(b,0,null),e=$CLJS.H(b,1,null);b=$CLJS.Q;var f=$CLJS.M_.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.oU,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,
$CLJS.xS,Qfa(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.VY.h(c)],null)},null,a));return $CLJS.n($CLJS.PT.h(a))?$CLJS.iZ(a,new $CLJS.k(null,1,[$CLJS.PT,$CLJS.jQ],null)):a},P_=function(a,b,c){var d=$CLJS.F.g($CLJS.E($CLJS.I.g(a,b)),1)?$CLJS.mZ.j(a,b,$CLJS.Ck.g($CLJS.M_,$CLJS.B)):a;a=1<$CLJS.E($CLJS.I.g(a,b))?$CLJS.mZ.j(d,b,function(e){return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.st],null),$CLJS.ff.h($CLJS.M_),e)}):d;return $CLJS.iZ(a,$CLJS.Fe([b,c]))},Rfa=function(a){var b=$CLJS.yZ.h(a);
return $CLJS.n(b)?$CLJS.R.j($CLJS.vk.g(a,$CLJS.yZ),$CLJS.LS,["card__",$CLJS.p.h(b)].join("")):a},H_=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550),Kfa=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475),Q_=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672),R_=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",
1648102782),Pfa=new $CLJS.M(null,"m","m",1632677161),S_=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var T_,U_,G_,Sfa,Tfa,Ufa,Vfa,Wfa,Xfa,Yfa,Zfa,$fa,aga;T_=$CLJS.N;U_=$CLJS.N;G_=new $CLJS.dh(null,new $CLJS.k(null,7,[$CLJS.PZ,null,$CLJS.SI,null,$CLJS.gR,null,$CLJS.FT,null,$CLJS.mS,null,$CLJS.vV,null,$CLJS.XJ,null],null),null);Sfa=$CLJS.Se($CLJS.N);Tfa=$CLJS.Se($CLJS.N);Ufa=$CLJS.Se($CLJS.N);Vfa=$CLJS.Se($CLJS.N);Wfa=$CLJS.I.j(new $CLJS.k(null,1,[$CLJS.ej,$CLJS.CF],null),$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));
$CLJS.V_=new $CLJS.hi($CLJS.Ih.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.AF,Wfa,Sfa,Tfa,Ufa,Vfa);$CLJS.V_.m(null,$CLJS.gi,function(a){if($CLJS.Ad(a)&&$CLJS.B(a)instanceof $CLJS.M){var b=$CLJS.sd(a);var c=$CLJS.yd(b);a=c?$CLJS.td(a):a;var d=$CLJS.A(a);a=$CLJS.B(d);d=$CLJS.C(d);b=$CLJS.ZZ($CLJS.eg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.ff.h($CLJS.V_),d))}else b=a;return b});$CLJS.V_.m(null,$CLJS.OZ,function(a){return a});
$CLJS.V_.m(null,$CLJS.DZ,function(a){var b=$CLJS.V_.h($CLJS.XJ.h(a)),c=$CLJS.He($CLJS.Ek.g(function(g){var l=$CLJS.H(g,0,null);g=$CLJS.H(g,1,null);return $CLJS.k_($CLJS.V_.h(g),l)},$CLJS.FT.h(a))),d=U_;U_=$CLJS.eg.j($CLJS.N,$CLJS.Xl(function(g,l){$CLJS.H(l,0,null);l=$CLJS.H(l,1,null);l=$CLJS.Ge(l);l=$CLJS.I.g(l,$CLJS.yI);return new $CLJS.P(null,2,5,$CLJS.Q,[g,l],null)}),b);try{var e=Ifa(Ofa(a),b,$CLJS.G([$CLJS.FT,c])),f=$CLJS.bb(function(g,l){return $CLJS.Ta($CLJS.I.g(g,l))?g:$CLJS.Fk.j(g,l,$CLJS.V_)},
e,$CLJS.wk.l(G_,$CLJS.XJ,$CLJS.G([$CLJS.FT])));return $CLJS.n($CLJS.gR.h(f))?$CLJS.Fk.j(f,$CLJS.gR,Nfa):f}finally{U_=d}});$CLJS.V_.m(null,$CLJS.JZ,function(a){return $CLJS.mZ.v(a,$CLJS.lU,$CLJS.Lu,function(b){return $CLJS.mZ.j(b,$CLJS.KP,$CLJS.V_)})});
$CLJS.V_.m(null,$CLJS.WO,function(a){a=$CLJS.Fk.j($CLJS.Fk.j(a,$CLJS.dP,$CLJS.V_),$CLJS.aP,$CLJS.V_);var b=$CLJS.n($CLJS.SI.h(a))?$CLJS.Fk.j(a,$CLJS.SI,function(c){return $CLJS.Dl(c)?$CLJS.Ek.g($CLJS.V_,c):$CLJS.Ch.h(c)}):a;return $CLJS.Ta($CLJS.WI.h(a))?$CLJS.R.j(b,$CLJS.WI,"__join"):b});$CLJS.V_.m(null,$CLJS.wE,function(a){return $CLJS.Ek.g($CLJS.V_,a)});
$CLJS.V_.m(null,$CLJS.yE,function(a){return $CLJS.n($CLJS.ij.h(a))?Mfa($CLJS.Fk.j($CLJS.o_(a),$CLJS.aP,function(b){return $CLJS.Ek.g($CLJS.V_,b)})):$CLJS.Lu(a,$CLJS.V_)});$CLJS.V_.m(null,$CLJS.hK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=$CLJS.yd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return $CLJS.ZZ(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hK,a,b],null))});
$CLJS.V_.m(null,$CLJS.zj,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=$CLJS.iZ(a,new $CLJS.k(null,3,[$CLJS.RG,$CLJS.CE,$CLJS.iS,$CLJS.Pj,$CLJS.DV,$CLJS.uI],null));var c=$CLJS.R.j;var d=$CLJS.Hi.h(a);$CLJS.n(d)||(d=$CLJS.CE.h(a),d=$CLJS.n(d)?d:$CLJS.KI(b));a=c.call($CLJS.R,a,$CLJS.Hi,d);return $CLJS.ZZ(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,a,b],null))});
$CLJS.V_.m(null,$CLJS.SJ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.gi.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SJ,$CLJS.vk.g(c,$CLJS.gi),$CLJS.Ek.g($CLJS.V_,b)],null);b=$CLJS.ZZ(b);return null!=a?$CLJS.ae.g(b,$CLJS.V_.h(a)):b});$CLJS.V_.m(null,$CLJS.zE,function(a){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.ZZ(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.V_.m(null,$CLJS.XJ,function(a){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.ZZ(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,I_(U_,c)],null))});$CLJS.V_.m(null,$CLJS.WP,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);b=$CLJS.V_.h(b);var c=$CLJS.A(b);b=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);d=$CLJS.C(d);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.Uk.l($CLJS.G([c,a]))],null),d)});Xfa=$CLJS.Se($CLJS.N);Yfa=$CLJS.Se($CLJS.N);Zfa=$CLJS.Se($CLJS.N);
$fa=$CLJS.Se($CLJS.N);aga=$CLJS.I.j(new $CLJS.k(null,1,[$CLJS.ej,$CLJS.CF],null),$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));$CLJS.M_=new $CLJS.hi($CLJS.Ih.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.AF,aga,Xfa,Yfa,Zfa,$fa);
$CLJS.M_.m(null,$CLJS.gi,function(a){if($CLJS.Ad(a)&&$CLJS.B(a)instanceof $CLJS.M){a=$CLJS.A(a);var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);if($CLJS.yd(a)){c=$CLJS.eg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.cf.g;d=$CLJS.ff.g($CLJS.M_,d);a=L_(a);a=c.call($CLJS.eg,b,e.call($CLJS.cf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.ff.g($CLJS.M_,$CLJS.de(a,d)))}else a=$CLJS.yd(a)?$CLJS.Lu(K_(a),$CLJS.M_):
a;return a});for(var W_=$CLJS.A(new $CLJS.P(null,2,5,$CLJS.Q,[S_,R_],null)),X_=null,Y_=0,Z_=0;;)if(Z_<Y_){var bga=X_.X(null,Z_);$CLJS.tJ(bga,Q_);Z_+=1}else{var $_=$CLJS.A(W_);if($_){var a0=$_;if($CLJS.Bd(a0)){var b0=$CLJS.kc(a0),cga=$CLJS.lc(a0),dga=b0,ega=$CLJS.E(b0);W_=cga;X_=dga;Y_=ega}else{var fga=$CLJS.B(a0);$CLJS.tJ(fga,Q_);W_=$CLJS.C(a0);X_=null;Y_=0}Z_=0}else break}
for(var c0=$CLJS.A(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.TA,$CLJS.bK,$CLJS.qK,$CLJS.OJ,$CLJS.ck,$CLJS.vK,$CLJS.On,$CLJS.UJ,$CLJS.oK,$CLJS.yK,$CLJS.uK,$CLJS.CK],null)),d0=null,e0=0,f0=0;;)if(f0<e0){var gga=d0.X(null,f0);$CLJS.tJ(gga,S_);f0+=1}else{var g0=$CLJS.A(c0);if(g0){var h0=g0;if($CLJS.Bd(h0)){var i0=$CLJS.kc(h0),hga=$CLJS.lc(h0),iga=i0,jga=$CLJS.E(i0);c0=hga;d0=iga;e0=jga}else{var kga=$CLJS.B(h0);$CLJS.tJ(kga,S_);c0=$CLJS.C(h0);d0=null;e0=0}f0=0}else break}
for(var j0=$CLJS.A($CLJS.xf([$CLJS.Os,$CLJS.qu,$CLJS.Ps,$CLJS.DK,$CLJS.SJ,$CLJS.kK,$CLJS.KJ,$CLJS.FK,$CLJS.DJ,$CLJS.WJ,$CLJS.GJ,$CLJS.fK,$CLJS.mK,$CLJS.HJ,$CLJS.$M,$CLJS.bN,$CLJS.pC,$CLJS.WM,$CLJS.dN,$CLJS.QM,$CLJS.hN,$CLJS.VM,$CLJS.OM,$CLJS.gN,$CLJS.oN,$CLJS.kN,$CLJS.UM,$CLJS.rN,$CLJS.jN,$CLJS.pN,$CLJS.JJ,$CLJS.EJ,$CLJS.GK,$CLJS.hM,$CLJS.jK,$CLJS.KA,$CLJS.zK,$CLJS.BK,$CLJS.HK,$CLJS.Qu,$CLJS.ev],!0)),k0=null,l0=0,m0=0;;)if(m0<l0){var lga=k0.X(null,m0);$CLJS.tJ(lga,R_);m0+=1}else{var n0=$CLJS.A(j0);
if(n0){var o0=n0;if($CLJS.Bd(o0)){var p0=$CLJS.kc(o0),mga=$CLJS.lc(o0),nga=p0,oga=$CLJS.E(p0);j0=mga;k0=nga;l0=oga}else{var pga=$CLJS.B(o0);$CLJS.tJ(pga,R_);j0=$CLJS.C(o0);k0=null;l0=0}m0=0}else break}$CLJS.M_.m(null,Q_,function(a){return N_(a)});$CLJS.M_.m(null,$CLJS.yE,function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Ck.g(J_(),$CLJS.ff.h(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.M_.h(b)],null)})),a)});
$CLJS.M_.m(null,$CLJS.XJ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);if($CLJS.yd(b))try{var d=L_(b),e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XJ,I_(T_,c)],null);return $CLJS.n(d)?$CLJS.ae.g(e,d):e}catch(f){throw b=f,c=$CLJS.bZ(b),c=$CLJS.ha.g?$CLJS.ha.g("Error converting aggregation reference to pMBQL: %s",c):$CLJS.ha.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.ki(c,new $CLJS.k(null,1,[$CLJS.W,a],null),b);}else return a});
$CLJS.M_.m(null,$CLJS.wE,function(a){return $CLJS.Ek.g($CLJS.M_,a)});$CLJS.M_.m(null,$CLJS.hK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=null==b||$CLJS.yd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hK,$CLJS.M_.h(a),L_(b)],null)});
$CLJS.M_.m(null,$CLJS.zj,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);b=L_($CLJS.iZ(b,new $CLJS.k(null,3,[$CLJS.CE,$CLJS.RG,$CLJS.Pj,$CLJS.iS,$CLJS.uI,$CLJS.DV],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,a,b],null)});
$CLJS.M_.m(null,$CLJS.WO,function(a){var b=K_(a);a=0==$CLJS.WI.h(a).lastIndexOf("__join",0)?$CLJS.vk.g(b,$CLJS.WI):b;return $CLJS.Uk.l($CLJS.G([$CLJS.Lu($CLJS.vk.l(a,$CLJS.aP,$CLJS.G([$CLJS.dP])),$CLJS.M_),P_($CLJS.bh(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dP],null)),$CLJS.dP,$CLJS.xV),O_(a)]))});
$CLJS.M_.m(null,$CLJS.DZ,function(a){var b=T_;T_=$CLJS.eg.j($CLJS.N,$CLJS.Xl(function(c,d){$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);d=$CLJS.Ge(d);d=$CLJS.I.g(d,$CLJS.yI);return new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null)}),$CLJS.XJ.h(a));try{return $CLJS.bb(function(c,d){return $CLJS.mZ.j(c,d,$CLJS.M_)},P_($CLJS.mZ.j($CLJS.mZ.j(Rfa(K_(a)),$CLJS.XJ,function(c){return $CLJS.Ek.g(N_,c)}),$CLJS.FT,function(c){return $CLJS.eg.g($CLJS.N,function(){return function f(e){return new $CLJS.me(null,function(){for(;;){var g=
$CLJS.A(e);if(g){if($CLJS.Bd(g)){var l=$CLJS.kc(g),m=$CLJS.E(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.md(l,u),x=$CLJS.M_.h(v);v=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.j_(v),$CLJS.F.g($CLJS.zj,$CLJS.B(x))?$CLJS.jd(x):x],null);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.lc(g))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.B(g);l=$CLJS.M_.h(t);return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.j_(t),$CLJS.F.g($CLJS.zj,$CLJS.B(l))?$CLJS.jd(l):l],null),f($CLJS.Lc(g)))}return null}},
null,null)}(c)}())}),$CLJS.PZ,$CLJS.KQ),$CLJS.wk.l(G_,$CLJS.XJ,$CLJS.G([$CLJS.PZ,$CLJS.FT])))}finally{T_=b}});$CLJS.M_.m(null,$CLJS.JZ,function(a){return $CLJS.Lu(K_(a),$CLJS.M_)});
$CLJS.M_.m(null,$CLJS.OZ,function(a){try{var b=K_(a),c=$CLJS.XQ.h(b),d=O_(b),e=$CLJS.F.g($CLJS.Aj.h($CLJS.kd($CLJS.aP.h(a))),$CLJS.JZ)?$CLJS.PT:$CLJS.jQ;return $CLJS.Uk.l($CLJS.G([$CLJS.Lu($CLJS.vk.l(b,$CLJS.aP,$CLJS.G([$CLJS.XQ])),$CLJS.M_),function(){var g=$CLJS.Fe([$CLJS.ij,e,e,d]);return $CLJS.A(c)?$CLJS.R.j(g,$CLJS.XQ,c):g}()]))}catch(g){var f=g;throw $CLJS.ki(function(){var l=$CLJS.bZ(f);return $CLJS.ha.g?$CLJS.ha.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.ha.call(null,"Error converting MLv2 query to legacy query: %s",
l)}(),new $CLJS.k(null,1,[$CLJS.jQ,a],null),f);}});
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.js");
'use strict';var l3,p3,Bha,Cha,q3,r3,Dha,Eha,s3,t3;$CLJS.k3=function(a,b){a=$CLJS.s2(a,b);return $CLJS.n(a)?$CLJS.cd(b,a):null};
l3=function(a){return $CLJS.n($CLJS.P2.g?$CLJS.P2.g($CLJS.I2,a):$CLJS.P2.call(null,$CLJS.I2,a))?$CLJS.$2:$CLJS.n($CLJS.P2.g?$CLJS.P2.g($CLJS.F2,a):$CLJS.P2.call(null,$CLJS.F2,a))?$CLJS.$2:$CLJS.n($CLJS.P2.g?$CLJS.P2.g($CLJS.C2,a):$CLJS.P2.call(null,$CLJS.C2,a))?$CLJS.lha:$CLJS.n($CLJS.P2.g?$CLJS.P2.g($CLJS.L2,a):$CLJS.P2.call(null,$CLJS.L2,a))?$CLJS.mha:$CLJS.n($CLJS.P2.g?$CLJS.P2.g($CLJS.K2,a):$CLJS.P2.call(null,$CLJS.K2,a))?$CLJS.nha:$CLJS.n($CLJS.P2.g?$CLJS.P2.g($CLJS.B2,a):$CLJS.P2.call(null,
$CLJS.B2,a))?$CLJS.oha:$CLJS.n($CLJS.P2.g?$CLJS.P2.g($CLJS.z2,a):$CLJS.P2.call(null,$CLJS.z2,a))?$CLJS.pha:$CLJS.n($CLJS.P2.g?$CLJS.P2.g($CLJS.A2,a):$CLJS.P2.call(null,$CLJS.A2,a))?$CLJS.qha:$CLJS.n($CLJS.P2.g?$CLJS.P2.g($CLJS.G2,a):$CLJS.P2.call(null,$CLJS.G2,a))?$CLJS.rha:$CLJS.sha};$CLJS.m3=function(a){if("number"===typeof a)return a;switch(a instanceof $CLJS.M?a.T:null){case "current":return 0;case "next":return 1;case "last":return-1;default:return 0}};
$CLJS.n3=function(a,b){a=$CLJS.m3(a);b=$CLJS.n(b)?b:$CLJS.Gv;return 0===a?$CLJS.F.g(b,$CLJS.Gv)?$CLJS.HI("Today"):$CLJS.GI("This {0}",$CLJS.G([$CLJS.e3.h(b)])):$CLJS.F.g(a,1)?$CLJS.F.g(b,$CLJS.Gv)?$CLJS.HI("Tomorrow"):$CLJS.GI("Next {0}",$CLJS.G([$CLJS.e3.h(b)])):$CLJS.F.g(a,-1)?$CLJS.F.g(b,$CLJS.Gv)?$CLJS.HI("Yesterday"):$CLJS.GI("Previous {0}",$CLJS.G([$CLJS.e3.h(b)])):0>a?$CLJS.GI("Previous {0} {1}",$CLJS.G([Math.abs(a),$CLJS.e3.g(Math.abs(a),b)])):0<a?$CLJS.GI("Next {0} {1}",$CLJS.G([a,$CLJS.e3.g(a,
b)])):null};p3=function(a){var b=l3(a);b=$CLJS.He(b);return null==b?a:$CLJS.R.j(a,$CLJS.o3,b)};Bha=new $CLJS.M(null,"legacy-filter","legacy-filter",-948552546);Cha=new $CLJS.M(null,"query-filters","query-filters",409521440);q3=new $CLJS.M("metabase.lib.filter","unary","metabase.lib.filter/unary",-336199667);r3=new $CLJS.M("metabase.lib.filter","varargs","metabase.lib.filter/varargs",1417033079);Dha=new $CLJS.M(null,"matching-filters","matching-filters",-326129659);
Eha=new $CLJS.M("mbql","filter-parts","mbql/filter-parts",893592485);$CLJS.o3=new $CLJS.M(null,"operators","operators",-2064102509);s3=new $CLJS.M("metabase.lib.filter","compound","metabase.lib.filter/compound",1924103780);t3=new $CLJS.M("metabase.lib.filter","binary","metabase.lib.filter/binary",-1921360491);for(var u3=$CLJS.A(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.st,$CLJS.lt],null)),v3=null,w3=0,x3=0;;)if(x3<w3){var Fha=v3.X(null,x3);$CLJS.tJ(Fha,s3);x3+=1}else{var y3=$CLJS.A(u3);if(y3){var z3=y3;if($CLJS.Bd(z3)){var A3=$CLJS.kc(z3),Gha=$CLJS.lc(z3),Hha=A3,Iha=$CLJS.E(A3);u3=Gha;v3=Hha;w3=Iha}else{var Jha=$CLJS.B(z3);$CLJS.tJ(Jha,s3);u3=$CLJS.C(z3);v3=null;w3=0}x3=0}else break}
for(var B3=$CLJS.A(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.AK],null)),C3=null,D3=0,E3=0;;)if(E3<D3){var Kha=C3.X(null,E3);$CLJS.tJ(Kha,r3);E3+=1}else{var F3=$CLJS.A(B3);if(F3){var G3=F3;if($CLJS.Bd(G3)){var H3=$CLJS.kc(G3),Lha=$CLJS.lc(G3),Mha=H3,Nha=$CLJS.E(H3);B3=Lha;C3=Mha;D3=Nha}else{var Oha=$CLJS.B(G3);$CLJS.tJ(Oha,r3);B3=$CLJS.C(G3);C3=null;D3=0}E3=0}else break}
for(var I3=$CLJS.A(new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Is,$CLJS.Ks,$CLJS.Es,$CLJS.Gs,$CLJS.ZJ,$CLJS.sK,$CLJS.gK,$CLJS.$J],null)),J3=null,K3=0,L3=0;;)if(L3<K3){var Pha=J3.X(null,L3);$CLJS.tJ(Pha,t3);L3+=1}else{var M3=$CLJS.A(I3);if(M3){var N3=M3;if($CLJS.Bd(N3)){var O3=$CLJS.kc(N3),Qha=$CLJS.lc(N3),Rha=O3,Sha=$CLJS.E(O3);I3=Qha;J3=Rha;K3=Sha}else{var Tha=$CLJS.B(N3);$CLJS.tJ(Tha,t3);I3=$CLJS.C(N3);J3=null;K3=0}L3=0}else break}
for(var P3=$CLJS.A(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.LJ,$CLJS.VJ,$CLJS.NJ,$CLJS.TJ,$CLJS.nt],null)),Q3=null,R3=0,S3=0;;)if(S3<R3){var Uha=Q3.X(null,S3);$CLJS.tJ(Uha,q3);S3+=1}else{var T3=$CLJS.A(P3);if(T3){var U3=T3;if($CLJS.Bd(U3)){var V3=$CLJS.kc(U3),Vha=$CLJS.lc(U3),Wha=V3,Xha=$CLJS.E(V3);P3=Vha;Q3=Wha;R3=Xha}else{var Yha=$CLJS.B(U3);$CLJS.tJ(Yha,q3);P3=$CLJS.C(U3);Q3=null;R3=0}S3=0}else break}
$CLJS.t1.m(null,$CLJS.PZ,function(a,b){var c=$CLJS.He($CLJS.PZ.h($CLJS.q0(a,b)));return $CLJS.n(c)?$CLJS.GI("Filtered by {0}",$CLJS.G([$CLJS.c3($CLJS.HI("and"),function(){return function f(e){return new $CLJS.me(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.Bd(g)){var l=$CLJS.kc(g),m=$CLJS.E(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.md(l,u);v=$CLJS.V0.v(a,b,v,$CLJS.W0);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.lc(g))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.B(g);
return $CLJS.de($CLJS.V0.v(a,b,t,$CLJS.W0),f($CLJS.Lc(g)))}return null}},null,null)}(c)}())])):null});
$CLJS.q1.m(null,s3,function(a,b,c,d){c=$CLJS.A(c);var e=$CLJS.B(c);c=$CLJS.C(c);$CLJS.B(c);var f=$CLJS.C(c);return $CLJS.c3(function(){var g=e instanceof $CLJS.M?e.T:null;switch(g){case "and":return $CLJS.HI("and");case "or":return $CLJS.HI("or");default:throw Error(["No matching clause: ",$CLJS.p.h(g)].join(""));}}(),function(){return function m(l){return new $CLJS.me(null,function(){for(;;){var t=$CLJS.A(l);if(t){if($CLJS.Bd(t)){var u=$CLJS.kc(t),v=$CLJS.E(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<
v){var D=$CLJS.md(u,z);D=$CLJS.V0.v(a,b,D,d);x.add(D);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),m($CLJS.lc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.B(t);return $CLJS.de($CLJS.V0.v(a,b,x,d),m($CLJS.Lc(t)))}return null}},null,null)}(f)}())});
$CLJS.q1.m(null,r3,function(a,b,c,d){var e=$CLJS.A(c);c=$CLJS.B(e);e=$CLJS.C(e);$CLJS.B(e);var f=$CLJS.C(e);e=$CLJS.ff.g(function(g){return $CLJS.V0.v(a,b,g,d)},f);if($CLJS.F.g($CLJS.E(f),2))switch(f=$CLJS.H(e,0,null),e=$CLJS.H(e,1,null),c=c instanceof $CLJS.M?c.T:null,c){case "\x3d":return $CLJS.GI("{0} equals {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.GI("{0} does not equal {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}else switch(f=$CLJS.B(e),e=$CLJS.c3($CLJS.HI("or"),
$CLJS.Lc(e)),c=c instanceof $CLJS.M?c.T:null,c){case "\x3d":return $CLJS.GI("{0} equals any of {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.GI("{0} does not equal any of {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.q1.m(null,t3,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);var f=$CLJS.H(c,2,null);c=$CLJS.H(c,3,null);f=$CLJS.V0.v(a,b,f,d);a=$CLJS.V0.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "\x3c":return $CLJS.GI("{0} is less than {1}",$CLJS.G([f,a]));case "\x3c\x3d":return $CLJS.GI("{0} is less than or equal to {1}",$CLJS.G([f,a]));case "\x3e":return $CLJS.GI("{0} is greater than {1}",$CLJS.G([f,a]));case "\x3e\x3d":return $CLJS.GI("{0} is greater than or equal to {1}",$CLJS.G([f,
a]));case "starts-with":return $CLJS.GI("{0} starts with {1}",$CLJS.G([f,a]));case "ends-with":return $CLJS.GI("{0} ends with {1}",$CLJS.G([f,a]));case "contains":return $CLJS.GI("{0} contains {1}",$CLJS.G([f,a]));case "does-not-contain":return $CLJS.GI("{0} does not contain {1}",$CLJS.G([f,a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});
$CLJS.q1.m(null,$CLJS.MJ,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null),f=$CLJS.H(c,3,null);c=$CLJS.H(c,4,null);return $CLJS.GI("{0} is between {1} and {2}",$CLJS.G([$CLJS.V0.v(a,b,e,d),$CLJS.V0.v(a,b,f,d),$CLJS.V0.v(a,b,c,d)]))});
$CLJS.q1.m(null,$CLJS.RJ,function(a,b,c,d){$CLJS.H(c,0,null);var e=$CLJS.H(c,1,null),f=$CLJS.H(c,2,null),g=$CLJS.H(c,3,null),l=$CLJS.H(c,4,null),m=$CLJS.H(c,5,null),t=$CLJS.H(c,6,null);c=$CLJS.H(c,7,null);return $CLJS.V0.v(a,b,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.st,e,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.MJ,e,f,t,l],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.MJ,e,g,m,c],null)],null),d)});
$CLJS.q1.m(null,q3,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);a=$CLJS.V0.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "is-null":return $CLJS.GI("{0} is empty",$CLJS.G([a]));case "not-null":return $CLJS.GI("{0} is not empty",$CLJS.G([a]));case "is-empty":return $CLJS.GI("{0} is empty",$CLJS.G([a]));case "not-empty":return $CLJS.GI("{0} is not empty",$CLJS.G([a]));case "not":return $CLJS.GI("not {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",
$CLJS.p.h(e)].join(""));}});$CLJS.q1.m(null,$CLJS.tK,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null),f=$CLJS.H(c,3,null);c=$CLJS.H(c,4,null);return $CLJS.GI("{0} is within {1}",$CLJS.G([$CLJS.V0.v(a,b,e,d),$CLJS.n3(f,c).toLowerCase()]))});
$CLJS.Zha=function(){function a(d,e,f){for(;;)if($CLJS.F.g($CLJS.AF(f),$CLJS.u_))f=$CLJS.$Z(f);else return e=$CLJS.n(e)?e:-1,f=$CLJS.c_.h(f),$CLJS.B_.l(d,e,$CLJS.Fk,$CLJS.G([$CLJS.PZ,$CLJS.XH($CLJS.ae,$CLJS.Bf),f]))}function b(d,e){return c.j?c.j(d,null,e):c.call(null,d,null,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.W3=function(){function a(d,e){return $CLJS.He($CLJS.PZ.h($CLJS.q0(d,$CLJS.n(e)?e:-1)))}function b(d){return c.g?c.g(d,null):c.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.$ha=function(){function a(d,e){var f=$CLJS.q0(d,e);return $CLJS.He($CLJS.eg.j($CLJS.Bf,$CLJS.Ck.g($CLJS.ff.h(p3),$CLJS.hf($CLJS.o3)),$CLJS.C1.j(d,e,f)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.X3=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var g=Array(arguments.length-2);f<g.length;)g[f]=arguments[f+2],++f;f=new $CLJS.y(g,0,null)}return b.call(this,c,d,f)}function b(c,d,e){return $CLJS.ZZ($CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aK.h(c),$CLJS.N,$CLJS.c_.h(d)],null),$CLJS.ff.g($CLJS.c_,e)))}a.A=2;a.B=function(c){var d=$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.Lc(c);return b(d,e,c)};a.l=b;return a}();
$CLJS.aia=function(){function a(d,e,f){var g=$CLJS.H(f,0,null);$CLJS.H(f,1,null);f=$CLJS.H(f,2,null);var l=$CLJS.q0(d,e);d=$CLJS.C1.j(d,e,l);d=$CLJS.k3(f,d);d=$CLJS.CL(function(m){return $CLJS.F.g($CLJS.aK.h(m),g)},l3(d));return $CLJS.n(d)?d:$CLJS.Z2.h(g)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.bia=function(){function a(d,e,f){d=$CLJS.Cf($CLJS.W3.g(d,e));e=$CLJS.jf(function(g){return $CLJS.F.g($CLJS.M_.h(g),f)},d);if($CLJS.A(e)){if($CLJS.C(e))throw $CLJS.li("Multiple matching filters found",new $CLJS.k(null,3,[Bha,f,Cha,d,Dha,e],null));return $CLJS.B(e)}return null}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();
$CLJS.cia=function(){function a(d,e,f){f=$CLJS.A(f);var g=$CLJS.B(f),l=$CLJS.C(f);f=$CLJS.B(l);var m=$CLJS.C(l);l=$CLJS.B(m);m=$CLJS.C(m);var t=$CLJS.q0(d,e);d=$CLJS.C1.j(d,e,t);var u=$CLJS.k3(l,d);return new $CLJS.k(null,5,[$CLJS.Aj,Eha,$CLJS.vI,function(){var v=$CLJS.CL(function(x){return $CLJS.F.g($CLJS.aK.h(x),g)},l3(u));return $CLJS.n(v)?v:$CLJS.Z2.h(g)}(),$CLJS.cj,f,$CLJS.Si,null==u?null:p3(u),$CLJS.Xt,$CLJS.Cf(m)],null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;
c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.util.malli.registry.js");
'use strict';var wM,xM,cba,dba,eba,AM,BM,CM,EM,fba,GM;wM=function(a){switch(arguments.length){case 2:return $CLJS.sJ(arguments[0],arguments[1]);case 3:return $CLJS.rJ(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};xM=function(a){return["(?:",$CLJS.p.h($CLJS.O.g($CLJS.p,a)),")?"].join("")};cba=new $CLJS.M("metabase.lib.schema.literal","temporal","metabase.lib.schema.literal/temporal",1227892305);
dba=new $CLJS.M("metabase.lib.schema.literal","non-integer-real","metabase.lib.schema.literal/non-integer-real",1396696695);$CLJS.yM=new $CLJS.M("metabase.lib.schema.literal","string.year","metabase.lib.schema.literal/string.year",-936788792);eba=new $CLJS.M("metabase.lib.schema.literal","string","metabase.lib.schema.literal/string",-1434211409);$CLJS.zM=new $CLJS.M("metabase.lib.schema.literal","time","metabase.lib.schema.literal/time",1928421901);
AM=new $CLJS.M("metabase.lib.schema.literal","string.time","metabase.lib.schema.literal/string.time",1501090167);BM=new $CLJS.M("metabase.lib.schema.literal","string.date","metabase.lib.schema.literal/string.date",1716358402);CM=new $CLJS.M("metabase.lib.schema.literal","string.datetime","metabase.lib.schema.literal/string.datetime",-1597850940);$CLJS.DM=new $CLJS.M("metabase.lib.schema.literal","date","metabase.lib.schema.literal/date",-906463835);
EM=new $CLJS.M("metabase.lib.schema.literal","value.options","metabase.lib.schema.literal/value.options",-1918568845);fba=new $CLJS.M("metabase.lib.schema.literal","integer","metabase.lib.schema.literal/integer",-1120986283);$CLJS.FM=new $CLJS.M("metabase.lib.schema.literal","string.year-month","metabase.lib.schema.literal/string.year-month",-1014149016);GM=new $CLJS.M("metabase.lib.schema.literal","boolean","metabase.lib.schema.literal/boolean",1793502815);
$CLJS.HM=new $CLJS.M("metabase.lib.schema.literal","datetime","metabase.lib.schema.literal/datetime",985830761);$CLJS.JI.m(null,$CLJS.GE,function(){return $CLJS.wj});$CLJS.X(GM,$CLJS.Ct);$CLJS.JI.m(null,$CLJS.JE,function(){return $CLJS.Fj});$CLJS.X(GM,$CLJS.Ct);$CLJS.X(fba,$CLJS.yt);$CLJS.JI.m(null,$CLJS.uE,function(){return $CLJS.sj});$CLJS.X(dba,$CLJS.xt);$CLJS.JI.m(null,$CLJS.sE,function(){return $CLJS.NH});$CLJS.X(eba,$CLJS.Tj);
var gba=[":\\d{2}",xM($CLJS.G(["\\.\\d{1,6}"]))].join(""),IM=["\\d{2}:\\d{2}",xM($CLJS.G([gba]))].join(""),JM=["\\d{4}-\\d{2}-\\d{2}T",IM].join(""),KM=["(?:Z|(?:[+-]",IM,"))"].join(""),hba=$CLJS.sh("^\\d{4}-\\d{2}-\\d{2}$"),iba=$CLJS.sh(["^",IM,"$"].join("")),jba=$CLJS.sh(["^",IM,KM,"$"].join("")),kba=$CLJS.sh(["^",JM,"$"].join("")),lba=$CLJS.sh(["^",JM,KM,"$"].join(""));$CLJS.X(BM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.k(null,1,[$CLJS.Au,"date string literal"],null),hba],null));
$CLJS.X(AM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.k(null,1,[$CLJS.Au,"local time string literal"],null),iba],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.k(null,1,[$CLJS.Au,"offset time string literal"],null),jba],null)],null));
$CLJS.X(CM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.k(null,1,[$CLJS.Au,"local date time string literal"],null),kba],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.k(null,1,[$CLJS.Au,"offset date time string literal"],null),lba],null)],null));
$CLJS.JI.m(null,$CLJS.tE,function(a){return $CLJS.n($CLJS.sJ?$CLJS.sJ(CM,a):wM.call(null,CM,a))?new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.Xj,null,$CLJS.dH,null],null),null):$CLJS.n($CLJS.sJ?$CLJS.sJ(BM,a):wM.call(null,BM,a))?new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.vG,null,$CLJS.Xj,null],null),null):$CLJS.n($CLJS.sJ?$CLJS.sJ(AM,a):wM.call(null,AM,a))?new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.TH,null,$CLJS.Xj,null],null),null):$CLJS.Xj});$CLJS.X($CLJS.DM,BM);$CLJS.X($CLJS.zM,AM);
$CLJS.X($CLJS.HM,CM);$CLJS.X(cba,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lt,$CLJS.DM,$CLJS.zM,$CLJS.HM],null));$CLJS.LM=$CLJS.sh("^\\d{4}-\\d{2}$");$CLJS.X($CLJS.FM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.k(null,1,[$CLJS.Au,"year-month string literal"],null),$CLJS.LM],null));$CLJS.MM=$CLJS.sh("^\\d{4}$");$CLJS.X($CLJS.yM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.k(null,1,[$CLJS.Au,"year string literal"],null),$CLJS.MM],null));
$CLJS.X(EM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hi,$CLJS.AI],null)],null)],null));$CLJS.IK.g($CLJS.zj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ot,new $CLJS.k(null,1,[$CLJS.Au,"Value :value clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.zj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EM],null),$CLJS.ul],null));
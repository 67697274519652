var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var Fga,M0,Gga,P0,Q0,f1,Hga,Iga,j1,Z0,l1,Jga,a1;Fga=function(a){return $CLJS.A(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};M0=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Id($CLJS.RE,a)?a:"_":$CLJS.n(b)?encodeURIComponent(a):a};
Gga=function(a){var b=$CLJS.Ge(new $CLJS.k(null,1,[$CLJS.FE,!0],null)),c=$CLJS.I.g(b,$CLJS.DE),d=$CLJS.I.g(b,$CLJS.FE);return $CLJS.A(a)?(b=$CLJS.fZ(function(){return function g(f){return new $CLJS.me(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.Bd(l)){var m=$CLJS.kc(l),t=$CLJS.E(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.md(m,v);x=M0(x,$CLJS.Ta(d));u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),g($CLJS.lc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.B(l);return $CLJS.de(M0(u,
$CLJS.Ta(d)),g($CLJS.Lc(l)))}return null}},null,null)}(Fga($CLJS.kE(a)))}()),$CLJS.n(c)?$CLJS.fZ($CLJS.Xe(c,b)):b):null};$CLJS.N0=function(a){return $CLJS.LS.h($CLJS.B($CLJS.aP.h(a)))};$CLJS.O0=function(a){if(null!=a&&null!=a.Zf)a=a.Zf(a);else{var b=$CLJS.O0[$CLJS.xa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=$CLJS.O0._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.Za("MetadataProvider.database",a);}return a};
P0=function(a,b){if(null!=a&&null!=a.fg)a=a.fg(a,b);else{var c=P0[$CLJS.xa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=P0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Za("MetadataProvider.table",a);}return a};Q0=function(a,b){if(null!=a&&null!=a.Yf)a=a.Yf(a,b);else{var c=Q0[$CLJS.xa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=Q0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Za("MetadataProvider.card",a);}return a};
$CLJS.R0=function(a){return $CLJS.s0(a)?a:$CLJS.UY.h(a)};$CLJS.S0=function(a,b){return P0($CLJS.R0(a),b)};$CLJS.T0=function(a,b){return Q0($CLJS.R0(a),b)};
f1=function(a,b,c){var d=$CLJS.U0.j(a,b,c);return $CLJS.Uk.l($CLJS.G([$CLJS.bh(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.S,$CLJS.nI,$CLJS.Pj],null)),function(){var e=$CLJS.V0.v(a,b,c,$CLJS.W0);return $CLJS.n(e)?new $CLJS.k(null,1,[$CLJS.X0,e],null):null}(),function(){var e=$CLJS.WD($CLJS.Hi,$CLJS.CE)(d);return $CLJS.n(e)?new $CLJS.k(null,1,[$CLJS.Hi,e],null):null}(),function(){var e=$CLJS.w_.h(d);return $CLJS.n(e)?new $CLJS.k(null,1,[$CLJS.y_,$CLJS.Y0.j(a,b,$CLJS.Gd(e)?$CLJS.S0(a,e):"string"===typeof e?
$CLJS.T0(a,$CLJS.q_(e)):null)],null):null}(),function(){var e=$CLJS.t_.h(d);return $CLJS.n(e)?new $CLJS.k(null,4,[Z0,$CLJS.F.g(e,$CLJS.u0),$CLJS.$0,$CLJS.F.g(e,$CLJS.A0),a1,$CLJS.F.g(e,$CLJS.x0),$CLJS.b1,$CLJS.F.g(e,$CLJS.z0)],null):null}(),function(){var e=$CLJS.D0.h(d);return null==e?null:new $CLJS.k(null,1,[$CLJS.c1,e],null)}(),$CLJS.bh(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.d1,$CLJS.e1],null))]))};$CLJS.g1=new $CLJS.M(null,"direction","direction",-633359395);
$CLJS.b1=new $CLJS.M(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);$CLJS.d1=new $CLJS.M(null,"breakout-position","breakout-position",-760153191);$CLJS.h1=new $CLJS.M(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);$CLJS.c1=new $CLJS.M(null,"selected","selected",574897764);Hga=new $CLJS.M(null,"is-order-by-column","is-order-by-column",1320802549);
Iga=new $CLJS.w("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);$CLJS.i1=new $CLJS.M(null,"stage-number","stage-number",-1752729638);j1=new $CLJS.M("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.k1=new $CLJS.M(null,"include-joined?","include-joined?",-410632824);Z0=new $CLJS.M(null,"is-from-previous-stage","is-from-previous-stage",956780376);
l1=new $CLJS.M(null,"is-source-table","is-source-table",1559075056);$CLJS.m1=new $CLJS.M(null,"unique-name-fn","unique-name-fn",-755402870);$CLJS.$0=new $CLJS.M(null,"is-from-join","is-from-join",-1107917905);$CLJS.n1=new $CLJS.M(null,"requires-column","requires-column",934105295);$CLJS.e1=new $CLJS.M(null,"order-by-position","order-by-position",-1307229997);Jga=new $CLJS.M(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.X0=new $CLJS.M(null,"long-display-name","long-display-name",1550784464);
$CLJS.W0=new $CLJS.M(null,"long","long",-171452093);$CLJS.o1=new $CLJS.M(null,"short-name","short-name",-1767085022);$CLJS.p1=new $CLJS.M(null,"include-expressions?","include-expressions?",-1817639288);a1=new $CLJS.M(null,"is-calculated","is-calculated",-86687269);$CLJS.q1=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.I.j(new $CLJS.k(null,1,[$CLJS.ej,$CLJS.CF],null),$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","display-name-method"),function(f,g,l){return $CLJS.AF(l)},e,a,b,c,d)}();
$CLJS.r1=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.I.j(new $CLJS.k(null,1,[$CLJS.ej,$CLJS.CF],null),$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","column-name-method"),function(f,g,l){return $CLJS.AF(l)},e,a,b,c,d)}();
$CLJS.V0=function(){function a(f,g,l,m){var t=$CLJS.nI.h($CLJS.EI(l));if($CLJS.n(t))return t;try{return $CLJS.q1.v(f,g,l,m)}catch(u){if(u instanceof Error)throw g=u,$CLJS.ki($CLJS.GI("Error calculating display name for {0}: {1}",$CLJS.G([$CLJS.Ph.l($CLJS.G([l])),$CLJS.bZ(g)])),new $CLJS.k(null,2,[$CLJS.jQ,f,$CLJS.zI,l],null),g);throw u;}}function b(f,g,l){return e.v?e.v(f,g,l,$CLJS.gi):e.call(null,f,g,l,$CLJS.gi)}function c(f,g){return e.j?e.j(f,-1,g):e.call(null,f,-1,g)}function d(f){return e.g?
e.g(f,f):e.call(null,f,f)}var e=null;e=function(f,g,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,g);case 3:return b.call(this,f,g,l);case 4:return a.call(this,f,g,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
$CLJS.s1=function(){function a(d,e,f){var g=$CLJS.S.h($CLJS.EI(f));if($CLJS.n(g))return g;try{return $CLJS.r1.j(d,e,f)}catch(l){if(l instanceof Error)throw g=l,$CLJS.ki($CLJS.GI("Error calculating column name for {0}: {1}",$CLJS.G([$CLJS.Ph.l($CLJS.G([f])),$CLJS.bZ(g)])),new $CLJS.k(null,3,[$CLJS.zI,f,$CLJS.jQ,d,$CLJS.i1,e],null),g);throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,
d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.q1.m(null,$CLJS.gi,function(a,b,c){a=$CLJS.iE($CLJS.oC);$CLJS.n($CLJS.hE("metabase.lib.metadata.calculation",a))&&$CLJS.gE("metabase.lib.metadata.calculation",a,$CLJS.f_("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.G([$CLJS.Ph.l($CLJS.G([c])),Iga,$CLJS.AF(c)])),null);return $CLJS.Ad(c)&&$CLJS.B(c)instanceof $CLJS.M?$CLJS.jh($CLJS.B(c)):$CLJS.Ph.l($CLJS.G([c]))});
$CLJS.r1.m(null,$CLJS.gi,function(a,b,c){return Gga($CLJS.aE($CLJS.V0.j(a,b,c),/[\(\)]/,""))});$CLJS.t1=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.I.j(new $CLJS.k(null,1,[$CLJS.ej,$CLJS.CF],null),$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,g,l){return l},e,a,b,c,d)}();
$CLJS.u1=function(){function a(d,e,f){return $CLJS.t1.j(d,e,$CLJS.Ch.h(f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.v1=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.I.j(new $CLJS.k(null,1,[$CLJS.ej,$CLJS.CF],null),$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","type-of-method"),function(f,g,l){return $CLJS.AF(l)},e,a,b,c,d)}();
$CLJS.w1=function(){function a(d,e,f){var g=$CLJS.EI(f);g=$CLJS.Ge(g);var l=$CLJS.I.g(g,$CLJS.nK),m=$CLJS.Hi.h(g);if($CLJS.n(m))return m;l=$CLJS.n($CLJS.n(l)?$CLJS.Id($CLJS.zL,l):l)?$CLJS.sj:null;if($CLJS.n(l))return l;g=$CLJS.CE.h(g);if($CLJS.n(g))return g;d=$CLJS.v1.j(d,e,f);return $CLJS.PF(d,$CLJS.wj)?d:$CLJS.wj}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.v1.m(null,$CLJS.gi,function(a,b,c){return $CLJS.KI(c)});$CLJS.v1.m(null,$CLJS.eJ,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);return $CLJS.w1.j(a,b,c)});$CLJS.v1.m(null,$CLJS.XM,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null);return"string"===typeof d?$CLJS.KI(c):$CLJS.w1.j(a,b,d)});
$CLJS.x1=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.I.j(new $CLJS.k(null,1,[$CLJS.ej,$CLJS.CF],null),$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","metadata-method"),function(f,g,l){return $CLJS.AF(l)},e,a,b,c,d)}();
$CLJS.x1.m(null,$CLJS.gi,function(a,b,c){try{return new $CLJS.k(null,4,[$CLJS.Aj,$CLJS.a_,$CLJS.CE,$CLJS.w1.j(a,b,c),$CLJS.S,$CLJS.s1.j(a,b,c),$CLJS.nI,$CLJS.V0.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.ki($CLJS.GI("Error calculating metadata for {0}: {1}",$CLJS.G([$CLJS.Ph.l($CLJS.G([$CLJS.AF(c)])),$CLJS.bZ(d)])),new $CLJS.k(null,3,[$CLJS.jQ,a,$CLJS.i1,b,$CLJS.zI,c],null),d);}throw e;}});
$CLJS.U0=function(){function a(e,f,g){return $CLJS.x1.j(e,f,g)}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return d.j?d.j(e,-1,e):d.call(null,e,-1,e)}var d=null;d=function(e,f,g){switch(arguments.length){case 1:return c.call(this,e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,g)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();
$CLJS.y1=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.I.j(new $CLJS.k(null,1,[$CLJS.ej,$CLJS.CF],null),$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","display-info-method"),function(f,g,l){return $CLJS.AF(l)},e,a,b,c,d)}();
$CLJS.X(j1,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nI,$CLJS.Tj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.X0,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Tj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.y_,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,j1],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Z0,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.Ct],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$0,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.Ct],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[a1,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.Ct],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.b1,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.Ct],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[l1,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.kt,$CLJS.Ct],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Jga,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.Ct],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Hga,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.Ct],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.S,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Tj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PK,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Tj],null),
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iv,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Tj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.o1,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Tj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.n1,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Ct],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.c1,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Ct],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gi,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Ct],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.g1,
new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ht,$CLJS.eK,$CLJS.YJ],null)],null)],null));
$CLJS.Y0=function(){function a(d,e,f){try{return $CLJS.y1.j(d,e,f)}catch(l){if(l instanceof Error){var g=l;throw $CLJS.ki($CLJS.GI("Error calculating display info for {0}: {1}",$CLJS.G([$CLJS.AF(f),$CLJS.bZ(g)])),new $CLJS.k(null,3,[$CLJS.jQ,d,$CLJS.i1,e,$CLJS.zI,f],null),g);}throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.y1.m(null,$CLJS.gi,function(a,b,c){return f1(a,b,c)});$CLJS.y1.m(null,$CLJS.v_,function(a,b,c){return $CLJS.Uk.l($CLJS.G([f1(a,b,c),new $CLJS.k(null,1,[l1,$CLJS.F.g($CLJS.N0(a),$CLJS.Wi.h(c))],null)]))});
$CLJS.z1=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.I.j(new $CLJS.k(null,1,[$CLJS.ej,$CLJS.CF],null),$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","returned-columns-method"),function(f,g,l){return $CLJS.AF(l)},e,a,b,c,d)}();$CLJS.z1.m(null,$CLJS.GE,function(){return $CLJS.Bf});
$CLJS.A1=function(){function a(f,g,l,m){m=$CLJS.Uk.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.m1,$CLJS.s_()],null),m]));return $CLJS.z1.v(f,g,l,m)}function b(f,g,l){return e.v?e.v(f,g,l,null):e.call(null,f,g,l,null)}function c(f,g){return e.j?e.j(f,-1,g):e.call(null,f,-1,g)}function d(f){var g=$CLJS.q0(f,-1);return e.g?e.g(f,g):e.call(null,f,g)}var e=null;e=function(f,g,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,g);case 3:return b.call(this,f,g,l);case 4:return a.call(this,
f,g,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();$CLJS.B1=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.I.j(new $CLJS.k(null,1,[$CLJS.ej,$CLJS.CF],null),$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","visible-columns-method"),function(f,g,l){return $CLJS.AF(l)},e,a,b,c,d)}();$CLJS.B1.m(null,$CLJS.GE,function(){return $CLJS.Bf});
$CLJS.B1.m(null,$CLJS.gi,function(a,b,c,d){return $CLJS.z1.v(a,b,c,d)});
$CLJS.C1=function(){function a(f,g,l,m){m=$CLJS.Uk.l($CLJS.G([$CLJS.Uk.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.m1,$CLJS.s_()],null),new $CLJS.k(null,3,[$CLJS.k1,!0,$CLJS.p1,!0,$CLJS.h1,!0],null)])),m]));return $CLJS.B1.v(f,g,l,m)}function b(f,g,l){return e.v?e.v(f,g,l,null):e.call(null,f,g,l,null)}function c(f,g){return e.j?e.j(f,-1,g):e.call(null,f,-1,g)}function d(f){var g=$CLJS.q0(f,-1);return e.g?e.g(f,g):e.call(null,f,g)}var e=null;e=function(f,g,l,m){switch(arguments.length){case 1:return d.call(this,
f);case 2:return c.call(this,f,g);case 3:return b.call(this,f,g,l);case 4:return a.call(this,f,g,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();module.exports={display_name:$CLJS.V0};
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.formatting.internal.numbers.js");require("./metabase.shared.formatting.internal.numbers_core.js");require("./metabase.util.js");
'use strict';var nja=function(a,b){return new $CLJS.me(null,function(){a:for(var c=a,d=b;;){d=$CLJS.A(d);var e;if(e=d)e=$CLJS.B(d),e=c.h?c.h(e):c.call(null,e);if($CLJS.n(e))d=$CLJS.Lc(d);else break a}return d},null,null)},oja=function(a,b){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ew(a,b),nja(a,b)],null)},x5=function(a){var b=$CLJS.Uk.l;var c=$CLJS.Ge(a);var d=$CLJS.I.g(c,$CLJS.q5);c=$CLJS.I.g(c,$CLJS.r5);if($CLJS.n($CLJS.n(d)?$CLJS.F.g(c,"currency"):d)){c=$CLJS.l5.h;d=$CLJS.Ch.h(d);var e=$CLJS.q($CLJS.i5);
d=e.h?e.h(d):e.call(null,d);c=c.call($CLJS.l5,d);c=new $CLJS.k(null,2,[$CLJS.p5,c,$CLJS.s5,c],null)}else c=new $CLJS.k(null,1,[$CLJS.s5,2],null);b=b.call($CLJS.Uk,$CLJS.G([c,a]));return $CLJS.n($CLJS.t5.h(a))?$CLJS.R.l($CLJS.vk.g(b,$CLJS.t5),$CLJS.s5,$CLJS.t5.h(a),$CLJS.G([$CLJS.p5,$CLJS.t5.h(a)])):b},y5=function(a,b){if(null!=a&&null!=a.De)a=a.De(a,b);else{var c=y5[$CLJS.xa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=y5._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Za("NumberFormatter.format-number-basic",
a);}return a},pja=function(a,b){var c=$CLJS.DD(a.formatToParts(b),$CLJS.G([new $CLJS.k(null,1,[$CLJS.Di,!0],null)]));a=$CLJS.n($CLJS.Ne(function(d){return $CLJS.F.g($CLJS.ij.h(d),"exponentMinusSign")},c))?c:function(){var d=oja(function(f){return $CLJS.ah.g($CLJS.ij.h(f),"exponentInteger")},c),e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return $CLJS.cf.l(e,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.k(null,2,[$CLJS.ij,"exponentPlusSign",$CLJS.zj,"+"],null)],null),$CLJS.G([d]))}();return $CLJS.O.g($CLJS.p,
$CLJS.ff.g(function(d){var e=$CLJS.Ge(d);d=$CLJS.I.g(e,$CLJS.ij);e=$CLJS.I.g(e,$CLJS.zj);switch(d){case "exponentSeparator":return"e";default:return e}},a))},qja=function(a,b){switch($CLJS.r5.h(b)){case "scientific":return function(c){return pja(a,c)};default:return function(c){return a.format(c)}}},z5=function(a,b,c,d,e){this.options=a;this.gg=b;this.jg=c;this.Ud=d;this.Oh=e;this.C=393216;this.I=0},rja=function(a,b){return $CLJS.O.g($CLJS.p,function(){return function e(d){return new $CLJS.me(null,
function(){for(var f=d;;)if(f=$CLJS.A(f)){if($CLJS.Bd(f)){var g=$CLJS.kc(f),l=$CLJS.E(g),m=$CLJS.qe(l);return function(){for(var v=0;;)if(v<l){var x=$CLJS.md(g,v),z=$CLJS.Ge(x);x=$CLJS.I.g(z,$CLJS.ij);z=$CLJS.I.g(z,$CLJS.zj);var D=new $CLJS.dh(null,new $CLJS.k(null,2,["currency",null,"integer",null],null),null);D=D.h?D.h(x):D.call(null,x);$CLJS.n(D)&&(x=$CLJS.F.g(x,"integer")?b:z,m.add(x));v+=1}else return!0}()?$CLJS.te($CLJS.ve(m),e($CLJS.lc(f))):$CLJS.te($CLJS.ve(m),null)}var t=$CLJS.B(f);t=$CLJS.Ge(t);
var u=$CLJS.I.g(t,$CLJS.ij);t=$CLJS.I.g(t,$CLJS.zj);if($CLJS.n(function(){var v=new $CLJS.dh(null,new $CLJS.k(null,2,["currency",null,"integer",null],null),null);return v.h?v.h(u):v.call(null,u)}()))return $CLJS.de($CLJS.F.g(u,"integer")?b:t,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}($CLJS.DD(a.gg.formatToParts(1),$CLJS.G([$CLJS.Di,!0])))}())},A5=function(a){var b=$CLJS.F.g($CLJS.r5.h(a),"currency")?2:null;b=new Intl.NumberFormat("en",$CLJS.Xk($CLJS.dE($CLJS.Ua,$CLJS.Ig([$CLJS.kja,
$CLJS.cja,$CLJS.fja,$CLJS.hja,$CLJS.mja,$CLJS.q5,$CLJS.gja,$CLJS.ija,$CLJS.dja,$CLJS.eja],[$CLJS.s5.g(a,b),$CLJS.v5.h(a),$CLJS.u5.h(a),$CLJS.jja.h(a),$CLJS.F.g($CLJS.r5.h(a),"scientific")?null:$CLJS.r5.g(a,"decimal"),$CLJS.q5.h(a),!0,$CLJS.p5.g(a,b),$CLJS.F.g($CLJS.r5.h(a),"scientific")?"scientific":null,$CLJS.w5.h(a)]))));var c=$CLJS.v5.h(a);var d=$CLJS.q5.h(a);c=$CLJS.n(d)?null==c||$CLJS.F.g(c,"symbol"):d;d=qja(b,a);return new z5(a,b,c,d,$CLJS.N)},B5=function(a,b){return A5(x5(b)).De(null,a)},D5=
function(a,b){var c=$CLJS.vk.l(b,$CLJS.KE,$CLJS.G([$CLJS.r5])),d=Math.abs(a);if(0===a)return"0";if(1E3>d)return $CLJS.C5.g?$CLJS.C5.g(a,c):$CLJS.C5.call(null,a,c);b=$CLJS.B($CLJS.jf(function(f){return d>=$CLJS.B(f)},sja));var e=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return[$CLJS.p.h(function(){var f=a/e,g=$CLJS.Uk.l($CLJS.G([c,new $CLJS.k(null,2,[$CLJS.p5,1,$CLJS.s5,1],null)]));return $CLJS.C5.g?$CLJS.C5.g(f,g):$CLJS.C5.call(null,f,g)}()),$CLJS.p.h(b)].join("")},tja=function(a,b){var c=x5(b);if($CLJS.n(E5.h(c)))b=
E5.h(c);else if($CLJS.Ta($CLJS.t5.h(c))&&$CLJS.Ta($CLJS.p5.h(c))&&$CLJS.ah.g($CLJS.r5.h(c),"currency")&&Math.abs(a)<($CLJS.F.g($CLJS.r5.h(c),"percent")?.01:1)){b=$CLJS.R.j;var d=$CLJS.vk.g(c,$CLJS.s5);c=$CLJS.w5.g(c,0);b=A5(b.call($CLJS.R,d,$CLJS.u5,2>c?2:c))}else b=A5(c);return y5(b,a)};z5.prototype.P=function(a,b){return new z5(this.options,this.gg,this.jg,this.Ud,b)};z5.prototype.O=function(){return this.Oh};
z5.prototype.De=function(a,b){a=this.Ud.h?this.Ud.h(b):this.Ud.call(null,b);var c=$CLJS.lja.h(this.options);$CLJS.n($CLJS.n(c)?$CLJS.ah.g(c,".,"):c)&&(b=$CLJS.B(c),c=$CLJS.jd(c),a=$CLJS.aE(a,/[\.,]/,new $CLJS.k(null,2,[",",$CLJS.n(c)?c:"",".",b],null)));$CLJS.n(this.jg)&&(b=$CLJS.q5.h(this.options),c=$CLJS.j5(b),a=$CLJS.aE($CLJS.aE(a,[$CLJS.jh(b)," "].join(""),c),$CLJS.jh(b),c));return a};
var E5=new $CLJS.M(null,"number-formatter","number-formatter",929788393),F5=new $CLJS.M(null,"scale","scale",-230427353),G5=new $CLJS.M(null,"negative-in-parentheses","negative-in-parentheses",-1676084141),uja=new $CLJS.M(null,"scientific","scientific",316285837);var H5=new $CLJS.k(null,1,[$CLJS.v5,"symbol"],null),vja=$CLJS.Xk(H5),sja=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[1E12,"T"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E9,"B"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E6,"M"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E3,"k"],null)],null),I5=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.shared.formatting.numbers",
"format-number-compact*"),function(f,g){f=$CLJS.Ge(g);return $CLJS.I.g(f,$CLJS.r5)},e,a,b,c,d)}();I5.m(null,$CLJS.gi,function(a,b){return D5(a,b)});I5.m(null,"percent",function(a,b){return[$CLJS.p.h(D5(100*a,b)),"%"].join("")});I5.m(null,"currency",function(a,b){b=$CLJS.Uk.l($CLJS.G([b,H5]));var c=A5(b);return 1E3>Math.abs(a)?c.De(null,a):rja(c,D5(a,b))});I5.m(null,"scientific",function(a,b){return B5(a,$CLJS.Uk.l($CLJS.G([b,new $CLJS.k(null,2,[$CLJS.s5,1,$CLJS.p5,1],null)])))});
$CLJS.C5=function C5(a,b){b=$CLJS.oE(b);var d=$CLJS.Ge(b);b=$CLJS.I.g(d,$CLJS.KE);var e=$CLJS.I.g(d,G5),f=$CLJS.I.g(d,$CLJS.r5),g=$CLJS.I.g(d,F5);return $CLJS.n($CLJS.n(g)?!isNaN(g):g)?(b=g*a,f=$CLJS.vk.g(d,F5),C5.g?C5.g(b,f):C5.call(null,b,f)):$CLJS.n(function(){var l=0>a;return l?e:l}())?["(",$CLJS.p.h(function(){var l=-a,m=$CLJS.R.j(d,G5,!1);return C5.g?C5.g(l,m):C5.call(null,l,m)}()),")"].join(""):$CLJS.n(b)?I5.g(a,x5($CLJS.vk.g(d,$CLJS.KE))):$CLJS.F.g($CLJS.Ch.h(f),uja)?B5(a,d):tja(a,d)};
module.exports={compact_currency_options_js:vja,format_number:$CLJS.C5};
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.core.protocols.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var q9,s9,r9,Eoa,x9,y9,Foa,Goa,Hoa,Ioa,t9,Joa,D9,Koa,Loa,Moa;q9=function(a,b){return $CLJS.n(a)?$CLJS.Da(a,b):null};s9=function(a,b){return r9(a,b,$CLJS.N)};
r9=function(a,b,c){c=$CLJS.Ge(c);c=$CLJS.I.j(c,t9,!0);if($CLJS.yd(b))return $CLJS.eg.j($CLJS.N,a,b);c=$CLJS.n(c)?function(){var d=q9(b,"_plainObject");d=null==d?null:$CLJS.ED(d);return null==d?null:$CLJS.He(d)}():null;return $CLJS.n(c)?$CLJS.eg.j($CLJS.N,a,c):$CLJS.eg.j($CLJS.N,$CLJS.Ck.j($CLJS.ff.h(function(d){return new $CLJS.P(null,2,5,$CLJS.Q,[d,q9(b,d)],null)}),$CLJS.Dk.h(function(d){$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return $CLJS.F.g($CLJS.xa(d),"function")}),a),$CLJS.Ca(b))};
Eoa=function(a){var b=u9.h(a),c=v9.h(a);return $CLJS.Ck.j($CLJS.ff.h(function(d){var e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ch.h((0,$CLJS.nE)(e)),d],null)}),$CLJS.ud(b)?$CLJS.Td:$CLJS.Dk.h(function(d){var e=$CLJS.H(d,0,null);$CLJS.H(d,1,null);return $CLJS.Id(b,e)}),$CLJS.Ta(c)?$CLJS.Td:$CLJS.ff.h(function(d){var e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[e,c.g?c.g(e,d):c.call(null,e,d)],null)}))};
x9=function(a,b){var c=Eoa(a),d=w9.h(a);return function(e){try{var f=$CLJS.R.j(r9(c,e,b),$CLJS.Aj,d),g=$CLJS.iE($CLJS.iC);$CLJS.n($CLJS.hE("metabase.lib.js.metadata",g))&&$CLJS.gE("metabase.lib.js.metadata",g,$CLJS.f_("Parsed metadata %s %s\n%s",$CLJS.G([a,$CLJS.Wi.h(f),$CLJS.g_(f)])),null);return f}catch(l){if(l instanceof Error)return f=l,g=$CLJS.iE($CLJS.kC),$CLJS.n($CLJS.hE("metabase.lib.js.metadata",g))&&(f instanceof Error?$CLJS.gE("metabase.lib.js.metadata",g,$CLJS.f_("Error parsing %s %s: %s",
$CLJS.G([a,$CLJS.Ph.l($CLJS.G([e])),$CLJS.bZ(f)])),f):$CLJS.gE("metabase.lib.js.metadata",g,$CLJS.f_(f,$CLJS.G(["Error parsing %s %s: %s",a,$CLJS.Ph.l($CLJS.G([e])),$CLJS.bZ(f)])),null)),null;throw l;}}};y9=function(a){return $CLJS.Ek.g(x9($CLJS.hK,$CLJS.N),a)};Foa=new $CLJS.M(null,"plain-object","plain-object",-943264281);$CLJS.z9=new $CLJS.M(null,"cards","cards",169174038);Goa=new $CLJS.M(null,"db","db",993250759);$CLJS.A9=new $CLJS.M(null,"metrics","metrics",394093469);
Hoa=new $CLJS.M(null,"fks","fks",2041987076);$CLJS.B9=new $CLJS.M(null,"segments","segments",1937535949);Ioa=new $CLJS.M(null,"dimensions","dimensions",-254818097);t9=new $CLJS.M(null,"use-plain-object?","use-plain-object?",-1174595475);Joa=new $CLJS.M(null,"metadata","metadata",1799301597);$CLJS.C9=new $CLJS.M(null,"tables","tables",1334623052);D9=new $CLJS.M(null,"dimension-options","dimension-options",2122379680);Koa=new $CLJS.M(null,"schema-name","schema-name",1666725119);
Loa=new $CLJS.M(null,"_comesFromEndpoint","_comesFromEndpoint",-1390455588);Moa=new $CLJS.M(null,"default-dimension-option","default-dimension-option",149055958);$CLJS.E9=new $CLJS.M(null,"databases","databases",2040134125);var u9,Noa=$CLJS.Se($CLJS.N),Ooa=$CLJS.Se($CLJS.N),Poa=$CLJS.Se($CLJS.N),Qoa=$CLJS.Se($CLJS.N),Roa=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null)),G9,bpa,cpa,dpa,epa,fpa;u9=new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","excluded-keys"),$CLJS.Ch,Roa,Noa,Ooa,Poa,Qoa);u9.m(null,$CLJS.gi,function(){return null});var v9,Soa=$CLJS.Se($CLJS.N),Toa=$CLJS.Se($CLJS.N),Uoa=$CLJS.Se($CLJS.N),Voa=$CLJS.Se($CLJS.N),Woa=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));
v9=new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","parse-field-fn"),$CLJS.Ch,Woa,Soa,Toa,Uoa,Voa);v9.m(null,$CLJS.gi,function(){return null});var w9,Xoa=$CLJS.Se($CLJS.N),Yoa=$CLJS.Se($CLJS.N),Zoa=$CLJS.Se($CLJS.N),$oa=$CLJS.Se($CLJS.N),apa=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));w9=new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","lib-type"),$CLJS.Ch,apa,Xoa,Yoa,Zoa,$oa);
$CLJS.F9=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","parse-objects"),function(f){return $CLJS.Ch.h(f)},e,a,b,c,d)}();bpa=$CLJS.Se($CLJS.N);cpa=$CLJS.Se($CLJS.N);dpa=$CLJS.Se($CLJS.N);epa=$CLJS.Se($CLJS.N);fpa=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));
G9=new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","parse-objects-default-key"),$CLJS.Ch,fpa,bpa,cpa,dpa,epa);$CLJS.F9.m(null,$CLJS.gi,function(a,b){var c=x9(a,$CLJS.N);return s9($CLJS.ff.h(function(d){var e=$CLJS.H(d,0,null),f=$CLJS.H(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.e_(e),new $CLJS.Mh(function(){return c.h?c.h(f):c.call(null,f)})],null)}),q9(b,G9.h(a)))});w9.m(null,$CLJS.AT,function(){return $CLJS.Gfa});
u9.m(null,$CLJS.AT,function(){return new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.SI,null,$CLJS.C9,null],null),null)});v9.m(null,$CLJS.AT,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "dbms-version":return $CLJS.DD(b,$CLJS.G([$CLJS.Di,!0]));case "features":return $CLJS.eg.j($CLJS.eh,$CLJS.ff.h($CLJS.Ch),b);case "native-permissions":return $CLJS.Ch.h(b);default:return b}}});G9.m(null,$CLJS.AT,function(){return"databases"});w9.m(null,$CLJS.y_,function(){return $CLJS.v_});
u9.m(null,$CLJS.y_,function(){return new $CLJS.dh(null,new $CLJS.k(null,5,[D9,null,$CLJS.SI,null,$CLJS.B9,null,$CLJS.AT,null,$CLJS.A9,null],null),null)});v9.m(null,$CLJS.y_,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "entity-type":return $CLJS.Ch.h(b);case "field-order":return $CLJS.Ch.h(b);case "initial-sync-status":return $CLJS.Ch.h(b);case "visibility-type":return $CLJS.Ch.h(b);default:return b}}});
$CLJS.F9.m(null,$CLJS.y_,function(a,b){var c=x9(a,$CLJS.N);return s9($CLJS.Ck.g($CLJS.Dk.h(function(d){var e=$CLJS.H(d,0,null);$CLJS.H(d,1,null);return 0==e.lastIndexOf("card__",0)}),$CLJS.ff.h(function(d){var e=$CLJS.H(d,0,null),f=$CLJS.H(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.e_(e),new $CLJS.Mh(function(){return c.h?c.h(f):c.call(null,f)})],null)})),q9(b,"tables"))});w9.m(null,$CLJS.hK,function(){return $CLJS.a_});
u9.m(null,$CLJS.hK,function(){return new $CLJS.dh(null,new $CLJS.k(null,7,[D9,null,$CLJS.y_,null,Ioa,null,Moa,null,$CLJS.AT,null,Loa,null,$CLJS.A9,null],null),null)});
v9.m(null,$CLJS.hK,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "base-type":return $CLJS.Ch.h(b);case "coercion-strategy":return $CLJS.Ch.h(b);case "effective-type":return $CLJS.Ch.h(b);case "fingerprint":return $CLJS.yd(b)?$CLJS.eZ(b):$CLJS.DD(b,$CLJS.G([$CLJS.Di,!0]));case "has-field-values":return $CLJS.Ch.h(b);case "semantic-type":return $CLJS.Ch.h(b);case "visibility-type":return $CLJS.Ch.h(b);case "id":return $CLJS.Ad(b)?$CLJS.jd(b):b;default:return b}}});
$CLJS.F9.m(null,$CLJS.hK,function(a,b){var c=x9(a,$CLJS.N),d=q9(b,"fields");return s9($CLJS.Ol(function(e){var f=$CLJS.H(e,0,null),g=$CLJS.H(e,1,null);e=function(){var l=$CLJS.e_(f);if($CLJS.n(l))return l;l=$CLJS.qh(/card__\d+:(\d+)/,f);if($CLJS.n(l)){$CLJS.H(l,0,null);l=$CLJS.H(l,1,null);var m=null==q9(d,l);return m?$CLJS.e_(l):m}return null}();return $CLJS.n(e)?new $CLJS.P(null,2,5,$CLJS.Q,[e,new $CLJS.Mh(function(){return c.h?c.h(g):c.call(null,g)})],null):null}),d)});w9.m(null,$CLJS.YQ,function(){return $CLJS.z_});
u9.m(null,$CLJS.YQ,function(){return new $CLJS.dh(null,new $CLJS.k(null,11,[D9,null,$CLJS.hl,null,Hoa,null,$CLJS.y_,null,Goa,null,Foa,null,$CLJS.B9,null,$CLJS.AT,null,Joa,null,$CLJS.A9,null,Koa,null],null),null)});
v9.m(null,$CLJS.YQ,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "result-metadata":return $CLJS.n($CLJS.WD($CLJS.xd,$CLJS.Sa)(b))?y9(b):$CLJS.DD(b,$CLJS.G([$CLJS.Di,!0]));case "fields":return y9(b);case "visibility-type":return $CLJS.Ch.h(b);case "dataset-query":return $CLJS.DD(b,$CLJS.G([$CLJS.Di,!0]));case "dataset":return b;default:return b}}});
$CLJS.F9.m(null,$CLJS.YQ,function(a,b){return $CLJS.eg.j($CLJS.N,$CLJS.ff.h(function(c){return new $CLJS.P(null,2,5,$CLJS.Q,[c,new $CLJS.Mh(function(){var d=x9($CLJS.YQ,new $CLJS.k(null,1,[t9,!1],null)),e=x9($CLJS.YQ,$CLJS.N),f=$CLJS.Uk.l;d=$CLJS.R.j(d(q9(q9(b,"tables"),["card__",$CLJS.p.h(c)].join(""))),$CLJS.Wi,c);var g=q9(q9(b,"questions"),$CLJS.p.h(c));var l=q9(g,"_card");g=$CLJS.n(l)?l:g;e=e.h?e.h(g):e.call(null,g);return f.call($CLJS.Uk,$CLJS.G([d,e]))})],null)}),$CLJS.eg.j($CLJS.eg.j($CLJS.eh,
$CLJS.Ol($CLJS.q_),$CLJS.Ca(q9(b,"tables"))),$CLJS.ff.h($CLJS.e_),$CLJS.Ca(q9(b,"questions"))))});w9.m(null,$CLJS.cK,function(){return $CLJS.b_});u9.m(null,$CLJS.cK,function(){return new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.y_,null,$CLJS.AT,null],null),null)});v9.m(null,$CLJS.cK,function(){return function(a,b){return b}});G9.m(null,$CLJS.cK,function(){return"metrics"});w9.m(null,$CLJS.IJ,function(){return $CLJS.u_});
u9.m(null,$CLJS.IJ,function(){return new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.y_,null,$CLJS.AT,null],null),null)});v9.m(null,$CLJS.IJ,function(){return function(a,b){return b}});G9.m(null,$CLJS.IJ,function(){return"segments"});
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var r0,qga,sga,tga,vga,wga,E0,zga,Aga,Bga,Cga,Dga,K0,Ega;$CLJS.q0=function(a,b){a=$CLJS.o_(a);a=$CLJS.Ge(a);var c=$CLJS.I.g(a,$CLJS.aP);return $CLJS.I.g($CLJS.Cf(c),$CLJS.p_(a,b))};r0=function(){};$CLJS.s0=function(a){return null!=a?$CLJS.r===a.Qh?!0:a.Pc?!1:$CLJS.Xa(r0,a):$CLJS.Xa(r0,a)};qga=new $CLJS.w(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.t0=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
$CLJS.u0=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.rga=new $CLJS.M(null,"database-id","database-id",1883826326);$CLJS.v0=new $CLJS.M(null,"metadata-fn","metadata-fn",915781389);$CLJS.w0=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);sga=new $CLJS.M(null,"human-readable-values","human-readable-values",-624842907);tga=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.uga=new $CLJS.M(null,"dataset","dataset",1159262238);
vga=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.x0=new $CLJS.M("source","expressions","source/expressions",-458367840);$CLJS.y0=new $CLJS.M(null,"definition","definition",-1198729982);wga=new $CLJS.M(null,"state","state",-1988618099);$CLJS.z0=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);$CLJS.A0=new $CLJS.M("source","joins","source/joins",1225821486);$CLJS.B0=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);
$CLJS.C0=new $CLJS.M(null,"fk-target-field-id","fk-target-field-id",2019750287);$CLJS.D0=new $CLJS.M(null,"selected?","selected?",-742502788);E0=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.F0=new $CLJS.M("source","card","source/card",-139977973);$CLJS.G0=new $CLJS.M(null,"active","active",1895962068);$CLJS.xga=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);$CLJS.yga=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);
$CLJS.H0=new $CLJS.M("source","fields","source/fields",-649667981);zga=new $CLJS.M("metabase.lib.metadata","persisted-info","metabase.lib.metadata/persisted-info",1906179826);Aga=new $CLJS.M("metabase.lib.metadata","column.remapping.external","metabase.lib.metadata/column.remapping.external",-228294930);$CLJS.I0=new $CLJS.M("source","native","source/native",-1444604147);Bga=new $CLJS.M("metabase.lib.metadata","column.remapping.internal","metabase.lib.metadata/column.remapping.internal",-716967804);
$CLJS.J0=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);Cga=new $CLJS.M("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);Dga=new $CLJS.w(null,"metadata-provider?","metadata-provider?",1669871524,null);K0=new $CLJS.M("metabase.lib.metadata","persisted-info.definition","metabase.lib.metadata/persisted-info.definition",1100108182);Ega=new $CLJS.M("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);
$CLJS.L0=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.X(new $CLJS.M("metabase.lib.metadata","column-source","metabase.lib.metadata/column-source",-458963769),new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ht,$CLJS.F0,$CLJS.I0,$CLJS.u0,$CLJS.w0,$CLJS.H0,$CLJS.A_,$CLJS.x_,$CLJS.A0,$CLJS.x0,$CLJS.z0],null));
$CLJS.X(Aga,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,Ega],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,$CLJS.PO],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,$CLJS.sI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oZ,$CLJS.UO],null)],null));
$CLJS.X(Bga,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,Cga],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,$CLJS.PO],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,$CLJS.sI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,$CLJS.Dt],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[sga,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,$CLJS.Dt],null)],null)],null));
$CLJS.X(K0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[E0,$CLJS.sI],null),new $CLJS.P(null,2,5,$CLJS.Q,[vga,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[tga,$CLJS.sI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CE,$CLJS.AI],null)],null)],null)],null)],null)],null));
$CLJS.X(zga,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.G0,$CLJS.Ct],null),new $CLJS.P(null,2,5,$CLJS.Q,[wga,$CLJS.sI],null),new $CLJS.P(null,2,5,$CLJS.Q,[E0,$CLJS.sI],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.y0,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,K0],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QP,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,
$CLJS.sI],null)],null)],null));$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,$CLJS.Si,$CLJS.jj,$CLJS.mk,$CLJS.yi,$CLJS.T,$CLJS.ek],[qga,Dga,"metabase/lib/metadata/protocols.cljc",25,1,74,74,$CLJS.V(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Pq],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.s0)?$CLJS.s0.H:null]);
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var U5=function(a){return $CLJS.n(T5)?["(",$CLJS.p.h(a),")"].join(""):a},Fja=function(a,b,c){var d=$CLJS.q0(a,b);a=$CLJS.C1.j(a,b,d);var e=$CLJS.kE(c);return $CLJS.Ne(function(f){return $CLJS.F.g($CLJS.kE($CLJS.S.h(f)),e)},a)},Gja=function(a,b){var c=$CLJS.Fk.v(a,$CLJS.FT,$CLJS.XH($CLJS.ae,$CLJS.Bf),b);return $CLJS.Ad($CLJS.SI.h(a))?$CLJS.Fk.v(c,$CLJS.SI,$CLJS.ae,$CLJS.ZZ(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zE,$CLJS.N,$CLJS.j_(b)],null))):c},Hja=function(a,b,c){var d=$CLJS.j_(c);return $CLJS.R.l($CLJS.U0.j(a,
b,c),$CLJS.t_,$CLJS.x0,$CLJS.G([$CLJS.S,d,$CLJS.nI,d]))},V5=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);var Ija=function(){function a(d,e,f){var g=$CLJS.q0(d,e);g=$CLJS.CL($CLJS.Ck.g($CLJS.fh([f]),$CLJS.j_),$CLJS.FT.h(g));if($CLJS.n(g))return g;throw $CLJS.li($CLJS.GI("No expression named {0}",$CLJS.G([$CLJS.Ph.l($CLJS.G([f]))])),new $CLJS.k(null,3,[$CLJS.HV,f,$CLJS.jQ,d,$CLJS.i1,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.v1.m(null,$CLJS.zE,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);c=Ija.j(a,b,c);return $CLJS.w1.j(a,b,c)});$CLJS.x1.m(null,$CLJS.zE,function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null),e=$CLJS.H(c,2,null);return new $CLJS.k(null,7,[$CLJS.Aj,$CLJS.a_,$CLJS.d3,$CLJS.yI.h(d),$CLJS.S,e,$CLJS.$I,e,$CLJS.nI,$CLJS.V0.j(a,b,c),$CLJS.CE,$CLJS.w1.j(a,b,c),$CLJS.t_,$CLJS.x0],null)});$CLJS.q1.m(null,$CLJS.uE,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.q1.m(null,$CLJS.sE,function(a,b,c){return $CLJS.p.h(c)});$CLJS.q1.m(null,$CLJS.tE,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.q1.m(null,$CLJS.zE,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);return $CLJS.H(c,2,null)});$CLJS.r1.m(null,$CLJS.zE,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);return $CLJS.H(c,2,null)});
for(var T5=!1,Jja=new $CLJS.k(null,4,[$CLJS.Os,"+",$CLJS.qu,"-",$CLJS.Ps,"×",$CLJS.DK,"÷"],null),W5=$CLJS.A(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Os,$CLJS.qu,$CLJS.DK,$CLJS.Ps],null)),X5=null,Y5=0,Z5=0;;)if(Z5<Y5){var Kja=X5.X(null,Z5);$CLJS.tJ(Kja,V5);Z5+=1}else{var $5=$CLJS.A(W5);if($5){var a6=$5;if($CLJS.Bd(a6)){var b6=$CLJS.kc(a6),Lja=$CLJS.lc(a6),Mja=b6,Nja=$CLJS.E(b6);W5=Lja;X5=Mja;Y5=Nja}else{var Oja=$CLJS.B(a6);$CLJS.tJ(Oja,V5);W5=$CLJS.C(a6);X5=null;Y5=0}Z5=0}else break}
$CLJS.q1.m(null,V5,function(a,b,c){var d=$CLJS.A(c);c=$CLJS.B(d);d=$CLJS.C(d);$CLJS.B(d);d=$CLJS.C(d);c=$CLJS.I.g(Jja,c);a:{var e=T5;T5=!0;try{var f=$CLJS.Mu([" ",$CLJS.jh(c)," "].join(""),$CLJS.ff.g($CLJS.Iv($CLJS.V0,a,b),d));break a}finally{T5=e}f=void 0}return U5(f)});$CLJS.r1.m(null,V5,function(){return"expression"});
$CLJS.v1.m(null,$CLJS.IL,function(a,b,c){c=$CLJS.A(c);$CLJS.B(c);c=$CLJS.C(c);$CLJS.B(c);var d=$CLJS.C(c);return $CLJS.Qd($CLJS.vJ,function(){return function g(f){return new $CLJS.me(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.Bd(l)){var m=$CLJS.kc(l),t=$CLJS.E(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.md(m,v);x=$CLJS.w1.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),g($CLJS.lc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.B(l);return $CLJS.de($CLJS.w1.j(a,
b,u),g($CLJS.Lc(l)))}return null}},null,null)}(d)}())});$CLJS.q1.m(null,$CLJS.jN,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null),f=$CLJS.H(c,3,null);c=$CLJS.H(c,4,null);a=$CLJS.p.h($CLJS.V0.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.e3.g(f,c).toLowerCase();0<f?f=$CLJS.ha.j?$CLJS.ha.j("+ %d %s",f,c):$CLJS.ha.call(null,"+ %d %s",f,c):(f=Math.abs(f),f=$CLJS.ha.j?$CLJS.ha.j("- %d %s",f,c):$CLJS.ha.call(null,"- %d %s",f,c));f=U5(f);return[a," ",d.call(b,f)].join("")});
$CLJS.r1.m(null,$CLJS.jN,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null),e=$CLJS.H(c,3,null);c=$CLJS.H(c,4,null);a=$CLJS.p.h($CLJS.s1.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.e3.g(e,c).toLowerCase();0<e?e=$CLJS.ha.j?$CLJS.ha.j("plus_%s_%s",e,c):$CLJS.ha.call(null,"plus_%s_%s",e,c):(e=Math.abs(e),e=$CLJS.ha.j?$CLJS.ha.j("minus_%d_%s",e,c):$CLJS.ha.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.q1.m(null,$CLJS.kK,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return $CLJS.V0.v(a,b,e,d)});$CLJS.r1.m(null,$CLJS.kK,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return $CLJS.s1.j(a,b,d)});
$CLJS.Pja=function(){function a(d,e,f,g){e=$CLJS.n(e)?e:-1;if($CLJS.n(Fja(d,e,f)))throw $CLJS.li("Expression name conflicts with a column in the same query stage",new $CLJS.k(null,1,[$CLJS.HV,f],null));return $CLJS.B_.l(d,e,Gja,$CLJS.G([$CLJS.k_($CLJS.c_.h(g),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,g){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,g)}throw Error("Invalid arity: "+arguments.length);
};c.j=b;c.v=a;return c}();$CLJS.Qja=function(){function a(d,e){var f=$CLJS.He($CLJS.FT.h($CLJS.q0(d,e)));return null==f?null:$CLJS.Ek.g($CLJS.Iv(Hja,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.c6=function(){function a(d,e){return $CLJS.He($CLJS.FT.h($CLJS.q0(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.VZ.m(null,$CLJS.zE,function(a){return a});
$CLJS.Rja=function(){function a(d,e,f){var g=$CLJS.eg.g($CLJS.N,$CLJS.Vl(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.j_(t),m],null)},$CLJS.c6.g(d,e))),l=$CLJS.q0(d,e);d=$CLJS.C1.j(d,e,l);return $CLJS.He($CLJS.aZ(function(m){return $CLJS.Ta(f)||$CLJS.ah.g($CLJS.t_.h(m),$CLJS.x0)||$CLJS.I.g(g,$CLJS.S.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();
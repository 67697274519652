var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var dia,eia,fia,$3,gia,h4,i4,j4,k4,hia,iia,jia,kia,lia,mia,nia,oia,pia,v4,w4,x4,y4,qia,ria,u4,t4,sia;$CLJS.Y3=function(a,b){$CLJS.bb(function(c,d){return a.h?a.h(d):a.call(null,d)},null,b)};
dia=function(a){return function(b){var c=$CLJS.We(!0);return function(){function d(l,m){var t=$CLJS.q(c);if($CLJS.n($CLJS.n(t)?a.h?a.h(m):a.call(null,m):t))return l;$CLJS.xl(c,null);return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var g=null;g=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};g.o=
f;g.h=e;g.g=d;return g}()}};eia=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.y(b.slice(3),0,null):null;return $CLJS.Ta($CLJS.A($CLJS.XD(c,d)))?c:$CLJS.O.N($CLJS.lZ,c,d,e,b)};$CLJS.Z3=function(a,b){return $CLJS.i_(a)&&$CLJS.F.g($CLJS.B(a),b)};
fia=function(a,b){return $CLJS.B($CLJS.Ul(function(c){if($CLJS.n($CLJS.n(c)?b:c)){var d=$CLJS.Pu.h(c);c=$CLJS.Uu.h(c);d=$CLJS.n($CLJS.rh(d,b))?$CLJS.aE(b,d,c):null}else d=null;return d},a))};$3=function(a){a=$CLJS.a2(a);var b=$CLJS.bE(a);b||($CLJS.n(a)?(b=$CLJS.n(a)?!$CLJS.Id($CLJS.q($CLJS.g2),$CLJS.a2(a).toLowerCase()):null,b=$CLJS.Ta(b)):b=null);return $CLJS.n(b)?a:fia($CLJS.Zb($CLJS.q($CLJS.c2)),a)};
$CLJS.a4=function(a,b){if($CLJS.A(a)){b=$CLJS.Ek.g($CLJS.$Z,b);var c=$CLJS.eg.j($CLJS.eh,$CLJS.ff.h(function(d){return $CLJS.s2(d,a)}),b);return $CLJS.Ek.g(function(d){var e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return $CLJS.R.j(d,$CLJS.D0,$CLJS.Id(c,e))},$CLJS.Vl($CLJS.Gt,a))}return null};$CLJS.b4=function(a,b){return $CLJS.f3.g(a,b instanceof $CLJS.M?b:$CLJS.wi.h(b))};$CLJS.c4=function(a){a=$CLJS.g3.h(a);return $CLJS.n(a)?new $CLJS.k(null,2,[$CLJS.Aj,$CLJS.lL,$CLJS.wi,a],null):null};
$CLJS.d4=function(a){return $CLJS.F.g($CLJS.AF(a),$CLJS.WO)};$CLJS.f4=function(a){var b=$CLJS.AF(a);b=b instanceof $CLJS.M?b.T:null;switch(b){case "dispatch-type/nil":return null;case "field":return $CLJS.$S.h($CLJS.EI(a));case "metadata/column":return $CLJS.e4.h(a);case "mbql/join":return $CLJS.WI.h(a);default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};
gia=function(a,b){return $CLJS.n($CLJS.SI.h(a))?$CLJS.Fk.j(a,$CLJS.SI,function(c){return $CLJS.xd(c)?$CLJS.Ek.g(function(d){return $CLJS.g4.g?$CLJS.g4.g(d,b):$CLJS.g4.call(null,d,b)},c):c}):a};
h4=function(a){if($CLJS.n(a)){var b=$CLJS.jf;a:try{if($CLJS.Ad(a)&&4===$CLJS.E(a))try{if($CLJS.cd(a,0)instanceof $CLJS.M)try{var c=$CLJS.cd(a,2);if($CLJS.Ad(c)&&3===$CLJS.E(c))try{var d=$CLJS.cd(c,0);if($CLJS.ge(d,$CLJS.hK))try{var e=$CLJS.cd(a,3);if($CLJS.Ad(e)&&3===$CLJS.E(e))try{var f=$CLJS.cd(e,0);if($CLJS.ge(f,$CLJS.hK)){$CLJS.cd(e,1);$CLJS.cd(e,2);$CLJS.cd(c,1);$CLJS.cd(c,2);$CLJS.cd(a,0);$CLJS.cd(a,1);var g=new $CLJS.P(null,1,5,$CLJS.Q,[!0],null)}else throw $CLJS.Y;}catch(m){if(m instanceof
Error){var l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof
Error){l=m;if(l===$CLJS.Y){g=new $CLJS.P(null,1,5,$CLJS.Q,[!1],null);break a}throw l;}throw m;}a=$CLJS.B($CLJS.A(b($CLJS.Ua,g)))}else a=null;return a};i4=function(a){if($CLJS.n(h4(a))){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);$CLJS.H(a,3,null);return b}return null};j4=function(a){return $CLJS.n(h4(a))?($CLJS.H(a,0,null),$CLJS.H(a,1,null),$CLJS.H(a,2,null),$CLJS.H(a,3,null)):null};
k4=function(a,b){var c=$CLJS.g4;if($CLJS.Ta(h4(a)))return a;var d=$CLJS.H(a,0,null),e=$CLJS.H(a,1,null),f=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[d,e,f,$CLJS.O.j(c,a,b)],null)};
hia=function(a,b,c){return $CLJS.ud($CLJS.dP.h(a))?a:$CLJS.n(b)?eia(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dP],null),function(d){return function l(f,g){try{if($CLJS.Ad(g)&&3===$CLJS.E(g))try{var m=$CLJS.cd(g,0);if($CLJS.ge(m,$CLJS.hK))try{var t=$CLJS.cd(g,1);if(null!=t?t.C&256||$CLJS.r===t.We||(t.C?0:$CLJS.Xa($CLJS.rb,t)):$CLJS.Xa($CLJS.rb,t))try{var u=$CLJS.I.j(t,$CLJS.$S,$CLJS.pZ);if($CLJS.F.g(u,b))return $CLJS.cd(g,2),$CLJS.g4.g?$CLJS.g4.g(g,c):$CLJS.g4.call(null,g,c);throw $CLJS.Y;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)return $CLJS.kZ(l,f,g);throw v;}throw x;}}($CLJS.Bf,d)}):$CLJS.Fk.j(a,$CLJS.dP,function(d){return $CLJS.Ek.g(function(e){return k4(e,$CLJS.G([c]))},d)})};
$CLJS.g4=function(a,b){var c=$CLJS.AF(a);c=c instanceof $CLJS.M?c.T:null;switch(c){case "dispatch-type/nil":return b=$CLJS.iE($CLJS.kC),$CLJS.n($CLJS.hE("metabase.lib.join",b))&&$CLJS.gE("metabase.lib.join",b,$CLJS.vy.l($CLJS.G(["with-join-value should not be called with",$CLJS.Ph.l($CLJS.G([a]))])),null),a;case "field":return $CLJS.XZ(a,$CLJS.WZ,$CLJS.G([$CLJS.$S,b]));case "metadata/column":return $CLJS.WZ(a,$CLJS.e4,b);case "mbql/join":return c=$CLJS.f4(a),hia(gia($CLJS.WZ(a,$CLJS.WI,b),b),c,b);
default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}};$CLJS.l4=function(a,b){return $CLJS.ha.j?$CLJS.ha.j("%s__%s",a,b):$CLJS.ha.call(null,"%s__%s",a,b)};$CLJS.m4=function(a,b,c){var d=$CLJS.R.l,e=$CLJS.WD($CLJS.t0,$CLJS.S)(c);a=$CLJS.l4($CLJS.WI.h(a),$CLJS.WD($CLJS.t0,$CLJS.S)(c));b=b.h?b.h(a):b.call(null,a);return d.call($CLJS.R,c,$CLJS.t0,e,$CLJS.G([$CLJS.B0,b]))};
iia=function(a,b){return $CLJS.Ta(b)?a:$CLJS.Ek.g(function(c){var d=j4(c);d=$CLJS.n(d)?$CLJS.n($CLJS.f4(d))?null:k4(c,$CLJS.G([b])):null;return $CLJS.n(d)?d:c},a)};$CLJS.n4=function(a,b){b=iia($CLJS.Ek.g($CLJS.c_,b),$CLJS.f4(a));return $CLJS.WZ(a,$CLJS.dP,$CLJS.He(b))};
$CLJS.o4=function(a,b){b=b instanceof $CLJS.M?b:$CLJS.F.g(b,$CLJS.Bf)?$CLJS.Xu:$CLJS.He($CLJS.eg.j($CLJS.Bf,$CLJS.Ck.g($CLJS.ff.h($CLJS.$Z),function(){var c=$CLJS.f4(a);return $CLJS.n(c)?$CLJS.ff.h(function(d){return $CLJS.g4(d,c)}):$CLJS.Td}()),b));return $CLJS.WZ(a,$CLJS.SI,b)};
jia=function(a,b){var c=$CLJS.eg.j($CLJS.N,$CLJS.Ol(function(e){var f=$CLJS.r2($CLJS.$Z(e),b);return $CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[f,e],null):null}),a);c=$CLJS.ff.g(c,b);var d=$CLJS.CL($CLJS.WD($CLJS.S2,$CLJS.T2),c);if($CLJS.n(d))return d;c=$CLJS.B(c);if($CLJS.n(c))return c;c=$CLJS.CL($CLJS.S2,a);if($CLJS.n(c))return c;c=$CLJS.CL($CLJS.T2,a);return $CLJS.n(c)?c:$CLJS.B(a)};kia=function(a){return"string"===typeof a?(0,$CLJS.ma)($CLJS.aE(a,/ id$/i,"")):null};
lia=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.Mh(function(){return $3(b)});var d=new $CLJS.Mh(function(){return $3(a)});return $CLJS.F.g(a,b)||$CLJS.F.g(a,$CLJS.q(c))||$CLJS.F.g($CLJS.q(d),b)||$CLJS.F.g($CLJS.q(d),$CLJS.q(c))}return c}return c};
mia=function(a,b,c){var d=$CLJS.V0.g($CLJS.F.g($CLJS.Aj.h(b),$CLJS.OZ)?b:a,b),e=$CLJS.n(c)?kia($CLJS.V0.g(a,c)):null,f=lia(d,e);return function(){var g=function(){if($CLJS.n(d)){if($CLJS.n(e)){var l=$CLJS.Ta($CLJS.qh(/id/i,e));return l?(l=!f)?[$CLJS.p.h(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.n(g)?g:$CLJS.n(d)?d:$CLJS.n(e)?e:"source"}()};
nia=function(a,b,c){return function g(e,f){try{if($CLJS.Z3(f,$CLJS.hK)&&$CLJS.Fd($CLJS.k3(f,c)))return $CLJS.g4(f,b);throw $CLJS.Y;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Y)return $CLJS.kZ(g,e,f);throw l;}throw m;}}($CLJS.Bf,a)};
oia=function(a,b,c,d){a=nia(a,b,d);return function l(f,g){try{if($CLJS.Ad(g)&&4===$CLJS.E(g))try{var m=$CLJS.cd(g,2);if($CLJS.Z3(m,$CLJS.hK))try{var t=$CLJS.cd(g,3);if($CLJS.Z3(t,$CLJS.hK)){var u=$CLJS.cd(g,3),v=$CLJS.cd(g,2),x=$CLJS.cd(g,0),z=$CLJS.cd(g,1),D=$CLJS.f4(v),J=$CLJS.f4(u);if($CLJS.Ta($CLJS.n(D)?D:J))return $CLJS.n($CLJS.k3(u,c))?new $CLJS.P(null,4,5,$CLJS.Q,[x,z,$CLJS.g4(v,b),u],null):new $CLJS.P(null,4,5,$CLJS.Q,[x,z,v,$CLJS.g4(u,b)],null);if($CLJS.F.g(D,b)&&$CLJS.F.g(J,b)){var K=$CLJS.XZ(v,
$CLJS.vk,$CLJS.G([$CLJS.$S])),U=$CLJS.XZ(u,$CLJS.vk,$CLJS.G([$CLJS.$S])),Z=null==$CLJS.k3(K,c);var da=Z?$CLJS.s2(U,c):Z;return $CLJS.n(da)?new $CLJS.P(null,4,5,$CLJS.Q,[x,z,v,U],null):new $CLJS.P(null,4,5,$CLJS.Q,[x,z,K,u],null)}return g}throw $CLJS.Y;}catch(va){if(va instanceof Error){var qa=va;if(qa===$CLJS.Y)throw $CLJS.Y;throw qa;}throw va;}else throw $CLJS.Y;}catch(va){if(va instanceof Error){qa=va;if(qa===$CLJS.Y)throw $CLJS.Y;throw qa;}throw va;}else throw $CLJS.Y;}catch(va){if(va instanceof
Error){qa=va;if(qa===$CLJS.Y)return $CLJS.kZ(l,f,g);throw qa;}throw va;}}($CLJS.Bf,a)};pia=function(a,b){var c=$CLJS.s_();$CLJS.Y3(c,b);return c(a)};
$CLJS.p4=function(a,b,c){if($CLJS.Id(c,$CLJS.WI))return c;var d=$CLJS.q0(a,b),e=$CLJS.C1.j(a,b,d);b=$CLJS.A($CLJS.jf($CLJS.Ua,function u(m,t){try{if($CLJS.Ad(t)&&1<=$CLJS.E(t))try{var v=$CLJS.Jk.j(t,0,1);if($CLJS.Ad(v)&&1===$CLJS.E(v))try{var x=$CLJS.cd(v,0);if($CLJS.ge(x,$CLJS.hK))return new $CLJS.P(null,1,5,$CLJS.Q,[t],null);throw $CLJS.Y;}catch(D){if(D instanceof Error){var z=D;if(z===$CLJS.Y)throw $CLJS.Y;throw z;}throw D;}else throw $CLJS.Y;}catch(D){if(D instanceof Error){z=D;if(z===$CLJS.Y)throw $CLJS.Y;
throw z;}throw D;}else throw $CLJS.Y;}catch(D){if(D instanceof Error){z=D;if(z===$CLJS.Y)return $CLJS.jZ(u,m,t);throw z;}throw D;}}($CLJS.Bf,$CLJS.dP.h(c))));b=jia(e,b);var f=pia(mia(a,c,b),$CLJS.Ul($CLJS.WI,$CLJS.gR.h(d))),g=$CLJS.A1.h($CLJS.I1.g(a,$CLJS.aP.h(c)));return $CLJS.g4($CLJS.Fk.j(c,$CLJS.dP,function(m){return $CLJS.Ek.g(function(t){return oia(t,f,e,g)},m)}),f)};$CLJS.q4=function(a){return $CLJS.dP.h(a)};
$CLJS.r4=function(a){return $CLJS.Uk.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.Aj,$CLJS.eP,$CLJS.CI,a],null),$CLJS.F.g(a,$CLJS.cJ)?new $CLJS.k(null,1,[$CLJS.gi,!0],null):null]))};$CLJS.s4=function(a,b){b=$CLJS.B($CLJS.aP.h(b));return $CLJS.n($CLJS.yZ.h(b))?$CLJS.T0(a,$CLJS.yZ.h(b)):$CLJS.n($CLJS.LS.h(b))?$CLJS.S0(a,$CLJS.LS.h(b)):null};
v4=function(a){a=$CLJS.BL(function(d){return $CLJS.T2(d)?t4:$CLJS.S2(d)?u4:$CLJS.xv},a);var b=$CLJS.Ge(a);a=$CLJS.I.g(b,t4);var c=$CLJS.I.g(b,u4);b=$CLJS.I.g(b,$CLJS.xv);return $CLJS.cf.l(a,c,$CLJS.G([b]))};w4=function(a,b){return $CLJS.Ta(a)?b:$CLJS.Ek.g(function(c){return $CLJS.n($CLJS.D0.h(c))?$CLJS.b4(c,$CLJS.c4(a)):c},$CLJS.a4(b,new $CLJS.P(null,1,5,$CLJS.Q,[a],null)))};x4=function(a,b,c){return $CLJS.CL($CLJS.T2,$CLJS.C1.j(a,b,c))};
y4=function(a,b){var c=$CLJS.Wi.h(a);return $CLJS.n(c)?$CLJS.CL(function(d){d=$CLJS.Ge(d);var e=$CLJS.I.g(d,$CLJS.C0);return $CLJS.S2(d)&&$CLJS.F.g(e,c)},b):null};qia={};ria=new $CLJS.M("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);u4=new $CLJS.M(null,"fk","fk",398442651);$CLJS.e4=new $CLJS.M("metabase.lib.join","join-alias","metabase.lib.join/join-alias",1166233023);t4=new $CLJS.M(null,"pk","pk",-771936732);
sia=new $CLJS.w("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);$CLJS.q1.m(null,$CLJS.WO,function(a,b,c){b=$CLJS.Ge(c);b=$CLJS.I.g(b,$CLJS.aP);b=$CLJS.H(b,0,null);c=$CLJS.Ge(b);b=$CLJS.I.g(c,$CLJS.LS);c=$CLJS.I.g(c,$CLJS.yZ);a=$CLJS.n(b)?$CLJS.nI.h($CLJS.S0(a,b)):null;if($CLJS.n(a))return a;a=$CLJS.n(c)?$CLJS.Q1(c):null;return $CLJS.n(a)?a:$CLJS.HI("Native Query")});$CLJS.y1.m(null,$CLJS.WO,function(a,b,c){a=$CLJS.V0.j(a,b,c);c=$CLJS.WI.h(c);return new $CLJS.k(null,2,[$CLJS.S,$CLJS.n(c)?c:a,$CLJS.nI,a],null)});
$CLJS.x1.m(null,$CLJS.WO,function(){throw $CLJS.li("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.q1.m(null,$CLJS.eP,function(a,b,c){a=$CLJS.Ge(c);a=$CLJS.I.g(a,$CLJS.CI);a=a instanceof $CLJS.M?a.T:null;switch(a){case "left-join":return $CLJS.HI("Left outer join");case "right-join":return $CLJS.HI("Right outer join");case "inner-join":return $CLJS.HI("Inner join");case "full-join":return $CLJS.HI("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});
$CLJS.y1.m(null,$CLJS.eP,function(a,b,c){var d=$CLJS.Ge(c),e=$CLJS.I.g(d,$CLJS.CI);c=$CLJS.I.g(d,$CLJS.gi);a=new $CLJS.k(null,2,[$CLJS.o1,$CLJS.jE(e),$CLJS.nI,$CLJS.V0.j(a,b,d)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.gi,!0):a});
$CLJS.z1.m(null,$CLJS.WO,function(a,b,c,d){var e=$CLJS.Ge(c),f=$CLJS.I.g(e,$CLJS.WI),g=$CLJS.I.j(e,$CLJS.SI,$CLJS.Xu),l=$CLJS.I.g(e,$CLJS.aP);c=$CLJS.Ge(d);var m=$CLJS.I.g(c,$CLJS.m1);if($CLJS.F.g(g,$CLJS.Xu))return null;var t="undefined"!==typeof $CLJS.FP&&"undefined"!==typeof qia&&"undefined"!==typeof $CLJS.tia&&"undefined"!==typeof $CLJS.z4?new $CLJS.Gc(function(){return $CLJS.z4},$CLJS.qd(sia,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),null):null,u=function(){var v=$CLJS.R.j(a,$CLJS.aP,l);return $CLJS.n(t)?
t.g?t.g(v,-1):t.call(null,v,-1):v}();c=$CLJS.F.g(g,$CLJS.cC)?$CLJS.A1.v(u,-1,$CLJS.sd(l),c):function(){return function z(x){return new $CLJS.me(null,function(){for(;;){var D=$CLJS.A(x);if(D){if($CLJS.Bd(D)){var J=$CLJS.kc(D),K=$CLJS.E(J),U=$CLJS.qe(K);a:for(var Z=0;;)if(Z<K){var da=$CLJS.md(J,Z);da=$CLJS.XZ(da,$CLJS.vk,$CLJS.G([$CLJS.$S]));da=$CLJS.U0.j(u,-1,da);U.add(da);Z+=1}else{J=!0;break a}return J?$CLJS.te($CLJS.ve(U),z($CLJS.lc(D))):$CLJS.te($CLJS.ve(U),null)}U=$CLJS.B(D);U=$CLJS.XZ(U,$CLJS.vk,
$CLJS.G([$CLJS.$S]));return $CLJS.de($CLJS.U0.j(u,-1,U),z($CLJS.Lc(D)))}return null}},null,null)}(g)}();return $CLJS.Ek.g(function(v){v=$CLJS.R.j(v,$CLJS.L0,f);v=$CLJS.g4($CLJS.R.l(v,$CLJS.nI,$CLJS.V0.j(a,b,v),$CLJS.G([$CLJS.t_,$CLJS.A0])),f);return $CLJS.m4(e,m,v)},c)});$CLJS.B1.m(null,$CLJS.WO,function(a,b,c,d){return $CLJS.A1.v(a,b,$CLJS.R.j(c,$CLJS.SI,$CLJS.cC),d)});
var A4,uia=$CLJS.Se($CLJS.N),via=$CLJS.Se($CLJS.N),wia=$CLJS.Se($CLJS.N),xia=$CLJS.Se($CLJS.N),yia=$CLJS.I.j(new $CLJS.k(null,1,[$CLJS.ej,$CLJS.CF],null),$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));A4=new $CLJS.hi($CLJS.Ih.g("metabase.lib.join","join-clause-method"),$CLJS.AF,yia,uia,via,wia,xia);A4.m(null,$CLJS.WO,function(a){return a});A4.m(null,$CLJS.OZ,function(a){return $CLJS.ZZ(new $CLJS.k(null,2,[$CLJS.Aj,$CLJS.WO,$CLJS.aP,$CLJS.aP.h($CLJS.o_(a))],null))});
A4.m(null,$CLJS.DZ,function(a){return $CLJS.ZZ(new $CLJS.k(null,2,[$CLJS.Aj,$CLJS.WO,$CLJS.aP,new $CLJS.P(null,1,5,$CLJS.Q,[a],null)],null))});A4.m(null,$CLJS.z_,function(a){return $CLJS.ZZ(new $CLJS.k(null,2,[$CLJS.Aj,$CLJS.WO,$CLJS.aP,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.k(null,2,[$CLJS.yZ,$CLJS.Wi.h(a),$CLJS.Aj,$CLJS.DZ],null)],null)],null))});
A4.m(null,$CLJS.v_,function(a){var b=$CLJS.Ge(a),c=$CLJS.I.g(b,$CLJS.e4);a=$CLJS.I.g(b,ria);b=A4.h(new $CLJS.k(null,3,[$CLJS.Aj,$CLJS.DZ,$CLJS.EE,new $CLJS.k(null,1,[$CLJS.yI,$CLJS.p.h($CLJS.FI())],null),$CLJS.LS,$CLJS.Wi.h(b)],null));c=$CLJS.n(c)?$CLJS.g4(b,c):b;return $CLJS.n(a)?$CLJS.o4.g?$CLJS.o4.g(c,a):$CLJS.o4.call(null,c,a):c});
$CLJS.B4=function(){function a(d,e){return $CLJS.n4(c.h?c.h(d):c.call(null,d),e)}function b(d){return $CLJS.b3(A4.h(d),$CLJS.SI,$CLJS.cC)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.zia=function(){function a(d,e,f){var g=$CLJS.B4.h(f);f=$CLJS.ud($CLJS.q4.h?$CLJS.q4.h(g):$CLJS.q4.call(null,g))?function(){var m=$CLJS.s4.g?$CLJS.s4.g(d,g):$CLJS.s4.call(null,d,g);return $CLJS.C4.j?$CLJS.C4.j(d,e,m):$CLJS.C4.call(null,d,e,m)}():null;f=$CLJS.n(f)?$CLJS.n4(g,new $CLJS.P(null,1,5,$CLJS.Q,[f],null)):g;var l=$CLJS.p4(d,e,f);return $CLJS.B_.l(d,e,$CLJS.Fk,$CLJS.G([$CLJS.gR,function(m){return $CLJS.ae.g($CLJS.Cf(m),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}
var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.D4=function(){function a(d,e){return $CLJS.He($CLJS.I.g($CLJS.q0(d,e),$CLJS.gR))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Aia=function(){function a(d){d=$CLJS.O0($CLJS.R0(d));d=$CLJS.Zu.h(d);return $CLJS.eg.j($CLJS.Bf,$CLJS.Ck.g($CLJS.hf($CLJS.Qe($CLJS.Id,d)),$CLJS.ff.h($CLJS.r4)),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.cJ,$CLJS.OI,$CLJS.iJ,$CLJS.kJ],null))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Bia=function(){function a(d,e,f,g){var l=$CLJS.d4(f)?$CLJS.f4(f):null,m=$CLJS.eg.j($CLJS.eh,$CLJS.Ck.g($CLJS.ff.h($CLJS.f4),dia(function(t){return $CLJS.ah.g(t,l)})),$CLJS.D4.g(d,e));f=$CLJS.n(g)?g:$CLJS.d4(f)?i4($CLJS.B($CLJS.q4(f))):null;return v4(w4(f,$CLJS.Dk.g(function(t){t=$CLJS.f4(t);return $CLJS.n(t)?$CLJS.Id(m,t):null},$CLJS.C1.v(d,e,$CLJS.q0(d,e),new $CLJS.k(null,1,[$CLJS.h1,!1],null)))))}function b(d,e,f,g){return c.N?c.N(d,-1,e,f,g):c.call(null,d,-1,e,f,g)}var c=null;c=function(d,
e,f,g,l){switch(arguments.length){case 4:return b.call(this,d,e,f,g);case 5:return a.call(this,d,e,f,g,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();
$CLJS.Cia=function(){function a(d,e,f,g,l){g=$CLJS.d4(f)?$CLJS.s4(d,f):f;var m=$CLJS.d4(f)?$CLJS.f4(f):null;f=$CLJS.n(l)?l:$CLJS.d4(f)?j4($CLJS.B($CLJS.q4(f))):null;return v4(w4(f,$CLJS.ff.g(function(t){t=$CLJS.R.j(t,$CLJS.t_,$CLJS.A0);return $CLJS.n(m)?$CLJS.g4(t,m):t},$CLJS.C1.v(d,e,g,new $CLJS.k(null,1,[$CLJS.h1,!1],null)))))}function b(d,e,f,g){return c.N?c.N(d,-1,e,f,g):c.call(null,d,-1,e,f,g)}var c=null;c=function(d,e,f,g,l){switch(arguments.length){case 4:return b.call(this,d,e,f,g);case 5:return a.call(this,
d,e,f,g,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();$CLJS.Dia=function(){function a(c,d,e){return b.v?b.v(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.a3}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.v=function(){return $CLJS.a3};return b}();
$CLJS.C4=function(){function a(d,e,f){function g(t,u){return $CLJS.X3.l($CLJS.Z2.h($CLJS.Ls),t,$CLJS.G([u]))}var l=function(){var t=x4(d,e,f);if($CLJS.n(t)){if($CLJS.n(t)){var u=$CLJS.C1.j(d,e,$CLJS.q0(d,e));u=y4(t,u)}else u=null;return $CLJS.n(u)?g(u,t):null}return null}();if($CLJS.n(l))return l;l=x4(d,e,$CLJS.q0(d,e));if($CLJS.n(l)){var m=y4(l,$CLJS.C1.j(d,e,f));return $CLJS.n(m)?g(l,m):null}return null}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Eia=function(){function a(e,f,g,l){l=$CLJS.n(l)?l:$CLJS.d4(g)?i4($CLJS.B($CLJS.q4(g))):null;$CLJS.n(l)?(l=$CLJS.Y0.j(e,f,l),l=$CLJS.XD(l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.y_,$CLJS.nI],null))):l=null;if($CLJS.n(l))return l;if(l=0===$CLJS.p_(e,f)){l=$CLJS.D4.g(e,f);var m=$CLJS.ud(l);g=m?m:$CLJS.d4(g)?$CLJS.F.g($CLJS.WI.h(g),$CLJS.WI.h($CLJS.B(l))):null;g=$CLJS.n(g)?$CLJS.N0(e):g}else g=l;$CLJS.n(g)?(g=$CLJS.N0(e),g=$CLJS.S0(e,g),e=$CLJS.V0.j(e,f,g)):e=null;return $CLJS.n(e)?e:$CLJS.HI("Previous results")}
function b(e,f,g){return d.v?d.v(e,-1,f,g):d.call(null,e,-1,f,g)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,g,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,g);case 4:return a.call(this,e,f,g,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();
$CLJS.Fia=function(){function a(d,e,f,g){f=$CLJS.c_.h(f);$CLJS.H(f,0,null);$CLJS.H(f,1,null);var l=$CLJS.H(f,2,null),m=$CLJS.H(f,3,null);g=g instanceof $CLJS.M?g:$CLJS.wi.h(g);e=$CLJS.p_(d,e);l=$CLJS.j3.j(d,e,l);d=$CLJS.j3.j(d,e,m);m=null==g||$CLJS.Id($CLJS.ih($CLJS.ff.g($CLJS.wi,l)),g);d=null==g||$CLJS.Id($CLJS.ih($CLJS.ff.g($CLJS.wi,d)),g);f=m?$CLJS.Fk.v(f,2,$CLJS.b4,g):f;return d?$CLJS.Fk.v(f,3,$CLJS.b4,g):f}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,g){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,g)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
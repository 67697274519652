var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");
'use strict';var V6=function(a){var b=U6;return $CLJS.cE(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)},Rka=function(a){var b=W6;return $CLJS.cE(function(c){return function(d,e,f){f=b.h?b.h(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)},X6=function(a){if($CLJS.od(a))return a;throw $CLJS.li("Invalid pattern: don't know how to handle symbol.",new $CLJS.k(null,1,[$CLJS.Ii,a],null));},Y6=function(a){var b=(b=a instanceof
$CLJS.M)?$CLJS.he(a):b;return $CLJS.n(b)?[$CLJS.he(a),"/",$CLJS.jh(a)].join(""):$CLJS.jh(a)},Z6=function(a,b){var c=$CLJS.xd(b)&&!$CLJS.yf(b)&&$CLJS.B(b)instanceof $CLJS.M;return c?$CLJS.vd(a)?(b=$CLJS.B(b),a=$CLJS.ih(a),a.h?a.h(b):a.call(null,b)):$CLJS.F.g(a,$CLJS.B(b)):c},$6=function(a,b){return $CLJS.gf(function(c){var d=$CLJS.jf;a:try{if($CLJS.Ad(c)&&1<=$CLJS.E(c))try{var e=$CLJS.Jk.j(c,0,1);if($CLJS.Ad(e)&&1===$CLJS.E(e))try{var f=$CLJS.cd(e,0);if($CLJS.n($CLJS.Qe($CLJS.F,a)(f))){var g=$CLJS.Jk.g(c,
1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[g],null)}else throw $CLJS.Y;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.B($CLJS.A(d($CLJS.Ua,l)))},$CLJS.G([b]))},Ska=function(a){return function e(c,
d){try{if($CLJS.n(function(){var zr=X6($CLJS.Ed);return zr.h?zr.h(d):zr.call(null,d)}()))return e(c,$CLJS.Cf(d));throw $CLJS.Y;}catch(zr){if(zr instanceof Error){var f=zr;if(f===$CLJS.Y)try{if(null===d)return null;throw $CLJS.Y;}catch(dM){if(dM instanceof Error)if(f=dM,f===$CLJS.Y)try{if($CLJS.Ad(d)&&0<=$CLJS.E(d))try{var g=$CLJS.Jk.j(d,0,0);if($CLJS.Ad(g)&&0===$CLJS.E(g))try{var l=$CLJS.Jk.g(d,0);if($CLJS.n($CLJS.Qe($CLJS.Me,$CLJS.Ra)(l)))return null;throw $CLJS.Y;}catch(pl){if(pl instanceof Error){var m=
pl;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw pl;}else throw $CLJS.Y;}catch(pl){if(pl instanceof Error){m=pl;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw pl;}else throw $CLJS.Y;}catch(pl){if(pl instanceof Error)if(m=pl,m===$CLJS.Y)try{if($CLJS.Ad(d)&&0===$CLJS.E(d))return null;throw $CLJS.Y;}catch(eM){if(eM instanceof Error)if(g=eM,g===$CLJS.Y)try{if($CLJS.Ad(d)&&1===$CLJS.E(d))try{var t=$CLJS.cd(d,0);if($CLJS.ge(t,$CLJS.st))return null;throw $CLJS.Y;}catch(Ar){if(Ar instanceof Error)if(g=Ar,g===
$CLJS.Y)try{t=$CLJS.cd(d,0);if($CLJS.ge(t,$CLJS.lt))return null;throw $CLJS.Y;}catch(df){if(df instanceof Error){var u=df;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw df;}else throw g;else throw Ar;}else throw $CLJS.Y;}catch(Ar){if(Ar instanceof Error)if(g=Ar,g===$CLJS.Y)try{if($CLJS.Ad(d)&&1<=$CLJS.E(d))try{var v=$CLJS.Jk.j(d,0,1);if($CLJS.Ad(v)&&1===$CLJS.E(v))try{var x=$CLJS.cd(v,0);if($CLJS.n(function(){var df=new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.lt,null,$CLJS.st,null],null),null);return df.h?
df.h(x):df.call(null,x)}()))try{var z=$CLJS.Jk.g(d,1);if($CLJS.n($CLJS.Qe($CLJS.Ne,$CLJS.Ra)(z))){var D=$CLJS.Jk.g(d,1),J=$CLJS.cd(v,0);return e(c,$CLJS.O.j($CLJS.Gt,J,$CLJS.aZ($CLJS.Ua,D)))}throw $CLJS.Y;}catch(df){if(df instanceof Error){u=df;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw df;}else throw $CLJS.Y;}catch(df){if(df instanceof Error){u=df;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw df;}else throw $CLJS.Y;}catch(df){if(df instanceof Error){u=df;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw df;
}else throw $CLJS.Y;}catch(df){if(df instanceof Error)if(u=df,u===$CLJS.Y)try{if($CLJS.Ad(d)&&2===$CLJS.E(d))try{var K=$CLJS.cd(d,0);if($CLJS.ge(K,$CLJS.nt))try{var U=$CLJS.cd(d,1);if($CLJS.Ad(U)&&1<=$CLJS.E(U))try{var Z=$CLJS.Jk.j(U,0,1);if($CLJS.Ad(Z)&&1===$CLJS.E(Z))try{var da=$CLJS.cd(Z,0);if($CLJS.ge(da,$CLJS.st))return D=$CLJS.Jk.g(U,1),e(c,$CLJS.O.j($CLJS.Gt,$CLJS.lt,$CLJS.ff.g(function(Gf){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,Gf],null)},D)));throw $CLJS.Y;}catch(Gf){if(Gf instanceof
Error){var qa=Gf;if(qa===$CLJS.Y)try{da=$CLJS.cd(Z,0);if($CLJS.ge(da,$CLJS.lt))return D=$CLJS.Jk.g(U,1),e(c,$CLJS.O.j($CLJS.Gt,$CLJS.st,$CLJS.ff.g(function(Ee){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,Ee],null)},D)));throw $CLJS.Y;}catch(Ee){if(Ee instanceof Error){var va=Ee;if(va===$CLJS.Y)throw $CLJS.Y;throw va;}throw Ee;}else throw qa;}else throw Gf;}else throw $CLJS.Y;}catch(Gf){if(Gf instanceof Error){qa=Gf;if(qa===$CLJS.Y)throw $CLJS.Y;throw qa;}throw Gf;}else throw $CLJS.Y;}catch(Gf){if(Gf instanceof
Error){qa=Gf;if(qa===$CLJS.Y)throw $CLJS.Y;throw qa;}throw Gf;}else throw $CLJS.Y;}catch(Gf){if(Gf instanceof Error)if(qa=Gf,qa===$CLJS.Y)try{K=$CLJS.cd(d,0);if($CLJS.ge(K,$CLJS.st)){var sb=$CLJS.cd(d,1);return e(c,sb)}throw $CLJS.Y;}catch(Ee){if(Ee instanceof Error)if(va=Ee,va===$CLJS.Y)try{K=$CLJS.cd(d,0);if($CLJS.ge(K,$CLJS.lt))return sb=$CLJS.cd(d,1),e(c,sb);throw $CLJS.Y;}catch(oe){if(oe instanceof Error){var Nb=oe;if(Nb===$CLJS.Y)throw $CLJS.Y;throw Nb;}throw oe;}else throw va;else throw Ee;
}else throw qa;else throw Gf;}else throw $CLJS.Y;}catch(Gf){if(Gf instanceof Error)if(qa=Gf,qa===$CLJS.Y)try{if($CLJS.Ad(d)&&1<=$CLJS.E(d))try{var Tb=$CLJS.Jk.j(d,0,1);if($CLJS.Ad(Tb)&&1===$CLJS.E(Tb))try{var Ac=$CLJS.cd(Tb,0);if($CLJS.ge(Ac,$CLJS.st))try{var dc=$CLJS.Jk.g(d,1);if($CLJS.n($CLJS.Qe($CLJS.Ne,$CLJS.Qe(Z6,$CLJS.st))(dc)))return D=$CLJS.Jk.g(d,1),e(c,$CLJS.O.j($CLJS.Gt,$CLJS.st,$6($CLJS.st,D)));throw $CLJS.Y;}catch(Ee){if(Ee instanceof Error){va=Ee;if(va===$CLJS.Y)throw $CLJS.Y;throw va;
}throw Ee;}else throw $CLJS.Y;}catch(Ee){if(Ee instanceof Error)if(va=Ee,va===$CLJS.Y)try{if(Ac=$CLJS.cd(Tb,0),$CLJS.ge(Ac,$CLJS.lt))try{dc=$CLJS.Jk.g(d,1);if($CLJS.n($CLJS.Qe($CLJS.Ne,$CLJS.Qe(Z6,$CLJS.lt))(dc)))return D=$CLJS.Jk.g(d,1),e(c,$CLJS.O.j($CLJS.Gt,$CLJS.lt,$6($CLJS.lt,D)));throw $CLJS.Y;}catch(oe){if(oe instanceof Error){Nb=oe;if(Nb===$CLJS.Y)throw $CLJS.Y;throw Nb;}throw oe;}else throw $CLJS.Y;}catch(oe){if(oe instanceof Error)if(Nb=oe,Nb===$CLJS.Y)try{if(Ac=$CLJS.cd(Tb,0),$CLJS.n(function(){var ef=
new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.lt,null,$CLJS.st,null],null),null);return ef.h?ef.h(Ac):ef.call(null,Ac)}()))try{dc=$CLJS.Jk.g(d,1);if($CLJS.Ta($CLJS.O.g($CLJS.WF,dc))){D=$CLJS.Jk.g(d,1);var Bc=$CLJS.cd(Tb,0);return e(c,$CLJS.O.j($CLJS.Gt,Bc,$CLJS.Wk.h(D)))}throw $CLJS.Y;}catch(ef){if(ef instanceof Error){var wb=ef;if(wb===$CLJS.Y)throw $CLJS.Y;throw wb;}throw ef;}else throw $CLJS.Y;}catch(ef){if(ef instanceof Error){wb=ef;if(wb===$CLJS.Y)throw $CLJS.Y;throw wb;}throw ef;}else throw Nb;
else throw oe;}else throw va;else throw Ee;}else throw $CLJS.Y;}catch(Ee){if(Ee instanceof Error){va=Ee;if(va===$CLJS.Y)throw $CLJS.Y;throw va;}throw Ee;}else throw $CLJS.Y;}catch(Ee){if(Ee instanceof Error)if(va=Ee,va===$CLJS.Y)try{if($CLJS.Ad(d)&&2===$CLJS.E(d))try{var Ib=$CLJS.cd(d,0);if($CLJS.ge(Ib,$CLJS.nt))try{var dd=$CLJS.cd(d,1);if($CLJS.Ad(dd)&&2===$CLJS.E(dd))try{var si=$CLJS.cd(dd,0);if($CLJS.ge(si,$CLJS.nt))return sb=$CLJS.cd(dd,1),e(c,sb);throw $CLJS.Y;}catch(oe){if(oe instanceof Error){Nb=
oe;if(Nb===$CLJS.Y)throw $CLJS.Y;throw Nb;}throw oe;}else throw $CLJS.Y;}catch(oe){if(oe instanceof Error){Nb=oe;if(Nb===$CLJS.Y)throw $CLJS.Y;throw Nb;}throw oe;}else throw $CLJS.Y;}catch(oe){if(oe instanceof Error){Nb=oe;if(Nb===$CLJS.Y)throw $CLJS.Y;throw Nb;}throw oe;}else throw $CLJS.Y;}catch(oe){if(oe instanceof Error)if(Nb=oe,Nb===$CLJS.Y)try{if($CLJS.Ad(d)&&1<=$CLJS.E(d))try{var rf=$CLJS.Jk.j(d,0,1);if($CLJS.Ad(rf)&&1===$CLJS.E(rf))try{var Ue=$CLJS.cd(rf,0);if($CLJS.ge(Ue,$CLJS.Vu))return a;
throw $CLJS.Y;}catch(ef){if(ef instanceof Error){wb=ef;if(wb===$CLJS.Y)throw $CLJS.Y;throw wb;}throw ef;}else throw $CLJS.Y;}catch(ef){if(ef instanceof Error){wb=ef;if(wb===$CLJS.Y)throw $CLJS.Y;throw wb;}throw ef;}else throw $CLJS.Y;}catch(ef){if(ef instanceof Error){wb=ef;if(wb===$CLJS.Y)return $CLJS.kZ(e,c,d);throw wb;}throw ef;}else throw Nb;else throw oe;}else throw va;else throw Ee;}else throw qa;else throw Gf;}else throw u;else throw df;}else throw g;else throw Ar;}else throw g;else throw eM;
}else throw m;else throw pl;}else throw f;else throw dM;}else throw f;}else throw zr;}}($CLJS.Bf,a)},a7=function(a){var b=$CLJS.xd(a);return b?(b=!$CLJS.yf(a))?$CLJS.WD($CLJS.fe,$CLJS.Wa)($CLJS.B(a)):b:b},U6=function(a){return $CLJS.n($CLJS.WD($CLJS.fe,$CLJS.Wa)(a))?$CLJS.Ch.h($CLJS.aE(Y6(a).toLowerCase(),/_/,"-")):a},b7=function(a,b){var c=a7(b);return $CLJS.n(c)?(b=U6($CLJS.B(b)),$CLJS.vd(a)?(a=$CLJS.ih(a),a.h?a.h(b):a.call(null,b)):$CLJS.F.g(a,b)):c},Tka=function(a){return $CLJS.I.j(new $CLJS.k(null,
3,[$CLJS.gi,$CLJS.Td,$CLJS.ij,U6,$CLJS.tR,U6],null),a,function(b){var c=$CLJS.I,d=c.g;b=$CLJS.Fe([a,b]);b=c7.g?c7.g(b,d7):c7.call(null,b,d7);return d.call(c,b,a)})},Uka=function(a){a=$CLJS.eg.j($CLJS.N,$CLJS.ff.h(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);c=U6(c);var d=Tka(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.F.g($CLJS.ij.h(a),$CLJS.KP)&&$CLJS.Ta($CLJS.tR.h(a))?$CLJS.R.j(a,$CLJS.tR,$CLJS.MU):a},Vka=function(a){return $CLJS.eg.j($CLJS.N,
$CLJS.ff.h(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);c=Y6(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(Uka(b),$CLJS.S,c)],null)}),a)},e7=function(a){return $CLJS.mZ.j($CLJS.mZ.j($CLJS.bb(function(b,c){return $CLJS.mZ.j(b,c,$CLJS.Ch)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.RG,$CLJS.iH,$CLJS.iS,Wka,$CLJS.Tu,$CLJS.wi],null)),$CLJS.BF,$CLJS.Ck.g(W6,c7)),$CLJS.vR,$CLJS.eZ)},f7=function(a){return $CLJS.Gd(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hK,a,null],null):a},h7=function(a){return g7.h(f7(a))},
i7=function(a){var b=$CLJS.A(a);a=$CLJS.B(b);b=$CLJS.C(b);return Ska($CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.ff.g(g7,b)))},j7=function(a){var b=$CLJS.A(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,h7(b)],null),$CLJS.ff.g(g7,c))},k7=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,h7(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)},l7=function(a){var b=$CLJS.H(a,
0,null);a=$CLJS.H(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,h7(a)],null)},m7=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,g7.h(a)],null)},W6=function(a){return n7(function(b){if($CLJS.yd(b))return Rka(b);if($CLJS.Ta(a7(b)))return b;try{return g7.h(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.iE($CLJS.kC);if($CLJS.n($CLJS.hE("metabase.mbql.normalize",d))){var e=$CLJS.HI("Invalid clause:");e instanceof Error?$CLJS.gE("metabase.mbql.normalize",
d,$CLJS.vy.l($CLJS.G([b])),e):$CLJS.gE("metabase.mbql.normalize",d,$CLJS.vy.l($CLJS.G([e,b])),null)}throw $CLJS.ki($CLJS.GI("Invalid MBQL clause: {0}",$CLJS.G([$CLJS.bZ(c)])),new $CLJS.k(null,1,[$CLJS.GV,b],null),c);}throw f;}},a)},Xka=function(a){return $CLJS.Cf($CLJS.Ul(W6,o7(a)))},Yka=function(a){for(;;)if($CLJS.n(a7(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.He($CLJS.Ek.g(f7,a))},p7=function(a){a=$CLJS.Ge(a);var b=$CLJS.I.g(a,$CLJS.KP);return $CLJS.n(b)?$CLJS.Fk.j(a,$CLJS.KP,
g7):a},Zka=function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.me(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.Bd(e)){var f=$CLJS.kc(e),g=$CLJS.E(f),l=$CLJS.qe(g);a:for(var m=0;;)if(m<g){var t=$CLJS.md(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,p7(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.lc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);return $CLJS.de(new $CLJS.P(null,
2,5,$CLJS.Q,[l,p7(f)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},q7=function(a){a=$CLJS.Ge(a);var b=$CLJS.I.g(a,$CLJS.lU);return $CLJS.n(b)?$CLJS.Fk.j(a,$CLJS.lU,Zka):a},$ka=function(a){var b=$CLJS.Ge(a);a=$CLJS.I.g(b,$CLJS.PT);b=$CLJS.Ta(a)?r7.h?r7.h(b):r7.call(null,b):b;return $CLJS.n(a)?q7(b):b},s7=function(a){return $CLJS.vd(a)?$CLJS.A(a):null!=a},ala=function(a){var b=$CLJS.Ge(a);a=$CLJS.I.g(b,$CLJS.jQ);var c=$CLJS.I.g(b,$CLJS.XQ),d=$CLJS.I.g(b,$CLJS.xS),e=$CLJS.I.g(b,$CLJS.PT);
try{if($CLJS.n(d)){var f=$CLJS.Ge(b),g=$CLJS.I.g(f,$CLJS.xS);var l=$CLJS.vP($CLJS.vk.g(f,$CLJS.xS),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jQ,$CLJS.xS],null),g)}else l=b;var m=$CLJS.n(a)?$CLJS.Fk.j(l,$CLJS.jQ,r7):l,t=$CLJS.n(c)?$CLJS.Fk.j(m,$CLJS.XQ,$CLJS.Qe($CLJS.Ek,W6)):m,u=$CLJS.n(e)?$CLJS.Fk.j(t,$CLJS.PT,q7):t;return W6(u)}catch(v){if(v instanceof Error)throw m=v,$CLJS.ki($CLJS.GI("Error canonicalizing query: {0}",$CLJS.G([$CLJS.bZ(m)])),new $CLJS.k(null,1,[$CLJS.jQ,a],null),m);throw v;}},bla=function(a){a=
$CLJS.Ge(a);var b=$CLJS.I.g(a,$CLJS.jQ),c=$CLJS.Ge(b);b=$CLJS.I.g(c,$CLJS.mS);c=$CLJS.I.g(c,$CLJS.SI);return $CLJS.A(b)&&$CLJS.A(c)?(b=$CLJS.eg.j($CLJS.eh,$CLJS.gv,$CLJS.A($CLJS.jf($CLJS.Ua,function g(e,f){try{if($CLJS.Ad(f)&&3===$CLJS.E(f))try{var l=$CLJS.cd(f,0);if($CLJS.ge(l,$CLJS.hK)){var m=$CLJS.cd(f,1),t=$CLJS.cd(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hK,m,$CLJS.vk.g(t,$CLJS.nK)],null)],null)],null)}throw $CLJS.Y;}catch(u){if(u instanceof
Error){l=u;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw u;}else throw $CLJS.Y;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)return $CLJS.jZ(g,e,f);throw l;}throw u;}}($CLJS.Bf,b)))),$CLJS.lZ.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jQ,$CLJS.SI],null),$CLJS.Ck.g($CLJS.Cf,$CLJS.Qe($CLJS.Dk,b)))):a},cla=function(a){try{return bla(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.ki($CLJS.HI("Error performing whole query transformations"),new $CLJS.k(null,1,[$CLJS.jQ,a],null),b);}throw c;}},
dla=function(a,b){var c=$CLJS.eg.g($CLJS.ld(a),function(){return function f(e){return new $CLJS.me(null,function(){for(var g=e;;)if(g=$CLJS.A(g)){if($CLJS.Bd(g)){var l=$CLJS.kc(g),m=$CLJS.E(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var D=$CLJS.md(l,z),J=$CLJS.H(D,0,null);D=$CLJS.H(D,1,null);var K=$CLJS.ae.g(b,J);D=t7.g?t7.g(D,K):t7.call(null,D,K);null!=D&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[J,D],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.lc(g))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.B(g),v=$CLJS.H(u,0,null),x=$CLJS.H(u,1,null);u=function(){var z=x,D=$CLJS.ae.g(b,v);return t7.g?t7.g(z,D):t7.call(null,z,D)}();if(null!=u)return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Lc(g)));g=$CLJS.Lc(g)}else return null},null,null)}(a)}());return $CLJS.A(c)?c:null},ela=function(a,b){a=$CLJS.Ek.g(function(c){var d=$CLJS.ae.g(b,u7);return t7.g?t7.g(c,d):t7.call(null,c,d)},a);return $CLJS.n($CLJS.Ne($CLJS.Ua,a))?a:null},n7=function n7(a,b){return $CLJS.cZ($CLJS.Qe(n7,
a),$CLJS.Td,a.h?a.h(b):a.call(null,b))},fla=new $CLJS.M(null,"value_field","value_field",-980977878),v7=new $CLJS.M(null,"ascending","ascending",-988350486),w7=new $CLJS.M(null,"named","named",-422393479),x7=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),y7=new $CLJS.M(null,"descending","descending",-24766135),z7=new $CLJS.M(null,"binning-strategy","binning-strategy",2063329158),A7=new $CLJS.M(null,"datetime-field","datetime-field",21731696),gla=new $CLJS.M(null,"use-as-display-name?",
"use-as-display-name?",686752941),B7=new $CLJS.M(null,"field-literal","field-literal",-1295883554),C7=new $CLJS.M(null,"viz-settings","viz-settings",256055379),hla=new $CLJS.M(null,"rows","rows",850049680),ila=new $CLJS.M(null,"special-fn","special-fn",1290649344),u7=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),d7=new $CLJS.M(null,"ignore-path","ignore-path",944069061),jla=new $CLJS.M(null,"label_field","label_field",-1573182765),kla=new $CLJS.M(null,
"fk-\x3e","fk-\x3e",-499026738),lla=new $CLJS.M(null,"joined-field","joined-field",-2048778268),Wka=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var D7,mla=$CLJS.Se($CLJS.N),nla=$CLJS.Se($CLJS.N),ola=$CLJS.Se($CLJS.N),pla=$CLJS.Se($CLJS.N),qla=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));D7=new $CLJS.hi($CLJS.Ih.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Ck.g(U6,$CLJS.B),qla,mla,nla,ola,pla);D7.m(null,$CLJS.XJ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XJ,b],null);return null!=a?$CLJS.ae.g(b,a):b});
D7.m(null,$CLJS.zE,function(a){$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zE,a instanceof $CLJS.M?Y6(a):a],null)});D7.m(null,z7,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.n(a)?$CLJS.ae.g(D7.h(new $CLJS.P(null,3,5,$CLJS.Q,[z7,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[z7,c7.g?c7.g(b,d7):c7.call(null,b,d7),U6(c)],null)});
D7.m(null,$CLJS.hK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);var c=c7.g?c7.g(a,d7):c7.call(null,a,d7);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hK,b,function(){var d=$CLJS.n($CLJS.CE.h(c))?$CLJS.Fk.j(c,$CLJS.CE,$CLJS.Ch):c;d=$CLJS.n($CLJS.nK.h(c))?$CLJS.Fk.j(d,$CLJS.nK,$CLJS.Ch):d;return $CLJS.n($CLJS.cV.h(c))?$CLJS.Fk.j(d,$CLJS.cV,function(e){return $CLJS.n($CLJS.CI.h(e))?$CLJS.Fk.j(e,$CLJS.CI,$CLJS.Ch):e}):d}()],null)});
D7.m(null,B7,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[B7,b instanceof $CLJS.M?Y6(b):b,$CLJS.Ch.h(a)],null)});D7.m(null,A7,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[A7,c7.g?c7.g(b,d7):c7.call(null,b,d7),$CLJS.ou,U6(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[A7,c7.g?c7.g(b,d7):c7.call(null,b,d7),U6(c)],null)});
D7.m(null,$CLJS.tK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null),d=$CLJS.H(a,3,null);a=$CLJS.H(a,4,null);return $CLJS.n(a)?$CLJS.ae.g(D7.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.tK,b,c,d],null)),c7.g?c7.g(a,d7):c7.call(null,a,d7)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.tK,c7.g?c7.g(b,d7):c7.call(null,b,d7),$CLJS.Gd(c)?c:U6(c),U6(d)],null)});
D7.m(null,$CLJS.bN,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bN,b,U6(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bN,$CLJS.Lx],null)});D7.m(null,$CLJS.$M,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$M,b,U6(a)],null)});
D7.m(null,$CLJS.jN,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jN,c7.g?c7.g(b,d7):c7.call(null,b,d7),c,U6(a)],null)});D7.m(null,$CLJS.pN,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.pN,c7.g?c7.g(b,d7):c7.call(null,b,d7),c,U6(a)],null)});
D7.m(null,$CLJS.hN,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hN,c7.g?c7.g(b,d7):c7.call(null,b,d7),U6(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hN,c7.g?c7.g(b,d7):c7.call(null,b,d7)],null)});
D7.m(null,$CLJS.nN,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.nN,c7.g?c7.g(b,d7):c7.call(null,b,d7),U6(c),U6(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nN,c7.g?c7.g(b,d7):c7.call(null,b,d7),U6(c)],null)});
D7.m(null,$CLJS.ZM,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ZM,c7.g?c7.g(b,d7):c7.call(null,b,d7),c7.g?c7.g(c,d7):c7.call(null,c,d7),U6(a)],null)});D7.m(null,$CLJS.zj,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,b,a],null)});
D7.m(null,$CLJS.gi,function(a){var b=$CLJS.A(a);a=$CLJS.B(b);b=$CLJS.C(b);return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[U6(a)],null),$CLJS.ff.h(function(c){return c7.g?c7.g(c,d7):c7.call(null,c,d7)}),b)});
var E7=function E7(a){if($CLJS.n($CLJS.WD($CLJS.fe,$CLJS.Wa)(a))){var c=U6(a);var d=new $CLJS.dh(null,new $CLJS.k(null,18,[$CLJS.On,null,$CLJS.Ps,null,$CLJS.yK,null,$CLJS.qK,null,$CLJS.qu,null,$CLJS.iK,null,$CLJS.CK,null,$CLJS.DK,null,$CLJS.OJ,null,$CLJS.UJ,null,$CLJS.Os,null,$CLJS.PJ,null,$CLJS.vK,null,$CLJS.oK,null,$CLJS.ck,null,$CLJS.TA,null,$CLJS.bK,null,$CLJS.uK,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(a7(a))?(a=$CLJS.B(a),E7.h?E7.h(a):E7.call(null,
a)):null},rla=new $CLJS.k(null,8,[$CLJS.ij,U6,$CLJS.PT,function(a){a=V6(a);return $CLJS.A($CLJS.lU.h(a))?$CLJS.Fk.j(a,$CLJS.lU,Vka):a},$CLJS.jQ,new $CLJS.k(null,6,[$CLJS.XJ,function F7(a){if($CLJS.n($CLJS.WD($CLJS.fe,$CLJS.Wa)(a)))return U6(a);if($CLJS.n(b7(w7,a))){a=$CLJS.A(a);$CLJS.B(a);var c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[w7,F7.h?F7.h(a):F7.call(null,a)],null),c)}return $CLJS.n($CLJS.n(a7(a))?E7($CLJS.jd(a)):null)?$CLJS.Ek.g(F7,a):c7.g?c7.g(a,
d7):c7.call(null,a,d7)},$CLJS.FT,function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.me(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.Bd(e)){var f=$CLJS.kc(e),g=$CLJS.E(f),l=$CLJS.qe(g);a:for(var m=0;;)if(m<g){var t=$CLJS.md(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[Y6(u),c7.g?c7.g(t,d7):c7.call(null,t,d7)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.lc(e))):$CLJS.te($CLJS.ve(l),null)}f=
$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[Y6(l),c7.g?c7.g(f,d7):c7.call(null,f,d7)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.vV,function(a){return $CLJS.Cf(function(){return function d(c){return new $CLJS.me(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.Bd(e)){var f=$CLJS.kc(e),g=$CLJS.E(f),l=$CLJS.qe(g);a:for(var m=0;;)if(m<g){var t=$CLJS.md(f,m);t=$CLJS.n(a7(t))?D7.h(t):$CLJS.be(D7.h($CLJS.be(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.lc(e))):$CLJS.te($CLJS.ve(l),null)}l=$CLJS.B(e);return $CLJS.de($CLJS.n(a7(l))?D7.h(l):$CLJS.be(D7.h($CLJS.be(l))),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.oU,function(a){a=V6(a);a=$CLJS.Ge(a);var b=$CLJS.I.g(a,$CLJS.PT);if($CLJS.n(b))return a=$CLJS.iZ(a,new $CLJS.k(null,1,[$CLJS.PT,$CLJS.jQ],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.PT],null),a=c7.g?c7.g(a,b):c7.call(null,a,b),$CLJS.iZ(a,new $CLJS.k(null,1,[$CLJS.jQ,$CLJS.PT],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jQ],null);return c7.g?c7.g(a,b):c7.call(null,a,b)},$CLJS.xS,new $CLJS.k(null,1,[u7,e7],null),$CLJS.gR,new $CLJS.k(null,1,[u7,function(a){a=c7.g?c7.g(a,$CLJS.jQ):c7.call(null,a,$CLJS.jQ);var b=$CLJS.Ge(a),c=$CLJS.I.g(b,$CLJS.CI),d=$CLJS.I.g(b,$CLJS.SI);a=$CLJS.I.g(b,$CLJS.WI);b=$CLJS.n(c)?$CLJS.Fk.j(b,$CLJS.CI,U6):b;d=$CLJS.n($CLJS.WD($CLJS.fe,$CLJS.Wa)(d))?$CLJS.Fk.j(b,$CLJS.SI,U6):b;return $CLJS.n(a)?$CLJS.Fk.j(d,$CLJS.WI,Y6):d}],null)],null),$CLJS.qC,
new $CLJS.k(null,1,[$CLJS.TR,$CLJS.Td],null),$CLJS.XQ,new $CLJS.k(null,1,[u7,function(a){var b=$CLJS.Ge(a),c=$CLJS.I.g(b,$CLJS.ij),d=$CLJS.I.g(b,$CLJS.uN),e=$CLJS.I.g(b,$CLJS.Wi);a=$CLJS.I.g(b,x7);b=$CLJS.n(e)?$CLJS.Fk.j(b,$CLJS.Wi,Y6):b;c=$CLJS.n(c)?$CLJS.Fk.j(b,$CLJS.ij,U6):b;d=$CLJS.n(d)?$CLJS.Fk.j(c,$CLJS.uN,function(f){return c7.g?c7.g(f,d7):c7.call(null,f,d7)}):c;d=$CLJS.n(a)?$CLJS.lZ.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[x7,jla],null),function(f){return c7.g?c7.g(f,d7):c7.call(null,f,d7)}):d;return $CLJS.n(a)?
$CLJS.lZ.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[x7,fla],null),function(f){return c7.g?c7.g(f,d7):c7.call(null,f,d7)}):d}],null),$CLJS.SQ,function(a){return null==a?null:U6(a)},$CLJS.xS,new $CLJS.k(null,1,[u7,e7],null),C7,U6],null),c7=function c7(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return c7.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
c7.l=function(a,b){b=$CLJS.H(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Cf(b);b=$CLJS.A(c)?$CLJS.XD(rla,c):null;try{return $CLJS.od(b)?b.h?b.h(a):b.call(null,a):$CLJS.zd(a)?a:$CLJS.yd(a)?$CLJS.eg.g($CLJS.N,function(){return function g(f){return new $CLJS.me(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.Bd(l)){var m=$CLJS.kc(l),t=$CLJS.E(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.md(m,v),z=$CLJS.H(x,0,null);x=$CLJS.H(x,1,null);z=U6(z);z=new $CLJS.P(null,
2,5,$CLJS.Q,[z,c7.l(x,$CLJS.G([$CLJS.ae.g($CLJS.Cf(c),z)]))],null);u.add(z);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),g($CLJS.lc(l))):$CLJS.te($CLJS.ve(u),null)}m=$CLJS.B(l);u=$CLJS.H(m,0,null);m=$CLJS.H(m,1,null);u=U6(u);return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[u,c7.l(m,$CLJS.G([$CLJS.ae.g($CLJS.Cf(c),u)]))],null),g($CLJS.Lc(l)))}return null}},null,null)}(a)}()):$CLJS.n(a7(a))?D7.h(a):$CLJS.xd(a)?$CLJS.Ek.g(function(e){return c7.l(e,$CLJS.G([$CLJS.ae.g($CLJS.Cf(c),u7)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.ki($CLJS.GI("Error normalizing form: {0}",$CLJS.G([$CLJS.bZ(d)])),new $CLJS.k(null,3,[$CLJS.Su,a,$CLJS.gl,c,ila,b],null),d);}throw e;}};c7.A=1;c7.B=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.l(b,a)};
var g7=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(a7(f))?$CLJS.B(f):null},e,a,b,c,d)}();g7.m(null,$CLJS.gi,function(a){return a});
g7.m(null,$CLJS.hK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);if($CLJS.n(b7($CLJS.hK,b))){$CLJS.H(b,0,null);var c=$CLJS.H(b,1,null);b=$CLJS.H(b,2,null);return g7.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hK,c,$CLJS.He($CLJS.Uk.l($CLJS.G([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hK,b,$CLJS.He(a)],null)});
g7.m(null,$CLJS.XJ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.ud(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XJ,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XJ,b,a],null)});g7.m(null,$CLJS.oZ,function(a){$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return $CLJS.n(a7(a))?g7.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hK,a,null],null)});
g7.m(null,B7,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hK,b,new $CLJS.k(null,1,[$CLJS.CE,a],null)],null)});g7.m(null,kla,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);var c=h7(b);$CLJS.H(c,0,null);b=$CLJS.H(c,1,null);$CLJS.H(c,2,null);c=h7(a);$CLJS.H(c,0,null);a=$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hK,a,$CLJS.R.j(c,$CLJS.hR,b)],null)});
g7.m(null,lla,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=h7(a);return $CLJS.O.v($CLJS.tZ,a,$CLJS.R,$CLJS.G([$CLJS.$S,b]))});
g7.m(null,A7,function(a){var b=$CLJS.E(a);switch(b){case 3:$CLJS.H(a,0,null);b=$CLJS.H(a,1,null);var c=$CLJS.H(a,2,null);a=h7(b);b=c;$CLJS.H(a,0,null);$CLJS.H(a,1,null);c=$CLJS.H(a,2,null);c=$CLJS.Ge(c);var d=$CLJS.I.g(c,$CLJS.CE);$CLJS.Ta(d)||$CLJS.rW.g(d,b)?a=$CLJS.O.v($CLJS.tZ,a,$CLJS.R,$CLJS.G([$CLJS.nK,b])):(c=$CLJS.iE($CLJS.oC),$CLJS.n($CLJS.hE("metabase.mbql.util",c))&&(b=$CLJS.GI("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.G([b,d,$CLJS.Ph.l($CLJS.G([a]))])),
b instanceof Error?$CLJS.gE("metabase.mbql.util",c,$CLJS.vy(),b):$CLJS.gE("metabase.mbql.util",c,$CLJS.vy.l($CLJS.G([b])),null)));return a;case 4:return $CLJS.H(a,0,null),b=$CLJS.H(a,1,null),$CLJS.H(a,2,null),c=$CLJS.H(a,3,null),g7.h(new $CLJS.P(null,3,5,$CLJS.Q,[A7,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
g7.m(null,z7,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null),d=$CLJS.H(a,3,null);a=$CLJS.H(a,4,null);var e=h7(b);$CLJS.H(e,0,null);b=$CLJS.H(e,1,null);e=$CLJS.H(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hK,b,$CLJS.R.j(e,$CLJS.cV,$CLJS.Uk.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.CI,c],null),$CLJS.n(d)?$CLJS.Fe([c,d]):null,a])))],null)});
for(var G7=$CLJS.A(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,$CLJS.lt,$CLJS.nt],null)),H7=null,I7=0,J7=0;;)if(J7<I7){var K7=H7.X(null,J7);g7.m(null,K7,function(){return function(a){return i7(a)}}(G7,H7,I7,J7,K7));J7+=1}else{var L7=$CLJS.A(G7);if(L7){var M7=L7;if($CLJS.Bd(M7)){var N7=$CLJS.kc(M7),sla=$CLJS.lc(M7),tla=N7,ula=$CLJS.E(N7);G7=sla;H7=tla;I7=ula}else{var O7=$CLJS.B(M7);g7.m(null,O7,function(){return function(a){return i7(a)}}(G7,H7,I7,J7,O7,M7,L7));G7=$CLJS.C(M7);H7=null;I7=0}J7=0}else break}
g7.m(null,$CLJS.RJ,function(a){a=$CLJS.A(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RJ,h7(a),h7(b)],null),c)});g7.m(null,$CLJS.tK,function(a){a=$CLJS.A(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);b=$CLJS.C(b);var c=h7(a);a=$CLJS.n(Z6($CLJS.hK,a))?$CLJS.tZ.l(c,$CLJS.vk,$CLJS.G([$CLJS.nK])):c;return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tK,a],null),b)});
for(var P7=$CLJS.A(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.ZJ,$CLJS.sK,$CLJS.gK,$CLJS.$J,$CLJS.Ls,$CLJS.AK,$CLJS.Is,$CLJS.Ks,$CLJS.Es,$CLJS.Gs,$CLJS.NJ,$CLJS.TJ,$CLJS.LJ,$CLJS.VJ,$CLJS.MJ],null)),Q7=null,R7=0,S7=0;;)if(S7<R7){var T7=Q7.X(null,S7);g7.m(null,T7,function(){return function(a){return j7(a)}}(P7,Q7,R7,S7,T7));S7+=1}else{var U7=$CLJS.A(P7);if(U7){var V7=U7;if($CLJS.Bd(V7)){var W7=$CLJS.kc(V7),vla=$CLJS.lc(V7),wla=W7,xla=$CLJS.E(W7);P7=vla;Q7=wla;R7=xla}else{var X7=$CLJS.B(V7);g7.m(null,X7,
function(){return function(a){return j7(a)}}(P7,Q7,R7,S7,X7,V7,U7));P7=$CLJS.C(V7);Q7=null;R7=0}S7=0}else break}g7.m(null,hla,function(){return null});g7.m(null,$CLJS.WP,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WP,g7.h(b),a],null)});
g7.m(null,w7,function(a){a=$CLJS.A(a);$CLJS.B(a);a=$CLJS.C(a);var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=g7.h;var e=$CLJS.Q;b=g7.h(b);d=$CLJS.H(d,0,null);d=$CLJS.Ge(d);a=!1===$CLJS.I.g(d,gla)?new $CLJS.k(null,1,[$CLJS.S,a],null):new $CLJS.k(null,1,[$CLJS.nI,a],null);return c.call(g7,new $CLJS.P(null,3,5,e,[$CLJS.WP,b,a],null))});
for(var Y7=$CLJS.A(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TA,$CLJS.iK],null)),Z7=null,$7=0,a8=0;;)if(a8<$7){var b8=Z7.X(null,a8);g7.m(null,b8,function(){return function(a){return k7(a)}}(Y7,Z7,$7,a8,b8));a8+=1}else{var c8=$CLJS.A(Y7);if(c8){var d8=c8;if($CLJS.Bd(d8)){var e8=$CLJS.kc(d8),yla=$CLJS.lc(d8),zla=e8,Ala=$CLJS.E(e8);Y7=yla;Z7=zla;$7=Ala}else{var f8=$CLJS.B(d8);g7.m(null,f8,function(){return function(a){return k7(a)}}(Y7,Z7,$7,a8,f8,d8,c8));Y7=$CLJS.C(d8);Z7=null;$7=0}a8=0}else break}
for(var g8=$CLJS.A(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.bK,$CLJS.EK,$CLJS.OJ,$CLJS.yK,$CLJS.uK,$CLJS.On,$CLJS.ck,$CLJS.vK,$CLJS.PJ],null)),h8=null,i8=0,j8=0;;)if(j8<i8){var k8=h8.X(null,j8);g7.m(null,k8,function(){return function(a){return l7(a)}}(g8,h8,i8,j8,k8));j8+=1}else{var l8=$CLJS.A(g8);if(l8){var m8=l8;if($CLJS.Bd(m8)){var n8=$CLJS.kc(m8),Bla=$CLJS.lc(m8),Cla=n8,Dla=$CLJS.E(n8);g8=Bla;h8=Cla;i8=Dla}else{var o8=$CLJS.B(m8);g7.m(null,o8,function(){return function(a){return l7(a)}}(g8,h8,i8,j8,
o8,m8,l8));g8=$CLJS.C(m8);h8=null;i8=0}j8=0}else break}g7.m(null,$CLJS.UJ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UJ,h7(b),a],null)});
for(var p8=$CLJS.A(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oK,$CLJS.qK],null)),q8=null,r8=0,s8=0;;)if(s8<r8){var t8=q8.X(null,s8);g7.m(null,t8,function(){return function(a){return m7(a)}}(p8,q8,r8,s8,t8));s8+=1}else{var u8=$CLJS.A(p8);if(u8){var v8=u8;if($CLJS.Bd(v8)){var w8=$CLJS.kc(v8),Ela=$CLJS.lc(v8),Fla=w8,Gla=$CLJS.E(w8);p8=Ela;q8=Fla;r8=Gla}else{var x8=$CLJS.B(v8);g7.m(null,x8,function(){return function(a){return m7(a)}}(p8,q8,r8,s8,x8,v8,u8));p8=$CLJS.C(v8);q8=null;r8=0}s8=0}else break}
g7.m(null,$CLJS.CK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CK,g7.h(b),g7.h(a)],null)});
g7.m(null,$CLJS.SJ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.n(a)?$CLJS.ae.g(g7.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SJ,b],null)),c7.l(a,$CLJS.G([d7]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SJ,$CLJS.Cf(function(){return function e(d){return new $CLJS.me(null,function(){for(;;){var f=$CLJS.A(d);if(f){if($CLJS.Bd(f)){var g=$CLJS.kc(f),l=$CLJS.E(g),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.md(g,t),v=$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[g7.h(v),g7.h(u)],null);m.add(v);t+=1}else{g=!0;break a}return g?$CLJS.te($CLJS.ve(m),e($CLJS.lc(f))):$CLJS.te($CLJS.ve(m),null)}g=$CLJS.B(f);m=$CLJS.H(g,0,null);g=$CLJS.H(g,1,null);return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[g7.h(m),g7.h(g)],null),e($CLJS.Lc(f)))}return null}},null,null)}(b)}())],null)});
g7.m(null,$CLJS.EJ,function(a){a=$CLJS.A(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EJ,g7.h(a),$CLJS.F.g(0,b)?1:g7.h(b)],null),$CLJS.ff.g(g7,c))});
var o7=function o7(a){return function f(d,e){try{if($CLJS.n(function(){var x=X6($CLJS.Ed);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.Cf(e));throw $CLJS.Y;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.Y)try{if($CLJS.n(function(){var z=X6($CLJS.fe);return z.h?z.h(e):z.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Y;}catch(z){if(z instanceof Error)if(d=z,d===$CLJS.Y)try{if($CLJS.Ad(e)&&2<=$CLJS.E(e))try{var g=$CLJS.Jk.j(e,
0,2);if($CLJS.Ad(g)&&2===$CLJS.E(g))try{var l=$CLJS.cd(g,0);if($CLJS.n($CLJS.oP($CLJS.fe,$CLJS.Oe(new $CLJS.dh(null,new $CLJS.k(null,5,[$CLJS.Ps,null,$CLJS.qu,null,$CLJS.DK,null,$CLJS.Os,null,w7,null],null),null)))(l)))try{var m=$CLJS.cd(g,1);if($CLJS.n(E7(m)))return $CLJS.eg.j($CLJS.Bf,$CLJS.hv(o7),a);throw $CLJS.Y;}catch(D){if(D instanceof Error){var t=D;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw D;}else throw $CLJS.Y;}catch(D){if(D instanceof Error){t=D;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw D;
}else throw $CLJS.Y;}catch(D){if(D instanceof Error){t=D;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw D;}else throw $CLJS.Y;}catch(D){if(D instanceof Error)if(t=D,t===$CLJS.Y)try{if($CLJS.Ad(e)&&1<=$CLJS.E(e))try{var u=$CLJS.Jk.j(e,0,1);if($CLJS.Ad(u)&&1===$CLJS.E(u))try{if($CLJS.cd(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(J){if(J instanceof Error){var v=J;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw J;}else throw $CLJS.Y;}catch(J){if(J instanceof Error){v=
J;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw J;}else throw $CLJS.Y;}catch(J){if(J instanceof Error){v=J;if(v===$CLJS.Y)return e;throw v;}throw J;}else throw t;else throw D;}else throw d;else throw z;}else throw d;else throw x;}}($CLJS.Bf,a)},y8=function y8(a){return function f(d,e){try{var g=X6($CLJS.Ed);var l=g.h?g.h(e):g.call(null,e);if($CLJS.n(l))return f(d,$CLJS.Cf(e));throw $CLJS.Y;}catch(D){if(D instanceof Error)if(l=D,l===$CLJS.Y)try{if($CLJS.Ad(e)&&2===$CLJS.E(e))try{var m=$CLJS.cd(e,1);
if($CLJS.ge(m,$CLJS.eK)){var t=$CLJS.cd(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eK,t],null))}throw $CLJS.Y;}catch(J){if(J instanceof Error)if(l=J,l===$CLJS.Y)try{m=$CLJS.cd(e,1);if($CLJS.ge(m,$CLJS.YJ))return t=$CLJS.cd(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YJ,t],null));throw $CLJS.Y;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Y)try{m=$CLJS.cd(e,1);if($CLJS.ge(m,v7))return t=$CLJS.cd(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eK,t],null));throw $CLJS.Y;}catch(U){if(U instanceof
Error)if(l=U,l===$CLJS.Y)try{m=$CLJS.cd(e,1);if($CLJS.ge(m,y7))return t=$CLJS.cd(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YJ,t],null));throw $CLJS.Y;}catch(Z){if(Z instanceof Error)if(m=Z,m===$CLJS.Y)try{var v=$CLJS.cd(e,0);if($CLJS.ge(v,v7))return t=$CLJS.cd(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eK,t],null));throw $CLJS.Y;}catch(da){if(da instanceof Error)if(m=da,m===$CLJS.Y)try{v=$CLJS.cd(e,0);if($CLJS.ge(v,y7))return t=$CLJS.cd(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YJ,t],null));
throw $CLJS.Y;}catch(qa){if(qa instanceof Error)if(m=qa,m===$CLJS.Y)try{v=$CLJS.cd(e,0);if($CLJS.ge(v,$CLJS.eK))return t=$CLJS.cd(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eK,h7(t)],null);throw $CLJS.Y;}catch(va){if(va instanceof Error)if(m=va,m===$CLJS.Y)try{v=$CLJS.cd(e,0);if($CLJS.ge(v,$CLJS.YJ))return t=$CLJS.cd(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YJ,h7(t)],null);throw $CLJS.Y;}catch(sb){if(sb instanceof Error){t=sb;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw sb;}else throw m;else throw va;
}else throw m;else throw qa;}else throw m;else throw da;}else throw m;else throw Z;}else throw l;else throw U;}else throw u;}else throw K;}else throw l;else throw J;}else throw $CLJS.Y;}catch(J){if(J instanceof Error)if(l=J,l===$CLJS.Y)try{if($CLJS.Ad(e)&&0<=$CLJS.E(e))try{var x=$CLJS.Jk.j(e,0,0);if($CLJS.Ad(x)&&0===$CLJS.E(x))try{var z=$CLJS.Jk.g(e,0);if($CLJS.F.g(z,a))return $CLJS.Cf($CLJS.Wk.h($CLJS.ff.g(y8,a)));throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;
}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)return $CLJS.kZ(f,d,e);throw u;}throw K;}else throw l;else throw J;}else throw l;else throw D;}}($CLJS.Bf,a)},r7=$CLJS.Ck.g(W6,function(a){var b=s7($CLJS.XJ.h(a))?$CLJS.Fk.j(a,$CLJS.XJ,Xka):a;b=s7($CLJS.mS.h(a))?$CLJS.Fk.j(b,$CLJS.mS,Yka):b;b=s7($CLJS.SI.h(a))?$CLJS.Fk.j(b,$CLJS.SI,$CLJS.Qe($CLJS.Ek,f7)):b;b=s7($CLJS.vV.h(a))?
$CLJS.Fk.j(b,$CLJS.vV,y8):b;return s7($CLJS.oU.h(a))?$CLJS.Fk.j(b,$CLJS.oU,$ka):b}),Hla=new $CLJS.k(null,3,[$CLJS.PT,$CLJS.Td,$CLJS.jQ,new $CLJS.k(null,2,[$CLJS.oU,function(a){a=$CLJS.Ge(a);var b=$CLJS.I.g(a,$CLJS.PT);if($CLJS.n(b))return a=$CLJS.iZ(a,new $CLJS.k(null,1,[$CLJS.PT,$CLJS.jQ],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.PT],null),a=t7.g?t7.g(a,b):t7.call(null,a,b),$CLJS.iZ(a,new $CLJS.k(null,1,[$CLJS.jQ,$CLJS.PT],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jQ],null);return t7.g?t7.g(a,
b):t7.call(null,a,b)},$CLJS.gR,new $CLJS.k(null,1,[u7,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jQ],null);return t7.g?t7.g(a,b):t7.call(null,a,b)}],null)],null),C7,$CLJS.Td],null),t7=function t7(a){switch(arguments.length){case 1:return t7.h(arguments[0]);case 2:return t7.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};t7.h=function(a){return t7.g(a,$CLJS.Bf)};
t7.g=function(a,b){try{var c=$CLJS.A(b)?$CLJS.XD(Hla,b):null;return $CLJS.od(c)?c.h?c.h(a):c.call(null,a):$CLJS.zd(a)?a:$CLJS.yd(a)?dla(a,b):$CLJS.xd(a)?ela(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.ki("Error removing empty clauses from form.",new $CLJS.k(null,2,[$CLJS.Su,a,$CLJS.gl,b],null),d);throw d;}};t7.A=2;
$CLJS.z8=function(){var a=$CLJS.Ck.l(t7,cla,ala,$CLJS.G([c7]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.ki($CLJS.GI("Error normalizing query: {0}",$CLJS.G([$CLJS.bZ(c)])),new $CLJS.k(null,1,[$CLJS.jQ,b],null),c);}throw d;}}}();$CLJS.A8=function A8(a,b){if($CLJS.Ta($CLJS.A(a)))a=(0,$CLJS.z8)(b);else{var d=$CLJS.I,e=d.g;var f=$CLJS.Ju(a);b=$CLJS.Fe([$CLJS.kd(a),b]);f=A8.g?A8.g(f,b):A8.call(null,f,b);a=e.call(d,f,$CLJS.kd(a))}return a};
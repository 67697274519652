var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.schema.common.js");require("./metabase.mbql.predicates.js");require("./metabase.mbql.schema.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var ZY,dZ,gZ;$CLJS.$Y=function(a,b){if("string"===typeof b)return ZY(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.aZ=function(a,b){return $CLJS.fc($CLJS.bb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.ec(c,d):c},$CLJS.cc($CLJS.Bf),b))};$CLJS.bZ=function(a){return a instanceof Error?a.message:null};
$CLJS.cZ=function(a,b,c){if($CLJS.Zd(c)){var d=$CLJS.O.g($CLJS.V,$CLJS.ff.g(a,c));return b.h?b.h(d):b.call(null,d)}return $CLJS.yf(c)?(d=new $CLJS.Yf(function(){var e=$CLJS.Ab(c);return a.h?a.h(e):a.call(null,e)}(),function(){var e=$CLJS.Bb(c);return a.h?a.h(e):a.call(null,e)}()),b.h?b.h(d):b.call(null,d)):$CLJS.Ed(c)?(d=$CLJS.ph($CLJS.ff.g(a,c)),b.h?b.h(d):b.call(null,d)):$CLJS.zd(c)?(d=$CLJS.bb(function(e,f){return $CLJS.ae.g(e,a.h?a.h(f):a.call(null,f))},c,c),b.h?b.h(d):b.call(null,d)):$CLJS.vd(c)?
(d=$CLJS.eg.g($CLJS.ld(c),$CLJS.ff.g(a,c)),b.h?b.h(d):b.call(null,d)):b.h?b.h(c):b.call(null,c)};$CLJS.eZ=function(a){function b(c){var d=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);return"string"===typeof d?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ch.h(d),c],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null)}return dZ(function(c){return $CLJS.yd(c)?$CLJS.eg.g($CLJS.N,$CLJS.ff.g(b,c)):c},a)};$CLJS.fZ=function(a){var b=new $CLJS.Fa;for(a=$CLJS.A(a);;)if(null!=a)b=b.append($CLJS.p.h($CLJS.B(a))),a=$CLJS.C(a);else return b.toString()};
$CLJS.hZ=function(a,b,c){a=$CLJS.aE(a,/''/,"'");var d=$CLJS.tv(a,gZ);d=$CLJS.F.g($CLJS.E(d),1)?new $CLJS.P(null,2,5,$CLJS.Q,[a,""],null):d;a=$CLJS.rh(/.*\{0\}.*/,a);return $CLJS.Fu.ngettext($CLJS.Fu.msgid($CLJS.Xk(d),$CLJS.n(a)?c:""),$CLJS.aE($CLJS.aE(b,/''/,"'"),gZ,$CLJS.p.h(c)),c)};$CLJS.iZ=function(a,b){return $CLJS.bb(function(c,d){var e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return $CLJS.Id(a,e)?$CLJS.R.j(c,d,$CLJS.I.g(a,e)):c},$CLJS.O.j($CLJS.vk,a,$CLJS.Xg(b)),b)};
$CLJS.jZ=function(a,b,c){return $CLJS.yd(c)?$CLJS.gf(function(d){var e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);e=$CLJS.ae.g(b,e);return a.g?a.g(e,d):a.call(null,e,d)},$CLJS.G([c])):$CLJS.xd(c)?$CLJS.gf($CLJS.Qe(a,$CLJS.B(c)instanceof $CLJS.M?$CLJS.ae.g(b,$CLJS.B(c)):b),$CLJS.G([c])):null};
$CLJS.kZ=function(a,b,c){return $CLJS.yd(c)?$CLJS.eg.g(c,function(){return function f(e){return new $CLJS.me(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.Bd(g)){var l=$CLJS.kc(g),m=$CLJS.E(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var D=$CLJS.md(l,z),J=$CLJS.H(D,0,null),K=$CLJS.H(D,1,null);D=t;var U=$CLJS.Q,Z=J;J=$CLJS.ae.g(b,J);J=a.g?a.g(J,K):a.call(null,J,K);D.add(new $CLJS.P(null,2,5,U,[Z,J],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.lc(g))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.B(g),v=$CLJS.H(u,0,null),x=$CLJS.H(u,1,null);return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var z=$CLJS.ae.g(b,v),D=x;return a.g?a.g(z,D):a.call(null,z,D)}()],null),f($CLJS.Lc(g)))}return null}},null,null)}(c)}()):$CLJS.xd(c)?$CLJS.Ek.g($CLJS.Qe(a,$CLJS.B(c)instanceof $CLJS.M?$CLJS.ae.g(b,$CLJS.B(c)):b),c):c};
ZY=function ZY(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Cf(d);return $CLJS.de(f,new $CLJS.me(null,function(){var g=e.length;g=d.index+(1>g?1:g);return g<=b.length?(g=b.substring(g),ZY.g?ZY.g(a,g):ZY.call(null,a,g)):null},null,null))};
$CLJS.lZ=function lZ(a){switch(arguments.length){case 3:return lZ.j(arguments[0],arguments[1],arguments[2]);case 4:return lZ.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return lZ.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return lZ.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return lZ.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.y(c.slice(6),0,null))}};$CLJS.lZ.j=function(a,b,c){var d=$CLJS.A(b);b=$CLJS.B(d);if(d=$CLJS.C(d))a=$CLJS.R.j(a,b,$CLJS.lZ.j($CLJS.I.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.I.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.lZ.v=function(a,b,c,d){var e=$CLJS.A(b);b=$CLJS.B(e);if(e=$CLJS.C(e))a=$CLJS.R.j(a,b,$CLJS.lZ.v($CLJS.I.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.I.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.lZ.N=function(a,b,c,d,e){var f=$CLJS.A(b);b=$CLJS.B(f);if(f=$CLJS.C(f))a=$CLJS.R.j(a,b,$CLJS.lZ.N($CLJS.I.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var g=$CLJS.I.g(a,b);c=c.j?c.j(g,d,e):c.call(null,g,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.lZ.W=function(a,b,c,d,e,f){var g=$CLJS.A(b);b=$CLJS.B(g);if(g=$CLJS.C(g))a=$CLJS.R.j(a,b,$CLJS.lZ.W($CLJS.I.g(a,b),g,c,d,e,f));else{g=$CLJS.R.j;var l=$CLJS.I.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=g.call($CLJS.R,a,b,c)}return a};$CLJS.lZ.l=function(a,b,c,d,e,f,g){var l=$CLJS.A(b);b=$CLJS.B(l);return(l=$CLJS.C(l))?$CLJS.R.j(a,b,$CLJS.O.l($CLJS.lZ,$CLJS.I.g(a,b),l,c,d,$CLJS.G([e,f,g]))):$CLJS.R.j(a,b,$CLJS.O.l(c,$CLJS.I.g(a,b),d,e,f,$CLJS.G([g])))};
$CLJS.lZ.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);var e=$CLJS.C(d);d=$CLJS.B(e);var f=$CLJS.C(e);e=$CLJS.B(f);var g=$CLJS.C(f);f=$CLJS.B(g);g=$CLJS.C(g);return this.l(b,a,c,d,e,f,g)};$CLJS.lZ.A=6;dZ=function dZ(a,b){return $CLJS.cZ($CLJS.Qe(dZ,a),a,b)};gZ=/\{0\}/;
$CLJS.mZ=function mZ(a){switch(arguments.length){case 3:return mZ.j(arguments[0],arguments[1],arguments[2]);case 4:return mZ.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return mZ.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return mZ.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return mZ.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.y(c.slice(6),0,null))}};$CLJS.mZ.j=function(a,b,c){var d=$CLJS.Wl(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Bb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.mZ.v=function(a,b,c,d){var e=$CLJS.Wl(a,b);if($CLJS.n(e)){var f=$CLJS.R.j;e=$CLJS.Bb(e);c=c.g?c.g(e,d):c.call(null,e,d);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.mZ.N=function(a,b,c,d,e){var f=$CLJS.Wl(a,b);if($CLJS.n(f)){var g=$CLJS.R.j;f=$CLJS.Bb(f);c=c.j?c.j(f,d,e):c.call(null,f,d,e);a=g.call($CLJS.R,a,b,c)}return a};$CLJS.mZ.W=function(a,b,c,d,e,f){var g=$CLJS.Wl(a,b);if($CLJS.n(g)){var l=$CLJS.R.j;g=$CLJS.Bb(g);c=c.v?c.v(g,d,e,f):c.call(null,g,d,e,f);a=l.call($CLJS.R,a,b,c)}return a};$CLJS.mZ.l=function(a,b,c,d,e,f,g){var l=$CLJS.Wl(a,b);return $CLJS.n(l)?$CLJS.R.j(a,b,$CLJS.O.l(c,$CLJS.Bb(l),d,e,f,$CLJS.G([g]))):a};
$CLJS.mZ.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);var e=$CLJS.C(d);d=$CLJS.B(e);var f=$CLJS.C(e);e=$CLJS.B(f);var g=$CLJS.C(f);f=$CLJS.B(g);g=$CLJS.C(g);return this.l(b,a,c,d,e,f,g)};$CLJS.mZ.A=6;$CLJS.nZ=new $CLJS.M(null,"unique-alias-fn","unique-alias-fn",32555420);$CLJS.oZ=new $CLJS.M(null,"field-id","field-id",-353751335);$CLJS.pZ=new $CLJS.M("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);
$CLJS.qZ=new $CLJS.M(null,"name-key-fn","name-key-fn",-1634839199);var ffa=$CLJS.Ig([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rN,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VM,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hN,$CLJS.aN],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hN,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oN,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gN,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kN,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OM,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lN,null],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.hN,$CLJS.SM],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hN,$CLJS.fN],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UM,null],null)],[$CLJS.$k,$CLJS.gL,$CLJS.sN,$CLJS.YM,$CLJS.Ai,$CLJS.cl,$CLJS.bl,$CLJS.dl,$CLJS.al,$CLJS.YM,$CLJS.tN,$CLJS.dL]),sZ;$CLJS.ih($CLJS.ff.g($CLJS.B,$CLJS.Xg(ffa)));var rZ,gfa=$CLJS.Se($CLJS.N),hfa=$CLJS.Se($CLJS.N),ifa=$CLJS.Se($CLJS.N),jfa=$CLJS.Se($CLJS.N),kfa=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));
rZ=new $CLJS.hi($CLJS.Ih.g("metabase.mbql.util","negate*"),$CLJS.B,kfa,gfa,hfa,ifa,jfa);rZ.m(null,$CLJS.nt,function(a){$CLJS.H(a,0,null);return $CLJS.H(a,1,null)});rZ.m(null,$CLJS.st,function(a){a=$CLJS.A(a);$CLJS.B(a);a=$CLJS.C(a);return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.lt],null),$CLJS.ff.g(rZ,a))});rZ.m(null,$CLJS.lt,function(a){a=$CLJS.A(a);$CLJS.B(a);a=$CLJS.C(a);return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.st],null),$CLJS.ff.g(rZ,a))});
rZ.m(null,$CLJS.Ls,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AK,b,a],null)});rZ.m(null,$CLJS.AK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ls,b,a],null)});rZ.m(null,$CLJS.Es,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,b,a],null)});
rZ.m(null,$CLJS.Is,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,b,a],null)});rZ.m(null,$CLJS.Gs,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Is,b,a],null)});rZ.m(null,$CLJS.Ks,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,b,a],null)});
rZ.m(null,$CLJS.MJ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Is,b,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,b,a],null)],null)});rZ.m(null,$CLJS.gK,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,a],null)});rZ.m(null,$CLJS.ZJ,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,a],null)});
rZ.m(null,$CLJS.sK,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,a],null)});
sZ=function sZ(a){return $CLJS.yd(a)?$CLJS.He($CLJS.eg.g($CLJS.N,function(){return function e(d){return new $CLJS.me(null,function(){for(var f=d;;)if(f=$CLJS.A(f)){if($CLJS.Bd(f)){var g=$CLJS.kc(f),l=$CLJS.E(g),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.md(g,t),v=$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);u=sZ.h?sZ.h(u):sZ.call(null,u);null!=u&&m.add(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null));t+=1}else{g=!0;break a}return g?$CLJS.te($CLJS.ve(m),e($CLJS.lc(f))):$CLJS.te($CLJS.ve(m),null)}g=$CLJS.B(f);
m=$CLJS.H(g,0,null);g=$CLJS.H(g,1,null);g=sZ.h?sZ.h(g):sZ.call(null,g);if(null!=g)return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[m,g],null),e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(a)}())):$CLJS.xd(a)?$CLJS.He($CLJS.eg.g($CLJS.ld(a),$CLJS.jf($CLJS.Ua,$CLJS.ff.g(sZ,a)))):a};
$CLJS.tZ=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var g=Array(arguments.length-2);f<g.length;)g[f]=arguments[f+2],++f;f=new $CLJS.y(g,0,null)}return b.call(this,c,d,f)}function b(c,d,e){var f=$CLJS.H(c,0,null),g=$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);d=$CLJS.He(sZ($CLJS.O.j(d,c,e)));return $CLJS.n(d)?new $CLJS.P(null,3,5,$CLJS.Q,[f,g,d],null):$CLJS.F.g(f,$CLJS.hK)?new $CLJS.P(null,3,5,$CLJS.Q,[f,g,null],null):new $CLJS.P(null,2,5,$CLJS.Q,[f,g],null)}a.A=2;a.B=function(c){var d=
$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.Lc(c);return b(d,e,c)};a.l=b;return a}();
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var KK,MK,NK,QK,SK,TK,UK,VK,WK,aaa,baa,caa,daa,eaa,faa,gaa,YK,haa;$CLJS.JK=new $CLJS.M(null,"supported-field","supported-field",-2061545519);KK=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.LK=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);MK=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
NK=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.OK=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$CLJS.PK=new $CLJS.M(null,"column-name","column-name",551523580);QK=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.RK=new $CLJS.M(null,"display-info","display-info",-816930907);SK=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);
TK=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);UK=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);VK=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);WK=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);aaa=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);baa=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);
caa=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);daa=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);eaa=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);faa=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.XK=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);gaa=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);
YK=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);$CLJS.ZK=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);haa=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.CJ($CLJS.TA,$CLJS.G([$CLJS.qu,$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YI],null)],null)],null)],null)]));$CLJS.CJ($CLJS.iK,$CLJS.G([$CLJS.qu,$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YI],null)],null)],null)],null)]));
$CLJS.AJ($CLJS.bK,$CLJS.G([$CLJS.qu,$CLJS.NH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lJ],null)],null)]));$CLJS.AJ($CLJS.OJ,$CLJS.G([$CLJS.qu,$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YI],null)],null)]));$CLJS.AJ($CLJS.qK,$CLJS.G([$CLJS.qu,$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XI],null)],null)]));
$CLJS.AJ($CLJS.ck,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fJ],null)],null)]));$CLJS.tJ($CLJS.ck,$CLJS.eJ);$CLJS.AJ($CLJS.vK,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lJ],null)],null)]));$CLJS.tJ($CLJS.vK,$CLJS.eJ);$CLJS.AJ($CLJS.On,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fJ],null)],null)]));$CLJS.tJ($CLJS.On,$CLJS.eJ);
$CLJS.X(VK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.st,new $CLJS.k(null,1,[$CLJS.Au,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.AJ($CLJS.UJ,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VK],null)]));$CLJS.tJ($CLJS.UJ,$CLJS.eJ);
$CLJS.AJ($CLJS.oK,$CLJS.G([$CLJS.qu,$CLJS.NH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XI],null)],null)]));$CLJS.AJ($CLJS.yK,$CLJS.G([$CLJS.qu,$CLJS.NH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lJ],null)],null)]));$CLJS.AJ($CLJS.uK,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lJ],null)],null)]));
$CLJS.AJ($CLJS.EK,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lJ],null)],null)]));$CLJS.tJ($CLJS.uK,$CLJS.eJ);$CLJS.tJ($CLJS.EK,$CLJS.eJ);$CLJS.AJ($CLJS.CK,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XI],null)],null)]));$CLJS.tJ($CLJS.CK,$CLJS.eJ);
$CLJS.AJ($CLJS.PJ,$CLJS.G([$CLJS.qu,$CLJS.NH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lJ],null)],null)]));$CLJS.X(YK,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.lt,gaa,eaa,TK,MK,KK,SK,UK,WK,aaa,daa,baa,haa,faa,NK,caa,$CLJS.ul],null));$CLJS.X($CLJS.LK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vj,new $CLJS.k(null,1,[$CLJS.On,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YK],null)],null));
$CLJS.$K=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.k(null,4,[$CLJS.aK,$CLJS.TA,$CLJS.OK,!1,$CLJS.XK,$CLJS.FJ,$CLJS.RK,function(){return new $CLJS.k(null,3,[$CLJS.nI,$CLJS.HI("Count of rows"),$CLJS.PK,$CLJS.HI("Count"),$CLJS.iv,$CLJS.HI("Total number of rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.aK,$CLJS.uK,$CLJS.JK,$CLJS.Zj,$CLJS.OK,!0,$CLJS.XK,$CLJS.FJ,$CLJS.RK,function(){return new $CLJS.k(null,3,[$CLJS.nI,$CLJS.HI("Sum of ..."),$CLJS.PK,$CLJS.HI("Sum"),$CLJS.iv,$CLJS.HI("Sum of all the values of a column.")],
null)}],null),new $CLJS.k(null,5,[$CLJS.aK,$CLJS.bK,$CLJS.JK,$CLJS.Zj,$CLJS.OK,!0,$CLJS.XK,$CLJS.FJ,$CLJS.RK,function(){return new $CLJS.k(null,3,[$CLJS.nI,$CLJS.HI("Average of ..."),$CLJS.PK,$CLJS.HI("Average"),$CLJS.iv,$CLJS.HI("Average of all the values of a column")],null)}],null),new $CLJS.k(null,5,[$CLJS.aK,$CLJS.vK,$CLJS.JK,$CLJS.Zj,$CLJS.OK,!0,$CLJS.XK,$CLJS.dK,$CLJS.RK,function(){return new $CLJS.k(null,3,[$CLJS.nI,$CLJS.HI("Median of ..."),$CLJS.PK,$CLJS.HI("Median"),$CLJS.iv,$CLJS.HI("Median of all the values of a column")],
null)}],null),new $CLJS.k(null,5,[$CLJS.aK,$CLJS.OJ,$CLJS.JK,$CLJS.Dt,$CLJS.OK,!0,$CLJS.XK,$CLJS.FJ,$CLJS.RK,function(){return new $CLJS.k(null,3,[$CLJS.nI,$CLJS.HI("Number of distinct values of ..."),$CLJS.PK,$CLJS.HI("Distinct values"),$CLJS.iv,$CLJS.HI("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.aK,$CLJS.EK,$CLJS.JK,$CLJS.Zj,$CLJS.OK,!0,$CLJS.XK,$CLJS.FJ,$CLJS.RK,function(){return new $CLJS.k(null,3,[$CLJS.nI,$CLJS.HI("Cumulative sum of ..."),
$CLJS.PK,$CLJS.HI("Sum"),$CLJS.iv,$CLJS.HI("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.k(null,4,[$CLJS.aK,$CLJS.iK,$CLJS.OK,!1,$CLJS.XK,$CLJS.FJ,$CLJS.RK,function(){return new $CLJS.k(null,3,[$CLJS.nI,$CLJS.HI("Cumulative count of rows"),$CLJS.PK,$CLJS.HI("Count"),$CLJS.iv,$CLJS.HI("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.k(null,5,[$CLJS.aK,$CLJS.yK,$CLJS.JK,$CLJS.Zj,$CLJS.OK,
!0,$CLJS.XK,$CLJS.rK,$CLJS.RK,function(){return new $CLJS.k(null,3,[$CLJS.nI,$CLJS.HI("Standard deviation of ..."),$CLJS.PK,$CLJS.HI("SD"),$CLJS.iv,$CLJS.HI("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.aK,$CLJS.On,$CLJS.JK,$CLJS.Vi,$CLJS.OK,!0,$CLJS.XK,$CLJS.FJ,$CLJS.RK,function(){return new $CLJS.k(null,3,[$CLJS.nI,$CLJS.HI("Minimum of ..."),$CLJS.PK,$CLJS.HI("Min"),$CLJS.iv,$CLJS.HI("Minimum value of a column")],
null)}],null),new $CLJS.k(null,5,[$CLJS.aK,$CLJS.ck,$CLJS.JK,$CLJS.Vi,$CLJS.OK,!0,$CLJS.XK,$CLJS.FJ,$CLJS.RK,function(){return new $CLJS.k(null,3,[$CLJS.nI,$CLJS.HI("Maximum of ..."),$CLJS.PK,$CLJS.HI("Max"),$CLJS.iv,$CLJS.HI("Maximum value of a column")],null)}],null)],null);
$CLJS.X(QK,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.ZK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aK,$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ht],null),$CLJS.ff.h($CLJS.aK),$CLJS.$K)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JK,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.Ui],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OK,$CLJS.Ct],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.XK,$CLJS.Ui],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RK,$CLJS.od],null)],null));
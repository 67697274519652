var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.domain_entities.converters.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var Kla,Lla,Nla,Ola,Pla,Qla,Sla;Kla=function(a,b){return $CLJS.cE(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};Lla=function(a,b){return $CLJS.fc($CLJS.bb(function(c,d){return $CLJS.hg.j(c,a.h?a.h(d):a.call(null,d),d)},$CLJS.cc($CLJS.N),b))};Nla=function(a){return $CLJS.eg.j($CLJS.eh,$CLJS.Ck.g($CLJS.hv(function(b){return $CLJS.$Y(b,a)}),$CLJS.ff.h($CLJS.jd)),Mla)};
Ola=function(a){return new $CLJS.k(null,3,[$CLJS.ij,$CLJS.HE,$CLJS.S,a,$CLJS.Wi,$CLJS.p.h($CLJS.FI())],null)};
Pla=function(a){a=$CLJS.Ge(a);var b=$CLJS.I.g(a,$CLJS.S);return $CLJS.Uk.l($CLJS.G([a,function(){var c=$CLJS.qh(/^#(\d+)(-[a-z0-9-]*)?$/,b);$CLJS.n(c)?($CLJS.H(c,0,null),c=$CLJS.H(c,1,null),c=$CLJS.e_(c)):c=null;return $CLJS.n(c)?new $CLJS.k(null,2,[$CLJS.ij,$CLJS.YQ,$CLJS.KU,c],null):null}(),function(){var c=0==b.lastIndexOf("snippet:",0)?(0,$CLJS.ma)(b.substring(8)):null;return $CLJS.n(c)?new $CLJS.k(null,2,[$CLJS.ij,$CLJS.hQ,$CLJS.UP,c],null):null}(),$CLJS.n($CLJS.nI.h(a))?null:new $CLJS.k(null,
1,[$CLJS.nI,$CLJS.M1.g($CLJS.L1,b)],null)]))};$CLJS.F8=function(a){a=$CLJS.O0($CLJS.R0(a));return $CLJS.n($CLJS.XD(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zu,Qla],null)))?$CLJS.ae.g($CLJS.eh,$CLJS.QR):$CLJS.eh};$CLJS.G8=function(a,b){var c=$CLJS.F8(a);return(0,$CLJS.B_)(a,0,function(d){var e=$CLJS.Pv.g(Rla,c);d=$CLJS.O.j($CLJS.vk,d,e);d=$CLJS.Uk.l($CLJS.G([d,$CLJS.bh(b,c)]));$CLJS.Pv.g(c,$CLJS.ih($CLJS.Xg(b)));$CLJS.q0(a,0);return d})};
Qla=new $CLJS.M(null,"native-requires-specified-collection","native-requires-specified-collection",1239149925);Sla=new $CLJS.M("metabase.lib.native","native-extras","metabase.lib.native/native-extras",1125545077);var H8,Mla,Rla;
H8=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qt,$CLJS.Tj,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ht,$CLJS.HE,$CLJS.hQ,$CLJS.YQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,$CLJS.Tj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,$CLJS.sI],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nI,new $CLJS.k(null,2,[$CLJS.gF,"display-name",$CLJS.Jt,!0],null),$CLJS.sI],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UP,new $CLJS.k(null,2,[$CLJS.gF,
"snippet-name",$CLJS.Jt,!0],null),$CLJS.sI],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KU,new $CLJS.k(null,2,[$CLJS.gF,"card-id",$CLJS.Jt,!0],null),$CLJS.yt],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KP,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Dt],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tR,new $CLJS.k(null,2,[$CLJS.gF,"widget-type",$CLJS.Jt,!0],null),$CLJS.Tj],null)],null)],null);
Mla=new $CLJS.P(null,3,5,$CLJS.Q,[/\{\{\s*([A-Za-z0-9_\.]+)\s*\}\}/,/\{\{\s*(snippet:\s*[^}]+)\s*\}\}/,/\{\{\s*(#([0-9]*)(-[a-z0-9-]*)?)\s*\}\}/],null);
$CLJS.I8=function(){function a(d,e){d=$CLJS.He(Nla(d));var f=$CLJS.He($CLJS.ih($CLJS.Xg(e)));if($CLJS.n($CLJS.n(d)?d:f)){var g=$CLJS.Pv.g(d,f);d=$CLJS.Pv.g(f,d);if($CLJS.F.l(1,$CLJS.E(g),$CLJS.G([$CLJS.E(d)]))){d=$CLJS.B(d);g=$CLJS.B(g);f=$CLJS.I.g(e,d);var l=$CLJS.F.g($CLJS.nI.h(f),$CLJS.M1.g($CLJS.L1,d))?$CLJS.M1.g($CLJS.L1,g):$CLJS.nI.h(f);f=$CLJS.R.l($CLJS.vk.l(f,$CLJS.UP,$CLJS.G([$CLJS.KU])),$CLJS.nI,l,$CLJS.G([$CLJS.S,g]));e=$CLJS.R.j($CLJS.vk.g(e,d),g,f)}else e=$CLJS.Uk.l($CLJS.G([Kla($CLJS.Oe(d),
e),Lla($CLJS.S,$CLJS.ff.g(Ola,g))]));e=$CLJS.Lu(e,Pla)}else e=$CLJS.N;return e}function b(d){return $CLJS.I8.g?$CLJS.I8.g(d,null):$CLJS.I8.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.Tla=$CLJS.XE(H8);$CLJS.Ula=$CLJS.WE(H8);Rla=new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.QR,null],null),null);
$CLJS.X(Sla,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QR,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.sI],null)],null));
$CLJS.J8=function(){function a(d,e,f,g){var l=$CLJS.I8.h(e);return $CLJS.G8($CLJS.I1.g(d,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ZZ(new $CLJS.k(null,4,[$CLJS.Aj,$CLJS.JZ,$CLJS.VY,f,$CLJS.lU,l,$CLJS.PT,e],null))],null)),g)}function b(d,e){return $CLJS.J8.v?$CLJS.J8.v(d,e,null,null):$CLJS.J8.call(null,d,e,null,null)}var c=null;c=function(d,e,f,g){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,g)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
$CLJS.K8=function(){function a(d,e,f){$CLJS.q0(d,0);return $CLJS.G8($CLJS.I1.g(e,$CLJS.aP.h(d)),f)}function b(d,e){return $CLJS.K8.j?$CLJS.K8.j(d,e,null):$CLJS.K8.call(null,d,e,null)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
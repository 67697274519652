var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$moment.js");require("./clojure.string.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.i18n.js");
'use strict';var V$=function(a){return new $CLJS.me(null,function(){return $CLJS.de(a.o?a.o():a.call(null),V$(a))},null,null)},W$=function(a,b){return new $CLJS.me(null,function(){var c=$CLJS.A(b);if(c){var d=$CLJS.B(c),e=a.h?a.h(d):a.call(null,d),f=$CLJS.de(d,$CLJS.ew(function(g){return $CLJS.F.g(e,a.h?a.h(g):a.call(null,g))},$CLJS.C(c)));return $CLJS.de(f,W$(a,new $CLJS.me(null,function(){return $CLJS.Ye($CLJS.E(f),c)},null,null)))}return null},null,null)},Bpa=function(a,b){switch(a){case "minutes":return $CLJS.hZ("Minute",
"Minutes",b);case "hours":return $CLJS.hZ("Hour","Hours",b);case "days":return $CLJS.hZ("Day","Days",b);case "weeks":return $CLJS.hZ("Week","Weeks",b);case "months":return $CLJS.hZ("Month","Months",b);case "quarters":return $CLJS.hZ("Quarter","Quarters",b);case "years":return $CLJS.hZ("Year","Years",b);default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}},Cpa=function(a,b,c){b=parseInt(b);c=Bpa(c,b);a=new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.F.g(b,1)],null);if($CLJS.F.g(new $CLJS.P(null,
2,5,$CLJS.Q,["past",!0],null),a))return $CLJS.GI("Previous {0}",$CLJS.G([c]));if($CLJS.F.g(new $CLJS.P(null,2,5,$CLJS.Q,["past",!1],null),a))return $CLJS.GI("Previous {0} {1}",$CLJS.G([b,c]));if($CLJS.F.g(new $CLJS.P(null,2,5,$CLJS.Q,["next",!0],null),a))return $CLJS.GI("Next {0}",$CLJS.G([c]));if($CLJS.F.g(new $CLJS.P(null,2,5,$CLJS.Q,["next",!1],null),a))return $CLJS.GI("Next {0} {1}",$CLJS.G([b,c]));throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));},Epa=function(a){return $CLJS.aE(a,
Dpa,function(b){return["\\",$CLJS.p.h(b)].join("")})},X$=function(a,b,c,d,e){this.tag=a;this.source=b;this.S=c;this.G=d;this.J=e;this.C=2230716170;this.I=139264},Gpa=function(a){var b=$CLJS.tv(a,Fpa),c=$CLJS.ff.g($CLJS.B,$CLJS.$Y(Y$,a)),d=function(){var e=$CLJS.E(b),f=$CLJS.E(c);return e>f?e:f}();a=$CLJS.cf.g(b,$CLJS.Xe(d-$CLJS.E(b),V$($CLJS.Pe(""))));d=$CLJS.cf.g(c,$CLJS.Xe(d-$CLJS.E(c),V$($CLJS.Pe(""))));a=$CLJS.af.g(a,d);return $CLJS.ff.g(function(e){var f=$CLJS.qh(Y$,e);return $CLJS.n(f)?($CLJS.H(f,
0,null),f=$CLJS.H(f,1,null),new X$(f,e,null,null,null)):e},a)},Hpa=function(a){return $CLJS.gf(function(b){return"string"===typeof $CLJS.B(b)?new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.fZ(b)],null):b},$CLJS.G([W$(function(b){return"string"===typeof b||$CLJS.Ta($CLJS.zj.h(b))},a)]))},Ipa=function(a,b,c,d){return $CLJS.ff.g(function(e){if(e instanceof X$){var f=$CLJS.R.j;var g=$CLJS.Cj.h(e),l=$CLJS.I.g(a,g);g=$CLJS.zj.h(l);l=$CLJS.ij.h(l);if($CLJS.n(g))try{var m=Z$.j(l,g,b);var t=$CLJS.n(c)?Epa(m):m}catch(u){if(u instanceof
Error)t=Z$.j($CLJS.gi,g,b);else throw u;}else t=null;e=f.call($CLJS.R,e,$CLJS.zj,t)}return e},d)},Lpa=function(a){a=$CLJS.fZ($CLJS.ff.g(function(b){return b instanceof X$?b:$CLJS.aE(b,Jpa,"")},a));return $CLJS.aE(a,Kpa,$CLJS.jd)},Mpa=function(a){return $CLJS.YD($CLJS.B($CLJS.A8(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.XQ],null),new $CLJS.P(null,1,5,$CLJS.Q,[a],null))),$CLJS.Ch)};var Z$=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.shared.parameters.parameters","formatted-value"),function(f){return $CLJS.Ch.h(f)},e,a,b,c,d)}();Z$.m(null,$CLJS.DS,function(a,b,c){return(0,$CLJS.NM)(b).locale(c).format("MMMM D, YYYY")});
Z$.m(null,$CLJS.bR,function(a,b,c){a=(0,$CLJS.NM)(b,"YYYY-MM").locale(c);return $CLJS.n(a.isValid())?a.format("MMMM, YYYY"):""});Z$.m(null,$CLJS.ZR,function(a,b,c){a=(0,$CLJS.NM)(b,"[Q]Q-YYYY").locale(c);return $CLJS.n(a.isValid())?a.format("[Q]Q, YYYY"):""});Z$.m(null,$CLJS.EQ,function(a,b,c){b=$CLJS.tv(b,/~/);a=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return $CLJS.n($CLJS.n(a)?b:a)?[$CLJS.p.h(Z$.j($CLJS.DS,a,c))," - ",$CLJS.p.h(Z$.j($CLJS.DS,b,c))].join(""):""});
Z$.m(null,$CLJS.$R,function(a,b){function c(d,e){return $CLJS.C($CLJS.rh(d,e))}if(c(/^today$/,b)||c(/^thisday$/,b))return $CLJS.HI("Today");if(c(/^thisweek$/,b))return $CLJS.HI("This Week");if(c(/^thismonth$/,b))return $CLJS.HI("This Month");if(c(/^thisquarter$/,b))return $CLJS.HI("This Quarter");if(c(/^thisyear$/,b))return $CLJS.HI("This Year");if(c(/^past1days$/,b))return $CLJS.HI("Yesterday");if(c(/^next1days$/,b))return $CLJS.HI("Tomorrow");if(a=c(/^(past|next)([0-9]+)([a-z]+)~?$/,b))return $CLJS.O.g(Cpa,
a);throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));});Z$.m(null,$CLJS.IV,function(a,b,c){function d(e,f){return $CLJS.jd($CLJS.rh(e,f))}a=d(/^(this[a-z]+)$/,b);if($CLJS.n(a))return Z$.j($CLJS.$R,a,c);a=d(/^~?([0-9-T:]+)~?$/,b);if($CLJS.n(a))return Z$.j($CLJS.DS,a,c);a=d(/^([0-9-T:]+~[0-9-T:]+)$/,b);return $CLJS.n(a)?Z$.j($CLJS.EQ,a,c):Z$.j($CLJS.$R,b,c)});
Z$.m(null,$CLJS.gi,function(a,b){$CLJS.xd(b)?(a=$CLJS.E(b),b=$CLJS.n($CLJS.F.g?$CLJS.F.g(1,a):$CLJS.F.call(null,1,a))?$CLJS.p.h($CLJS.B(b)):$CLJS.n($CLJS.F.g?$CLJS.F.g(2,a):$CLJS.F.call(null,2,a))?$CLJS.GI("{0} and {1}",$CLJS.G([$CLJS.B(b),$CLJS.jd(b)])):$CLJS.GI("{0}, {1}, and {2}",$CLJS.G([$CLJS.Mu(", ",$CLJS.fw(2,b)),$CLJS.cd(b,$CLJS.E(b)-2),$CLJS.kd(b)]))):b=$CLJS.p.h(b);return b});var Dpa=/[\\\/*_`'\[\](){}<>#+-.!$@%^&=|\?~]/,Y$=/\{\{\s*([A-Za-z0-9_\.]+?)\s*\}\}/,Fpa=/\{\{\s*[A-Za-z0-9_\.]+?\s*\}\}/;
$CLJS.h=X$.prototype;$CLJS.h.toString=function(){var a=$CLJS.zj.h(this);return $CLJS.n(a)?a:this.source};$CLJS.h.pa=function(a,b){return this.ca(null,b,null)};$CLJS.h.ca=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "tag":return this.tag;case "source":return this.source;default:return $CLJS.I.j(this.G,b,c)}};$CLJS.h.Cb=function(a,b,c){return $CLJS.bb(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.da=function(a,b,c){return $CLJS.vh(b,function(d){return $CLJS.vh(b,$CLJS.Bh,""," ","",c,d)},"#metabase.shared.parameters.parameters.TextParam{",", ","}",c,$CLJS.cf.g(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,this.tag],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tu,this.source],null)],null),this.G))};$CLJS.h.Da=function(){return new $CLJS.Ku(this,2,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,$CLJS.Tu],null),$CLJS.n(this.G)?$CLJS.qc(this.G):$CLJS.Je())};$CLJS.h.O=function(){return this.S};
$CLJS.h.ha=function(){return 2+$CLJS.E(this.G)};$CLJS.h.fa=function(){var a=this.J;return null!=a?a:this.J=a=-884441273^$CLJS.Qc(this)};$CLJS.h.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.tag,b.tag)&&$CLJS.F.g(this.source,b.source)&&$CLJS.F.g(this.G,b.G)};
$CLJS.h.Mb=function(a,b){return $CLJS.Id(new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.Tu,null,$CLJS.Cj,null],null),null),b)?$CLJS.vk.g($CLJS.Lb($CLJS.eg.g($CLJS.N,this),this.S),b):new X$(this.tag,this.source,this.S,$CLJS.He($CLJS.vk.g(this.G,b)),null)};$CLJS.h.kb=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "tag":case "source":return!0;default:return $CLJS.Id(this.G,b)}};
$CLJS.h.na=function(a,b,c){return $CLJS.n($CLJS.ge.g?$CLJS.ge.g($CLJS.Cj,b):$CLJS.ge.call(null,$CLJS.Cj,b))?new X$(c,this.source,this.S,this.G,null):$CLJS.n($CLJS.ge.g?$CLJS.ge.g($CLJS.Tu,b):$CLJS.ge.call(null,$CLJS.Tu,b))?new X$(this.tag,c,this.S,this.G,null):new X$(this.tag,this.source,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.h.ga=function(){return $CLJS.A($CLJS.cf.g(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.Yf($CLJS.Cj,this.tag),new $CLJS.Yf($CLJS.Tu,this.source)],null),this.G))};
$CLJS.h.P=function(a,b){return new X$(this.tag,this.source,b,this.G,this.J)};$CLJS.h.ja=function(a,b){return $CLJS.Ad(b)?this.na(null,$CLJS.md(b,0),$CLJS.md(b,1)):$CLJS.bb($CLJS.kb,this,b)};var Jpa=/\[\[.+\]\]/,Kpa=/\[\[(.+?)\]\]/,$$=function $$(a){switch(arguments.length){case 2:return $$.g(arguments[0],arguments[1]);case 4:return $$.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.Aa("metabase.shared.parameters.parameters.substitute_tags",$$);$$.g=function(a,b){return $$.v(a,b,"en",!0)};$$.v=function(a,b,c,d){return $CLJS.n(a)?(b=$CLJS.ED(b),b=$CLJS.Lu(b,Mpa),Lpa(Hpa(Ipa(b,c,d,Gpa(a))))):null};$$.A=4;module.exports={tag_names:function(a){a=$CLJS.ih($CLJS.ff.g($CLJS.jd,$CLJS.$Y(Y$,$CLJS.n(a)?a:"")));return $CLJS.Xk(a)},substitute_tags:$$};
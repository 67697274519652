var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");
'use strict';var Jv,Kv,Mv,Ov,Rv,Sv,Tv;
$CLJS.Iv=function(a,b,c){return function(){function d(t,u,v){return a.N?a.N(b,c,t,u,v):a.call(null,b,c,t,u,v)}function e(t,u){return a.v?a.v(b,c,t,u):a.call(null,b,c,t,u)}function f(t){return a.j?a.j(b,c,t):a.call(null,b,c,t)}function g(){return a.g?a.g(b,c):a.call(null,b,c)}var l=null,m=function(){function t(v,x,z,D){var J=null;if(3<arguments.length){J=0;for(var K=Array(arguments.length-3);J<K.length;)K[J]=arguments[J+3],++J;J=new $CLJS.y(K,0,null)}return u.call(this,v,x,z,J)}function u(v,x,z,D){return $CLJS.O.l(a,
b,c,v,x,$CLJS.G([z,D]))}t.A=3;t.B=function(v){var x=$CLJS.B(v);v=$CLJS.C(v);var z=$CLJS.B(v);v=$CLJS.C(v);var D=$CLJS.B(v);v=$CLJS.Lc(v);return u(x,z,D,v)};t.l=u;return t}();l=function(t,u,v,x){switch(arguments.length){case 0:return g.call(this);case 1:return f.call(this,t);case 2:return e.call(this,t,u);case 3:return d.call(this,t,u,v);default:var z=null;if(3<arguments.length){z=0;for(var D=Array(arguments.length-3);z<D.length;)D[z]=arguments[z+3],++z;z=new $CLJS.y(D,0,null)}return m.l(t,u,v,z)}throw Error("Invalid arity: "+
arguments.length);};l.A=3;l.B=m.B;l.o=g;l.h=f;l.g=e;l.j=d;l.l=m.l;return l}()};Jv=function(a,b){return $CLJS.F.g(a,b)?new $CLJS.P(null,3,5,$CLJS.Q,[null,null,a],null):new $CLJS.P(null,3,5,$CLJS.Q,[a,b,null],null)};Kv=function(a){return $CLJS.A(a)?$CLJS.bb(function(b,c){var d=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);return $CLJS.R.j(b,d,c)},$CLJS.Cf($CLJS.$e($CLJS.O.g($CLJS.zk,$CLJS.Xg(a)),null)),a):null};
Mv=function(a,b,c){var d=$CLJS.I.g(a,c),e=$CLJS.I.g(b,c),f=$CLJS.Lv(d,e),g=$CLJS.H(f,0,null),l=$CLJS.H(f,1,null);f=$CLJS.H(f,2,null);a=$CLJS.Id(a,c);b=$CLJS.Id(b,c);d=a&&b&&(null!=f||null==d&&null==e);return new $CLJS.P(null,3,5,$CLJS.Q,[!a||null==g&&d?null:$CLJS.Fe([c,g]),!b||null==l&&d?null:$CLJS.Fe([c,l]),d?$CLJS.Fe([c,f]):null],null)};
Ov=function(a,b){var c=$CLJS.ff.g,d=Nv.j,e=$CLJS.Ad(a)?a:$CLJS.Cf(a),f=$CLJS.Ad(b)?b:$CLJS.Cf(b);a=$CLJS.E(a);b=$CLJS.E(b);return $CLJS.Cf(c.call($CLJS.ff,Kv,d.call(Nv,e,f,$CLJS.oh(0,a>b?a:b))))};Rv=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.He($CLJS.Pv.g(a,b)),$CLJS.He($CLJS.Pv.g(b,a)),$CLJS.He($CLJS.Qv.g(a,b))],null)};
Sv=function(a){if(null!=a&&null!=a.Mg)a=a.Mg(a);else{var b=Sv[$CLJS.xa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=Sv._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.Za("EqualityPartition.equality-partition",a);}return a};Tv=function(a,b){if(null!=a&&null!=a.Lg)a=a.Lg(a,b);else{var c=Tv[$CLJS.xa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=Tv._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Za("Diff.diff-similar",a);}return a};
$CLJS.Lv=function(a,b){return $CLJS.F.g(a,b)?new $CLJS.P(null,3,5,$CLJS.Q,[null,null,a],null):$CLJS.F.g(Sv(a),Sv(b))?Tv(a,b):Jv(a,b)};$CLJS.Qv=function Qv(a){switch(arguments.length){case 1:return Qv.h(arguments[0]);case 2:return Qv.g(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Qv.l(arguments[0],arguments[1],new $CLJS.y(c.slice(2),0,null))}};$CLJS.Qv.h=function(a){return a};
$CLJS.Qv.g=function(a,b){for(;;)if($CLJS.E(b)<$CLJS.E(a)){var c=a;a=b;b=c}else return $CLJS.bb(function(d,e){return function(f,g){return $CLJS.Id(e,g)?f:$CLJS.wk.g(f,g)}}(a,b),a,a)};$CLJS.Qv.l=function(a,b,c){a=$CLJS.Dv(function(d){return-$CLJS.E(d)},$CLJS.ae.l(c,b,$CLJS.G([a])));return $CLJS.bb($CLJS.Qv,$CLJS.B(a),$CLJS.Lc(a))};$CLJS.Qv.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return this.l(b,a,c)};$CLJS.Qv.A=2;
$CLJS.Pv=function Pv(a){switch(arguments.length){case 1:return Pv.h(arguments[0]);case 2:return Pv.g(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Pv.l(arguments[0],arguments[1],new $CLJS.y(c.slice(2),0,null))}};$CLJS.Pv.h=function(a){return a};$CLJS.Pv.g=function(a,b){return $CLJS.E(a)<$CLJS.E(b)?$CLJS.bb(function(c,d){return $CLJS.Id(b,d)?$CLJS.wk.g(c,d):c},a,a):$CLJS.bb($CLJS.wk,a,b)};
$CLJS.Pv.l=function(a,b,c){return $CLJS.bb($CLJS.Pv,a,$CLJS.ae.g(c,b))};$CLJS.Pv.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return this.l(b,a,c)};$CLJS.Pv.A=2;var Uv=new $CLJS.M(null,"atom","atom",-397043653);var Nv=function Nv(a){switch(arguments.length){case 2:return Nv.g(arguments[0],arguments[1]);case 3:return Nv.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};Nv.g=function(a,b){return Nv.j(a,b,$CLJS.Hv.g($CLJS.Xg(a),$CLJS.Xg(b)))};Nv.j=function(a,b,c){return $CLJS.bb(function(d,e){return $CLJS.ph($CLJS.ff.j($CLJS.Uk,d,e))},new $CLJS.P(null,3,5,$CLJS.Q,[null,null,null],null),$CLJS.ff.g($CLJS.Iv(Mv,a,b),c))};Nv.A=3;
Sv["null"]=function(){return Uv};Sv.string=function(){return Uv};Sv.number=function(){return Uv};Sv.array=function(){return $CLJS.vj};Sv["function"]=function(){return Uv};Sv["boolean"]=function(){return Uv};
Sv._=function(a){return(null!=a?a.C&1024||$CLJS.r===a.Ag||(a.C?0:$CLJS.Xa($CLJS.yb,a)):$CLJS.Xa($CLJS.yb,a))?$CLJS.bk:(null!=a?a.C&4096||$CLJS.r===a.Eg||(a.C?0:$CLJS.Xa($CLJS.Cb,a)):$CLJS.Xa($CLJS.Cb,a))?$CLJS.Xi:(null!=a?a.C&16777216||$CLJS.r===a.Ze||(a.C?0:$CLJS.Xa($CLJS.Vb,a)):$CLJS.Xa($CLJS.Vb,a))?$CLJS.vj:Uv};Tv["null"]=function(a,b){return Jv(a,b)};Tv.string=function(a,b){return Jv(a,b)};Tv.number=function(a,b){return Jv(a,b)};Tv.array=function(a,b){return Ov(a,b)};
Tv["function"]=function(a,b){return Jv(a,b)};Tv["boolean"]=function(a,b){return Jv(a,b)};Tv._=function(a,b){var c=function(){var d=Sv(a);d=d instanceof $CLJS.M?d.T:null;switch(d){case "atom":return Jv;case "set":return Rv;case "sequential":return Ov;case "map":return Nv;default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}}();return c.g?c.g(a,b):c.call(null,a,b)};
import { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { PLUGIN_LOGO_ICON_COMPONENTS } from "metabase/plugins";

class DefaultLogoIcon extends Component {
  static defaultProps = {
    height: 32,
  };
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    dark: PropTypes.bool,
  };

  render() {
    const { dark, height, width } = this.props;
    return (
      <svg
        className={cx("Icon", { "text-brand": !dark }, { "text-white": dark })}
        viewBox="0 0 66 85"
        width={width}
        height={height}
        fill="currentcolor"
        data-testid="main-logo"
      >
        <path
          d="M61.7,19.8l-26.2-11c-0.7-0.3-1.8-0.5-2.5-0.5v11.3h1 c0.5,0,0.9,0.2,1.3,0.5l2.5,2.5c0.3,0.3,0.5,0.8,0.5,1.3v4.2l0,0c-2,0-3.9,0.6-5.4,1.7v2.9c1.3-1.5,3.2-2.5,5.4-2.5 c3.9,0,7.1,3.2,7.1,7.1c0,1.3-0.4,2.5-1,3.6l4.3,4.3c0.4,0.4,0.4,0.9,0,1.3l-1.3,1.3c-0.3,0.3-0.9,0.3-1.3,0l-4.3-4.3 c-1.1,0.6-2.3,1-3.6,1c-2.2,0-4.1-1-5.4-2.5v2.9c1.5,1.1,3.4,1.8,5.4,1.8c-0.2,0.8-0.9,1.4-1.7,1.4h-3.7v19h0.1 C36.5,66.7,45.6,60,48.6,52c4.5-8.4,5.5-24,5.7-24.9c0.3-0.9,0.9-1.6,1.6-2.1c0.7-0.5,1.7-0.8,2.9-0.8h6.8 C65,22.3,63.6,20.6,61.7,19.8z M38.3,33.9c-2,0-3.6,1.6-3.6,3.6s1.6,3.6,3.6,3.6s3.6-1.6,3.6-3.6C41.8,35.4,40.3,33.9,38.3,33.9z"
          opacity="0.6"
        />
        <path
          d="M32.9,32.8v-2.9c-2.4,1.7-3.9,4.4-3.9,7.5c0,3.1,1.5,5.8,3.9,7.5 V42c-1.1-1.2-1.7-2.9-1.7-4.6C31.2,35.6,31.8,34,32.9,32.8z M16.9,46.3V41V28.2v-6.8c0-1,0.8-1.8,1.8-1.8h14.2V8.3 c-0.7,0-1.9,0.2-2.5,0.5l-26.2,11c-1.9,0.8-3.3,2.5-3.8,4.4l0,0h0.5h6.3c1.2,0,2.1,0.3,2.9,0.8c0.7,0.5,1.3,1.2,1.6,2.1 s1.2,16.5,5.7,24.9c3,7.9,11.9,14.6,15.5,15.1v-19H18.7C17.7,48.1,16.9,47.3,16.9,46.3z"
          opacity="0.4"
        />
        <path
          d="M38.3,44.5c1.3,0,2.5-0.4,3.6-1l4.3,4.3c0.3,0.3,0.9,0.3,1.3,0l1.3-1.3c0.4-0.3,0.4-0.9,0-1.3 L44.4,41c0.6-1.1,1-2.3,1-3.6c0-3.9-3.2-7.1-7.1-7.1s-7.1,3.2-7.1,7.1C31.2,41.3,34.4,44.5,38.3,44.5z M38.3,33.9 c2,0,3.6,1.6,3.6,3.6s-1.6,3.6-3.6,3.6s-3.6-1.6-3.6-3.6C34.7,35.4,36.3,33.9,38.3,33.9z M18.7,48.1h17.8c0.8,0,1.6-0.6,1.7-1.4 c-5.1,0-9.2-4.2-9.2-9.3s4.2-9.3,9.3-9.3l0,0v-4.2c0-0.5-0.2-0.9-0.5-1.3l-2.5-2.5c-0.3-0.3-0.8-0.5-1.3-0.5H18.7 c-1,0-1.8,0.8-1.8,1.8v6.8V41v5.3C17,47.3,17.8,48.1,18.7,48.1z M32.9,78.5c1.7,0,3.9-1,6.1-2.1c4.2-2.3,12.8-8.2,19.7-21.2 c5.5-11.1,6.7-19.5,7-26.3l0,0c0.1-2.9,0-3.9-0.2-4.8h-6.8c-1.2,0-2.1,0.3-2.9,0.8c-0.7,0.5-1.3,1.2-1.6,2.1c-0.2,1-1.1,16.6-5.7,25 c-3,8-12,14.7-15.6,15.1l0,0C29.4,66.8,20.3,60,17.3,52c-4.5-8.4-5.5-24-5.7-24.9c-0.3-0.9-0.8-1.6-1.6-2.1 c-0.7-0.5-1.7-0.8-2.9-0.8H0.4l0,0C0.1,25,0,26,0.1,29c0.3,6.8,1.5,15.2,7,26.3C14,68.2,22.6,74.2,26.8,76.5 C29,77.5,31.3,78.5,32.9,78.5"
        />
      </svg>
    );
  }
}

export default function LogoIcon(props) {
  const [Component = DefaultLogoIcon] = PLUGIN_LOGO_ICON_COMPONENTS;
  return <Component {...props} />;
}

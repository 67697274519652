var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.join.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Sja,d6,Tja,Uja,Vja,f6,g6,h6,i6,Wja,Xja,l6,j6,k6,m6,n6,Yja,Zja,$ja,o6,aka,bka,p6,cka,dka;Sja=function(a,b){return $CLJS.E(a)<=$CLJS.E(b)&&$CLJS.Me(function(c){return $CLJS.Id(b,c)},a)};d6=function(a,b){if(b=$CLJS.A(b)){var c=$CLJS.A(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.A(c)?(c=d6($CLJS.I.g(a,b),c),$CLJS.ud(c)?$CLJS.vk.g(a,b):$CLJS.R.j(a,b,c)):$CLJS.vk.g(a,b)}return a};
Tja=function(a,b,c,d){return function t(f,g,l,m){var u=$CLJS.A(g);g=$CLJS.B(u);u=$CLJS.C(u);var v=$CLJS.Wl(f,g);return $CLJS.n(v)?u?$CLJS.R.j(f,g,t($CLJS.Bb(v),u,l,m)):$CLJS.R.j(f,g,$CLJS.O.j(l,$CLJS.Bb(v),m)):f}(a,b,c,d)};Uja=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return Tja(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.y(b.slice(3),0,null):null)};$CLJS.e6=function(a){return $CLJS.yI.h($CLJS.EI(a))};
Vja=function(a,b,c,d){var e=$CLJS.F.g($CLJS.FT,$CLJS.B(b))?$CLJS.k_(d,$CLJS.j_(c)):d;return Uja(a,b,function(f){return $CLJS.Cf(function(){return function m(l){return new $CLJS.me(null,function(){for(;;){var t=$CLJS.A(l);if(t){if($CLJS.Bd(t)){var u=$CLJS.kc(t),v=$CLJS.E(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var D=$CLJS.md(u,z);D=$CLJS.F.g($CLJS.e6(D),$CLJS.e6(c))?e:D;x.add(D);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),m($CLJS.lc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.B(t);return $CLJS.de($CLJS.F.g($CLJS.e6(x),
$CLJS.e6(c))?e:x,m($CLJS.Lc(t)))}return null}},null,null)}(f)}())})};
f6=function(a,b,c){var d=$CLJS.XD(a,b);if($CLJS.n(d)){var e=$CLJS.e6(c),f=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.B(b),$CLJS.kd(b)],null);c=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);d=$CLJS.eg.j($CLJS.Bf,$CLJS.Dk.h($CLJS.Ck.g($CLJS.fh([e]),$CLJS.e6)),d);d=$CLJS.F.g(b,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.SI],null))&&$CLJS.Me(function(g){return $CLJS.Z3(g,$CLJS.zE)},d)?null:d;if($CLJS.A(d))return $CLJS.vP(a,b,d);if($CLJS.F.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gR,$CLJS.dP],null),new $CLJS.P(null,2,5,$CLJS.Q,
[c,f],null)))throw $CLJS.li($CLJS.HI("Cannot remove the final join condition"),new $CLJS.k(null,1,[$CLJS.dP,$CLJS.XD(a,b)],null));return $CLJS.F.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gR,$CLJS.SI],null),new $CLJS.P(null,2,5,$CLJS.Q,[c,f],null))?$CLJS.lZ.v(a,$CLJS.td(b),$CLJS.vk,f):d6(a,b)}return a};g6=function(a,b){a=$CLJS.Ge(a);a=$CLJS.I.g(a,$CLJS.aP);b=0>b?$CLJS.E(a)+b:b;return b+1<$CLJS.E(a)?b+1:null};
h6=function(a,b){var c=$CLJS.D4.g(a,b),d=$CLJS.oh(0,$CLJS.E(c));return $CLJS.cf.l(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.vV],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.mS],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.PZ],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.SI],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.XJ],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.FT],null)],null),function(){return function g(f){return new $CLJS.me(null,function(){for(var l=f;;)if(l=$CLJS.A(l)){if($CLJS.Bd(l)){var m=
$CLJS.kc(l),t=$CLJS.E(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.md(m,v),z=$CLJS.cd(c,x);$CLJS.SI.h(z)instanceof $CLJS.M||u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gR,x,$CLJS.SI],null));v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),g($CLJS.lc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.B(l);m=$CLJS.cd(c,u);if($CLJS.SI.h(m)instanceof $CLJS.M)l=$CLJS.Lc(l);else return $CLJS.de(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gR,u,$CLJS.SI],null),g($CLJS.Lc(l)))}else return null},null,null)}(d)}(),$CLJS.G([function(){return function g(f){return new $CLJS.me(null,
function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.Bd(l)){var m=$CLJS.kc(l),t=$CLJS.E(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.md(m,v);u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gR,x,$CLJS.dP],null));v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),g($CLJS.lc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.B(l);return $CLJS.de(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gR,u,$CLJS.dP],null),g($CLJS.Lc(l)))}return null}},null,null)}(d)}()]))};
i6=function(a,b,c){var d=$CLJS.H(c,0,null),e=$CLJS.H(c,1,null);e=$CLJS.Ge(e);var f=$CLJS.I.g(e,$CLJS.nK),g=$CLJS.I.g(e,$CLJS.cV),l=$CLJS.H(c,2,null);return $CLJS.B($CLJS.CL(function(m){$CLJS.H(m,0,null);m=$CLJS.H(m,1,null);$CLJS.H(m,0,null);$CLJS.H(m,1,null);m=$CLJS.H(m,2,null);return $CLJS.F.g($CLJS.B(m),d)&&$CLJS.F.g($CLJS.nK.h($CLJS.jd(m)),f)&&$CLJS.F.g($CLJS.cV.h($CLJS.jd(m)),g)&&$CLJS.F.g($CLJS.kd(m),l)},$CLJS.Vl($CLJS.Gt,$CLJS.vV.h($CLJS.q0(a,b)))))};
Wja=function(a,b,c,d){c=i6(a,b,c);return $CLJS.n(c)?$CLJS.B_.l(a,b,$CLJS.lZ,$CLJS.G([new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vV,c,2,1],null),$CLJS.Ck.g(function(e){return $CLJS.dE($CLJS.Oe($CLJS.Ra),e)},$CLJS.Uk),d])):a};Xja=function(a,b,c){c=i6(a,b,c);return $CLJS.n(c)?$CLJS.B_.l(a,b,f6,$CLJS.G([new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.vV],null),$CLJS.XD($CLJS.q0(a,b),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vV,c],null))])):a};
l6=function(a,b,c,d,e,f){var g=$CLJS.B_.l(a,b,f,$CLJS.G([d,e])),l=$CLJS.e6(e);return $CLJS.ah.g(a,g)?$CLJS.B($CLJS.A($CLJS.jf($CLJS.Ua,function(m,t){try{if($CLJS.Ad(t)&&1===$CLJS.E(t))try{var u=$CLJS.cd(t,0);if($CLJS.ge(u,$CLJS.FT))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var K=$CLJS.j_(e);K=j6.W?j6.W(g,b,c,$CLJS.zE,$CLJS.N,K):j6.call(null,g,b,c,$CLJS.zE,$CLJS.N,K);return k6.v?k6.v(K,b,c,l):k6.call(null,K,b,c,l)}()],null);throw $CLJS.Y;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Y)try{u=
$CLJS.cd(t,0);if($CLJS.ge(u,$CLJS.XJ))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var U=j6.W?j6.W(g,b,c,$CLJS.XJ,$CLJS.N,l):j6.call(null,g,b,c,$CLJS.XJ,$CLJS.N,l);return k6.v?k6.v(U,b,c,l):k6.call(null,U,b,c,l)}()],null);throw $CLJS.Y;}catch(U){if(U instanceof Error){var v=U;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw U;}else throw m;else throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Y)try{if($CLJS.Ad(t)&&1===$CLJS.E(t))try{var x=$CLJS.cd(t,0);if($CLJS.ge(x,$CLJS.mS))return new $CLJS.P(null,
1,5,$CLJS.Q,[k6.v?k6.v(g,b,c,l):k6.call(null,g,b,c,l)],null);throw $CLJS.Y;}catch(U){if(U instanceof Error)if(v=U,v===$CLJS.Y)try{x=$CLJS.cd(t,0);if($CLJS.ge(x,$CLJS.SI))return new $CLJS.P(null,1,5,$CLJS.Q,[k6.v?k6.v(g,b,c,l):k6.call(null,g,b,c,l)],null);throw $CLJS.Y;}catch(Z){if(Z instanceof Error){var z=Z;if(z===$CLJS.Y)throw $CLJS.Y;throw z;}throw Z;}else throw v;else throw U;}else throw $CLJS.Y;}catch(U){if(U instanceof Error)if(v=U,v===$CLJS.Y)try{if($CLJS.Ad(t)&&3===$CLJS.E(t))try{var D=$CLJS.cd(t,
0);if($CLJS.ge(D,$CLJS.gR))try{var J=$CLJS.cd(t,2);if($CLJS.ge(J,$CLJS.SI))return new $CLJS.P(null,1,5,$CLJS.Q,[k6.v?k6.v(g,b,c,l):k6.call(null,g,b,c,l)],null);throw $CLJS.Y;}catch(Z){if(Z instanceof Error){z=Z;if(z===$CLJS.Y)throw $CLJS.Y;throw z;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){z=Z;if(z===$CLJS.Y)throw $CLJS.Y;throw z;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){z=Z;if(z===$CLJS.Y)return new $CLJS.P(null,1,5,$CLJS.Q,[g],null);throw z;}throw Z;}else throw v;
else throw U;}else throw m;else throw K;}}($CLJS.Bf,d)))):g};
j6=function(a,b,c,d,e,f){var g=$CLJS.q0(a,b),l=$CLJS.gf(function(m){var t=$CLJS.XD(g,m);return $CLJS.n(t)?$CLJS.Ul(function(u){return $CLJS.B($CLJS.A($CLJS.jf($CLJS.Ua,function D(x,z){try{if($CLJS.Ad(z)&&3===$CLJS.E(z))try{var J=$CLJS.cd(z,0);if($CLJS.F.g(J,d))try{var K=$CLJS.cd(z,1);if($CLJS.ud(e)||Sja($CLJS.ih(e),$CLJS.ih(K)))try{var U=$CLJS.cd(z,2);if($CLJS.F.g(U,f))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null)],null);throw $CLJS.Y;}catch(da){if(da instanceof Error){var Z=
da;if(Z===$CLJS.Y)throw $CLJS.Y;throw Z;}throw da;}else throw $CLJS.Y;}catch(da){if(da instanceof Error){Z=da;if(Z===$CLJS.Y)throw $CLJS.Y;throw Z;}throw da;}else throw $CLJS.Y;}catch(da){if(da instanceof Error){Z=da;if(Z===$CLJS.Y)throw $CLJS.Y;throw Z;}throw da;}else throw $CLJS.Y;}catch(da){if(da instanceof Error){Z=da;if(Z===$CLJS.Y)return $CLJS.jZ(D,x,z);throw Z;}throw da;}}($CLJS.Bf,u))))},t):null},$CLJS.G([h6(a,b)]));return $CLJS.bb(function(m,t){var u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);
return l6(m,b,c,u,t,f6)},a,l)};k6=function(a,b,c,d){b=g6(c,b);if($CLJS.n(b)){var e=$CLJS.q0(c,b);c=$CLJS.Ne(function(f){f=$CLJS.Ge(f);var g=$CLJS.I.g(f,$CLJS.t_),l=$CLJS.I.g(f,$CLJS.d3);return $CLJS.F.g($CLJS.u0,g)&&$CLJS.F.g(d,l)?$CLJS.B0.h(f):null},$CLJS.C1.j(c,b,e));return $CLJS.n(c)?j6(a,b,a,$CLJS.hK,$CLJS.N,c):a}return a};
m6=function(a,b,c,d,e){var f=$CLJS.c_.h(c),g=$CLJS.q0(a,b);c=$CLJS.CL(function(t){var u=$CLJS.XD(g,t);if($CLJS.n(u)){var v=$CLJS.e6(f);return $CLJS.n($CLJS.Ne($CLJS.Ck.j($CLJS.fh([v]),$CLJS.yI,$CLJS.jd),u))?t:null}return null},h6(a,b));var l=(d=$CLJS.F.g($CLJS.GK,d))?$CLJS.c_.h(e):null;e=d?function(t,u,v){return Vja(t,u,v,l)}:f6;var m=$CLJS.F.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.mS],null),c);a=d&&m&&$CLJS.F.g($CLJS.B(f),$CLJS.B(l))&&$CLJS.F.g($CLJS.kd(f),$CLJS.kd(l))?Wja(a,b,f,$CLJS.bh($CLJS.jd(l),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cV,$CLJS.nK],null))):m?Xja(a,b,f):a;return $CLJS.n(c)?l6(a,b,a,c,f,e):a};n6=function(a,b){return $CLJS.Z3(a,$CLJS.hK)&&$CLJS.F.g($CLJS.f4(a),b)};Yja=function(a,b,c){return function g(e,f){try{if(n6(f,b))return $CLJS.g4(f,c);throw $CLJS.Y;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Y)return $CLJS.kZ(g,e,f);throw l;}throw m;}}($CLJS.Bf,a)};
Zja=function(a,b,c){var d=$CLJS.gR.h(a),e=-1<b&&b<$CLJS.E(d)?new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.XD(d,new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.WI],null))],null):null;b=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);if($CLJS.n($CLJS.n(b)?$CLJS.ah.g(e,c):b)){var f=$CLJS.s_();$CLJS.Y3(f,$CLJS.ff.g($CLJS.WI,d));c=f(c);return Yja($CLJS.vP(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gR,b,$CLJS.WI],null),c),e,c)}return a};
$ja=function(a,b,c){if($CLJS.Gd(c))return c;var d=function(){var e=$CLJS.fh([c]);return"string"===typeof c?$CLJS.Ck.g(e,$CLJS.WI):e}();return $CLJS.Ne(function(e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return $CLJS.n(d.h?d.h(e):d.call(null,e))?f:null},$CLJS.Vl($CLJS.Gt,$CLJS.gR.h($CLJS.q0(a,b))))};
o6=function(a,b,c,d){b=$CLJS.Pv.g($CLJS.ih($CLJS.C1.j(b,c,$CLJS.q0(b,c))),$CLJS.ih($CLJS.C1.j(a,c,$CLJS.q0(a,c))));return $CLJS.bb(function(e,f){return $CLJS.O.N(j6,e,c,a,d.h?d.h(f):d.call(null,f))},a,b)};aka=function(a,b,c){a=o6(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hK,new $CLJS.k(null,1,[$CLJS.$S,$CLJS.e4.h(d)],null),$CLJS.Wi.h(d)],null)});c=g6(a,c);return $CLJS.n(c)?o6(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hK,$CLJS.N,$CLJS.B0.h(d)],null)}):a};
bka=function(a,b,c){return $CLJS.Gd(c)?$CLJS.XD($CLJS.q0(a,b),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gR,c,$CLJS.WI],null)):$CLJS.yd(c)?$CLJS.WI.h(c):c};p6=function(a,b,c,d){var e=bka(a,b,c);return $CLJS.n(e)?(c=function(){var f=(0,$CLJS.B_)(a,b,function(g){var l=$CLJS.gR.h(g);l=d.g?d.g(l,e):d.call(null,l,e);return $CLJS.WZ(g,$CLJS.gR,l)});return(0,$CLJS.B_)(f,b,function(g){return $CLJS.mZ.j(g,$CLJS.gR,function(l){return $CLJS.Ek.g(function(m){return $CLJS.p4(f,b,m)},l)})})}(),aka(c,a,b)):a};
cka=function(a,b){return null!=$CLJS.B($CLJS.A($CLJS.jf($CLJS.Ua,function f(d,e){try{if(n6(e,b))return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(l){if(l instanceof Error){var g=l;if(g===$CLJS.Y)return $CLJS.jZ(f,d,e);throw g;}throw l;}}($CLJS.Bf,a))))};dka=new $CLJS.M(null,"remove","remove",-131428414);$CLJS.eka=function(){function a(d,e,f){return $CLJS.yd(f)&&$CLJS.F.g($CLJS.Aj.h(f),$CLJS.WO)?$CLJS.q6.j?$CLJS.q6.j(d,e,f):$CLJS.q6.call(null,d,e,f):m6(d,e,f,dka,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.fka=function(){function a(d,e,f,g){return $CLJS.yd(f)&&$CLJS.F.g($CLJS.Aj.h(f),$CLJS.WO)?$CLJS.r6.v?$CLJS.r6.v(d,e,f,g):$CLJS.r6.call(null,d,e,f,g):m6(d,e,f,$CLJS.GK,g)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,g){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,g)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.gka=function(){function a(d,e,f,g){f=$ja(d,e,f);return $CLJS.n(f)?$CLJS.B_.l(d,e,Zja,$CLJS.G([f,g])):d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,g){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,g)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.q6=function(){function a(d,e,f){return p6(d,e,f,function(g,l){return $CLJS.He($CLJS.aZ(function(m){return!($CLJS.F.g($CLJS.WI.h(m),l)||cka(m,l))},g))})}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.r6=function(){function a(d,e,f,g){return null==g?$CLJS.q6.j(d,e,f):p6(d,e,f,function(l,m){return $CLJS.Ek.g(function(t){return $CLJS.F.g($CLJS.WI.h(t),m)?g:t},l)})}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,g){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,g)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
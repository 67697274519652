var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.js");
'use strict';$CLJS.b3=function(a,b,c){return null==c||$CLJS.Id(a,b)?a:$CLJS.R.j(a,b,c)};$CLJS.c3=function(a,b){if($CLJS.A(b)){if($CLJS.F.g($CLJS.E(b),1))return $CLJS.B(b);a=[" ",(0,$CLJS.ma)(a)," "].join("");return $CLJS.F.g($CLJS.E(b),2)?[$CLJS.p.h($CLJS.B(b)),a,$CLJS.p.h($CLJS.jd(b))].join(""):[$CLJS.Mu(", ",$CLJS.Ju(b)),",",a,$CLJS.p.h($CLJS.kd(b))].join("")}return null};$CLJS.d3=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var tha,uha,vha,wha,xha,h3;
$CLJS.e3=function(){function a(e,f){if($CLJS.Ta(f))return"";e=Math.abs(e);var g=$CLJS.Ch.h(f);switch(g instanceof $CLJS.M?g.T:null){case "default":return $CLJS.hZ("Default period","Default periods",e);case "millisecond":return $CLJS.hZ("Millisecond","Milliseconds",e);case "second":return $CLJS.hZ("Second","Seconds",e);case "minute":return $CLJS.hZ("Minute","Minutes",e);case "hour":return $CLJS.hZ("Hour","Hours",e);case "day":return $CLJS.hZ("Day","Days",e);case "week":return $CLJS.hZ("Week","Weeks",
e);case "month":return $CLJS.hZ("Month","Months",e);case "quarter":return $CLJS.hZ("Quarter","Quarters",e);case "year":return $CLJS.hZ("Year","Years",e);case "minute-of-hour":return $CLJS.hZ("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.hZ("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.hZ("Day of week","Days of week",e);case "day-of-month":return $CLJS.hZ("Day of month","Days of month",e);case "day-of-year":return $CLJS.hZ("Day of year","Days of year",e);case "week-of-year":return $CLJS.hZ("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.hZ("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.hZ("Quarter of year","Quarters of year",e);default:return f=$CLJS.tv($CLJS.jh(f),/-/),e=$CLJS.A(f),f=$CLJS.B(e),e=$CLJS.C(e),$CLJS.Mu(" ",$CLJS.de($CLJS.SD(f),e))}}function b(e){return d.g?d.g(1,e):d.call(null,1,e)}function c(){return d.g?d.g(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.o=c;d.h=b;d.g=a;return d}();$CLJS.f3=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.I.j(new $CLJS.k(null,1,[$CLJS.ej,$CLJS.CF],null),$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.AF(f)},e,a,b,c,d)}();tha=$CLJS.Se($CLJS.N);uha=$CLJS.Se($CLJS.N);
vha=$CLJS.Se($CLJS.N);wha=$CLJS.Se($CLJS.N);xha=$CLJS.I.j(new $CLJS.k(null,1,[$CLJS.ej,$CLJS.CF],null),$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));$CLJS.g3=new $CLJS.hi($CLJS.Ih.g("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.AF,xha,tha,uha,vha,wha);$CLJS.g3.m(null,$CLJS.gi,function(){return null});h3=new $CLJS.dh(null,new $CLJS.k(null,4,[$CLJS.cL,null,$CLJS.fL,null,$CLJS.gL,null,$CLJS.dL,null],null),null);
$CLJS.yha=$CLJS.eg.j($CLJS.Bf,$CLJS.Ck.g($CLJS.Dk.h(h3),$CLJS.ff.h(function(a){var b=new $CLJS.k(null,2,[$CLJS.Aj,$CLJS.lL,$CLJS.wi,a],null);return $CLJS.F.g(a,$CLJS.Fv)?$CLJS.R.j(b,$CLJS.gi,!0):b})),$CLJS.xL);$CLJS.zha=$CLJS.Ek.g(function(a){var b=new $CLJS.k(null,2,[$CLJS.Aj,$CLJS.lL,$CLJS.wi,a],null);return $CLJS.F.g(a,$CLJS.Gv)?$CLJS.R.j(b,$CLJS.gi,!0):b},$CLJS.sL);
$CLJS.Aha=$CLJS.eg.j($CLJS.Bf,$CLJS.Ck.g($CLJS.Dk.h(h3),$CLJS.ff.h(function(a){var b=new $CLJS.k(null,2,[$CLJS.Aj,$CLJS.lL,$CLJS.wi,a],null);return $CLJS.F.g(a,$CLJS.Gv)?$CLJS.R.j(b,$CLJS.gi,!0):b})),$CLJS.yL);$CLJS.q1.m(null,$CLJS.lL,function(a,b,c){a=$CLJS.Ge(c);a=$CLJS.I.g(a,$CLJS.wi);return $CLJS.e3.h(a)});$CLJS.y1.m(null,$CLJS.lL,function(a,b,c){return $CLJS.Uk.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.nI,$CLJS.V0.j(a,b,c)],null),$CLJS.bh(c,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gi,$CLJS.c1],null))]))});
$CLJS.i3=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.I.j(new $CLJS.k(null,1,[$CLJS.ej,$CLJS.CF],null),$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,g,l){return $CLJS.AF(l)},e,a,b,c,d)}();$CLJS.i3.m(null,$CLJS.gi,function(){return $CLJS.eh});
$CLJS.j3=function(){function a(d,e,f){return $CLJS.i3.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
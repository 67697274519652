var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var d_,zfa,Afa,l_,Bfa,Cfa,Efa,h_,Ffa,Dfa;d_=function(a){return a+1};$CLJS.e_=function(a){if("string"===typeof a){var b=$CLJS.qh(/[+-]?\d+/,a);return $CLJS.n(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.Zl(a));};
zfa=function(a){var b=new $CLJS.Fa,c=$CLJS.hw;$CLJS.hw=new $CLJS.rc(b);try{var d=$CLJS.hw,e=$CLJS.Ta($CLJS.xx(d)),f=$CLJS.hw;$CLJS.hw=e?$CLJS.ox(d):d;try{d=$CLJS.zx;$CLJS.zx=!0;try{$CLJS.Bx(a)}finally{$CLJS.zx=d}$CLJS.F.g(0,$CLJS.pw($CLJS.hw,$CLJS.rw))||$CLJS.$b($CLJS.hw,"\n");$CLJS.ow()}finally{$CLJS.hw=f}$CLJS.xh($CLJS.p.h(b))}finally{$CLJS.hw=c}};$CLJS.f_=function(a,b){return $CLJS.O.j($CLJS.ha,a,b)};
$CLJS.g_=function(a){var b=new $CLJS.Fa,c=$CLJS.Oa,d=$CLJS.Pa;$CLJS.Oa=!0;$CLJS.Pa=function(e){return b.append(e)};try{zfa(a)}finally{$CLJS.Pa=d,$CLJS.Oa=c}return $CLJS.p.h(b)};
Afa=function(a){a=$CLJS.Ge(a);var b=$CLJS.I.j(a,$CLJS.qZ,$CLJS.Td),c=$CLJS.I.j(a,$CLJS.nZ,function(f,g){return[$CLJS.p.h(f),"_",$CLJS.p.h(g)].join("")}),d=$CLJS.Se($CLJS.N),e=$CLJS.Se($CLJS.N);return function(){function f(m,t){for(;;){var u=b.h?b.h(t):b.call(null,t),v=function(){var z=new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),D=$CLJS.q(d);return D.h?D.h(z):D.call(null,z)}();if($CLJS.n(v))return v;var x=$CLJS.I.g($CLJS.Rh.v(e,$CLJS.Fk,u,$CLJS.XH(d_,0)),u);if($CLJS.F.g(x,1))return $CLJS.Rh.v(d,$CLJS.R,
new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),t),t;t=function(){var z=t,D=$CLJS.p.h(x);return c.g?c.g(z,D):c.call(null,z,D)}()}}function g(m){var t=l,u=t.g;null==h_&&(h_=$CLJS.Se(0));var v=$CLJS.Ih.h([$CLJS.p.h("G__"),$CLJS.p.h($CLJS.Rh.g(h_,d_))].join(""));return u.call(t,v,m)}var l=null;l=function(m,t){switch(arguments.length){case 1:return g.call(this,m);case 2:return f.call(this,m,t)}throw Error("Invalid arity: "+arguments.length);};l.h=g;l.g=f;return l}()};
$CLJS.i_=function(a){return $CLJS.Ad(a)&&1<$CLJS.E(a)&&$CLJS.B(a)instanceof $CLJS.M&&$CLJS.yd($CLJS.jd(a))&&$CLJS.Id($CLJS.jd(a),$CLJS.yI)};$CLJS.j_=function(a){return $CLJS.i_(a)?$CLJS.XD(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,$CLJS.$I],null)):null};$CLJS.k_=function(a,b){return $CLJS.vP($CLJS.i_(a)?a:new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.k(null,2,[$CLJS.yI,$CLJS.p.h($CLJS.FI()),$CLJS.Hi,$CLJS.KI(a)],null),a],null),new $CLJS.P(null,2,5,$CLJS.Q,[1,$CLJS.$I],null),b)};
l_=function(a,b,c){var d=$CLJS.Id(a,b)?$CLJS.Fk.j(a,b,function(e){return $CLJS.Ad(e)&&$CLJS.F.g($CLJS.B(e),$CLJS.st)?$CLJS.Cf($CLJS.Ye(1,e)):new $CLJS.P(null,1,5,$CLJS.Q,[e],null)}):a;return $CLJS.Id(a,b)?$CLJS.iZ(d,$CLJS.Fe([b,c])):d};Bfa=function(a){var b=$CLJS.bh(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LS,$CLJS.oU],null));b=m_.h?m_.h(b):m_.call(null,b);return $CLJS.ZZ($CLJS.R.l(l_($CLJS.vk.l(a,$CLJS.LS,$CLJS.G([$CLJS.oU])),$CLJS.xV,$CLJS.dP),$CLJS.Aj,$CLJS.WO,$CLJS.G([$CLJS.aP,b])))};
Cfa=function(a){return $CLJS.Ek.g(Bfa,a)};Efa=function(a){return $CLJS.n(a)?$CLJS.R.j($CLJS.Fk.j($CLJS.Dl(a)?new $CLJS.k(null,1,[$CLJS.n_,a],null):a,$CLJS.n_,function(b){return $CLJS.Ek.g(function(c){return $CLJS.R.j($CLJS.YD(c,$CLJS.nE),$CLJS.Aj,$CLJS.a_)},b)}),$CLJS.Aj,Dfa):null};
$CLJS.o_=function(a){if($CLJS.F.g($CLJS.Aj.h(a),$CLJS.OZ))return a;var b=$CLJS.ij.h(a);b=b instanceof $CLJS.M?b.T:null;switch(b){case "native":return $CLJS.Uk.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.Aj,$CLJS.OZ,$CLJS.aP,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Uk.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.Aj,$CLJS.JZ],null),$CLJS.iZ($CLJS.PT.h(a),new $CLJS.k(null,1,[$CLJS.jQ,$CLJS.PT],null))]))],null)],null),$CLJS.vk.l(a,$CLJS.ij,$CLJS.G([$CLJS.PT]))]));case "query":return $CLJS.Uk.l($CLJS.G([new $CLJS.k(null,
2,[$CLJS.Aj,$CLJS.OZ,$CLJS.aP,m_($CLJS.jQ.h(a))],null),$CLJS.vk.l(a,$CLJS.ij,$CLJS.G([$CLJS.jQ]))]));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};$CLJS.p_=function(a,b){a=$CLJS.Ge(a);a=$CLJS.I.g(a,$CLJS.aP);var c=0>b?$CLJS.E(a)+b:b;if(c>=$CLJS.E(a)||0>c)throw $CLJS.li($CLJS.GI("Stage {0} does not exist",$CLJS.G([b])),new $CLJS.k(null,1,[Ffa,$CLJS.E(a)],null));return c};
$CLJS.q_=function(a){return"string"===typeof a&&(a=$CLJS.rh(/^card__(\d+)$/,a),$CLJS.n(a))?($CLJS.H(a,0,null),a=$CLJS.H(a,1,null),$CLJS.e_(a)):null};$CLJS.s_=function(){return $CLJS.Ck.g(r_,Afa($CLJS.G([$CLJS.qZ,$CLJS.kE,$CLJS.nZ,function(a,b){return r_.h([$CLJS.p.h(a),"_",$CLJS.p.h(b)].join(""))}])))};h_=null;$CLJS.t_=new $CLJS.M("lib","source","lib/source",-434086550);$CLJS.u_=new $CLJS.M("metadata","segment","metadata/segment",-1286738015);
$CLJS.v_=new $CLJS.M("metadata","table","metadata/table",-882499405);Ffa=new $CLJS.M(null,"num-stages","num-stages",1426797535);$CLJS.w_=new $CLJS.M(null,"table-id","table-id",-766649466);$CLJS.n_=new $CLJS.M(null,"columns","columns",1998437288);$CLJS.x_=new $CLJS.M("source","breakouts","source/breakouts",-1706018158);$CLJS.Gfa=new $CLJS.M("metadata","database","metadata/database",-924828824);$CLJS.y_=new $CLJS.M(null,"table","table",-564943036);
$CLJS.z_=new $CLJS.M("metadata","card","metadata/card",-1039333889);Dfa=new $CLJS.M("metadata","results","metadata/results",330329298);$CLJS.A_=new $CLJS.M("source","aggregations","source/aggregations",-1828710137);var m_,r_;
m_=function m_(a){a=$CLJS.Ge(a);var c=$CLJS.I.g(a,$CLJS.oU),d=$CLJS.I.g(a,$CLJS.xS);c=$CLJS.n(c)?m_.h?m_.h(c):m_.call(null,c):$CLJS.Bf;d=Efa(d);var e=$CLJS.E(c)-1;c=$CLJS.n($CLJS.n(d)?!(0>e):d)?$CLJS.vP(c,new $CLJS.P(null,2,5,$CLJS.Q,[e,$CLJS.VY],null),d):c;d=$CLJS.n($CLJS.PT.h(a))?$CLJS.JZ:$CLJS.DZ;a=$CLJS.Uk.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.Aj,d],null),$CLJS.vk.l(a,$CLJS.oU,$CLJS.G([$CLJS.xS]))]));a=$CLJS.A($CLJS.gR.h(a))?$CLJS.Fk.j(a,$CLJS.gR,Cfa):a;a=l_(a,$CLJS.KQ,$CLJS.PZ);return $CLJS.ae.g(c,a)};
$CLJS.B_=function(){function a(c,d,e,f){var g=null;if(3<arguments.length){g=0;for(var l=Array(arguments.length-3);g<l.length;)l[g]=arguments[g+3],++g;g=new $CLJS.y(l,0,null)}return b.call(this,c,d,e,g)}function b(c,d,e,f){c=$CLJS.o_(c);c=$CLJS.Ge(c);var g=$CLJS.I.g(c,$CLJS.aP);d=$CLJS.p_(c,d);e=$CLJS.O.N($CLJS.Fk,$CLJS.Cf(g),d,e,f);return $CLJS.R.j(c,$CLJS.aP,e)}a.A=3;a.B=function(c){var d=$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.C(c);var f=$CLJS.B(c);c=$CLJS.Lc(c);return b(d,e,f,c)};a.l=
b;return a}();
r_=function(){function a(d,e){if((new TextEncoder).encode(d).length<=e)return d;var f;a:for(f=($CLJS.UZ.str(d,0)>>>0).toString(16);;)if(8>$CLJS.E(f))f=["0",$CLJS.p.h(f)].join("");else break a;e=new Uint8Array(e-9);e=(new TextEncoder).encodeInto(d,e);return[d.substring(0,e.read),"_",$CLJS.p.h(f)].join("")}function b(d){return c.g?c.g(d,60):c.call(null,d,60)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.g=a;return c}();$CLJS.C_=RegExp(" id$","i");
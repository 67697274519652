var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.core.js");require("./metabase.lib.join.js");require("./metabase.lib.js.metadata.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.stage.js");require("./metabase.mbql.js.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var gpa=function(a){return $CLJS.fc($CLJS.bb(function(b,c){return $CLJS.hg.j(b,c,$CLJS.I.j(b,c,0)+1)},$CLJS.cc($CLJS.N),a))},I9=function(a,b){return new $CLJS.Mh(function(){try{return $CLJS.F9.g(a,b)}catch(e){if(e instanceof Error){var c=e,d=$CLJS.iE($CLJS.kC);$CLJS.n($CLJS.hE("metabase.lib.js.metadata",d))&&(c instanceof Error?$CLJS.gE("metabase.lib.js.metadata",d,$CLJS.f_("Error parsing %s objects: %s",$CLJS.G([a,$CLJS.bZ(c)])),c):$CLJS.gE("metabase.lib.js.metadata",d,$CLJS.f_(c,$CLJS.G(["Error parsing %s objects: %s",
a,$CLJS.bZ(c)])),null));return null}throw e;}})},hpa=function(a,b){return function e(d){return new $CLJS.me(null,function(){for(var f=d;;)if(f=$CLJS.A(f)){if($CLJS.Bd(f)){var g=$CLJS.kc(f),l=$CLJS.E(g),m=$CLJS.qe(l);return function(){for(var x=0;;)if(x<l){var z=$CLJS.md(g,x);$CLJS.H(z,0,null);var D=$CLJS.H(z,1,null),J=function(){var K=D;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=J;return $CLJS.n(K)?$CLJS.F.g($CLJS.w_.h(J),b):K}())&&m.add(J);x+=1}else return!0}()?$CLJS.te($CLJS.ve(m),
e($CLJS.lc(f))):$CLJS.te($CLJS.ve(m),null)}var t=$CLJS.B(f);$CLJS.H(t,0,null);var u=$CLJS.H(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.F.g($CLJS.w_.h(v),b):x}()))return $CLJS.de(v,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.SI.h(a);return null==d?null:$CLJS.q(d)}())},ipa=function(a,b){return function e(d){return new $CLJS.me(null,function(){for(var f=d;;)if(f=$CLJS.A(f)){if($CLJS.Bd(f)){var g=
$CLJS.kc(f),l=$CLJS.E(g),m=$CLJS.qe(l);return function(){for(var x=0;;)if(x<l){var z=$CLJS.md(g,x);$CLJS.H(z,0,null);var D=$CLJS.H(z,1,null),J=function(){var K=D;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=J;return $CLJS.n(K)?$CLJS.F.g($CLJS.w_.h(J),b):K}())&&m.add(J);x+=1}else return!0}()?$CLJS.te($CLJS.ve(m),e($CLJS.lc(f))):$CLJS.te($CLJS.ve(m),null)}var t=$CLJS.B(f);$CLJS.H(t,0,null);var u=$CLJS.H(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=
v;return $CLJS.n(x)?$CLJS.F.g($CLJS.w_.h(v),b):x}()))return $CLJS.de(v,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.A9.h(a);return null==d?null:$CLJS.q(d)}())},jpa=function(a,b){return function e(d){return new $CLJS.me(null,function(){for(var f=d;;)if(f=$CLJS.A(f)){if($CLJS.Bd(f)){var g=$CLJS.kc(f),l=$CLJS.E(g),m=$CLJS.qe(l);return function(){for(var x=0;;)if(x<l){var z=$CLJS.md(g,x);$CLJS.H(z,0,null);var D=$CLJS.H(z,1,null),J=function(){var K=D;
return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=J;return $CLJS.n(K)?$CLJS.F.g($CLJS.w_.h(J),b):K}())&&m.add(J);x+=1}else return!0}()?$CLJS.te($CLJS.ve(m),e($CLJS.lc(f))):$CLJS.te($CLJS.ve(m),null)}var t=$CLJS.B(f);$CLJS.H(t,0,null);var u=$CLJS.H(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.F.g($CLJS.w_.h(v),b):x}()))return $CLJS.de(v,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(function(){var d=null==
a?null:$CLJS.B9.h(a);return null==d?null:$CLJS.q(d)}())},J9=function(a,b,c,d){this.Mf=a;this.li=b;this.lc=c;this.Ph=d;this.C=393216;this.I=0},K9=function(a,b){if($CLJS.s0(b))a=b;else{var c=new $CLJS.k(null,6,[$CLJS.E9,I9($CLJS.AT,b),$CLJS.C9,I9($CLJS.y_,b),$CLJS.SI,I9($CLJS.hK,b),$CLJS.z9,I9($CLJS.YQ,b),$CLJS.A9,I9($CLJS.cK,b),$CLJS.B9,I9($CLJS.IJ,b)],null),d=$CLJS.iE($CLJS.iC);$CLJS.n($CLJS.hE("metabase.lib.js.metadata",d))&&$CLJS.gE("metabase.lib.js.metadata",d,$CLJS.vy.l($CLJS.G(["Created metadata provider for metadata"])),
null);a=new J9(a,b,c,$CLJS.N)}return a},L9=function(a,b){return $CLJS.mZ.j($CLJS.mZ.j((0,$CLJS.z8)($CLJS.H9($CLJS.ED(a))),$CLJS.PT,function(c){return $CLJS.Uk.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.lU,$CLJS.N],null),c]))}),$CLJS.jQ,function(c){var d=function(){var e=$CLJS.SI.h(c);return $CLJS.n(e)?e:function l(g){return new $CLJS.me(null,function(){for(;;){var m=$CLJS.A(g);if(m){if($CLJS.Bd(m)){var t=$CLJS.kc(m),u=$CLJS.E(t),v=$CLJS.qe(u);a:for(var x=0;;)if(x<u){var z=$CLJS.md(t,x);v.add(new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.hK,z,null],null));x+=1}else{t=!0;break a}return t?$CLJS.te($CLJS.ve(v),l($CLJS.lc(m))):$CLJS.te($CLJS.ve(v),null)}v=$CLJS.B(m);return $CLJS.de(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hK,v,null],null),l($CLJS.Lc(m)))}return null}},null,null)}(b)}();return $CLJS.R.j(c,$CLJS.SI,gpa(d))})};$CLJS.h=J9.prototype;$CLJS.h.P=function(a,b){return new J9(this.Mf,this.li,this.lc,b)};$CLJS.h.O=function(){return this.Ph};$CLJS.h.Qh=$CLJS.r;
$CLJS.h.bg=function(a,b){a=this.lc;a=null==a?null:$CLJS.A9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.I.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.h.cg=function(a,b){return ipa(this.lc,b)};$CLJS.h.fg=function(a,b){a=this.lc;a=null==a?null:$CLJS.C9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.I.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.h.ag=function(a,b){return hpa(this.lc,b)};
$CLJS.h.$f=function(a,b){a=this.lc;a=null==a?null:$CLJS.SI.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.I.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.h.eg=function(a,b){return jpa(this.lc,b)};$CLJS.h.Yf=function(a,b){a=this.lc;a=null==a?null:$CLJS.z9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.I.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.h.dg=function(a,b){a=this.lc;a=null==a?null:$CLJS.B9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.I.g(a,b);return null==b?null:$CLJS.q(b)};
$CLJS.h.Zf=function(){var a=this.lc,b=this.Mf;a=null==a?null:$CLJS.E9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.I.g(a,b);return null==b?null:$CLJS.q(b)};var M9=function M9(a){switch(arguments.length){case 1:return M9.h(arguments[0]);case 2:return M9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.extract_template_tags",M9);M9.h=function(a){return M9.g(a,$CLJS.N)};M9.g=function(a,b){return $CLJS.n9.l($CLJS.G([$CLJS.koa.l($CLJS.G([a,$CLJS.m9.l($CLJS.G([b]))]))]))};M9.A=2;
var N9=function N9(a){return $CLJS.le(a)?[$CLJS.he(a),"/",$CLJS.jh(a)].join(""):$CLJS.yd(a)?$CLJS.Lu(a,N9):$CLJS.xd(a)?$CLJS.ff.g(N9,a):a},O9=function O9(a){switch(arguments.length){case 1:return O9.h(arguments[0]);case 2:return O9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.order_by_clause",O9);O9.h=function(a){return O9.g(a,$CLJS.eK)};
O9.g=function(a,b){return $CLJS.noa.l($CLJS.G([$CLJS.o9.l($CLJS.G([$CLJS.DD(a,$CLJS.G([$CLJS.Di,!0]))])),$CLJS.Ch.h(b)]))};O9.A=2;var P9=function P9(a){switch(arguments.length){case 2:return P9.g(arguments[0],arguments[1]);case 3:return P9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.available_binning_strategies",P9);P9.g=function(a,b){return $CLJS.we($CLJS.l9.l($CLJS.G([a,b])))};
P9.j=function(a,b,c){return $CLJS.we($CLJS.l9.l($CLJS.G([a,b,c])))};P9.A=3;var Q9=function Q9(a){switch(arguments.length){case 2:return Q9.g(arguments[0],arguments[1]);case 3:return Q9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.available_temporal_buckets",Q9);Q9.g=function(a,b){return $CLJS.we($CLJS.p9.l($CLJS.G([a,b])))};Q9.j=function(a,b,c){return $CLJS.we($CLJS.p9.l($CLJS.G([a,b,c])))};
Q9.A=3;var R9=function R9(a){switch(arguments.length){case 2:return R9.g(arguments[0],arguments[1]);case 3:return R9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.query_EQ_",R9);R9.g=function(a,b){return R9.j(a,b,null)};R9.j=function(a,b,c){a=L9(a,c);b=L9(b,c);return $CLJS.F.g(a,b)};R9.A=3;
var S9=function S9(a){switch(arguments.length){case 1:return S9.h(arguments[0]);case 2:return S9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.aggregation_clause",S9);S9.h=function(a){return $CLJS.k9.l($CLJS.G([a]))};S9.g=function(a,b){return $CLJS.k9.l($CLJS.G([a,b]))};S9.A=2;
var T9=function T9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return T9.l(arguments[0],arguments[1],2<c.length?new $CLJS.y(c.slice(2),0,null):null)};$CLJS.Aa("metabase.lib.js.filter_clause",T9);T9.l=function(a,b,c){return $CLJS.O.v($CLJS.Ena,a,b,c)};T9.A=2;T9.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return this.l(b,a,c)};
var U9=function U9(a){switch(arguments.length){case 2:return U9.g(arguments[0],arguments[1]);case 3:return U9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.expressionable_columns",U9);U9.g=function(a,b){return U9.g(a,b)};U9.j=function(a,b,c){return $CLJS.we($CLJS.sna.l($CLJS.G([a,b,c])))};U9.A=3;
var V9=function V9(a){switch(arguments.length){case 3:return V9.j(arguments[0],arguments[1],arguments[2]);case 4:return V9.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.with_different_database",V9);V9.j=function(a,b,c){return V9.v(a,b,c,null)};V9.v=function(a,b,c,d){return $CLJS.joa.l($CLJS.G([a,K9(b,c),$CLJS.DD(d,$CLJS.G([$CLJS.Di,!0]))]))};V9.A=4;
module.exports={extract_template_tags:M9,field_id:function(a){return $CLJS.una.l($CLJS.G([a]))},filter_operator:function(a,b,c){return $CLJS.Fna.l($CLJS.G([a,b,c]))},binning:function(a){return $CLJS.hna.l($CLJS.G([a]))},query:function(a,b,c){c=$CLJS.DD(c,$CLJS.G([$CLJS.Di,!0]));c=$CLJS.n($CLJS.ij.h(c))?c:$CLJS.R.j(c,$CLJS.ij,$CLJS.jQ);c=(0,$CLJS.z8)(c);c=$CLJS.V_.h(c);var d=$CLJS.iE($CLJS.iC);$CLJS.n($CLJS.hE("metabase.lib.js",d))&&$CLJS.gE("metabase.lib.js",d,$CLJS.f_("query map: %s",$CLJS.G([$CLJS.Ph.l($CLJS.G([c]))])),
null);return $CLJS.qoa.l($CLJS.G([K9(a,b),c]))},columns_group_columns:function(a){return $CLJS.we($CLJS.mna.l($CLJS.G([a])))},join_fields:function(a){a=$CLJS.Qna.l($CLJS.G([a]));return a instanceof $CLJS.M?$CLJS.jE(a):$CLJS.we(a)},aggregate:function(a,b,c){return $CLJS.cna.l($CLJS.G([a,b,$CLJS.DD(c,$CLJS.G([$CLJS.Di,!0]))]))},join_conditions:function(a){return $CLJS.we($CLJS.Pna.l($CLJS.G([a])))},query_EQ_:R9,temporal_bucket:function(a){return $CLJS.Coa.l($CLJS.G([a]))},remove_clause:function(a,b,
c){return $CLJS.soa.l($CLJS.G([a,b,$CLJS.o9.l($CLJS.G([$CLJS.DD(c,$CLJS.G([$CLJS.Di,!0]))]))]))},suggested_join_condition:function(a,b,c){return $CLJS.Vna.l($CLJS.G([a,b,c]))},replace_clause:function(a,b,c,d){return $CLJS.voa.l($CLJS.G([a,b,$CLJS.o9.l($CLJS.G([$CLJS.DD(c,$CLJS.G([$CLJS.Di,!0]))])),$CLJS.o9.l($CLJS.G([$CLJS.DD(d,$CLJS.G([$CLJS.Di,!0]))]))]))},drop_stage:function(a){return $CLJS.yoa.l($CLJS.G([a]))},join_lhs_display_name:function(a,b,c,d){return $CLJS.Rna.l($CLJS.G([a,b,c,d]))},add_field:function(a,
b,c){return $CLJS.tna.l($CLJS.G([a,b,c]))},native_extras:function(a){return $CLJS.Xk($CLJS.hoa.l($CLJS.G([a])))},available_temporal_buckets:Q9,with_binning:function(a,b){return $CLJS.ina.l($CLJS.G([a,b]))},append_stage:function(a){return $CLJS.xoa.l($CLJS.G([a]))},join:function(a,b,c){return $CLJS.Jna.l($CLJS.G([a,b,c]))},aggregations:function(a,b){return $CLJS.we($CLJS.ena.l($CLJS.G([a,b])))},raw_native_query:function(a){return $CLJS.coa.l($CLJS.G([a]))},filterable_column_operators:function(a){return $CLJS.we($CLJS.Dna.l($CLJS.G([a])))},
with_temporal_bucket:function(a,b){return $CLJS.Doa.l($CLJS.G([a,b]))},with_join_conditions:function(a,b){return $CLJS.Yna.l($CLJS.G([a,$CLJS.DD(b,$CLJS.G([$CLJS.Di,!0]))]))},available_metrics:function(a){return $CLJS.we($CLJS.aoa.l($CLJS.G([a])))},with_different_database:V9,with_fields:function(a,b,c){return $CLJS.zna.l($CLJS.G([a,b,c]))},join_clause:function(a,b){return $CLJS.Kna.l($CLJS.G([a,b]))},aggregation_clause:S9,external_op:function(a){a=$CLJS.ona.l($CLJS.G([a]));var b=$CLJS.Ge(a);a=$CLJS.I.g(b,
$CLJS.vI);var c=$CLJS.I.g(b,$CLJS.cj);b=$CLJS.I.g(b,$CLJS.Xt);return{operator:a,options:$CLJS.Xk(c),args:$CLJS.we(b)}},with_join_fields:function(a,b){return $CLJS.Wna.l($CLJS.G([a,"string"===typeof b?$CLJS.Ch.h(b):b]))},find_visible_column_for_legacy_ref:function(a,b,c){c=$CLJS.V_.h($CLJS.Fk.j($CLJS.DD(c,$CLJS.G([$CLJS.Di,!0])),0,$CLJS.Ch));return $CLJS.xna.l($CLJS.G([a,b,c]))},available_binning_strategies:P9,expression:function(a,b,c,d){return $CLJS.qna.l($CLJS.G([a,b,c,d]))},describe_relative_datetime:function(a,
b){a="string"===typeof a?$CLJS.Ch.h(a):a;b="string"===typeof b?$CLJS.Ch.h(b):b;return $CLJS.Boa.l($CLJS.G([a,b]))},order_bys:function(a,b){return $CLJS.we($CLJS.ooa.l($CLJS.G([a,b])))},display_info:function(a,b,c){return $CLJS.Xk.l($CLJS.Fk.v($CLJS.YD($CLJS.Zna.l($CLJS.G([$CLJS.z4(a,b),b,c])),$CLJS.QE),$CLJS.y_,$CLJS.YD,$CLJS.QE),$CLJS.G([$CLJS.jk,$CLJS.jE]))},fieldable_columns:function(a,b){return $CLJS.we($CLJS.vna.l($CLJS.G([a,b])))},template_tags:function(a){return $CLJS.n9.l($CLJS.G([$CLJS.eoa.l($CLJS.G([a]))]))},
selected_aggregation_operators:function(a,b){return $CLJS.we($CLJS.gna.l($CLJS.G([$CLJS.A(a),b])))},order_by_clause:O9,filterable_columns:function(a,b){return $CLJS.we($CLJS.Cna.l($CLJS.G([a,b])))},join_condition_rhs_columns:function(a,b,c,d,e){return $CLJS.we($CLJS.Nna.l($CLJS.G([a,b,c,d,e])))},join_condition_update_temporal_bucketing:function(a,b,c,d){return $CLJS.Ona.l($CLJS.G([a,b,c,d]))},filters:function(a,b){return $CLJS.we($CLJS.Bna.l($CLJS.G([a,b])))},join_condition_lhs_columns:function(a,
b,c,d,e){return $CLJS.we($CLJS.Lna.l($CLJS.G([a,b,c,d,e])))},table_or_card_metadata:function(a,b){var c=$CLJS.q_(b);return $CLJS.n(c)?$CLJS.T0(a,c):$CLJS.S0(a,b)},orderable_columns:function(a,b){return $CLJS.we($CLJS.poa.l($CLJS.G([a,b])))},find_filter_for_legacy_filter:function(a,b,c){return $CLJS.Hna.l($CLJS.G([a,b,$CLJS.A8(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jQ,$CLJS.KQ],null),$CLJS.DD(c,$CLJS.G([$CLJS.Di,!0])))]))},suggestedName:function(a){return $CLJS.$na.l($CLJS.G([a]))},metadataProvider:K9,
filter_clause:T9,fields:function(a,b){return $CLJS.we($CLJS.wna.l($CLJS.G([a,b])))},available_aggregation_operators:function(a,b){return $CLJS.we($CLJS.fna.l($CLJS.G([a,b])))},breakouts:function(a,b){return $CLJS.we($CLJS.lna.l($CLJS.G([a,b])))},joinable_columns:function(a,b,c){return $CLJS.we($CLJS.Tna.l($CLJS.G([a,b,c])))},group_columns:function(a){return $CLJS.we($CLJS.nna.l($CLJS.G([a])))},describe_temporal_unit:function(a,b){b="string"===typeof b?$CLJS.Ch.h(b):b;return $CLJS.zoa.l($CLJS.G([a,
b]))},joins:function(a,b){return $CLJS.we($CLJS.Una.l($CLJS.G([a,b])))},native_query:function(a,b,c){return $CLJS.boa.l($CLJS.G([K9(a,b),c]))},available_join_strategies:function(a,b){return $CLJS.we($CLJS.Ina.l($CLJS.G([a,b])))},legacy_query:function(a){return $CLJS.Xk.l(N9($CLJS.M_.h(a)),$CLJS.G([$CLJS.jk,$CLJS.jE]))},with_native_extras:function(a,b){return $CLJS.ioa.l($CLJS.G([a,$CLJS.DD(b,$CLJS.G([$CLJS.Di,!0]))]))},join_strategy:function(a){return $CLJS.Sna.l($CLJS.G([a]))},picker_info:function(a,
b){var c=$CLJS.Aj.h(b);switch(c instanceof $CLJS.M?c.T:null){case "metadata/table":return{databaseId:$CLJS.AT.h(a),tableId:$CLJS.Wi.h(b)};case "metadata/card":return{databaseId:$CLJS.AT.h(a),tableId:["card__",$CLJS.p.h($CLJS.Wi.h(b))].join(""),cardId:$CLJS.Wi.h(b),isModel:$CLJS.uga.h(b)};default:return a=$CLJS.iE($CLJS.oC),$CLJS.n($CLJS.hE("metabase.lib.js",a))&&$CLJS.gE("metabase.lib.js",a,$CLJS.vy.l($CLJS.G(["Cannot provide picker-info for",$CLJS.Aj.h(b)])),null),null}},aggregation_operator_columns:function(a){return $CLJS.we($CLJS.dna.l($CLJS.G([a])))},
available_segments:function(a){return $CLJS.we($CLJS.woa.l($CLJS.G([a])))},join_condition_operators:function(a,b,c,d){return $CLJS.we($CLJS.Mna.l($CLJS.G([a,b,c,d])))},expressionable_columns:U9,filter:function(a,b,c){return $CLJS.Ana.l($CLJS.G([a,b,$CLJS.DD(c,$CLJS.G([$CLJS.Di,!0]))]))},change_direction:function(a,b){return $CLJS.loa.l($CLJS.G([a,b]))},remove_field:function(a,b,c){return $CLJS.yna.l($CLJS.G([a,b,c]))},with_template_tags:function(a,b){return $CLJS.foa.l($CLJS.G([a,$CLJS.m9.l($CLJS.G([b]))]))},
expressions:function(a,b){return $CLJS.we($CLJS.rna.l($CLJS.G([a,b])))},filter_parts:function(a,b,c){a=$CLJS.Gna.l($CLJS.G([a,b,c]));var d=$CLJS.Ge(a);a=$CLJS.I.g(d,$CLJS.vI);b=$CLJS.I.g(d,$CLJS.cj);c=$CLJS.I.g(d,$CLJS.Si);d=$CLJS.I.g(d,$CLJS.Xt);return{operator:a,options:$CLJS.Xk($CLJS.bh(b,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pK,$CLJS.xK],null))),column:c,args:$CLJS.we(d)}},with_join_strategy:function(a,b){return $CLJS.Xna.l($CLJS.G([a,b]))},order_by:function(a,b,c,d){return $CLJS.moa.l($CLJS.G([a,
b,c,$CLJS.Ch.h(d)]))},describe_temporal_interval:function(a,b){a="string"===typeof a?$CLJS.Ch.h(a):a;b="string"===typeof b?$CLJS.Ch.h(b):b;return $CLJS.Aoa.l($CLJS.G([a,b]))},breakout:function(a,b,c){return $CLJS.jna.l($CLJS.G([a,b,$CLJS.roa.l($CLJS.G([c]))]))},database_id:function(a){return $CLJS.pna.l($CLJS.G([a]))},required_native_extras:function(a,b){return $CLJS.we($CLJS.goa.l($CLJS.G([K9(a,b)])))},remove_join:function(a,b,c){return $CLJS.toa.l($CLJS.G([a,b,c]))},joined_thing:function(a,b){return $CLJS.s4(a,
b)},with_native_query:function(a,b){return $CLJS.doa.l($CLJS.G([a,b]))},breakoutable_columns:function(a,b){return $CLJS.we($CLJS.kna.l($CLJS.G([a,b])))},rename_join:function(a,b,c,d){return $CLJS.uoa.l($CLJS.G([a,b,c,d]))}};
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var oI,qI,tI,DI;$CLJS.kI=new $CLJS.M("metabase.lib.schema.common","positive-int","metabase.lib.schema.common/positive-int",-733946956);$CLJS.lI=new $CLJS.M(null,"num-bins","num-bins",847672055);$CLJS.mI=new $CLJS.M(null,"mbql","mbql",69346710);$CLJS.nI=new $CLJS.M(null,"display-name","display-name",694513143);oI=new $CLJS.M("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);
$CLJS.pI=new $CLJS.M("metabase.lib.schema.common","semantic-or-relation-type","metabase.lib.schema.common/semantic-or-relation-type",-158604052);qI=new $CLJS.M("metabase.lib.schema.common","relation-type","metabase.lib.schema.common/relation-type",1412287664);$CLJS.rI=new $CLJS.M("lib","external-op","lib/external-op",-1470923877);$CLJS.sI=new $CLJS.M("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);
tI=new $CLJS.M("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);$CLJS.uI=new $CLJS.M(null,"database-type","database-type",-426840562);$CLJS.vI=new $CLJS.M(null,"operator","operator",-1860875338);$CLJS.wI=new $CLJS.M("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);
$CLJS.xI=new $CLJS.M("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);$CLJS.yI=new $CLJS.M("lib","uuid","lib/uuid",-2145250720);$CLJS.zI=new $CLJS.M(null,"x","x",2099068185);$CLJS.AI=new $CLJS.M("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);$CLJS.BI=new $CLJS.M(null,"bin-width","bin-width",1377922579);$CLJS.CI=new $CLJS.M(null,"strategy","strategy",-1471631918);
DI=new $CLJS.M("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.X($CLJS.sI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,new $CLJS.k(null,1,[$CLJS.On,1],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"non-blank string"],null),$CLJS.Oe($CLJS.bE)],null)],null));$CLJS.X($CLJS.wI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yt,new $CLJS.k(null,1,[$CLJS.On,0],null)],null));$CLJS.X($CLJS.kI,$CLJS.Gl);$CLJS.X(DI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,new $CLJS.k(null,2,[$CLJS.On,36,$CLJS.ck,36],null)],null));
$CLJS.X(tI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,2,[$CLJS.Au,"valid semantic type",$CLJS.Cu,function(a){a=$CLJS.Ge(a);a=$CLJS.I.g(a,$CLJS.zj);return["Not a valid semantic type: ",$CLJS.Ph.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.PF(a,$CLJS.oj)}],null));
$CLJS.X(qI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,2,[$CLJS.Au,"valid relation type",$CLJS.Cu,function(a){a=$CLJS.Ge(a);a=$CLJS.I.g(a,$CLJS.zj);return["Not a valid relation type: ",$CLJS.Ph.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.PF(a,$CLJS.qi)}],null));$CLJS.X($CLJS.pI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qI],null)],null));
$CLJS.X($CLJS.AI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,2,[$CLJS.Au,"valid base type",$CLJS.Cu,function(a){a=$CLJS.Ge(a);a=$CLJS.I.g(a,$CLJS.zj);return["Not a valid base type: ",$CLJS.Ph.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.PF(a,$CLJS.wj)}],null));
$CLJS.X($CLJS.xI,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yI,DI],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.AI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hi,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.AI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Pj,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,
$CLJS.pI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uI,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.sI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.S,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.sI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nI,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.sI],null)],null)],null));
$CLJS.X(oI,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.rI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,$CLJS.Tj,$CLJS.Ui],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,$CLJS.Dt],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cj,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.xI],null)],null));
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var JY,KY,LY,NY,OY,PY,QY,RY,SY,TY;JY=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);KY=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);LY=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.MY=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);NY=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);OY=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);PY=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
QY=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);RY=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);SY=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);TY=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.X(JY,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid template tag :widget-type"],null),$CLJS.Xu],null),$CLJS.Xg($CLJS.EY)));$CLJS.X(NY,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ht,$CLJS.hQ,$CLJS.YQ,$CLJS.KP,$CLJS.vv,$CLJS.HE,$CLJS.hT],null));
$CLJS.X(QY,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,$CLJS.sI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nI,$CLJS.sI],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,$CLJS.sI,$CLJS.Bt],null)],null)],null));
$CLJS.X(RY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gi,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.ul],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AR,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Ct],null)],null)],null));
$CLJS.X(OY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RY],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.KP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KP,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JY],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cj,new $CLJS.k(null,1,[$CLJS.Jt,!0],
null),$CLJS.bk],null)],null)],null));$CLJS.X(PY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QY],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.hQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UP,$CLJS.sI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zT,$CLJS.TO],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AT,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.RO],null)],null)],null));
$CLJS.X(TY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.YQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KU,$CLJS.QO],null)],null)],null));$CLJS.X(SY,$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ht],null),$CLJS.sY));
$CLJS.X(KY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RY],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SY],null)],null)],null)],null));
$CLJS.X(LY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NY],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,1,[$CLJS.Oi,$CLJS.ij],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KP,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OY],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hQ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PY],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YQ,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TY],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Po,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KY],null)],null)],null)],null));
$CLJS.X($CLJS.MY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qt,$CLJS.sI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LY],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Me(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return $CLJS.F.g(c,$CLJS.S.h(b))},a)}],null)],null));
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var iaa,jaa,kaa,laa,maa,naa,oaa,paa,qaa;iaa=function(a,b){a.sort(b||$CLJS.Ea)};jaa=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Ea;iaa(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};
$CLJS.aL=function(a){return $CLJS.F.g(a,$CLJS.Jd)?$CLJS.Jd:function(b,c){var d=a.g?a.g(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.n(d)?-1:$CLJS.n(a.g?a.g(c,b):a.call(null,c,b))?1:0}};$CLJS.bL=function(a,b){if($CLJS.A(b)){var c=$CLJS.we.h?$CLJS.we.h(b):$CLJS.we.call(null,b);jaa(c,$CLJS.aL(a));return $CLJS.qd($CLJS.A(c),$CLJS.rd(b))}return $CLJS.Kc};$CLJS.cL=new $CLJS.M(null,"second","second",-444702010);$CLJS.dL=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);
$CLJS.eL=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.fL=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.gL=new $CLJS.M(null,"year-of-era","year-of-era",682445876);kaa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);
laa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.hL=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.iL=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);
$CLJS.jL=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);maa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);naa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);
oaa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);paa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.kL=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);
$CLJS.lL=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.mL=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);$CLJS.nL=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
qaa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var oL=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.al,$CLJS.cl,$CLJS.Bj,$CLJS.ui,$CLJS.dl,$CLJS.$k,$CLJS.ni,$CLJS.gL],null),pL=$CLJS.ih(oL),raa,saa,taa;$CLJS.X(oaa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid date extraction unit"],null)],null),pL));var qL=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Gv,$CLJS.kj,$CLJS.tj,$CLJS.ti,$CLJS.ni],null),rL=$CLJS.ih(qL);
$CLJS.X(naa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid date truncation unit"],null)],null),rL));$CLJS.sL=$CLJS.eg.j($CLJS.Bf,$CLJS.Wk.o(),$CLJS.cf.g(qL,oL));raa=$CLJS.ih($CLJS.sL);$CLJS.X($CLJS.jL,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid date bucketing unit"],null)],null),raa));var tL=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dL,$CLJS.bl,$CLJS.Ai],null),uL=$CLJS.ih(tL);
$CLJS.X(maa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid time extraction unit"],null)],null),uL));var vL=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fL,$CLJS.cL,$CLJS.Ev,$CLJS.Fv],null),wL=$CLJS.ih(vL);$CLJS.X(qaa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid time truncation unit"],null)],null),wL));$CLJS.xL=$CLJS.eg.j($CLJS.Bf,$CLJS.Wk.o(),$CLJS.cf.g(vL,tL));saa=$CLJS.ih($CLJS.xL);
$CLJS.X(laa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid time bucketing unit"],null)],null),saa));$CLJS.yL=$CLJS.eg.j($CLJS.Bf,$CLJS.Wk.o(),$CLJS.cf.l(vL,qL,$CLJS.G([tL,oL])));taa=$CLJS.ih($CLJS.yL);$CLJS.X($CLJS.eL,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid datetime bucketing unit"],null)],null),$CLJS.yL));var uaa=$CLJS.ae.g(taa,$CLJS.gi);
$CLJS.X($CLJS.mL,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid temporal bucketing unit"],null)],null),uaa));var vaa=$CLJS.Hv.g(rL,wL);$CLJS.X($CLJS.hL,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid datetime truncation unit"],null)],null),vaa));$CLJS.zL=$CLJS.Hv.g(pL,uL);$CLJS.X(kaa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid datetime extraction unit"],null)],null),$CLJS.zL));
var AL=$CLJS.ae.g(rL,$CLJS.ni);$CLJS.X($CLJS.nL,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid date interval unit"],null)],null),AL));$CLJS.X($CLJS.iL,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid time interval unit"],null)],null),wL));var waa=$CLJS.Hv.g(AL,wL);$CLJS.X($CLJS.kL,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid datetime interval unit"],null)],null),waa));
$CLJS.X(paa,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.lL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wi,$CLJS.mL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gi,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Ct],null)],null));
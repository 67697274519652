var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.registry.js");
'use strict';var uZ,vZ,lfa,zZ,AZ,nfa,BZ,CZ,EZ,FZ,GZ,HZ,IZ,KZ,LZ,MZ,NZ,ofa,QZ,RZ,mfa;
uZ=function(a,b){a=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bf,a],null)],null);for(var c=$CLJS.Bf;;){var d=$CLJS.sd(a);if($CLJS.n(d)){var e=d,f=$CLJS.H(e,0,null),g=$CLJS.H(e,1,null),l=e,m=$CLJS.td(a);a=function(t,u,v,x,z,D,J,K){return function(U){return $CLJS.eg.j(v,$CLJS.ff.h(function(Z,da,qa,va,sb){return function(Nb){var Tb=$CLJS.H(Nb,0,null);Nb=$CLJS.H(Nb,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ae.g(sb,Tb),Nb],null)}}(t,u,v,x,z,D,J,K)),U)}}(a,c,m,e,f,g,l,d);
$CLJS.n(b.h?b.h(g):b.call(null,g))?(a=m,c=$CLJS.ae.g(c,l)):a=$CLJS.yd(g)?a(g):$CLJS.xd(g)?a($CLJS.Vl($CLJS.Gt,g)):m}else return c}};vZ=function(a,b,c){return $CLJS.Ad(c)&&$CLJS.F.g(a,$CLJS.B(c))&&!$CLJS.Id(b,$CLJS.I.g(c,2))};$CLJS.wZ=function(a){var b=$CLJS.eg.j($CLJS.eh,$CLJS.ff.h($CLJS.Ck.g($CLJS.$I,$CLJS.jd)),$CLJS.FT.h(a));return uZ($CLJS.vk.l(a,$CLJS.gR,$CLJS.G([$CLJS.VY])),function(c){return vZ($CLJS.zE,b,c)})};
$CLJS.xZ=function(a){var b=$CLJS.eg.j($CLJS.eh,$CLJS.ff.h($CLJS.Ck.g($CLJS.yI,$CLJS.jd)),$CLJS.XJ.h(a));return uZ($CLJS.vk.l(a,$CLJS.gR,$CLJS.G([$CLJS.VY])),function(c){return vZ($CLJS.XJ,b,c)})};lfa=function(a){function b(d){return $CLJS.gf(c,$CLJS.G([$CLJS.gR.h(d)]))}function c(d){return $CLJS.de($CLJS.WI.h(d),$CLJS.gf(b,$CLJS.G([$CLJS.aP.h(d)])))}return $CLJS.n($CLJS.yZ.h(a))?$CLJS.Pe(!0):$CLJS.ih(b(a))};
zZ=function(a){if($CLJS.xd(a))for(var b=$CLJS.Pe(!1),c=$CLJS.A(a),d=$CLJS.B(c),e=$CLJS.C(c),f=b,g=0,l=a;;){var m=f,t=g,u=l,v=$CLJS.A(u),x=$CLJS.B(v),z=$CLJS.C(v),D=x,J=z,K=$CLJS.WD(m,lfa(D));f=$CLJS.B($CLJS.A($CLJS.jf($CLJS.Ua,function(U,Z,da,qa,va,sb){return function dc(Tb,Ac){try{if($CLJS.Ad(Ac)&&3===$CLJS.E(Ac))try{var Bc=$CLJS.cd(Ac,0);if($CLJS.ge(Bc,$CLJS.hK))try{var wb=$CLJS.cd(Ac,1);if($CLJS.n($CLJS.$S.h(wb)))try{var Ib=$CLJS.cd(Ac,1);if(null!=Ib?Ib.C&256||$CLJS.r===Ib.We||(Ib.C?0:$CLJS.Xa($CLJS.rb,
Ib)):$CLJS.Xa($CLJS.rb,Ib))try{var dd=$CLJS.I.j(Ib,$CLJS.$S,$CLJS.pZ);if($CLJS.n($CLJS.Oe(qa)(dd))){var si=$CLJS.I.g(Ib,$CLJS.$S);$CLJS.cd(Ac,2);return new $CLJS.P(null,1,5,$CLJS.Q,[["Invalid :field reference in stage ",$CLJS.p.h(sb),": no join named ",$CLJS.Ph.l($CLJS.G([si]))].join("")],null)}throw $CLJS.Y;}catch(Ue){if(Ue instanceof Error){var rf=Ue;if(rf===$CLJS.Y)throw $CLJS.Y;throw rf;}throw Ue;}else throw $CLJS.Y;}catch(Ue){if(Ue instanceof Error){rf=Ue;if(rf===$CLJS.Y)throw $CLJS.Y;throw rf;
}throw Ue;}else throw $CLJS.Y;}catch(Ue){if(Ue instanceof Error){rf=Ue;if(rf===$CLJS.Y)throw $CLJS.Y;throw rf;}throw Ue;}else throw $CLJS.Y;}catch(Ue){if(Ue instanceof Error){rf=Ue;if(rf===$CLJS.Y)throw $CLJS.Y;throw rf;}throw Ue;}else throw $CLJS.Y;}catch(Ue){if(Ue instanceof Error){rf=Ue;if(rf===$CLJS.Y)return $CLJS.jZ(dc,Tb,Ac);throw rf;}throw Ue;}}}(f,g,l,K,m,t,u,v,x,z,D,J,b,0,a,a,c,d,e,d,e)($CLJS.Bf,$CLJS.vk.l(D,$CLJS.gR,$CLJS.G([mfa]))))));if($CLJS.n(f))return f;if($CLJS.A(J))g=t+1,f=K,l=J;
else return null}else return null};AZ=new $CLJS.M("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);nfa=new $CLJS.M("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);BZ=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);CZ=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);
$CLJS.DZ=new $CLJS.M("mbql.stage","mbql","mbql.stage/mbql",1578747798);EZ=new $CLJS.M("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);FZ=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);GZ=new $CLJS.M("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);
HZ=new $CLJS.M("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);IZ=new $CLJS.M("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);$CLJS.JZ=new $CLJS.M("mbql.stage","native","mbql.stage/native",359422194);KZ=new $CLJS.M("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);LZ=new $CLJS.M("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);
MZ=new $CLJS.M("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);NZ=new $CLJS.M("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.OZ=new $CLJS.M("mbql","query","mbql/query",-1285688662);$CLJS.yZ=new $CLJS.M(null,"source-card","source-card",-1580820390);ofa=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);$CLJS.PZ=new $CLJS.M(null,"filters","filters",974726919);
QZ=new $CLJS.M("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);RZ=new $CLJS.M("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);$CLJS.SZ=new $CLJS.M("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);mfa=new $CLJS.M("stage","metadata","stage/metadata",1707239131);$CLJS.X(GZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.JZ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PT,$CLJS.Ua],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xt,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,$CLJS.ul],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QR,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.sI],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lU,new $CLJS.k(null,1,[$CLJS.Jt,
!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.MY],null)],null)],null));$CLJS.X(AZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vj,new $CLJS.k(null,1,[$CLJS.On,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lK],null)],null));$CLJS.X(LZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vj,new $CLJS.k(null,1,[$CLJS.On,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lK],null)],null));
$CLJS.X(MZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XI],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.IJ],null),$CLJS.bk,$CLJS.Tj],null)],null));$CLJS.X(KZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vj,new $CLJS.k(null,1,[$CLJS.On,1],null),MZ],null));
var TZ=$CLJS.WD(function(a){a=$CLJS.B($CLJS.wZ(a));if($CLJS.n(a)){var b=$CLJS.XD(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :expression reference: no expression named ",$CLJS.Ph.l($CLJS.G([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.h($CLJS.I.g(a,1))].join("")}return null},function(a){a=$CLJS.B($CLJS.xZ(a));if($CLJS.n(a)){var b=$CLJS.XD(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.h(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.h($CLJS.I.g(a,1))].join("")}return null});$CLJS.X(NZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,2,[$CLJS.Au,"Valid references for a single query stage",$CLJS.Cu,function(a){a=$CLJS.Ge(a);a=$CLJS.I.g(a,$CLJS.zj);return TZ(a)}],null),$CLJS.Oe(TZ)],null));
$CLJS.X($CLJS.SZ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.DZ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gR,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZO],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FT,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.mS,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),AZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XJ,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SI,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),LZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PZ,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),KZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vV,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fP],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LS,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OO],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yZ,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QO],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Id(a,$CLJS.oU)}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,
new $CLJS.k(null,1,[$CLJS.Au,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.Oe($CLJS.oP($CLJS.LS,$CLJS.yZ))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NZ],null)],null));$CLJS.X(BZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SZ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OO],null)],null)],null)],null));
$CLJS.X(CZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SZ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QO],null)],null)],null)],null));$CLJS.X(FZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BZ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CZ],null)],null));
$CLJS.X(HZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.Oe($CLJS.WD($CLJS.LS,$CLJS.yZ))],null)],null));$CLJS.X(IZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ht,$CLJS.JZ,$CLJS.DZ],null));
$CLJS.X(nfa,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,IZ],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,1,[$CLJS.Oi,$CLJS.Aj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GZ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SZ],null)],null)],null)],null));
$CLJS.X(RZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,IZ],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,1,[$CLJS.Oi,$CLJS.Aj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GZ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FZ],null)],null)],null)],null));$CLJS.X(EZ,HZ);
$CLJS.X(QZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,2,[$CLJS.Au,"Valid references for all query stages",$CLJS.Cu,function(a){a=$CLJS.Ge(a);a=$CLJS.I.g(a,$CLJS.zj);return zZ.h?zZ.h(a):zZ.call(null,a)}],null),$CLJS.Oe(zZ)],null));
$CLJS.X($CLJS.XO,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RZ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EZ],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QZ],null)],null));
$CLJS.X(ofa,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.OZ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,$CLJS.RO,$CLJS.SO],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aP,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XO],null)],null)],null),$CLJS.efa],null));
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var qP,rP,sP,eca,fca,wP,gca,zP,AP,BP,CP,hca,DP,EP,GP,HP,tP,IP,ica,JP,jca,LP,MP,NP,kca,OP,PP,lca,RP,mca,SP,TP,nca,VP,oca,pca,qca,XP,YP,ZP,$P,aQ,bQ,cQ,dQ,rca,eQ,fQ,gQ,iQ,kQ,sca,lQ,mQ,nQ,oQ,tca,pQ,qQ,rQ,uca,sQ,tQ,uQ,vQ,vca,wQ,wca,xQ,yQ,zQ,xca,AQ,BQ,CQ,DQ,FQ,GQ,yca,HQ,IQ,zca,JQ,LQ,MQ,NQ,OQ,Aca,PQ,Bca,QQ,RQ,TQ,Cca,UQ,VQ,WQ,Dca,ZQ,$Q,Eca,Fca,aR,cR,dR,eR,fR,Gca,iR,jR,Hca,Ica,Jca,kR,lR,mR,nR,oR,pR,Kca,qR,Lca,rR,sR,uR,wR,xR,yR,zR,Mca,BR,Nca,Oca,CR,DR,ER,FR,GR,Pca,HR,Qca,Rca,IR,JR,KR,LR,MR,Sca,
Tca,Uca,NR,Vca,OR,PR,Wca,RR,SR,Xca,UR,VR,WR,YR,Yca,aS,Zca,$ca,bS,cS,dS,ada,eS,fS,gS,hS,bda,jS,kS,lS,nS,oS,cda,pS,qS,dda,rS,sS,tS,uS,vS,wS,yS,eda,zS,fda,AS,BS,CS,gda,hda,ida,ES,FS,GS,jda,HS,IS,JS,KS,kda,lda,MS,mda,nda,NS,OS,PS,QS,RS,oda,SS,TS,US,VS,pda,WS,XS,YS,ZS,aT,qda,bT,cT,rda,dT,eT,fT,sda,gT,tda,iT,uda,jT,kT,lT,mT,nT,oT,pT,vda,wda,xda,qT,yda,rT,sT,zda,Ada,tT,uT,vT,Bda,wT,Cda,xT,Dda,Eda,yT,Fda,Gda,Hda,BT,CT,Ida,DT,ET,GT,Jda,HT,Kda,IT,JT,KT,Lda,Mda,LT,MT,NT,OT,Nda,QT,RT,Oda,ST,TT,UT,VT,WT,XT,YT,
ZT,Pda,$T,aU,Qda,bU,Rda,cU,dU,eU,Sda,fU,gU,Tda,Uda,hU,iU,jU,Vda,Wda,kU,Xda,Yda,Zda,nU,$da,qU,aea,bea,rU,sU,cea,dea,eea,tU,uU,vU,wU,xU,yU,zU,fea,AU,BU,gea,CU,hea,iea,jea,DU,EU,FU,kea,GU,HU,lea,IU,mea,JU,nea,oea,LU,NU,OU,PU,QU,pea,RU,SU,qea,TU,UU,VU,WU,XU,YU,ZU,$U,rea,aV,bV,dV,eV,fV,gV,hV,iV,jV,kV,lV,sea,mV,nV,oV,pV,qV,tea,rV,sV,tV,uV,uea,wV,vea,wea,yV,xea,zV,yea,AV,zea,Aea,BV,Bea,CV,EV,FV,Cea,JV,KV,Dea,Eea,LV,MV,NV,OV,PV,QV,RV,TV,UV,Fea;
$CLJS.oP=function(a,b){return function(){function c(l,m,t){return $CLJS.Fd(function(){var u=a.h?a.h(l):a.call(null,l);return $CLJS.n(u)?(u=a.h?a.h(m):a.call(null,m),$CLJS.n(u)?(u=a.h?a.h(t):a.call(null,t),$CLJS.n(u)?(u=b.h?b.h(l):b.call(null,l),$CLJS.n(u)?(u=b.h?b.h(m):b.call(null,m),$CLJS.n(u)?b.h?b.h(t):b.call(null,t):u):u):u):u):u}())}function d(l,m){return $CLJS.Fd(function(){var t=a.h?a.h(l):a.call(null,l);return $CLJS.n(t)?(t=a.h?a.h(m):a.call(null,m),$CLJS.n(t)?(t=b.h?b.h(l):b.call(null,l),
$CLJS.n(t)?b.h?b.h(m):b.call(null,m):t):t):t}())}function e(l){var m=a.h?a.h(l):a.call(null,l);l=$CLJS.n(m)?b.h?b.h(l):b.call(null,l):m;return $CLJS.Fd(l)}var f=null,g=function(){function l(t,u,v,x){var z=null;if(3<arguments.length){z=0;for(var D=Array(arguments.length-3);z<D.length;)D[z]=arguments[z+3],++z;z=new $CLJS.y(D,0,null)}return m.call(this,t,u,v,z)}function m(t,u,v,x){return $CLJS.Fd(function(){var z=f.j(t,u,v);return $CLJS.n(z)?$CLJS.Me(function(D){var J=a.h?a.h(D):a.call(null,D);return $CLJS.n(J)?
b.h?b.h(D):b.call(null,D):J},x):z}())}l.A=3;l.B=function(t){var u=$CLJS.B(t);t=$CLJS.C(t);var v=$CLJS.B(t);t=$CLJS.C(t);var x=$CLJS.B(t);t=$CLJS.Lc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 0:return!0;case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.y(x,0,null)}return g.l(l,m,t,
v)}throw Error("Invalid arity: "+arguments.length);};f.A=3;f.B=g.B;f.o=function(){return!0};f.h=e;f.g=d;f.j=c;f.l=g.l;return f}()};
$CLJS.pP=function(a){var b=$CLJS.no.g(a,null),c=$CLJS.pJ(b,$CLJS.Lt,function(d){return $CLJS.mn(d,$CLJS.Bf)});return function(){function d(g,l,m){return(l=$CLJS.A(c.j?c.j(g,l,m):c.call(null,g,l,m)))?new $CLJS.k(null,3,[$CLJS.hl,b,$CLJS.zj,g,$CLJS.St,l],null):null}function e(g){return f.j(g,$CLJS.Bf,$CLJS.Bf)}var f=null;f=function(g,l,m){switch(arguments.length){case 1:return e.call(this,g);case 3:return d.call(this,g,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
qP=function(a,b){return $CLJS.yd(a)?$CLJS.I.g(a,b):a};rP=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.pn($CLJS.no.g($CLJS.hl.h(a),null));if($CLJS.n(b)){var e=qP($CLJS.Cu.h(b),c);$CLJS.n(e)?(e=$CLJS.wq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:qP($CLJS.Au.h(b),c)}return null};sP=function(a,b){return $CLJS.wd(a)||$CLJS.Al(a)?$CLJS.I.g(a,b):$CLJS.xd(a)?$CLJS.I.g($CLJS.Cf(a),b):null};
eca=function(a,b){a=$CLJS.Ge(a);var c=$CLJS.I.g(a,$CLJS.hl),d=$CLJS.I.g(a,$CLJS.ij);b=$CLJS.Ge(b);var e=$CLJS.I.j(b,$CLJS.St,$CLJS.zF),f=$CLJS.I.j(b,$CLJS.rF,!0),g=$CLJS.I.g(b,$CLJS.yF),l=$CLJS.I.j(b,$CLJS.tF,$CLJS.pF),m=rP(a,$CLJS.Iq.h(c),g,b);if($CLJS.n(m))return m;m=rP(a,$CLJS.gu.h(c),g,b);if($CLJS.n(m))return m;m=rP(a,e.h?e.h(d):e.call(null,d),g,b);if($CLJS.n(m))return m;m=rP(a,function(){var t=$CLJS.Un.h(c);return e.h?e.h(t):e.call(null,t)}(),g,b);if($CLJS.n(m))return m;m=rP(a,$CLJS.Iq.h(c),
l,b);if($CLJS.n(m))return m;m=rP(a,$CLJS.gu.h(c),l,b);if($CLJS.n(m))return m;d=rP(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=rP(a,function(){var t=$CLJS.Un.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;g=$CLJS.n(f)?rP(a,e.h?e.h($CLJS.nF):e.call(null,$CLJS.nF),g,b):f;return $CLJS.n(g)?g:$CLJS.n(f)?rP(a,e.h?e.h($CLJS.nF):e.call(null,$CLJS.nF),l,b):f};
fca=function(a,b,c){a=$CLJS.Q;var d=$CLJS.eg.g;var e=$CLJS.el.h(b);var f=$CLJS.Ge(b);var g=$CLJS.I.g(f,$CLJS.hl),l=$CLJS.Ge(c);f=$CLJS.I.g(l,$CLJS.yF);l=$CLJS.I.j(l,$CLJS.tF,$CLJS.pF);g=$CLJS.Iq.h(g);f=qP($CLJS.qF.h(g),f);f=$CLJS.n(f)?f:qP($CLJS.qF.h(g),l);d=d.call($CLJS.eg,e,f);return new $CLJS.P(null,2,5,a,[d,eca(b,c)],null)};
$CLJS.uP=function(a){var b=$CLJS.Ge(a),c=$CLJS.I.g(b,$CLJS.zj);a=$CLJS.I.g(b,$CLJS.St);var d=$CLJS.Ge(null),e=$CLJS.I.j(d,$CLJS.sF,$CLJS.Qi),f=$CLJS.I.j(d,$CLJS.wF,fca);return $CLJS.n(a)?$CLJS.bb(function(g,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.H(m,0,null);m=$CLJS.H(m,1,null);l=$CLJS.R.j(l,$CLJS.Qi,m);l=e.h?e.h(l):e.call(null,l);return tP(g,c,t,l)},null,a):null};
wP=function(a,b,c){var d=$CLJS.XD($CLJS.q($CLJS.gI),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Rh.v($CLJS.gI,$CLJS.vP,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.xP=function(a){return wP($CLJS.lk,a,function(){return $CLJS.qJ.h(a)})};gca=function(){var a=yP;return wP($CLJS.Lt,a,function(){var b=$CLJS.qJ.h(a),c=$CLJS.pP(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
zP=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,$CLJS.le(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};AP=function(a){if($CLJS.Ad(a)){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);switch(b instanceof $CLJS.M?b.T:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,zP(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,zP(c)],null);default:return zP(a)}}else return zP(a)};
BP=function(a,b){var c=$CLJS.Ad(b);return c?(c=$CLJS.B(b)instanceof $CLJS.M)?$CLJS.vd(a)?(b=$CLJS.B(b),a=$CLJS.ih(a),a.h?a.h(b):a.call(null,b)):$CLJS.F.g(a,$CLJS.B(b)):c:c};
CP=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Qe(BP,a)],null),$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.at,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,a],null)],null)],null),function(){return function e(d){return new $CLJS.me(null,function(){for(;;){var f=$CLJS.A(d);if(f){if($CLJS.Bd(f)){var g=$CLJS.kc(f),l=$CLJS.E(g),m=$CLJS.qe(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.md(g,t),v=$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,AP(u)],null);m.add(v);t+=1}else{g=!0;break a}return g?$CLJS.te($CLJS.ve(m),e($CLJS.lc(f))):$CLJS.te($CLJS.ve(m),null)}g=$CLJS.B(f);m=$CLJS.H(g,0,null);g=$CLJS.H(g,1,null);return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[m,AP(g)],null),e($CLJS.Lc(f)))}return null}},null,null)}($CLJS.ju(2,2,b))}())],null)};hca=function(a){return $CLJS.Ad(a)&&$CLJS.B(a)instanceof $CLJS.M?$CLJS.B(a):null};
DP=function(a){return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Oi,hca,$CLJS.Au,["valid instance of one of these MBQL clauses: ",$CLJS.Mu(", ",$CLJS.ff.g($CLJS.B,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.me(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.Bd(e)){var f=$CLJS.kc(e),g=$CLJS.E(f),l=$CLJS.qe(g);a:for(var m=0;;)if(m<g){var t=$CLJS.md(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.le(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.lc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.le(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};
EP=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nP],null)],null)};$CLJS.FP={};GP={};HP={};$CLJS.vP=function vP(a,b,c){var e=$CLJS.A(b);b=$CLJS.B(e);var f=$CLJS.C(e);if(f){e=$CLJS.R.j;var g=$CLJS.I.g(a,b);c=vP.j?vP.j(g,f,c):vP.call(null,g,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
tP=function tP(a,b,c,d){var f=$CLJS.A(c);c=$CLJS.B(f);var g=$CLJS.C(f),l=sP(b,c);f=$CLJS.n(a)?a:$CLJS.xd(b)?$CLJS.Bf:$CLJS.zd(b)?$CLJS.N:$CLJS.ld(b);return $CLJS.n($CLJS.n(c)?$CLJS.oF.h($CLJS.rd(f)):c)?a:$CLJS.n(c)?(b=sP(f,c),d=tP.v?tP.v(b,l,g,d):tP.call(null,b,l,g,d),$CLJS.Fl(c)&&$CLJS.xd(f)&&c>$CLJS.E(f)&&(b=$CLJS.$e(c-$CLJS.E(f),null),b=$CLJS.cf.g(f,b),f=null==f||$CLJS.Ed(f)?b:$CLJS.eg.g($CLJS.ld(f),b)),null==f||$CLJS.Al(f)?$CLJS.R.j(f,c,d):$CLJS.wd(f)?$CLJS.ae.g(f,d):$CLJS.O.g($CLJS.V,$CLJS.R.j($CLJS.Cf(f),
c,d))):$CLJS.yd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.vF],null),tP.v?tP.v(f,b,c,d):tP.call(null,f,b,c,d)):$CLJS.n($CLJS.oF.h($CLJS.rd(f)))?$CLJS.ae.g(f,d):$CLJS.Ad($CLJS.He(f))?f:$CLJS.qd(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.k(null,1,[$CLJS.oF,!0],null))};IP=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);ica=new $CLJS.w("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
JP=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);$CLJS.KP=new $CLJS.M(null,"dimension","dimension",543254198);jca=new $CLJS.w("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);LP=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);MP=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);
NP=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);kca=new $CLJS.w("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);OP=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);PP=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);lca=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);
$CLJS.QP=new $CLJS.M(null,"query-hash","query-hash",-1524484965);RP=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);mca=new $CLJS.M(null,"from","from",1815293044);SP=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);TP=new $CLJS.w(null,"does-not-contain","does-not-contain",1365584674,null);nca=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);
$CLJS.UP=new $CLJS.M(null,"snippet-name","snippet-name",819240328);VP=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);oca=new $CLJS.w("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);pca=new $CLJS.w("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.WP=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);
qca=new $CLJS.w("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);XP=new $CLJS.M("location","zip_code","location/zip_code",1641155222);YP=new $CLJS.w(null,"ExpressionArg","ExpressionArg",1060743736,null);ZP=new $CLJS.w(null,"get-hour","get-hour",-2031721710,null);$P=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);aQ=new $CLJS.w(null,"ag:var","ag:var",-1804440286,null);
bQ=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);cQ=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);dQ=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);rca=new $CLJS.M(null,"lon-max","lon-max",1590224717);eQ=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);
fQ=new $CLJS.w(null,"stddev","stddev",775056588,null);gQ=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);$CLJS.hQ=new $CLJS.M(null,"snippet","snippet",953581994);iQ=new $CLJS.w("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);$CLJS.jQ=new $CLJS.M(null,"query","query",-1288509510);kQ=new $CLJS.M(null,"string-expression","string-expression",-1395337766);sca=new $CLJS.M(null,"lat-field","lat-field",-830652957);
lQ=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);mQ=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);nQ=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);oQ=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);tca=new $CLJS.w("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);
pQ=new $CLJS.M("location","country","location/country",1666636202);qQ=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);rQ=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);uca=new $CLJS.w("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);sQ=new $CLJS.M(null,"unary","unary",-989314568);tQ=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
uQ=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);vQ=new $CLJS.w(null,"ceil","ceil",-184398425,null);vca=new $CLJS.M(null,"lon-min","lon-min",-787291357);wQ=new $CLJS.w(null,"NonBlankString","NonBlankString",-719244809,null);wca=new $CLJS.M(null,"match","match",1220059550);xQ=new $CLJS.w(null,"count-where","count-where",2025939247,null);yQ=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
zQ=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);xca=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);AQ=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);BQ=new $CLJS.w(null,"sum","sum",1777518341,null);CQ=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);DQ=new $CLJS.w("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.EQ=new $CLJS.M("date","range","date/range",1647265776);FQ=new $CLJS.w(null,"between","between",-1523336493,null);GQ=new $CLJS.M(null,"clause-form","clause-form",1820463737);yca=new $CLJS.w("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);HQ=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);IQ=new $CLJS.w(null,"field","field",338095027,null);zca=new $CLJS.M(null,"segment-id","segment-id",1810133590);
JQ=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);$CLJS.KQ=new $CLJS.M(null,"filter","filter",-948537934);LQ=new $CLJS.w(null,"not-null","not-null",313812992,null);MQ=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);NQ=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);OQ=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);
Aca=new $CLJS.M(null,"template-tag","template-tag",310841038);PQ=new $CLJS.M(null,"invalid","invalid",412869516);Bca=new $CLJS.w("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);QQ=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);RQ=new $CLJS.w(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.SQ=new $CLJS.M(null,"context","context",-830191113);
TQ=new $CLJS.w(null,"get-year","get-year",704520253,null);Cca=new $CLJS.M(null,"format-rows?","format-rows?",992129486);UQ=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);VQ=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);WQ=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.XQ=new $CLJS.M(null,"parameters","parameters",-1229919748);
Dca=new $CLJS.M(null,"json-download","json-download",-971130133);$CLJS.YQ=new $CLJS.M(null,"card","card",-1430355152);ZQ=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);$Q=new $CLJS.w(null,"!\x3d","!\x3d",-201205829,null);Eca=new $CLJS.w("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Fca=new $CLJS.w("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);aR=new $CLJS.w(null,"expression","expression",1842843403,null);
$CLJS.bR=new $CLJS.M("date","month-year","date/month-year",1948031290);cR=new $CLJS.M("metabase.mbql.schema","FieldOrAggregationReference","metabase.mbql.schema/FieldOrAggregationReference",-334144392);dR=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);eR=new $CLJS.w(null,"ends-with","ends-with",1183740516,null);fR=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);
$CLJS.gR=new $CLJS.M(null,"joins","joins",1033962699);Gca=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.hR=new $CLJS.M(null,"source-field","source-field",933829534);iR=new $CLJS.w(null,"Field","Field",430385967,null);jR=new $CLJS.w(null,"convert-timezone","convert-timezone",1515728526,null);Hca=new $CLJS.w("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);Ica=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);
Jca=new $CLJS.M(null,"items","items",1031954938);kR=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);lR=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);mR=new $CLJS.M(null,"more","more",-2058821800);nR=new $CLJS.M(null,"first-clause","first-clause",-20953491);oR=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);pR=new $CLJS.w(null,"OrderComparable","OrderComparable",772072595,null);
Kca=new $CLJS.w("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);qR=new $CLJS.w(null,"contains","contains",-1977535957,null);Lca=new $CLJS.w("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);rR=new $CLJS.w("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);sR=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.tR=new $CLJS.M(null,"widget-type","widget-type",1836256899);
uR=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.vR=new $CLJS.M(null,"fingerprint","fingerprint",598613022);wR=new $CLJS.w(null,"is-null","is-null",-356519403,null);xR=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);yR=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);zR=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);Mca=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);
$CLJS.AR=new $CLJS.M(null,"required","required",1807647006);BR=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);Nca=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);Oca=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);CR=new $CLJS.w(null,"CaseClauses","CaseClauses",-1749071354,null);DR=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);ER=new $CLJS.M("string","contains","string/contains",1602423827);
FR=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);GR=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);Pca=new $CLJS.w("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);HR=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);Qca=new $CLJS.w(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);
Rca=new $CLJS.w("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);IR=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);JR=new $CLJS.w("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);KR=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);LR=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);
MR=new $CLJS.w(null,"share","share",1051097594,null);Sca=new $CLJS.w("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);Tca=new $CLJS.w("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);Uca=new $CLJS.w("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);NR=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);Vca=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
OR=new $CLJS.w(null,"FieldOrAggregationReference","FieldOrAggregationReference",85477856,null);PR=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);Wca=new $CLJS.w("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.QR=new $CLJS.M(null,"collection","collection",-683361892);RR=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);
SR=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);$CLJS.TR=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);Xca=new $CLJS.w("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);UR=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);VR=new $CLJS.w(null,"metric","metric",2049329604,null);WR=new $CLJS.w(null,"concat","concat",-467652465,null);YR=new $CLJS.M(null,"variable","variable",-281346492);
$CLJS.ZR=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);Yca=new $CLJS.w(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.$R=new $CLJS.M("date","relative","date/relative",25987732);aS=new $CLJS.M("location","city","location/city",-1746973325);Zca=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);$ca=new $CLJS.w("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);
bS=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);cS=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);dS=new $CLJS.M("number","between","number/between",97700581);ada=new $CLJS.w("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);eS=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);fS=new $CLJS.w(null,"sqrt","sqrt",370479598,null);
gS=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);hS=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);$CLJS.iS=new $CLJS.M(null,"semantic_type","semantic_type",272485089);bda=new $CLJS.M(null,"metric-id","metric-id",-686486942);jS=new $CLJS.w(null,"*","*",345799209,null);kS=new $CLJS.w(null,"+","+",-740910886,null);lS=new $CLJS.w(null,"-","-",-471816912,null);
$CLJS.mS=new $CLJS.M(null,"breakout","breakout",-732419050);nS=new $CLJS.w(null,"template-tag","template-tag",1951372565,null);oS=new $CLJS.M(null,"allowed-for","allowed-for",122724334);cda=new $CLJS.M(null,"question","question",-1411720117);pS=new $CLJS.w(null,"asc","asc",1997386096,null);qS=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);dda=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);
rS=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);sS=new $CLJS.w(null,"\x3c\x3d","\x3c\x3d",1244895369,null);tS=new $CLJS.w(null,"\x3c","\x3c",993667236,null);uS=new $CLJS.w(null,"\x3e","\x3e",1085014381,null);vS=new $CLJS.w(null,"\x3d","\x3d",-1501502141,null);wS=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.xS=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);
yS=new $CLJS.M("string","ends-with","string/ends-with",302681156);eda=new $CLJS.w("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);zS=new $CLJS.w(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);fda=new $CLJS.w(null,"MetricID","MetricID",-2128635641,null);AS=new $CLJS.w(null,"and","and",668631710,null);BS=new $CLJS.w(null,"\x3e\x3d","\x3e\x3d",1016916022,null);CS=new $CLJS.w(null,"round","round",-645002441,null);
gda=new $CLJS.M(null,"to","to",192099007);$CLJS.DS=new $CLJS.M("date","single","date/single",1554682003);hda=new $CLJS.M(null,"action-id","action-id",-1727958578);ida=new $CLJS.w("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);ES=new $CLJS.w(null,"exp","exp",1378825265,null);FS=new $CLJS.w(null,"Filter","Filter",-424893332,null);GS=new $CLJS.w(null,"cum-count","cum-count",-323900016,null);jda=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);
HS=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);IS=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);JS=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);KS=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.LS=new $CLJS.M(null,"source-table","source-table",-225307692);kda=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);
lda=new $CLJS.w("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);MS=new $CLJS.w(null,"floor","floor",-772394748,null);mda=new $CLJS.M(null,"middleware","middleware",1462115504);nda=new $CLJS.w("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);NS=new $CLJS.M(null,"requires-features","requires-features",-101116256);OS=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);
PS=new $CLJS.M(null,"clause-name","clause-name",-996419059);QS=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);RS=new $CLJS.w(null,"now","now",-9994004,null);oda=new $CLJS.w("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);SS=new $CLJS.w(null,"not","not",1044554643,null);TS=new $CLJS.w(null,"avg","avg",1837937727,null);US=new $CLJS.w(null,"sum-where","sum-where",-519087341,null);
VS=new $CLJS.M(null,"max-results","max-results",-32858165);pda=new $CLJS.w("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);WS=new $CLJS.w(null,"case","case",-1510733573,null);XS=new $CLJS.w(null,"distinct","distinct",-148347594,null);YS=new $CLJS.w(null,"get-second","get-second",-425414791,null);ZS=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.$S=new $CLJS.M(null,"join-alias","join-alias",1454206794);
aT=new $CLJS.w(null,"is-empty","is-empty",600228619,null);qda=new $CLJS.w("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);bT=new $CLJS.w(null,"relative-datetime","relative-datetime",-1369266491,null);cT=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);rda=new $CLJS.M(null,"original","original",-445386197);dT=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);eT=new $CLJS.w(null,"abs","abs",1394505050,null);
fT=new $CLJS.w(null,"datetime-diff","datetime-diff",-1521323614,null);sda=new $CLJS.w("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);gT=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);tda=new $CLJS.w(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.hT=new $CLJS.M(null,"date","date",-1463434462);iT=new $CLJS.M(null,"second-clause","second-clause",-461435645);
uda=new $CLJS.w("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);jT=new $CLJS.w(null,"rtrim","rtrim",979195078,null);kT=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);lT=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);mT=new $CLJS.w("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);
nT=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);oT=new $CLJS.w(null,"CaseOptions","CaseOptions",1989286806,null);pT=new $CLJS.w(null,"or","or",1876275696,null);vda=new $CLJS.w("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);wda=new $CLJS.M(null,"constraints","constraints",422775616);xda=new $CLJS.w("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);
qT=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);yda=new $CLJS.M(null,"csv-download","csv-download",2141432084);rT=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);sT=new $CLJS.w(null,"datetime-add","datetime-add",1850134938,null);zda=new $CLJS.w("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);
Ada=new $CLJS.w("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);tT=new $CLJS.w("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);uT=new $CLJS.w("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);vT=new $CLJS.w("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);Bda=new $CLJS.w("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);
wT=new $CLJS.w(null,"get-quarter","get-quarter",-1326657176,null);Cda=new $CLJS.w("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);xT=new $CLJS.w("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);Dda=new $CLJS.w("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);Eda=new $CLJS.w("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);yT=new $CLJS.w(null,"temporal-extract","temporal-extract",-872749364,null);
Fda=new $CLJS.w("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);Gda=new $CLJS.w("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);Hda=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.zT=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);$CLJS.AT=new $CLJS.M(null,"database","database",1849087575);BT=new $CLJS.w(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);
CT=new $CLJS.w(null,"get-day-of-week","get-day-of-week",-644295017,null);Ida=new $CLJS.w("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);DT=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);ET=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.FT=new $CLJS.M(null,"expressions","expressions",255689909);
GT=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);Jda=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);HT=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);Kda=new $CLJS.w("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);IT=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);
JT=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);KT=new $CLJS.w(null,"absolute-datetime","absolute-datetime",1080191062,null);Lda=new $CLJS.w(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);Mda=new $CLJS.M(null,"action","action",-811238024);LT=new $CLJS.w(null,"get-day","get-day",1768100384,null);MT=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);
NT=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);OT=new $CLJS.w(null,"not-empty","not-empty",2029453590,null);$CLJS.PT=new $CLJS.M(null,"native","native",-613060878);Nda=new $CLJS.w("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);QT=new $CLJS.M(null,"page","page",849072397);RT=new $CLJS.w(null,"length","length",-2065447907,null);Oda=new $CLJS.M(null,"dashboard","dashboard",-631747508);
ST=new $CLJS.w(null,"get-week","get-week",752472178,null);TT=new $CLJS.w(null,"get-month","get-month",1271156796,null);UT=new $CLJS.w(null,"dimension","dimension",-2111181571,null);VT=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);WT=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);XT=new $CLJS.w(null,"StringFilterOptions","StringFilterOptions",-847398229,null);
YT=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);ZT=new $CLJS.w("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);Pda=new $CLJS.w("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);$T=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);aU=new $CLJS.w(null,"substring","substring",-1513569493,null);Qda=new $CLJS.w("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);
bU=new $CLJS.M(null,"internal","internal",-854870097);Rda=new $CLJS.w("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);cU=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);dU=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);eU=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);
Sda=new $CLJS.w("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);fU=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);gU=new $CLJS.w(null,"ltrim","ltrim",-1000166486,null);Tda=new $CLJS.w("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);Uda=new $CLJS.w("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);
hU=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);iU=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);jU=new $CLJS.w("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);Vda=new $CLJS.w("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);
Wda=new $CLJS.w("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);kU=new $CLJS.w(null,"desc","desc",-560950005,null);$CLJS.lU=new $CLJS.M(null,"template-tags","template-tags",1853115685);Xda=new $CLJS.M(null,"public-question","public-question",629369976);$CLJS.mU=new $CLJS.M(null,"fk-field-id","fk-field-id",1124127357);Yda=new $CLJS.w("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
Zda=new $CLJS.M(null,"binary","binary",-1802232288);nU=new $CLJS.w(null,"time-interval","time-interval",-1949813754,null);$da=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.oU=new $CLJS.M(null,"source-query","source-query",198004422);$CLJS.pU=new $CLJS.M(null,"settings","settings",1556144875);qU=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);aea=new $CLJS.w(null,"SegmentID","SegmentID",-1773652704,null);
bea=new $CLJS.w("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);rU=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);sU=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);cea=new $CLJS.M(null,"executed-by","executed-by",-739811161);dea=new $CLJS.w("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
eea=new $CLJS.w("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);tU=new $CLJS.M(null,"amount","amount",364489504);uU=new $CLJS.w(null,"percentile","percentile",1039342775,null);vU=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);wU=new $CLJS.w(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);xU=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
yU=new $CLJS.w(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);zU=new $CLJS.w(null,"trim","trim",-1880116002,null);fea=new $CLJS.w(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);AU=new $CLJS.w(null,"coalesce","coalesce",-1999813740,null);BU=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);gea=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
CU=new $CLJS.M("string","\x3d","string/\x3d",983744235);hea=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);iea=new $CLJS.w("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);jea=new $CLJS.w("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);DU=new $CLJS.w(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);EU=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
FU=new $CLJS.w(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);kea=new $CLJS.M(null,"lat-min","lat-min",1630784161);GU=new $CLJS.w(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);HU=new $CLJS.w(null,"inside","inside",-681932758,null);lea=new $CLJS.w("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);IU=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
mea=new $CLJS.w("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);JU=new $CLJS.w(null,"starts-with","starts-with",2007034536,null);nea=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);oea=new $CLJS.w("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.KU=new $CLJS.M(null,"card-id","card-id",-1770060179);LU=new $CLJS.M(null,"variadic","variadic",882626057);
$CLJS.MU=new $CLJS.M(null,"category","category",-593092832);NU=new $CLJS.w(null,"upper","upper",1886775433,null);OU=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);PU=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);QU=new $CLJS.w(null,"optional","optional",-600484260,null);pea=new $CLJS.w("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);
RU=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);SU=new $CLJS.M(null,"sugar","sugar",-73788488);qea=new $CLJS.M(null,"lat-max","lat-max",841568226);TU=new $CLJS.w(null,"power","power",702679448,null);UU=new $CLJS.w("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);VU=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);
WU=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);XU=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);YU=new $CLJS.w(null,"median","median",-2084869638,null);ZU=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);$U=new $CLJS.w(null,"cum-sum","cum-sum",-589533420,null);
rea=new $CLJS.w("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);aV=new $CLJS.M(null,"y","y",-1757859776);bV=new $CLJS.w(null,"aggregation","aggregation",-1056959073,null);$CLJS.cV=new $CLJS.M(null,"binning","binning",1709835866);dV=new $CLJS.w(null,"TimezoneId","TimezoneId",-1406242397,null);eV=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);fV=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);
gV=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);hV=new $CLJS.M(null,"b","b",1482224470);iV=new $CLJS.M(null,"a","a",-2123407586);jV=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);kV=new $CLJS.w(null,"replace","replace",853943757,null);lV=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);sea=new $CLJS.w("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);
mV=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);nV=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);oV=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);pV=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);qV=new $CLJS.w(null,"segment","segment",675610331,null);tea=new $CLJS.w("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);
rV=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);sV=new $CLJS.w("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);tV=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);uV=new $CLJS.M("string","starts-with","string/starts-with",1266861170);uea=new $CLJS.w(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.vV=new $CLJS.M(null,"order-by","order-by",1527318070);
wV=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);vea=new $CLJS.w("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);wea=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.xV=new $CLJS.M(null,"condition","condition",1668437652);yV=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);xea=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);
zV=new $CLJS.w("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);yea=new $CLJS.M(null,"card-name","card-name",-2035606807);AV=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);zea=new $CLJS.w("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Aea=new $CLJS.w("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);BV=new $CLJS.w(null,"log","log",45015523,null);
Bea=new $CLJS.w("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);CV=new $CLJS.w(null,"time","time",-1268547887,null);$CLJS.DV=new $CLJS.M(null,"database_type","database_type",-54700895);EV=new $CLJS.w(null,"EqualityComparable","EqualityComparable",-2076258858,null);FV=new $CLJS.w(null,"get-minute","get-minute",-1186349426,null);$CLJS.GV=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.HV=new $CLJS.M(null,"expression-name","expression-name",-1798450709);
$CLJS.IV=new $CLJS.M("date","all-options","date/all-options",549325958);Cea=new $CLJS.w("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);JV=new $CLJS.M("location","state","location/state",-114378652);KV=new $CLJS.w(null,"datetime-subtract","datetime-subtract",1915839224,null);Dea=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);Eea=new $CLJS.M(null,"lon-field","lon-field",517872067);
LV=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);MV=new $CLJS.w(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);NV=new $CLJS.M(null,"numeric","numeric",-1495594714);OV=new $CLJS.w(null,"variable","variable",1359185035,null);PV=new $CLJS.w(null,"lower","lower",-1534114948,null);QV=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);
RV=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.SV=new $CLJS.M(null,"limit","limit",-1355822363);TV=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);UV=new $CLJS.w(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);Fea=new $CLJS.M(null,"pulse","pulse",-244494476);var VV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.sI],null),WV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AI],null),XV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pI],null),YV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kI],null),ZV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wI],null),$V=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UO],null),Gea=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QO],null),Hea=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OO],null),aW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.DM],null),bW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.HM],null),cW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.zM],null),dW=new $CLJS.dh(null,new $CLJS.k(null,12,[$CLJS.ti,null,$CLJS.Gv,null,$CLJS.kj,null,$CLJS.gi,null,$CLJS.al,null,$CLJS.tj,null,$CLJS.dl,null,$CLJS.cl,null,$CLJS.ni,null,$CLJS.Bj,null,$CLJS.ui,null,$CLJS.$k,null],null),null),eW=new $CLJS.dh(null,new $CLJS.k(null,7,[$CLJS.Fv,null,$CLJS.cL,null,$CLJS.gi,null,$CLJS.Ai,null,$CLJS.fL,null,$CLJS.bl,null,$CLJS.Ev,null],null),null),
fW=$CLJS.Hv.g(dW,eW),Iea=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"date bucketing unit"],null)],null),dW),Jea=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"time bucketing unit"],null)],null),eW),gW=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"datetime bucketing unit"],null)],null),fW),hW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mN],null),Kea=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,
1,[$CLJS.Au,"temporal extract unit"],null),$CLJS.gL,$CLJS.$k,$CLJS.dl,$CLJS.YM,$CLJS.sN,$CLJS.tN,$CLJS.cl,$CLJS.al,$CLJS.Ai,$CLJS.bl,$CLJS.dL],null),Lea=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"datetime-diff unit"],null),$CLJS.cL,$CLJS.Ev,$CLJS.Fv,$CLJS.Gv,$CLJS.kj,$CLJS.tj,$CLJS.ti,$CLJS.ni],null),iW=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"temporal-extract week extraction mode"],null),$CLJS.SM,$CLJS.aN,$CLJS.fN],null),jW=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"relative-datetime unit"],null),$CLJS.gi,$CLJS.Ev,$CLJS.Fv,$CLJS.Gv,$CLJS.kj,$CLJS.tj,$CLJS.ti,$CLJS.ni],null),qW,Nea,yW,zW,AW,BW,CW,DW,EW,pY,Pea,qY,Qea,Rea,rY,Sea,Tea,Uea;$CLJS.X(uQ,CP($CLJS.bN,$CLJS.G(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.Lx],null),$CLJS.yt],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,jW],null)])));var kW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uQ],null);
$CLJS.X(dT,CP($CLJS.$M,$CLJS.G(["n",$CLJS.yt,"unit",jW])));var Mea=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dT],null);
$CLJS.X(QQ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Au,"valid :absolute-datetime clause",$CLJS.Oi,function(a){if($CLJS.Ta(BP($CLJS.WM,a)))a=PQ;else{a=$CLJS.jd(a);var b=$CLJS.xP(aW);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.hT:$CLJS.cN}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[PQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"not an :absolute-datetime clause"],null),$CLJS.Pe(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hT,CP($CLJS.WM,
$CLJS.G(["date",aW,"unit",Iea]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cN,CP($CLJS.WM,$CLJS.G(["datetime",bW,"unit",gW]))],null)],null));var lW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QQ],null);$CLJS.X(fR,CP($CLJS.pC,$CLJS.G(["time",cW,"unit",Jea])));var mW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fR],null),nW=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.lt,new $CLJS.k(null,1,[$CLJS.Au,"date or datetime literal"],null),lW,bW,aW],null);
$CLJS.X(OP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lt,new $CLJS.k(null,1,[$CLJS.Au,"time literal"],null),mW,cW],null));$CLJS.X(LV,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lt,new $CLJS.k(null,1,[$CLJS.Au,"temporal literal"],null),nW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OP],null)],null));var oW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LV],null);
$CLJS.X(gea,DP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof lW?new $CLJS.Gc(function(){return lW},$CLJS.qd(Hca,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,bU,$CLJS.jj,$CLJS.mk,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),KT,"metabase/mbql/schema.cljc",69,$CLJS.WM,1,!0,168,168,$CLJS.Kc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(lW)?lW.H:null])):null));return $CLJS.n(a)?a:KT}(),lW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof kW?new $CLJS.Gc(function(){return kW},$CLJS.qd(Cea,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,
$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bT,"metabase/mbql/schema.cljc",29,$CLJS.bN,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.bN,$CLJS.bz,$CLJS.V($CLJS.Kj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.Lx],null),$CLJS.yt],null)),$CLJS.wi,$CLJS.V($CLJS.Kj,$CLJS.V(QU,zS))],null),$CLJS.Kc,"Schema for a valid relative-datetime clause.",
$CLJS.n(kW)?kW.H:null])):null));return $CLJS.n(a)?a:bT}(),kW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof mW?new $CLJS.Gc(function(){return mW},$CLJS.qd(Eda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,bU,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CV,"metabase/mbql/schema.cljc",27,$CLJS.pC,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.pC,$CLJS.pC,$CLJS.V($CLJS.Kj,fea),$CLJS.wi,$CLJS.V($CLJS.Kj,Yca)],null),$CLJS.Kc,"Schema for a valid time clause.",$CLJS.n(mW)?mW.H:null])):null));return $CLJS.n(a)?a:CV}(),mW],null)])));
$CLJS.X(AV,CP($CLJS.zj,$CLJS.G(["value",$CLJS.Dt,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DV,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,VV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RG,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,WV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iS,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.kt,XV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wi,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,gW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.S,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,VV],null)],null)],null)],null)])));var pW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AV],null);$CLJS.X(hS,CP($CLJS.zE,$CLJS.G(["expression-name",VV,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,$CLJS.bk],null)])));
qW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hS],null);
Nea=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.Ge(b);b=$CLJS.I.g(c,$CLJS.CI);c=$CLJS.I.g(c,$CLJS.lI);return $CLJS.F.g(b,$CLJS.lI)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.Ge(b);b=$CLJS.I.g(c,$CLJS.CI);c=$CLJS.I.g(c,$CLJS.BI);return $CLJS.F.g(b,$CLJS.BI)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.bk,new $CLJS.k(null,1,[$CLJS.Au,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CI,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"binning strategy"],null),$CLJS.lI,$CLJS.BI,$CLJS.gi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lI,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),YV],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.BI,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,$CLJS.sl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"bin width must be \x3e\x3d 0."],null),$CLJS.Oe($CLJS.Nl)],null)],null)],null)],null)));$CLJS.rW=function rW(a){switch(arguments.length){case 1:return rW.h(arguments[0]);case 2:return rW.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.rW.h=function(a){var b=$CLJS.Ge(a);a=$CLJS.I.g(b,$CLJS.CE);b=$CLJS.I.g(b,$CLJS.nK);return $CLJS.rW.g(a,b)};$CLJS.rW.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.PF(a,$CLJS.vG)?dW:$CLJS.PF(a,$CLJS.TH)?eW:$CLJS.PF(a,$CLJS.dH)?fW:null:null;return $CLJS.n(a)?$CLJS.Id(a,b):!0};$CLJS.rW.A=2;
$CLJS.X(RU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bk,new $CLJS.k(null,1,[$CLJS.Au,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,WV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hR,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$V],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nK,new $CLJS.k(null,
1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,gW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$S,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,VV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cV,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,Nea],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.rW],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Oe($CLJS.CI)],null)],null));
$CLJS.X(rV,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.H(b,0,null);var c=$CLJS.H(b,1,null);b=$CLJS.H(b,2,null);b=$CLJS.Ge(b);b=$CLJS.I.g(b,$CLJS.CE);return"string"===typeof c?b:!0}],null)],null)}(CP($CLJS.hK,$CLJS.G(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,$V,VV],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RU],null)],null)]))));var sW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rV],null);
$CLJS.X(qT,DP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof qW?new $CLJS.Gc(function(){return qW},$CLJS.qd(rR,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FT,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aR,"metabase/mbql/schema.cljc",60,$CLJS.zE,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zE,$CLJS.HV,$CLJS.V($CLJS.Kj,wQ),$CLJS.cj,$CLJS.V($CLJS.Kj,$CLJS.V(QU,$CLJS.bk))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(qW)?qW.H:null])):null));return $CLJS.n(a)?a:aR}(),qW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof sW?new $CLJS.Gc(function(){return sW},
$CLJS.qd(JR,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Nr,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,$CLJS.yi,$CLJS.T,$CLJS.ek],["0.39.0",$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IQ,"metabase/mbql/schema.cljc",51,$CLJS.hK,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(sW)?sW.H:null])):null));return $CLJS.n(a)?a:IQ}(),sW],null)])));
$CLJS.tW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qT],null);$CLJS.X(IP,CP($CLJS.XJ,$CLJS.G(["aggregation-clause-index",$CLJS.yt,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,$CLJS.bk],null)])));var uW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IP],null);
$CLJS.X(cR,DP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof uW?new $CLJS.Gc(function(){return uW},$CLJS.qd(dea,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bV,"metabase/mbql/schema.cljc",23,$CLJS.XJ,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.XJ,Dea,$CLJS.V($CLJS.Kj,$CLJS.yt),$CLJS.cj,$CLJS.V($CLJS.Kj,$CLJS.V(QU,$CLJS.bk))],null),$CLJS.Kc,"Schema for a valid aggregation clause.",$CLJS.n(uW)?uW.H:null])):null));return $CLJS.n(a)?a:bV}(),uW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof qW?new $CLJS.Gc(function(){return qW},
$CLJS.qd(rR,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FT,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aR,"metabase/mbql/schema.cljc",60,$CLJS.zE,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zE,$CLJS.HV,$CLJS.V($CLJS.Kj,wQ),$CLJS.cj,
$CLJS.V($CLJS.Kj,$CLJS.V(QU,$CLJS.bk))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(qW)?qW.H:null])):null));return $CLJS.n(a)?a:aR}(),qW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof sW?new $CLJS.Gc(function(){return sW},$CLJS.qd(JR,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Nr,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,$CLJS.yi,
$CLJS.T,$CLJS.ek],["0.39.0",$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IQ,"metabase/mbql/schema.cljc",51,$CLJS.hK,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(sW)?sW.H:null])):null));return $CLJS.n(a)?a:IQ}(),sW],null)])));
var vW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cR],null),wW=new $CLJS.dh(null,new $CLJS.k(null,11,[$CLJS.Qu,null,$CLJS.BK,null,$CLJS.EJ,null,$CLJS.GK,null,$CLJS.JJ,null,$CLJS.kK,null,$CLJS.SJ,null,$CLJS.ev,null,$CLJS.zK,null,$CLJS.jK,null,$CLJS.HK,null],null),null),xW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oQ],null);
$CLJS.X(RP,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,1,[$CLJS.Oi,function(a){return"string"===typeof a?$CLJS.Tj:$CLJS.n(BP(wW,a))?kQ:$CLJS.n(BP($CLJS.zj,a))?$CLJS.zj:$CLJS.Vu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.Tj],null),new $CLJS.P(null,2,5,$CLJS.Q,[kQ,xW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,pW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vu,$CLJS.tW],null)],null));yW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RP],null);
zW=new $CLJS.dh(null,new $CLJS.k(null,26,[$CLJS.GJ,null,$CLJS.rN,null,$CLJS.Ps,null,$CLJS.nN,null,$CLJS.kN,null,$CLJS.WJ,null,$CLJS.qu,null,$CLJS.DJ,null,$CLJS.oN,null,$CLJS.hN,null,$CLJS.kK,null,$CLJS.DK,null,$CLJS.lN,null,$CLJS.mK,null,$CLJS.HJ,null,$CLJS.UM,null,$CLJS.Os,null,$CLJS.KJ,null,$CLJS.SJ,null,$CLJS.OM,null,$CLJS.KA,null,$CLJS.VM,null,$CLJS.gN,null,$CLJS.ZM,null,$CLJS.FK,null,$CLJS.fK,null],null),null);
AW=new $CLJS.dh(null,new $CLJS.k(null,9,[$CLJS.Ks,null,$CLJS.Es,null,$CLJS.lt,null,$CLJS.nt,null,$CLJS.Gs,null,$CLJS.AK,null,$CLJS.st,null,$CLJS.Ls,null,$CLJS.Is,null],null),null);BW=new $CLJS.dh(null,new $CLJS.k(null,17,[$CLJS.On,null,$CLJS.yK,null,$CLJS.qK,null,$CLJS.iK,null,$CLJS.CK,null,$CLJS.EK,null,$CLJS.OJ,null,$CLJS.UJ,null,$CLJS.WP,null,$CLJS.PJ,null,$CLJS.vK,null,$CLJS.oK,null,$CLJS.ck,null,$CLJS.TA,null,$CLJS.bK,null,$CLJS.cK,null,$CLJS.uK,null],null),null);
CW=new $CLJS.dh(null,new $CLJS.k(null,5,[$CLJS.dN,null,$CLJS.QM,null,$CLJS.pN,null,$CLJS.Os,null,$CLJS.jN,null],null),null);DW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IU],null);EW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dR],null);$CLJS.FW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hU],null);$CLJS.GW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PU],null);
$CLJS.X(WU,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Au,"numeric expression argument",$CLJS.Oi,function(a){return"number"===typeof a?$CLJS.vv:$CLJS.n(BP(zW,a))?KS:$CLJS.n(BP(BW,a))?$CLJS.XJ:$CLJS.n(BP($CLJS.zj,a))?$CLJS.zj:$CLJS.hK}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vv,$CLJS.sl],null),new $CLJS.P(null,2,5,$CLJS.Q,[KS,DW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XJ,$CLJS.GW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,pW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hK,
$CLJS.tW],null)],null));var HW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WU],null);
$CLJS.X(cU,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Au,"datetime expression argument",$CLJS.Oi,function(a){return $CLJS.n(BP(BW,a))?$CLJS.XJ:$CLJS.n(BP($CLJS.zj,a))?$CLJS.zj:$CLJS.n(BP(CW,a))?kR:$CLJS.Vu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XJ,$CLJS.GW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,pW],null),new $CLJS.P(null,2,5,$CLJS.Q,[kR,$CLJS.FW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vu,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,nW,$CLJS.tW],null)],null)],null));
var IW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cU],null);
$CLJS.X(GT,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Au,"expression argument",$CLJS.Oi,function(a){return"number"===typeof a?$CLJS.vv:$CLJS.Dd(a)?$CLJS.Ct:$CLJS.n(BP(AW,a))?VT:$CLJS.n(BP(zW,a))?KS:$CLJS.n(BP(CW,a))?kR:"string"===typeof a?$CLJS.Tj:$CLJS.n(BP(wW,a))?kQ:$CLJS.n(BP($CLJS.zj,a))?$CLJS.zj:$CLJS.Vu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vv,$CLJS.sl],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ct,$CLJS.Ct],null),new $CLJS.P(null,2,5,$CLJS.Q,[VT,EW],null),new $CLJS.P(null,
2,5,$CLJS.Q,[KS,DW],null),new $CLJS.P(null,2,5,$CLJS.Q,[kR,$CLJS.FW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.Tj],null),new $CLJS.P(null,2,5,$CLJS.Q,[kQ,xW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,pW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vu,$CLJS.tW],null)],null));var JW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GT],null);$CLJS.X(ET,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lt,new $CLJS.k(null,1,[$CLJS.Au,"numeric expression arg or interval"],null),Mea,HW],null));
var KW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ET],null);$CLJS.X(bQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Au,"int greater than zero or numeric expression",$CLJS.Oi,function(a){return"number"===typeof a?$CLJS.vv:$CLJS.Vu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vv,YV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vu,DW],null)],null));var Oea=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bQ],null);
$CLJS.X(lR,CP($CLJS.kK,$CLJS.G(["a",JW,"b",JW,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,JW],null)])));var LW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lR],null);$CLJS.X(NP,CP($CLJS.EJ,$CLJS.G(["s",yW,"start",Oea,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,HW],null)])));var MW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NP],null);$CLJS.X(WT,CP($CLJS.KA,$CLJS.G(["s",yW])));var NW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WT],null);$CLJS.X(qQ,CP($CLJS.zK,$CLJS.G(["s",yW])));
var OW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qQ],null);$CLJS.X(tQ,CP($CLJS.HK,$CLJS.G(["s",yW])));var PW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tQ],null);$CLJS.X(NQ,CP($CLJS.BK,$CLJS.G(["s",yW])));var QW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NQ],null);$CLJS.X(zR,CP($CLJS.Qu,$CLJS.G(["s",yW])));var RW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zR],null);$CLJS.X(gQ,CP($CLJS.ev,$CLJS.G(["s",yW])));var SW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gQ],null);
$CLJS.X(VU,CP($CLJS.GK,$CLJS.G(["s",yW,"match",$CLJS.Tj,"replacement",$CLJS.Tj])));var TW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VU],null);$CLJS.X(QV,CP($CLJS.JJ,$CLJS.G(["a",yW,"b",yW,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,yW],null)])));var UW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QV],null);$CLJS.X(rS,CP($CLJS.jK,$CLJS.G(["s",yW,"pattern",$CLJS.Tj])));var VW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rS],null);
$CLJS.X(pV,CP($CLJS.Os,$CLJS.G(["x",KW,"y",KW,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,KW],null)])));var WW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pV],null);$CLJS.X(nV,CP($CLJS.qu,$CLJS.G(["x",HW,"y",KW,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,KW],null)])));var XW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nV],null);$CLJS.X(mV,CP($CLJS.DK,$CLJS.G(["x",HW,"y",HW,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,HW],null)])));var YW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mV],null);
$CLJS.X(oV,CP($CLJS.Ps,$CLJS.G(["x",HW,"y",HW,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,HW],null)])));var ZW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oV],null);$CLJS.X(DT,CP($CLJS.fK,$CLJS.G(["x",HW])));var $W=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DT],null);$CLJS.X(xU,CP($CLJS.GJ,$CLJS.G(["x",HW])));var aX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xU],null);$CLJS.X(TV,CP($CLJS.mK,$CLJS.G(["x",HW])));var bX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TV],null);$CLJS.X(IS,CP($CLJS.KJ,$CLJS.G(["x",HW])));
var cX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IS],null);$CLJS.X(OS,CP($CLJS.HJ,$CLJS.G(["x",HW,"y",HW])));var dX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OS],null);$CLJS.X(kT,CP($CLJS.WJ,$CLJS.G(["x",HW])));var eX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kT],null);$CLJS.X(cQ,CP($CLJS.DJ,$CLJS.G(["x",HW])));var fX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cQ],null);$CLJS.X(JT,CP($CLJS.FK,$CLJS.G(["x",HW])));var gX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JT],null);
$CLJS.X(CQ,CP($CLJS.ZM,$CLJS.G(["datetime-x",IW,"datetime-y",IW,"unit",Lea])));var hX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CQ],null);$CLJS.X(VQ,CP($CLJS.nN,$CLJS.G(["datetime",IW,"unit",Kea,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,iW],null)])));var iX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VQ],null);$CLJS.X(QS,CP($CLJS.VM,$CLJS.G(["date",IW])));var jX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QS],null);$CLJS.X(rT,CP($CLJS.rN,$CLJS.G(["date",IW])));
var kX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rT],null);$CLJS.X(PP,CP($CLJS.OM,$CLJS.G(["date",IW])));var lX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PP],null);$CLJS.X(RV,CP($CLJS.hN,$CLJS.G(["date",IW,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,iW],null)])));var mX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RV],null);$CLJS.X(bS,CP($CLJS.gN,$CLJS.G(["date",IW])));var nX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bS],null);$CLJS.X(lT,CP($CLJS.lN,$CLJS.G(["date",IW])));
var oX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lT],null);$CLJS.X(yQ,CP($CLJS.oN,$CLJS.G(["datetime",IW])));var pX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yQ],null);$CLJS.X(gS,CP($CLJS.kN,$CLJS.G(["datetime",IW])));var qX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gS],null);$CLJS.X(rQ,CP($CLJS.UM,$CLJS.G(["datetime",IW])));var rX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rQ],null);$CLJS.X(KR,CP($CLJS.QM,$CLJS.G(["datetime",IW,"to",hW,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,hW],null)])));
var sX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KR],null),tX=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"datetime arithmetic unit"],null),$CLJS.fL,$CLJS.cL,$CLJS.Ev,$CLJS.Fv,$CLJS.Gv,$CLJS.kj,$CLJS.tj,$CLJS.ti,$CLJS.ni],null);$CLJS.X(MP,CP($CLJS.jN,$CLJS.G(["datetime",IW,"amount",HW,"unit",tX])));var uX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MP],null);
$CLJS.X(lQ,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return CP(arguments[0],1<b.length?new $CLJS.y(b.slice(1),0,null):null)}($CLJS.dN));var vX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lQ],null);$CLJS.X(LP,CP($CLJS.pN,$CLJS.G(["datetime",IW,"amount",HW,"unit",tX])));var wX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LP],null);
$CLJS.X(hU,DP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof WW?new $CLJS.Gc(function(){return WW},$CLJS.qd(xT,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FT,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),kS,"metabase/mbql/schema.cljc",51,$CLJS.Os,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Os,$CLJS.zI,$CLJS.V($CLJS.Kj,BT),aV,$CLJS.V($CLJS.Kj,BT),mR,$CLJS.V($CLJS.Kj,$CLJS.V($CLJS.Mi,BT))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(WW)?WW.H:null])):null));return $CLJS.n(a)?a:kS}(),WW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof uX?new $CLJS.Gc(function(){return uX},
$CLJS.qd(oda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[DR,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sT,"metabase/mbql/schema.cljc",67,$CLJS.jN,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jN,$CLJS.cN,$CLJS.V($CLJS.Kj,MV),tU,$CLJS.V($CLJS.Kj,
yU),$CLJS.wi,$CLJS.V($CLJS.Kj,GU)],null),$CLJS.Kc,"Schema for a valid datetime-add clause.",$CLJS.n(uX)?uX.H:null])):null));return $CLJS.n(a)?a:sT}(),uX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof wX?new $CLJS.Gc(function(){return wX},$CLJS.qd(ica,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,
$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[DR,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KV,"metabase/mbql/schema.cljc",72,$CLJS.pN,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.pN,$CLJS.cN,$CLJS.V($CLJS.Kj,MV),tU,$CLJS.V($CLJS.Kj,yU),$CLJS.wi,$CLJS.V($CLJS.Kj,GU)],null),$CLJS.Kc,"Schema for a valid datetime-subtract clause.",$CLJS.n(wX)?wX.H:null])):null));
return $CLJS.n(a)?a:KV}(),wX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof sX?new $CLJS.Gc(function(){return sX},$CLJS.qd(mea,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.QM,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jR,"metabase/mbql/schema.cljc",71,$CLJS.QM,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.QM,$CLJS.cN,$CLJS.V($CLJS.Kj,MV),gda,$CLJS.V($CLJS.Kj,dV),mca,$CLJS.V($CLJS.Kj,$CLJS.V(QU,dV))],null),$CLJS.Kc,"Schema for a valid convert-timezone clause.",$CLJS.n(sX)?sX.H:null])):null));return $CLJS.n(a)?a:jR}(),sX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&
"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof vX?new $CLJS.Gc(function(){return vX},$CLJS.qd(Pda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.dN,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RS,"metabase/mbql/schema.cljc",
45,$CLJS.dN,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dN],null),$CLJS.Kc,"Schema for a valid now clause.",$CLJS.n(vX)?vX.H:null])):null));return $CLJS.n(a)?a:RS}(),vX],null)])));$CLJS.xX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nT],null);$CLJS.X(SP,CP($CLJS.st,$CLJS.G(["first-clause",$CLJS.xX,"second-clause",$CLJS.xX,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,$CLJS.xX],null)])));var yX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SP],null);
$CLJS.X(GR,CP($CLJS.lt,$CLJS.G(["first-clause",$CLJS.xX,"second-clause",$CLJS.xX,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,$CLJS.xX],null)])));var zX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GR],null);$CLJS.X(nQ,CP($CLJS.nt,$CLJS.G(["clause",$CLJS.xX])));
var AX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nQ],null),BX=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,3,[$CLJS.Au,":field or :expression reference or :relative-datetime",$CLJS.Cu,$CLJS.Pe(":field or :expression reference or :relative-datetime"),$CLJS.Oi,function(a){return $CLJS.n(BP($CLJS.bN,a))?$CLJS.bN:$CLJS.Vu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bN,kW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vu,$CLJS.tW],null)],null);
$CLJS.X(eU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.Au,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.lt,$CLJS.Ct,$CLJS.sl,$CLJS.Tj,oW,BX,JW,pW],null)],null));var CX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eU],null);
$CLJS.X(wS,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Au,"order comparable",$CLJS.Oi,function(a){return $CLJS.n(BP($CLJS.zj,a))?$CLJS.zj:$CLJS.Vu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,pW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vu,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.lt,$CLJS.sl,$CLJS.Tj,oW,JW,BX],null)],null)],null));var DX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wS],null);
$CLJS.X(gV,CP($CLJS.Ls,$CLJS.G(["field",CX,"value-or-field",CX,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,CX],null)])));var EX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gV],null);$CLJS.X(jV,CP($CLJS.AK,$CLJS.G(["field",CX,"value-or-field",CX,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,CX],null)])));var FX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jV],null);$CLJS.X(fV,CP($CLJS.Is,$CLJS.G(["field",DX,"value-or-field",DX])));
var GX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fV],null);$CLJS.X(eV,CP($CLJS.Es,$CLJS.G(["field",DX,"value-or-field",DX])));var HX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eV],null);$CLJS.X(OQ,CP($CLJS.Ks,$CLJS.G(["field",DX,"value-or-field",DX])));var IX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OQ],null);$CLJS.X(UQ,CP($CLJS.Gs,$CLJS.G(["field",DX,"value-or-field",DX])));var JX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UQ],null);$CLJS.X(NT,CP($CLJS.MJ,$CLJS.G(["field",DX,"min",DX,"max",DX])));
var KX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NT],null);$CLJS.X(qU,CP($CLJS.RJ,$CLJS.G(["lat-field",DX,"lon-field",DX,"lat-max",DX,"lon-min",DX,"lat-min",DX,"lon-max",DX])));var LX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qU],null);$CLJS.X(yR,CP($CLJS.LJ,$CLJS.G(["field",$CLJS.tW])));var MX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yR],null);$CLJS.X(qS,CP($CLJS.VJ,$CLJS.G(["field",$CLJS.tW])));var NX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qS],null);$CLJS.X(RR,CP($CLJS.NJ,$CLJS.G(["field",$CLJS.tW])));
var OX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RR],null);$CLJS.X(VP,CP($CLJS.TJ,$CLJS.G(["field",$CLJS.tW])));var PX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VP],null),QX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pK,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Ct],null)],null);$CLJS.X(eS,CP($CLJS.ZJ,$CLJS.G(["field",yW,"string-or-field",yW,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,QX],null)])));var RX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eS],null);
$CLJS.X(ZS,CP($CLJS.sK,$CLJS.G(["field",yW,"string-or-field",yW,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,QX],null)])));var SX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZS],null);$CLJS.X(PR,CP($CLJS.gK,$CLJS.G(["field",yW,"string-or-field",yW,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,QX],null)])));var TX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PR],null);$CLJS.X(HT,CP($CLJS.$J,$CLJS.G(["field",yW,"string-or-field",yW,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,QX],null)])));
var UX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HT],null);$CLJS.X(dQ,CP($CLJS.tK,$CLJS.G(["field",$CLJS.tW,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,$CLJS.yt,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ht,$CLJS.Lx,$CLJS.dB,$CLJS.QJ],null)],null),"unit",jW,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xK,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Ct],null)],null)],null)])));var VX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dQ],null);
$CLJS.X(YT,CP($CLJS.IJ,$CLJS.G(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NO],null),VV],null)])));var WX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YT],null);
$CLJS.X(dR,DP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof yX?new $CLJS.Gc(function(){return yX},$CLJS.qd(UU,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),AS,"metabase/mbql/schema.cljc",15,$CLJS.st,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.st,nR,$CLJS.V($CLJS.Kj,FS),iT,$CLJS.V($CLJS.Kj,FS),xR,$CLJS.V($CLJS.Kj,$CLJS.V($CLJS.Mi,FS))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(yX)?yX.H:null])):null));return $CLJS.n(a)?a:AS}(),yX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof zX?new $CLJS.Gc(function(){return zX},
$CLJS.qd(mT,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pT,"metabase/mbql/schema.cljc",14,$CLJS.lt,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.lt,nR,$CLJS.V($CLJS.Kj,FS),iT,$CLJS.V($CLJS.Kj,FS),xR,$CLJS.V($CLJS.Kj,$CLJS.V($CLJS.Mi,FS))],null),$CLJS.Kc,
"Schema for a valid or clause.",$CLJS.n(zX)?zX.H:null])):null));return $CLJS.n(a)?a:pT}(),zX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof AX?new $CLJS.Gc(function(){return AX},$CLJS.qd(ZT,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),SS,"metabase/mbql/schema.cljc",15,$CLJS.nt,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nt,$CLJS.GV,$CLJS.V($CLJS.Kj,FS)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(AX)?AX.H:null])):null));return $CLJS.n(a)?a:SS}(),AX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof GX?new $CLJS.Gc(function(){return GX},$CLJS.qd(vT,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,
$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tS,"metabase/mbql/schema.cljc",13,$CLJS.Is,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Is,$CLJS.hK,$CLJS.V($CLJS.Kj,pR),EU,$CLJS.V($CLJS.Kj,pR)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",$CLJS.n(GX)?GX.H:null])):null));return $CLJS.n(a)?a:tS}(),GX],null),
new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof IX?new $CLJS.Gc(function(){return IX},$CLJS.qd(zV,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),sS,"metabase/mbql/schema.cljc",14,$CLJS.Ks,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ks,$CLJS.hK,$CLJS.V($CLJS.Kj,pR),EU,$CLJS.V($CLJS.Kj,pR)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(IX)?IX.H:null])):null));return $CLJS.n(a)?a:sS}(),IX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof HX?new $CLJS.Gc(function(){return HX},$CLJS.qd(tT,new $CLJS.k(null,
1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uS,"metabase/mbql/schema.cljc",13,$CLJS.Es,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Es,$CLJS.hK,$CLJS.V($CLJS.Kj,pR),EU,$CLJS.V($CLJS.Kj,pR)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(HX)?HX.H:null])):null));
return $CLJS.n(a)?a:uS}(),HX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof JX?new $CLJS.Gc(function(){return JX},$CLJS.qd(sV,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BS,"metabase/mbql/schema.cljc",14,$CLJS.Gs,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Gs,$CLJS.hK,$CLJS.V($CLJS.Kj,pR),EU,$CLJS.V($CLJS.Kj,pR)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(JX)?JX.H:null])):null));return $CLJS.n(a)?a:BS}(),JX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof EX?new $CLJS.Gc(function(){return EX},$CLJS.qd(uT,new $CLJS.k(null,
1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vS,"metabase/mbql/schema.cljc",13,$CLJS.Ls,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ls,$CLJS.hK,$CLJS.V($CLJS.Kj,EV),EU,$CLJS.V($CLJS.Kj,EV),fU,$CLJS.V($CLJS.Kj,$CLJS.V($CLJS.Mi,EV))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",
$CLJS.n(EX)?EX.H:null])):null));return $CLJS.n(a)?a:vS}(),EX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof FX?new $CLJS.Gc(function(){return FX},$CLJS.qd(DQ,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$Q,"metabase/mbql/schema.cljc",14,$CLJS.AK,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.AK,$CLJS.hK,$CLJS.V($CLJS.Kj,EV),EU,$CLJS.V($CLJS.Kj,EV),fU,$CLJS.V($CLJS.Kj,$CLJS.V($CLJS.Mi,EV))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(FX)?FX.H:null])):null));return $CLJS.n(a)?a:$Q}(),FX],null)])));
$CLJS.X(nT,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Au,"valid filter expression",$CLJS.Oi,function(a){return $CLJS.n(BP(CW,a))?$CLJS.cN:$CLJS.n(BP(zW,a))?NV:$CLJS.n(BP(wW,a))?$CLJS.Tj:$CLJS.n(BP(AW,a))?$CLJS.Ct:$CLJS.Vu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cN,$CLJS.FW],null),new $CLJS.P(null,2,5,$CLJS.Q,[NV,DW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,xW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ct,EW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vu,DP($CLJS.G([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof yX?new $CLJS.Gc(function(){return yX},$CLJS.qd(UU,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AS,"metabase/mbql/schema.cljc",
15,$CLJS.st,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.st,nR,$CLJS.V($CLJS.Kj,FS),iT,$CLJS.V($CLJS.Kj,FS),xR,$CLJS.V($CLJS.Kj,$CLJS.V($CLJS.Mi,FS))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(yX)?yX.H:null])):null));return $CLJS.n(a)?a:AS}(),yX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof zX?new $CLJS.Gc(function(){return zX},$CLJS.qd(mT,new $CLJS.k(null,1,[$CLJS.Vj,
!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pT,"metabase/mbql/schema.cljc",14,$CLJS.lt,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.lt,nR,$CLJS.V($CLJS.Kj,FS),iT,$CLJS.V($CLJS.Kj,FS),xR,$CLJS.V($CLJS.Kj,$CLJS.V($CLJS.Mi,FS))],null),$CLJS.Kc,"Schema for a valid or clause.",$CLJS.n(zX)?
zX.H:null])):null));return $CLJS.n(a)?a:pT}(),zX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof AX?new $CLJS.Gc(function(){return AX},$CLJS.qd(ZT,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),SS,"metabase/mbql/schema.cljc",15,$CLJS.nt,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nt,$CLJS.GV,$CLJS.V($CLJS.Kj,FS)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(AX)?AX.H:null])):null));return $CLJS.n(a)?a:SS}(),AX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof EX?new $CLJS.Gc(function(){return EX},$CLJS.qd(uT,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,
$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vS,"metabase/mbql/schema.cljc",13,$CLJS.Ls,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ls,$CLJS.hK,$CLJS.V($CLJS.Kj,EV),EU,$CLJS.V($CLJS.Kj,EV),fU,$CLJS.V($CLJS.Kj,$CLJS.V($CLJS.Mi,EV))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(EX)?EX.H:null])):null));
return $CLJS.n(a)?a:vS}(),EX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof FX?new $CLJS.Gc(function(){return FX},$CLJS.qd(DQ,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$Q,"metabase/mbql/schema.cljc",14,$CLJS.AK,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.AK,$CLJS.hK,$CLJS.V($CLJS.Kj,EV),EU,$CLJS.V($CLJS.Kj,EV),fU,$CLJS.V($CLJS.Kj,$CLJS.V($CLJS.Mi,EV))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(FX)?FX.H:null])):null));return $CLJS.n(a)?a:$Q}(),FX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof GX?new $CLJS.Gc(function(){return GX},
$CLJS.qd(vT,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tS,"metabase/mbql/schema.cljc",13,$CLJS.Is,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Is,$CLJS.hK,$CLJS.V($CLJS.Kj,pR),EU,$CLJS.V($CLJS.Kj,pR)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",
$CLJS.n(GX)?GX.H:null])):null));return $CLJS.n(a)?a:tS}(),GX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof HX?new $CLJS.Gc(function(){return HX},$CLJS.qd(tT,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),uS,"metabase/mbql/schema.cljc",13,$CLJS.Es,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Es,$CLJS.hK,$CLJS.V($CLJS.Kj,pR),EU,$CLJS.V($CLJS.Kj,pR)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(HX)?HX.H:null])):null));return $CLJS.n(a)?a:uS}(),HX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof IX?new $CLJS.Gc(function(){return IX},$CLJS.qd(zV,new $CLJS.k(null,
1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sS,"metabase/mbql/schema.cljc",14,$CLJS.Ks,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ks,$CLJS.hK,$CLJS.V($CLJS.Kj,pR),EU,$CLJS.V($CLJS.Kj,pR)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(IX)?IX.H:null])):
null));return $CLJS.n(a)?a:sS}(),IX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof JX?new $CLJS.Gc(function(){return JX},$CLJS.qd(sV,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BS,"metabase/mbql/schema.cljc",14,$CLJS.Gs,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Gs,$CLJS.hK,$CLJS.V($CLJS.Kj,pR),EU,$CLJS.V($CLJS.Kj,pR)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(JX)?JX.H:null])):null));return $CLJS.n(a)?a:BS}(),JX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof KX?new $CLJS.Gc(function(){return KX},$CLJS.qd(Gda,new $CLJS.k(null,
1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FQ,"metabase/mbql/schema.cljc",19,$CLJS.MJ,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.MJ,$CLJS.hK,$CLJS.V($CLJS.Kj,pR),$CLJS.On,$CLJS.V($CLJS.Kj,pR),$CLJS.ck,$CLJS.V($CLJS.Kj,pR)],null),$CLJS.Kc,"Schema for a valid between clause.",
$CLJS.n(KX)?KX.H:null])):null));return $CLJS.n(a)?a:FQ}(),KX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof RX?new $CLJS.Gc(function(){return RX},$CLJS.qd(Ida,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),JU,"metabase/mbql/schema.cljc",23,$CLJS.ZJ,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ZJ,$CLJS.hK,$CLJS.V($CLJS.Kj,DU),ZQ,$CLJS.V($CLJS.Kj,DU),$CLJS.cj,$CLJS.V($CLJS.Kj,$CLJS.V(QU,XT))],null),$CLJS.Kc,"Schema for a valid starts-with clause.",$CLJS.n(RX)?RX.H:null])):null));return $CLJS.n(a)?a:JU}(),RX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof SX?new $CLJS.Gc(function(){return SX},
$CLJS.qd(Kca,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eR,"metabase/mbql/schema.cljc",21,$CLJS.sK,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.sK,$CLJS.hK,$CLJS.V($CLJS.Kj,DU),ZQ,$CLJS.V($CLJS.Kj,DU),$CLJS.cj,$CLJS.V($CLJS.Kj,$CLJS.V(QU,XT))],null),
$CLJS.Kc,"Schema for a valid ends-with clause.",$CLJS.n(SX)?SX.H:null])):null));return $CLJS.n(a)?a:eR}(),SX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof TX?new $CLJS.Gc(function(){return TX},$CLJS.qd(Wca,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qR,"metabase/mbql/schema.cljc",20,$CLJS.gK,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gK,$CLJS.hK,$CLJS.V($CLJS.Kj,DU),ZQ,$CLJS.V($CLJS.Kj,DU),$CLJS.cj,$CLJS.V($CLJS.Kj,$CLJS.V(QU,XT))],null),$CLJS.Kc,"Schema for a valid contains clause.",$CLJS.n(TX)?TX.H:null])):null));return $CLJS.n(a)?a:qR}(),TX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==
typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof UX?new $CLJS.Gc(function(){return UX},$CLJS.qd(Aea,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,SU,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TP,"metabase/mbql/schema.cljc",36,$CLJS.$J,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.$J,$CLJS.hK,$CLJS.V($CLJS.Kj,DU),ZQ,$CLJS.V($CLJS.Kj,DU),$CLJS.cj,$CLJS.V($CLJS.Kj,$CLJS.V(QU,XT))],null),$CLJS.Kc,"Schema for a valid does-not-contain clause.",$CLJS.n(UX)?UX.H:null])):null));return $CLJS.n(a)?a:TP}(),UX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof LX?new $CLJS.Gc(function(){return LX},$CLJS.qd(jea,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),
$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,SU,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HU,"metabase/mbql/schema.cljc",26,$CLJS.RJ,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.RJ,sca,$CLJS.V($CLJS.Kj,pR),Eea,$CLJS.V($CLJS.Kj,pR),qea,$CLJS.V($CLJS.Kj,pR),vca,$CLJS.V($CLJS.Kj,pR),kea,$CLJS.V($CLJS.Kj,pR),rca,$CLJS.V($CLJS.Kj,
pR)],null),$CLJS.Kc,"Schema for a valid inside clause.",$CLJS.n(LX)?LX.H:null])):null));return $CLJS.n(a)?a:HU}(),LX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof OX?new $CLJS.Gc(function(){return OX},$CLJS.qd(Xca,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,SU,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aT,"metabase/mbql/schema.cljc",28,$CLJS.NJ,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NJ,$CLJS.hK,$CLJS.V($CLJS.Kj,iR)],null),$CLJS.Kc,"Schema for a valid is-empty clause.",$CLJS.n(OX)?OX.H:null])):null));return $CLJS.n(a)?a:aT}(),OX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&
"undefined"!==typeof PX?new $CLJS.Gc(function(){return PX},$CLJS.qd(Vda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,SU,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OT,"metabase/mbql/schema.cljc",29,$CLJS.TJ,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TJ,$CLJS.hK,$CLJS.V($CLJS.Kj,iR)],null),
$CLJS.Kc,"Schema for a valid not-empty clause.",$CLJS.n(PX)?PX.H:null])):null));return $CLJS.n(a)?a:OT}(),PX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof MX?new $CLJS.Gc(function(){return MX},$CLJS.qd(vea,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,SU,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wR,"metabase/mbql/schema.cljc",27,$CLJS.LJ,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LJ,$CLJS.hK,$CLJS.V($CLJS.Kj,iR)],null),$CLJS.Kc,"Schema for a valid is-null clause.",$CLJS.n(MX)?MX.H:null])):null));return $CLJS.n(a)?a:wR}(),MX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&
"undefined"!==typeof NX?new $CLJS.Gc(function(){return NX},$CLJS.qd(ida,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,SU,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LQ,"metabase/mbql/schema.cljc",28,$CLJS.VJ,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VJ,$CLJS.hK,$CLJS.V($CLJS.Kj,iR)],null),
$CLJS.Kc,"Schema for a valid not-null clause.",$CLJS.n(NX)?NX.H:null])):null));return $CLJS.n(a)?a:LQ}(),NX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof VX?new $CLJS.Gc(function(){return VX},$CLJS.qd(jca,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,SU,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nU,"metabase/mbql/schema.cljc",33,$CLJS.tK,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.tK,$CLJS.hK,$CLJS.V($CLJS.Kj,iR),$CLJS.bz,$CLJS.V($CLJS.Kj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,$CLJS.yt,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ht,$CLJS.Lx,$CLJS.dB,$CLJS.QJ],null)],null)),$CLJS.wi,$CLJS.V($CLJS.Kj,zS),$CLJS.cj,$CLJS.V($CLJS.Kj,$CLJS.V(QU,Lda))],null),$CLJS.Kc,"Schema for a valid time-interval clause.",
$CLJS.n(VX)?VX.H:null])):null));return $CLJS.n(a)?a:nU}(),VX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof WX?new $CLJS.Gc(function(){return WX},$CLJS.qd(qda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,SU,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),qV,"metabase/mbql/schema.cljc",27,$CLJS.IJ,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IJ,zca,$CLJS.V($CLJS.Kj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,aea,wQ],null))],null),$CLJS.Kc,"Schema for a valid segment clause.",$CLJS.n(WX)?WX.H:null])):null));return $CLJS.n(a)?a:qV}(),WX],null)]))],null)],null));
$CLJS.X($T,CP($CLJS.SJ,$CLJS.G(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ot,new $CLJS.k(null,1,[$CLJS.Au,":case subclause"],null),$CLJS.xX,JW],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bk,new $CLJS.k(null,1,[$CLJS.Au,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gi,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),JW],null)],null)],null)])));
var XX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$T],null);
$CLJS.X(IU,DP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof WW?new $CLJS.Gc(function(){return WW},$CLJS.qd(xT,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FT,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),kS,"metabase/mbql/schema.cljc",51,$CLJS.Os,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Os,$CLJS.zI,$CLJS.V($CLJS.Kj,BT),aV,$CLJS.V($CLJS.Kj,BT),mR,$CLJS.V($CLJS.Kj,$CLJS.V($CLJS.Mi,BT))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(WW)?WW.H:null])):null));return $CLJS.n(a)?a:kS}(),WW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof XW?new $CLJS.Gc(function(){return XW},
$CLJS.qd(Cda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FT,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lS,"metabase/mbql/schema.cljc",51,$CLJS.qu,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.qu,$CLJS.zI,$CLJS.V($CLJS.Kj,yU),
aV,$CLJS.V($CLJS.Kj,BT),mR,$CLJS.V($CLJS.Kj,$CLJS.V($CLJS.Mi,BT))],null),$CLJS.Kc,"Schema for a valid - clause.",$CLJS.n(XW)?XW.H:null])):null));return $CLJS.n(a)?a:lS}(),XW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP?new $CLJS.Gc(function(){return YW},$CLJS.qd(Bda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,PS,$CLJS.Si,$CLJS.jj,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,
new $CLJS.k(null,1,[$CLJS.FT,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Wu,"metabase/mbql/schema.cljc",$CLJS.DK,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.DK,$CLJS.zI,$CLJS.V($CLJS.Kj,yU),aV,$CLJS.V($CLJS.Kj,yU),mR,$CLJS.V($CLJS.Kj,$CLJS.V($CLJS.Mi,yU))],null),$CLJS.Kc,"Schema for a valid / clause.",$CLJS.n(YW)?YW.H:null])):null));return $CLJS.n(a)?a:$CLJS.Wu}(),YW],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof ZW?new $CLJS.Gc(function(){return ZW},$CLJS.qd(Dda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FT,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),jS,"metabase/mbql/schema.cljc",51,$CLJS.Ps,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ps,$CLJS.zI,$CLJS.V($CLJS.Kj,yU),aV,$CLJS.V($CLJS.Kj,yU),mR,$CLJS.V($CLJS.Kj,$CLJS.V($CLJS.Mi,yU))],null),$CLJS.Kc,"Schema for a valid * clause.",$CLJS.n(ZW)?ZW.H:null])):null));return $CLJS.n(a)?a:jS}(),ZW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof LW?new $CLJS.Gc(function(){return LW},
$CLJS.qd(jU,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FT,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AU,"metabase/mbql/schema.cljc",58,$CLJS.kK,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kK,iV,$CLJS.V($CLJS.Kj,YP),hV,$CLJS.V($CLJS.Kj,
YP),mR,$CLJS.V($CLJS.Kj,$CLJS.V($CLJS.Mi,YP))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(LW)?LW.H:null])):null));return $CLJS.n(a)?a:AU}(),LW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof NW?new $CLJS.Gc(function(){return NW},$CLJS.qd(Uda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,
$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FT,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RT,"metabase/mbql/schema.cljc",56,$CLJS.KA,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KA,$CLJS.RB,$CLJS.V($CLJS.Kj,DU)],null),$CLJS.Kc,"Schema for a valid length clause.",$CLJS.n(NW)?NW.H:null])):null));return $CLJS.n(a)?a:RT}(),NW],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof $W?new $CLJS.Gc(function(){return $W},$CLJS.qd(Lca,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FT,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),MS,"metabase/mbql/schema.cljc",55,$CLJS.fK,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fK,$CLJS.zI,$CLJS.V($CLJS.Kj,yU)],null),$CLJS.Kc,"Schema for a valid floor clause.",$CLJS.n($W)?$W.H:null])):null));return $CLJS.n(a)?a:MS}(),$W],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof aX?new $CLJS.Gc(function(){return aX},$CLJS.qd(Fca,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),
$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FT,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vQ,"metabase/mbql/schema.cljc",54,$CLJS.GJ,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GJ,$CLJS.zI,$CLJS.V($CLJS.Kj,yU)],null),$CLJS.Kc,"Schema for a valid ceil clause.",$CLJS.n(aX)?
aX.H:null])):null));return $CLJS.n(a)?a:vQ}(),aX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof bX?new $CLJS.Gc(function(){return bX},$CLJS.qd(Tca,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FT,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CS,"metabase/mbql/schema.cljc",55,$CLJS.mK,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mK,$CLJS.zI,$CLJS.V($CLJS.Kj,yU)],null),$CLJS.Kc,"Schema for a valid round clause.",$CLJS.n(bX)?bX.H:null])):null));return $CLJS.n(a)?a:CS}(),bX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&
"undefined"!==typeof cX?new $CLJS.Gc(function(){return cX},$CLJS.qd(Bea,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FT,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eT,"metabase/mbql/schema.cljc",53,$CLJS.KJ,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.KJ,$CLJS.zI,$CLJS.V($CLJS.Kj,yU)],null),$CLJS.Kc,"Schema for a valid abs clause.",$CLJS.n(cX)?cX.H:null])):null));return $CLJS.n(a)?a:eT}(),cX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof dX?new $CLJS.Gc(function(){return dX},$CLJS.qd(oea,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,
GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[wV,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TU,"metabase/mbql/schema.cljc",69,$CLJS.HJ,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.HJ,$CLJS.zI,$CLJS.V($CLJS.Kj,yU),aV,$CLJS.V($CLJS.Kj,yU)],null),$CLJS.Kc,"Schema for a valid power clause.",$CLJS.n(dX)?dX.H:null])):null));return $CLJS.n(a)?a:TU}(),dX],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof eX?new $CLJS.Gc(function(){return eX},$CLJS.qd(lda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[wV,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),fS,"metabase/mbql/schema.cljc",68,$CLJS.WJ,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WJ,$CLJS.zI,$CLJS.V($CLJS.Kj,yU)],null),$CLJS.Kc,"Schema for a valid sqrt clause.",$CLJS.n(eX)?eX.H:null])):null));return $CLJS.n(a)?a:fS}(),eX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof fX?new $CLJS.Gc(function(){return fX},$CLJS.qd(pea,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),
$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[wV,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ES,"metabase/mbql/schema.cljc",67,$CLJS.DJ,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DJ,$CLJS.zI,$CLJS.V($CLJS.Kj,yU)],null),$CLJS.Kc,"Schema for a valid exp clause.",$CLJS.n(fX)?
fX.H:null])):null));return $CLJS.n(a)?a:ES}(),fX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof gX?new $CLJS.Gc(function(){return gX},$CLJS.qd(yca,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[wV,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BV,"metabase/mbql/schema.cljc",67,$CLJS.FK,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FK,$CLJS.zI,$CLJS.V($CLJS.Kj,yU)],null),$CLJS.Kc,"Schema for a valid log clause.",$CLJS.n(gX)?gX.H:null])):null));return $CLJS.n(a)?a:BV}(),gX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&
"undefined"!==typeof XX?new $CLJS.Gc(function(){return XX},$CLJS.qd(iQ,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FJ,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WS,"metabase/mbql/schema.cljc",61,$CLJS.SJ,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.SJ,$CLJS.ez,$CLJS.V($CLJS.Kj,CR),$CLJS.cj,$CLJS.V($CLJS.Kj,$CLJS.V(QU,oT))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(XX)?XX.H:null])):null));return $CLJS.n(a)?a:WS}(),XX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof hX?new $CLJS.Gc(function(){return hX},$CLJS.qd(Bca,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,
$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.ZM,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fT,"metabase/mbql/schema.cljc",65,$CLJS.ZM,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ZM,Nca,$CLJS.V($CLJS.Kj,MV),Oca,$CLJS.V($CLJS.Kj,MV),$CLJS.wi,$CLJS.V($CLJS.Kj,tda)],null),$CLJS.Kc,"Schema for a valid datetime-diff clause.",
$CLJS.n(hX)?hX.H:null])):null));return $CLJS.n(a)?a:fT}(),hX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof iX?new $CLJS.Gc(function(){return iX},$CLJS.qd(Yda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.nN,"null"],null),null),$CLJS.qd(wU,
new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yT,"metabase/mbql/schema.cljc",71,$CLJS.nN,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.nN,$CLJS.cN,$CLJS.V($CLJS.Kj,MV),$CLJS.wi,$CLJS.V($CLJS.Kj,uea),$CLJS.Ci,$CLJS.V($CLJS.Kj,$CLJS.V(QU,UV))],null),$CLJS.Kc,"Schema for a valid temporal-extract clause.",$CLJS.n(iX)?iX.H:null])):null));return $CLJS.n(a)?a:yT}(),iX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof jX?new $CLJS.Gc(function(){return jX},$CLJS.qd(eda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,SU,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.nN,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),TQ,"metabase/mbql/schema.cljc",71,$CLJS.VM,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VM,$CLJS.hT,$CLJS.V($CLJS.Kj,MV)],null),$CLJS.Kc,"Schema for a valid get-year clause.",$CLJS.n(jX)?jX.H:null])):null));return $CLJS.n(a)?a:TQ}(),jX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof kX?new $CLJS.Gc(function(){return kX},$CLJS.qd(Uca,new $CLJS.k(null,1,[$CLJS.Vj,!0],
null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,SU,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.nN,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wT,"metabase/mbql/schema.cljc",74,$CLJS.rN,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rN,$CLJS.hT,$CLJS.V($CLJS.Kj,MV)],null),$CLJS.Kc,"Schema for a valid get-quarter clause.",
$CLJS.n(kX)?kX.H:null])):null));return $CLJS.n(a)?a:wT}(),kX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof lX?new $CLJS.Gc(function(){return lX},$CLJS.qd(Ada,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,SU,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.nN,"null"],null),null),
$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TT,"metabase/mbql/schema.cljc",72,$CLJS.OM,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OM,$CLJS.hT,$CLJS.V($CLJS.Kj,MV)],null),$CLJS.Kc,"Schema for a valid get-month clause.",$CLJS.n(lX)?lX.H:null])):null));return $CLJS.n(a)?a:TT}(),lX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==
typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof mX?new $CLJS.Gc(function(){return mX},$CLJS.qd(tea,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,SU,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.nN,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ST,"metabase/mbql/schema.cljc",
71,$CLJS.hN,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hN,$CLJS.hT,$CLJS.V($CLJS.Kj,MV),$CLJS.Ci,$CLJS.V($CLJS.Kj,$CLJS.V(QU,UV))],null),$CLJS.Kc,"Schema for a valid get-week clause.",$CLJS.n(mX)?mX.H:null])):null));return $CLJS.n(a)?a:ST}(),mX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof nX?new $CLJS.Gc(function(){return nX},$CLJS.qd(pca,new $CLJS.k(null,1,[$CLJS.Vj,
!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,SU,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.nN,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LT,"metabase/mbql/schema.cljc",70,$CLJS.gN,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gN,$CLJS.hT,$CLJS.V($CLJS.Kj,MV)],null),$CLJS.Kc,"Schema for a valid get-day clause.",
$CLJS.n(nX)?nX.H:null])):null));return $CLJS.n(a)?a:LT}(),nX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof oX?new $CLJS.Gc(function(){return oX},$CLJS.qd(uda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,SU,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.nN,"null"],null),null),
$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CT,"metabase/mbql/schema.cljc",78,$CLJS.lN,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lN,$CLJS.hT,$CLJS.V($CLJS.Kj,MV)],null),$CLJS.Kc,"Schema for a valid get-day-of-week clause.",$CLJS.n(oX)?oX.H:null])):null));return $CLJS.n(a)?a:CT}(),oX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==
typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof pX?new $CLJS.Gc(function(){return pX},$CLJS.qd(sda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,SU,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.nN,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZP,"metabase/mbql/schema.cljc",
71,$CLJS.oN,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oN,$CLJS.cN,$CLJS.V($CLJS.Kj,MV)],null),$CLJS.Kc,"Schema for a valid get-hour clause.",$CLJS.n(pX)?pX.H:null])):null));return $CLJS.n(a)?a:ZP}(),pX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof qX?new $CLJS.Gc(function(){return qX},$CLJS.qd(bea,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,
$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,SU,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.nN,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FV,"metabase/mbql/schema.cljc",73,$CLJS.kN,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kN,$CLJS.cN,$CLJS.V($CLJS.Kj,MV)],null),$CLJS.Kc,"Schema for a valid get-minute clause.",$CLJS.n(qX)?qX.H:null])):null));
return $CLJS.n(a)?a:FV}(),qX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof rX?new $CLJS.Gc(function(){return rX},$CLJS.qd(pda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,SU,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.nN,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YS,"metabase/mbql/schema.cljc",73,$CLJS.UM,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UM,$CLJS.cN,$CLJS.V($CLJS.Kj,MV)],null),$CLJS.Kc,"Schema for a valid get-second clause.",$CLJS.n(rX)?rX.H:null])):null));return $CLJS.n(a)?a:YS}(),rX],null)])));
$CLJS.X(oQ,DP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof MW?new $CLJS.Gc(function(){return MW},$CLJS.qd(Sda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FT,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aU,"metabase/mbql/schema.cljc",59,$CLJS.EJ,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.EJ,$CLJS.RB,$CLJS.V($CLJS.Kj,DU),$CLJS.qx,$CLJS.V($CLJS.Kj,Qca),$CLJS.KA,$CLJS.V($CLJS.Kj,$CLJS.V(QU,yU))],null),$CLJS.Kc,"Schema for a valid substring clause.",$CLJS.n(MW)?MW.H:null])):null));return $CLJS.n(a)?a:aU}(),MW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof OW?new $CLJS.Gc(function(){return OW},
$CLJS.qd(rea,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FT,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zU,"metabase/mbql/schema.cljc",54,$CLJS.zK,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zK,$CLJS.RB,$CLJS.V($CLJS.Kj,DU)],
null),$CLJS.Kc,"Schema for a valid trim clause.",$CLJS.n(OW)?OW.H:null])):null));return $CLJS.n(a)?a:zU}(),OW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof QW?new $CLJS.Gc(function(){return QW},$CLJS.qd(vda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,
1,[$CLJS.FT,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gU,"metabase/mbql/schema.cljc",55,$CLJS.BK,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BK,$CLJS.RB,$CLJS.V($CLJS.Kj,DU)],null),$CLJS.Kc,"Schema for a valid ltrim clause.",$CLJS.n(QW)?QW.H:null])):null));return $CLJS.n(a)?a:gU}(),QW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&
"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof PW?new $CLJS.Gc(function(){return PW},$CLJS.qd(Tda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FT,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jT,"metabase/mbql/schema.cljc",
55,$CLJS.HK,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HK,$CLJS.RB,$CLJS.V($CLJS.Kj,DU)],null),$CLJS.Kc,"Schema for a valid rtrim clause.",$CLJS.n(PW)?PW.H:null])):null));return $CLJS.n(a)?a:jT}(),PW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof TW?new $CLJS.Gc(function(){return TW},$CLJS.qd(kca,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,
$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FT,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kV,"metabase/mbql/schema.cljc",57,$CLJS.GK,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.GK,$CLJS.RB,$CLJS.V($CLJS.Kj,DU),wca,$CLJS.V($CLJS.Kj,$CLJS.Tj),$CLJS.Uu,$CLJS.V($CLJS.Kj,$CLJS.Tj)],null),$CLJS.Kc,"Schema for a valid replace clause.",
$CLJS.n(TW)?TW.H:null])):null));return $CLJS.n(a)?a:kV}(),TW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof SW?new $CLJS.Gc(function(){return SW},$CLJS.qd(Wda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FT,"null"],null),null),$CLJS.qd(wU,
new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PV,"metabase/mbql/schema.cljc",55,$CLJS.ev,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ev,$CLJS.RB,$CLJS.V($CLJS.Kj,DU)],null),$CLJS.Kc,"Schema for a valid lower clause.",$CLJS.n(SW)?SW.H:null])):null));return $CLJS.n(a)?a:PV}(),SW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==
typeof HP&&"undefined"!==typeof RW?new $CLJS.Gc(function(){return RW},$CLJS.qd(sea,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FT,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NU,"metabase/mbql/schema.cljc",55,$CLJS.Qu,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qu,$CLJS.RB,$CLJS.V($CLJS.Kj,DU)],null),$CLJS.Kc,"Schema for a valid upper clause.",$CLJS.n(RW)?RW.H:null])):null));return $CLJS.n(a)?a:NU}(),RW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof UW?new $CLJS.Gc(function(){return UW},$CLJS.qd(Kda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,
$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FT,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WR,"metabase/mbql/schema.cljc",56,$CLJS.JJ,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.JJ,iV,$CLJS.V($CLJS.Kj,DU),hV,$CLJS.V($CLJS.Kj,DU),mR,$CLJS.V($CLJS.Kj,$CLJS.V($CLJS.Mi,DU))],null),$CLJS.Kc,"Schema for a valid concat clause.",$CLJS.n(UW)?
UW.H:null])):null));return $CLJS.n(a)?a:WR}(),UW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof VW?new $CLJS.Gc(function(){return VW},$CLJS.qd(Rda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.FT,"null",$CLJS.$u,"null"],null),null),
$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RQ,"metabase/mbql/schema.cljc",74,$CLJS.jK,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.jK,$CLJS.RB,$CLJS.V($CLJS.Kj,DU),$CLJS.Pu,$CLJS.V($CLJS.Kj,$CLJS.Tj)],null),$CLJS.Kc,"Schema for a valid regex-match-first clause.",$CLJS.n(VW)?VW.H:null])):null));return $CLJS.n(a)?a:RQ}(),VW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==
typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof LW?new $CLJS.Gc(function(){return LW},$CLJS.qd(jU,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FT,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
AU,"metabase/mbql/schema.cljc",58,$CLJS.kK,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kK,iV,$CLJS.V($CLJS.Kj,YP),hV,$CLJS.V($CLJS.Kj,YP),mR,$CLJS.V($CLJS.Kj,$CLJS.V($CLJS.Mi,YP))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(LW)?LW.H:null])):null));return $CLJS.n(a)?a:AU}(),LW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof XX?new $CLJS.Gc(function(){return XX},
$CLJS.qd(iQ,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FJ,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WS,"metabase/mbql/schema.cljc",61,$CLJS.SJ,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.SJ,$CLJS.ez,$CLJS.V($CLJS.Kj,CR),$CLJS.cj,
$CLJS.V($CLJS.Kj,$CLJS.V(QU,oT))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(XX)?XX.H:null])):null));return $CLJS.n(a)?a:WS}(),XX],null)])));
$CLJS.YX=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Au,":field or :expression reference or expression",$CLJS.Oi,function(a){return $CLJS.n(BP(zW,a))?NV:$CLJS.n(BP(wW,a))?$CLJS.Tj:$CLJS.n(BP(AW,a))?$CLJS.Ct:$CLJS.n(BP(CW,a))?$CLJS.cN:$CLJS.n(BP($CLJS.SJ,a))?$CLJS.SJ:$CLJS.Vu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[NV,DW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,xW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ct,EW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cN,$CLJS.FW],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SJ,XX],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vu,$CLJS.tW],null)],null);$CLJS.X(tV,CP($CLJS.TA,$CLJS.G(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,$CLJS.tW],null)])));var ZX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tV],null);$CLJS.X(gT,CP($CLJS.iK,$CLJS.G(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,$CLJS.tW],null)])));var $X=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gT],null);$CLJS.X(mQ,CP($CLJS.bK,$CLJS.G(["field-or-expression",$CLJS.YX])));
var aY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mQ],null);$CLJS.X(lV,CP($CLJS.EK,$CLJS.G(["field-or-expression",$CLJS.YX])));var bY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lV],null);$CLJS.X(NR,CP($CLJS.OJ,$CLJS.G(["field-or-expression",$CLJS.YX])));var cY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NR],null);$CLJS.X(BU,CP($CLJS.uK,$CLJS.G(["field-or-expression",$CLJS.YX])));var dY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BU],null);$CLJS.X(IR,CP($CLJS.On,$CLJS.G(["field-or-expression",$CLJS.YX])));
var eY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IR],null);$CLJS.X(uR,CP($CLJS.ck,$CLJS.G(["field-or-expression",$CLJS.YX])));var fY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uR],null);$CLJS.X(oR,CP($CLJS.CK,$CLJS.G(["field-or-expression",$CLJS.YX,"pred",$CLJS.xX])));var gY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oR],null);$CLJS.X(FR,CP($CLJS.qK,$CLJS.G(["pred",$CLJS.xX])));var hY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FR],null);$CLJS.X(sU,CP($CLJS.oK,$CLJS.G(["pred",$CLJS.xX])));
var iY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sU],null);$CLJS.X(JQ,CP($CLJS.yK,$CLJS.G(["field-or-expression",$CLJS.YX])));var jY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JQ],null);$CLJS.X(JP,CP($CLJS.PJ,$CLJS.G(["field-or-expression",$CLJS.YX])));var kY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JP],null);$CLJS.X(SR,CP($CLJS.vK,$CLJS.G(["field-or-expression",$CLJS.YX])));var lY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SR],null);$CLJS.X(HS,CP($CLJS.UJ,$CLJS.G(["field-or-expression",$CLJS.YX,"percentile",HW])));
var mY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HS],null);$CLJS.X(vU,CP($CLJS.cK,$CLJS.G(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.MO],null),VV],null)])));var nY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vU],null);
$CLJS.X(rU,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Au,"unnamed aggregation clause or numeric expression",$CLJS.Oi,function(a){return $CLJS.n(BP(zW,a))?KS:$CLJS.Vu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[KS,DW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vu,DP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof aY?new $CLJS.Gc(function(){return aY},$CLJS.qd(Qda,
new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FJ,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TS,"metabase/mbql/schema.cljc",60,$CLJS.bK,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bK,OU,$CLJS.V($CLJS.Kj,FU)],null),$CLJS.Kc,"Schema for a valid avg clause.",
$CLJS.n(aY)?aY.H:null])):null));return $CLJS.n(a)?a:TS}(),aY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof bY?new $CLJS.Gc(function(){return bY},$CLJS.qd(ada,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FJ,"null"],null),null),$CLJS.qd(wU,
new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$U,"metabase/mbql/schema.cljc",64,$CLJS.EK,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EK,OU,$CLJS.V($CLJS.Kj,FU)],null),$CLJS.Kc,"Schema for a valid cum-sum clause.",$CLJS.n(bY)?bY.H:null])):null));return $CLJS.n(a)?a:$U}(),bY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==
typeof HP&&"undefined"!==typeof cY?new $CLJS.Gc(function(){return cY},$CLJS.qd(Sca,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FJ,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XS,"metabase/mbql/schema.cljc",65,$CLJS.OJ,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OJ,OU,$CLJS.V($CLJS.Kj,FU)],null),$CLJS.Kc,"Schema for a valid distinct clause.",$CLJS.n(cY)?cY.H:null])):null));return $CLJS.n(a)?a:XS}(),cY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof jY?new $CLJS.Gc(function(){return jY},$CLJS.qd(lea,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,
$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.rK,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fQ,"metabase/mbql/schema.cljc",76,$CLJS.yK,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yK,OU,$CLJS.V($CLJS.Kj,FU)],null),$CLJS.Kc,"Schema for a valid stddev clause.",$CLJS.n(jY)?jY.H:null])):null));return $CLJS.n(a)?a:fQ}(),jY],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof dY?new $CLJS.Gc(function(){return dY},$CLJS.qd(Fda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FJ,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BQ,"metabase/mbql/schema.cljc",60,$CLJS.uK,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uK,OU,$CLJS.V($CLJS.Kj,FU)],null),$CLJS.Kc,"Schema for a valid sum clause.",$CLJS.n(dY)?dY.H:null])):null));return $CLJS.n(a)?a:BQ}(),dY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof eY?new $CLJS.Gc(function(){return eY},$CLJS.qd(tca,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,
$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FJ,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Yt,"metabase/mbql/schema.cljc",60,$CLJS.On,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.On,OU,$CLJS.V($CLJS.Kj,FU)],null),$CLJS.Kc,"Schema for a valid min clause.",$CLJS.n(eY)?eY.H:
null])):null));return $CLJS.n(a)?a:$CLJS.Yt}(),eY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof fY?new $CLJS.Gc(function(){return fY},$CLJS.qd(oca,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FJ,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.cu,"metabase/mbql/schema.cljc",60,$CLJS.ck,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ck,OU,$CLJS.V($CLJS.Kj,FU)],null),$CLJS.Kc,"Schema for a valid max clause.",$CLJS.n(fY)?fY.H:null])):null));return $CLJS.n(a)?a:$CLJS.cu}(),fY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&
"undefined"!==typeof nY?new $CLJS.Gc(function(){return nY},$CLJS.qd(Pca,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VR,"metabase/mbql/schema.cljc",18,$CLJS.cK,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cK,bda,$CLJS.V($CLJS.Kj,new $CLJS.P(null,3,5,
$CLJS.Q,[$CLJS.lt,fda,wQ],null))],null),$CLJS.Kc,"Schema for a valid metric clause.",$CLJS.n(nY)?nY.H:null])):null));return $CLJS.n(a)?a:VR}(),nY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof iY?new $CLJS.Gc(function(){return iY},$CLJS.qd(nda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,
$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FJ,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MR,"metabase/mbql/schema.cljc",62,$CLJS.oK,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oK,$CLJS.go,$CLJS.V($CLJS.Kj,FS)],null),$CLJS.Kc,"Schema for a valid share clause.",$CLJS.n(iY)?iY.H:null])):null));return $CLJS.n(a)?a:MR}(),iY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof hY?new $CLJS.Gc(function(){return hY},$CLJS.qd(uca,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FJ,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),xQ,"metabase/mbql/schema.cljc",68,$CLJS.qK,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qK,$CLJS.go,$CLJS.V($CLJS.Kj,FS)],null),$CLJS.Kc,"Schema for a valid count-where clause.",$CLJS.n(hY)?hY.H:null])):null));return $CLJS.n(a)?a:xQ}(),hY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof gY?new $CLJS.Gc(function(){return gY},$CLJS.qd(zea,new $CLJS.k(null,1,[$CLJS.Vj,!0],
null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FJ,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),US,"metabase/mbql/schema.cljc",66,$CLJS.CK,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.CK,OU,$CLJS.V($CLJS.Kj,FU),$CLJS.go,$CLJS.V($CLJS.Kj,FS)],null),$CLJS.Kc,"Schema for a valid sum-where clause.",
$CLJS.n(gY)?gY.H:null])):null));return $CLJS.n(a)?a:US}(),gY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof XX?new $CLJS.Gc(function(){return XX},$CLJS.qd(iQ,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FJ,"null"],null),null),$CLJS.qd(wU,
new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WS,"metabase/mbql/schema.cljc",61,$CLJS.SJ,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.SJ,$CLJS.ez,$CLJS.V($CLJS.Kj,CR),$CLJS.cj,$CLJS.V($CLJS.Kj,$CLJS.V(QU,oT))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(XX)?XX.H:null])):null));return $CLJS.n(a)?a:WS}(),XX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&
"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof lY?new $CLJS.Gc(function(){return lY},$CLJS.qd(eea,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.dK,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YU,"metabase/mbql/schema.cljc",
68,$CLJS.vK,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vK,OU,$CLJS.V($CLJS.Kj,FU)],null),$CLJS.Kc,"Schema for a valid median clause.",$CLJS.n(lY)?lY.H:null])):null));return $CLJS.n(a)?a:YU}(),lY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof mY?new $CLJS.Gc(function(){return mY},$CLJS.qd(zda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,
PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.dK,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uU,"metabase/mbql/schema.cljc",72,$CLJS.UJ,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.UJ,OU,$CLJS.V($CLJS.Kj,FU),$CLJS.UJ,$CLJS.V($CLJS.Kj,yU)],null),$CLJS.Kc,"Schema for a valid percentile clause.",$CLJS.n(mY)?mY.H:null])):
null));return $CLJS.n(a)?a:uU}(),mY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof kY?new $CLJS.Gc(function(){return kY},$CLJS.qd(Eca,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aQ,"metabase/mbql/schema.cljc",77,$CLJS.PJ,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PJ,OU,$CLJS.V($CLJS.Kj,FU)],null),$CLJS.Kc,"Schema for a valid var clause.",$CLJS.n(kY)?kY.H:null])):null));return $CLJS.n(a)?a:aQ}(),kY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof $X?new $CLJS.Gc(function(){return $X},$CLJS.qd(qca,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,
$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,SU,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FJ,"null"],null),null),$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GS,"metabase/mbql/schema.cljc",74,$CLJS.iK,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iK,$CLJS.hK,$CLJS.V($CLJS.Kj,$CLJS.V(QU,iR))],null),$CLJS.Kc,"Schema for a valid cum-count clause.",
$CLJS.n($X)?$X.H:null])):null));return $CLJS.n(a)?a:GS}(),$X],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof ZX?new $CLJS.Gc(function(){return ZX},$CLJS.qd(Rca,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([NS,$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,SU,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.FJ,"null"],null),null),
$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Bi,"metabase/mbql/schema.cljc",70,$CLJS.TA,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TA,$CLJS.hK,$CLJS.V($CLJS.Kj,$CLJS.V(QU,iR))],null),$CLJS.Kc,"Schema for a valid count clause.",$CLJS.n(ZX)?ZX.H:null])):null));return $CLJS.n(a)?a:$CLJS.Bi}(),ZX],null)]))],null)],null));
$CLJS.X(IT,CP($CLJS.WP,$CLJS.G(["aggregation",rU,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.bk,new $CLJS.k(null,1,[$CLJS.Au,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.S,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),VV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nI,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),VV],null)],null)])));
$CLJS.X(PU,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Au,"aggregation clause or numeric expression",$CLJS.Oi,function(a){return $CLJS.n(BP($CLJS.WP,a))?$CLJS.WP:BR}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WP,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IT],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[BR,rU],null)],null));$CLJS.X(eQ,CP($CLJS.eK,$CLJS.G(["field",vW])));var oY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eQ],null);$CLJS.X(JS,CP($CLJS.YJ,$CLJS.G(["field",vW])));
pY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JS],null);
Pea=DP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof oY?new $CLJS.Gc(function(){return oY},$CLJS.qd(iea,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
pS,"metabase/mbql/schema.cljc",15,$CLJS.eK,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eK,$CLJS.hK,$CLJS.V($CLJS.Kj,OR)],null),$CLJS.Kc,"Schema for a valid asc clause.",$CLJS.n(oY)?oY.H:null])):null));return $CLJS.n(a)?a:pS}(),oY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof pY?new $CLJS.Gc(function(){return pY},$CLJS.qd($ca,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,
$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kU,"metabase/mbql/schema.cljc",16,$CLJS.YJ,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YJ,$CLJS.hK,$CLJS.V($CLJS.Kj,OR)],null),$CLJS.Kc,"Schema for a valid desc clause.",$CLJS.n(pY)?pY.H:null])):null));return $CLJS.n(a)?a:kU}(),pY],null)]));
qY=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ht,$CLJS.hQ,$CLJS.YQ,$CLJS.KP,$CLJS.vv,$CLJS.HE,$CLJS.hT],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,VV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nI,VV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),VV],null)],null);
Qea=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,qY,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.hQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UP,VV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zT,YV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AT,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),YV],null)],null)],null);
Rea=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,qY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.YQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KU,YV],null)],null)],null);
rY=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,qY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gi,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Dt],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AR,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Ct],null)],null)],null);Sea=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZU],null);
Tea=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,rY,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.KP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KP,sW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cj,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qt,$CLJS.Ui,$CLJS.Dt],null)],null)],null)],null);
$CLJS.sY=new $CLJS.dh(null,new $CLJS.k(null,4,[$CLJS.hT,null,$CLJS.vv,null,$CLJS.Ct,null,$CLJS.HE,null],null),null);Uea=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ht],null),$CLJS.sY);
$CLJS.X(dU,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,1,[$CLJS.Oi,$CLJS.ij],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KP,Tea],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hQ,Qea],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YQ,Rea],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Po,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,rY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij,Uea],null)],null)],null)],null)],null));
var tY=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lU,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qt,VV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Me(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return $CLJS.F.g(c,
$CLJS.S.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QR,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,VV],null)],null)],null),Vea=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,tY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jQ,$CLJS.Dt],null)],null)],null),uY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MT],null),vY=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,1,[$CLJS.Oi,function(a){return $CLJS.n($CLJS.oP($CLJS.yd,
$CLJS.PT)(a))?$CLJS.PT:$CLJS.mI}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,tY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PT,$CLJS.Dt],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mI,uY],null)],null),wY=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,VV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RG,WV],null),new $CLJS.P(null,2,5,$CLJS.Q,[Hda,VV],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.iS,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,XV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vR,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.bk],null)],null)],null),xY=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,Hea,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.k(null,1,[$CLJS.Au,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),Wea=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ht],null),
new $CLJS.dh(null,new $CLJS.k(null,4,[$CLJS.kJ,null,$CLJS.OI,null,$CLJS.cJ,null,$CLJS.iJ,null],null),null)),yY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XU],null);
$CLJS.X(LR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LS,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),xY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oU,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),vY],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xV,$CLJS.xX],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CI,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),Wea],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SI,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.lt,new $CLJS.k(null,1,[$CLJS.Au,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ht,$CLJS.cC,$CLJS.Xu],null),yY],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WI,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),VV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mU,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$V],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xS,new $CLJS.k(null,
1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,wY],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.oP($CLJS.WD($CLJS.LS,$CLJS.oU),$CLJS.Oe($CLJS.oP($CLJS.LS,$CLJS.oU)))],null)],null));var Xea=$CLJS.Q,zY;var AY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LR],null)],null);
if($CLJS.xd(AY)&&$CLJS.F.g($CLJS.B(AY),$CLJS.vj)){var BY=$CLJS.A(AY);$CLJS.B(BY);var CY=$CLJS.C(BY),Yea=$CLJS.yd($CLJS.B(CY))?CY:$CLJS.de(null,CY),DY=$CLJS.A(Yea),Zea=$CLJS.B(DY),$ea=$CLJS.C(DY);zY=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,$CLJS.R.j(Zea,$CLJS.On,1)],null),$ea)}else zY=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,AY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"non-empty"],null),$CLJS.A],null)],null);
$CLJS.X(MQ,new $CLJS.P(null,3,5,Xea,[$CLJS.st,zY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"All join aliases must be unique."],null),function(a){return $CLJS.mP($CLJS.jf($CLJS.Ua,$CLJS.ff.g($CLJS.WI,a)))}],null)],null));var afa=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MQ],null);
$CLJS.X(XU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hl,new $CLJS.k(null,1,[$CLJS.Au,"Distinct, non-empty sequence of Field clauses"],null),EP(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vj,new $CLJS.k(null,1,[$CLJS.On,1],null),$CLJS.tW],null))],null));
$CLJS.X(MT,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oU,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),vY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LS,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),xY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XJ,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vj,new $CLJS.k(null,1,[$CLJS.On,1],null),$CLJS.GW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mS,new $CLJS.k(null,1,
[$CLJS.Jt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vj,new $CLJS.k(null,1,[$CLJS.On,1],null),$CLJS.tW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FT,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qt,VV,$CLJS.YX],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SI,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),yY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KQ,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.xX],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SV,new $CLJS.k(null,
1,[$CLJS.Jt,!0],null),ZV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vV,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),EP(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vj,new $CLJS.k(null,1,[$CLJS.On,1],null),Pea],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[QT,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[QT,YV],null),new $CLJS.P(null,2,5,$CLJS.Q,[Jca,YV],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gR,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),afa],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xS,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,wY],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.F.g(1,$CLJS.E($CLJS.bh(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oU,$CLJS.LS],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,
new $CLJS.k(null,1,[$CLJS.Au,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.Ge(a);a=$CLJS.I.g(b,$CLJS.mS);b=$CLJS.I.g(b,$CLJS.SI);return $CLJS.ud($CLJS.Qv.g($CLJS.ih(a),$CLJS.ih(b)))}],null)],null));
$CLJS.EY=$CLJS.Ig([$CLJS.MU,$CLJS.hT,HR,sR,yS,JV,dS,$CLJS.IV,$CLJS.vv,cS,pQ,CU,yV,$CLJS.EQ,uV,ER,$CLJS.DS,aS,$CLJS.Wi,$CLJS.$R,XP,$CLJS.bR,$CLJS.ZR,cT,$CLJS.Ct,$CLJS.HE,UR],[new $CLJS.k(null,1,[oS,new $CLJS.dh(null,new $CLJS.k(null,5,[$CLJS.MU,null,$CLJS.hT,null,$CLJS.vv,null,$CLJS.Ct,null,$CLJS.HE,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.ij,$CLJS.hT,oS,new $CLJS.dh(null,new $CLJS.k(null,5,[$CLJS.MU,null,$CLJS.hT,null,$CLJS.IV,null,$CLJS.DS,null,$CLJS.Wi,null],null),null)],null),new $CLJS.k(null,
3,[$CLJS.ij,$CLJS.Tj,$CLJS.vI,LU,oS,new $CLJS.dh(null,new $CLJS.k(null,1,[HR,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.ij,NV,$CLJS.vI,LU,oS,new $CLJS.dh(null,new $CLJS.k(null,5,[$CLJS.MU,null,sR,null,$CLJS.vv,null,$CLJS.Wi,null,XP,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.ij,$CLJS.Tj,$CLJS.vI,sQ,oS,new $CLJS.dh(null,new $CLJS.k(null,1,[yS,null],null),null)],null),new $CLJS.k(null,1,[oS,new $CLJS.dh(null,new $CLJS.k(null,1,[JV,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.ij,
NV,$CLJS.vI,Zda,oS,new $CLJS.dh(null,new $CLJS.k(null,1,[dS,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.ij,$CLJS.hT,oS,new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.IV,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.ij,NV,oS,new $CLJS.dh(null,new $CLJS.k(null,5,[$CLJS.MU,null,sR,null,$CLJS.vv,null,$CLJS.Wi,null,XP,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.ij,NV,$CLJS.vI,sQ,oS,new $CLJS.dh(null,new $CLJS.k(null,1,[cS,null],null),null)],null),new $CLJS.k(null,1,[oS,new $CLJS.dh(null,
new $CLJS.k(null,1,[pQ,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.ij,$CLJS.Tj,$CLJS.vI,LU,oS,new $CLJS.dh(null,new $CLJS.k(null,8,[$CLJS.MU,null,JV,null,pQ,null,CU,null,aS,null,$CLJS.Wi,null,XP,null,$CLJS.HE,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.ij,$CLJS.Tj,$CLJS.vI,sQ,oS,new $CLJS.dh(null,new $CLJS.k(null,1,[yV,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.ij,$CLJS.hT,oS,new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.IV,null,$CLJS.EQ,null],null),null)],null),new $CLJS.k(null,
3,[$CLJS.ij,$CLJS.Tj,$CLJS.vI,sQ,oS,new $CLJS.dh(null,new $CLJS.k(null,1,[uV,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.ij,$CLJS.Tj,$CLJS.vI,sQ,oS,new $CLJS.dh(null,new $CLJS.k(null,1,[ER,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.ij,$CLJS.hT,oS,new $CLJS.dh(null,new $CLJS.k(null,5,[$CLJS.MU,null,$CLJS.hT,null,$CLJS.IV,null,$CLJS.DS,null,$CLJS.Wi,null],null),null)],null),new $CLJS.k(null,1,[oS,new $CLJS.dh(null,new $CLJS.k(null,1,[aS,null],null),null)],null),new $CLJS.k(null,1,[oS,
new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.Wi,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.ij,$CLJS.hT,oS,new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.IV,null,$CLJS.$R,null],null),null)],null),new $CLJS.k(null,1,[oS,new $CLJS.dh(null,new $CLJS.k(null,1,[XP,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.ij,$CLJS.hT,oS,new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.IV,null,$CLJS.bR,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.ij,$CLJS.hT,oS,new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.IV,
null,$CLJS.ZR,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.ij,NV,$CLJS.vI,LU,oS,new $CLJS.dh(null,new $CLJS.k(null,1,[cT,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.ij,$CLJS.Ct,oS,new $CLJS.dh(null,new $CLJS.k(null,3,[$CLJS.MU,null,$CLJS.Wi,null,$CLJS.Ct,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.ij,$CLJS.Tj,oS,new $CLJS.dh(null,new $CLJS.k(null,8,[$CLJS.MU,null,JV,null,pQ,null,CU,null,aS,null,$CLJS.Wi,null,XP,null,$CLJS.HE,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.ij,
NV,$CLJS.vI,sQ,oS,new $CLJS.dh(null,new $CLJS.k(null,1,[UR,null],null),null)],null)]);$CLJS.X(ZU,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"valid parameter type"],null)],null),$CLJS.Xg($CLJS.EY)));$CLJS.X(AQ,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"valid template tag widget type"],null),$CLJS.Xu],null),$CLJS.Xg($CLJS.EY)));
$CLJS.X(iU,CP(Aca,$CLJS.G(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,VV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,VV],null)],null)],null)])));var FY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iU],null);$CLJS.X($P,CP($CLJS.KP,$CLJS.G(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,$CLJS.tW,FY],null)])));var GY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$P],null);$CLJS.X(WQ,CP(YR,$CLJS.G(["target",FY])));
var HY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WQ],null),bfa=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,$CLJS.tW,DP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof GY?new $CLJS.Gc(function(){return GY},$CLJS.qd(xda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UT,"metabase/mbql/schema.cljc",21,$CLJS.KP,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KP,$CLJS.uN,$CLJS.V($CLJS.Kj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,iR,nS],null))],null),$CLJS.Kc,"Schema for a valid dimension clause.",$CLJS.n(GY)?GY.H:null])):null));return $CLJS.n(a)?a:UT}(),GY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=PS.h($CLJS.rd("undefined"!==typeof $CLJS.FP&&
"undefined"!==typeof GP&&"undefined"!==typeof HP&&"undefined"!==typeof HY?new $CLJS.Gc(function(){return HY},$CLJS.qd(Nda,new $CLJS.k(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ig([$CLJS.Ti,$CLJS.S,$CLJS.fk,$CLJS.zi,PS,$CLJS.Si,$CLJS.jj,$CLJS.mk,GQ,$CLJS.yi,$CLJS.T,$CLJS.ek],[$CLJS.qd(wU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OV,"metabase/mbql/schema.cljc",20,YR,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[YR,$CLJS.uN,
$CLJS.V($CLJS.Kj,nS)],null),$CLJS.Kc,"Schema for a valid variable clause.",$CLJS.n(HY)?HY.H:null])):null));return $CLJS.n(a)?a:OV}(),HY],null)]))],null),yP=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HQ],null);
$CLJS.X(HQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.st,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.oP($CLJS.WD($CLJS.PT,$CLJS.jQ),$CLJS.Oe($CLJS.oP($CLJS.PT,$CLJS.jQ)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.Ge(b);b=$CLJS.I.g(c,$CLJS.PT);var d=$CLJS.I.g(c,$CLJS.jQ);c=$CLJS.I.g(c,$CLJS.ij);c=c instanceof $CLJS.M?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AT,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lt,new $CLJS.k(null,1,[$CLJS.Au,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SO],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.RO],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ht,$CLJS.jQ,$CLJS.PT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PT,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),Vea],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jQ,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),uY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XQ,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,new $CLJS.P(null,
8,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ij,Sea],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),VV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uN,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),bfa],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Dt],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.S,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),VV],null),new $CLJS.P(null,3,5,$CLJS.Q,[Gca,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),
VV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gi,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Dt],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pU,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,3,5,$CLJS.Q,[xea,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),hW],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wda,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,3,5,$CLJS.Q,[VS,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),ZV],null),new $CLJS.P(null,3,5,$CLJS.Q,[zQ,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),ZV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.Ge(a);a=$CLJS.I.g(b,VS);b=$CLJS.I.g(b,zQ);return $CLJS.Ta($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[mda,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,3,5,$CLJS.Q,[hea,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Ct],null),new $CLJS.P(null,3,5,$CLJS.Q,[Cca,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Ct],null),new $CLJS.P(null,3,5,$CLJS.Q,[lca,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Ct],null),new $CLJS.P(null,3,5,$CLJS.Q,[Ica,new $CLJS.k(null,1,[$CLJS.Jt,
!0],null),$CLJS.Ct],null),new $CLJS.P(null,3,5,$CLJS.Q,[$da,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.Ct],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[nca,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.Ct],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[nea,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.Ct],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qC,new $CLJS.k(null,
1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SQ,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.ht,Mda,Jda,$CLJS.QR,yda,Oda,kda,xca,Dca,Mca,Zca,Xda,Fea,cda,Vca],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[cea,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,YV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[hda,
new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,YV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KU,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,Gea],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[yea,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,VV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[jda,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,YV],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[wea,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qt,$CLJS.Dt,$CLJS.Dt],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[dda,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,YV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TR,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qt,$CLJS.Dt,
$CLJS.Dt],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QP,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.Dt],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Oe($CLJS.xS)],null)],null));var IY=$CLJS.xP(yP);
(function(){var a=gca();return function(b){if($CLJS.n(IY.h?IY.h(b):IY.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.uP(b);throw $CLJS.li($CLJS.GI("Invalid query: {0}",$CLJS.G([$CLJS.Ph.l($CLJS.G([c]))])),new $CLJS.k(null,2,[$CLJS.kC,c,rda,b],null));}})();
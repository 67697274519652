var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.ref.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.js");
'use strict';var j2,l2,n2,o2,cha,dha,eha,t2,k2,u2;j2=function(a,b){var c=$CLJS.Hd,d=i2;for(a=$CLJS.A(a);;)if(null!=a){d=$CLJS.I.j(d,$CLJS.B(a),c);if(c===d)return b;a=$CLJS.C(a)}else return d};l2=function(a,b){var c=k2,d=$CLJS.rd(a);b=$CLJS.R.j?$CLJS.R.j(d,c,b):$CLJS.R.call(null,d,c,b);return $CLJS.qd(a,b)};
$CLJS.m2=function(a,b,c,d){a=$CLJS.o_(a);b=$CLJS.n(b)?b:-1;var e=$CLJS.q0(a,b);e=!($CLJS.A($CLJS.XJ.h(e))||$CLJS.A($CLJS.mS.h(e)));c=$CLJS.B_.l(a,b,$CLJS.Fk,$CLJS.G([c,function(f){return $CLJS.ae.g($CLJS.Cf(f),$CLJS.c_.h(d))}]));return e?$CLJS.Fk.N((0,$CLJS.B_)(c,b,function(f){return $CLJS.mZ.j($CLJS.vk.l(f,$CLJS.vV,$CLJS.G([$CLJS.SI])),$CLJS.gR,function(g){return $CLJS.Ek.g(function(l){return $CLJS.vk.g(l,$CLJS.SI)},g)})}),$CLJS.aP,$CLJS.Ck.g(function(f){return $CLJS.eg.g($CLJS.Bf,f)},$CLJS.Jk),
0,$CLJS.p_(a,b)+1):c};n2=function(a){return $CLJS.eg.j($CLJS.eh,$CLJS.Dk.h(function(b){return $CLJS.le(b)&&$CLJS.ah.g(b,$CLJS.Aj)}),$CLJS.Xg(a))};o2=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Xl(function(b,c){$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);c=$CLJS.Ge(c);c=$CLJS.I.g(c,$CLJS.yI);return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),$CLJS.XJ.h(a))};
cha=function(a){return $CLJS.YZ(a,function(b){return $CLJS.eg.j($CLJS.N,$CLJS.Dk.h(function(c){var d=$CLJS.H(c,0,null);$CLJS.H(c,1,null);return $CLJS.le(d)}),b)})};
$CLJS.p2=function(a){return $CLJS.B($CLJS.A($CLJS.jf($CLJS.Ua,function e(c,d){try{if($CLJS.Ad(d)&&3===$CLJS.E(d))try{var f=$CLJS.cd(d,0);if($CLJS.ge(f,$CLJS.hK))try{var g=$CLJS.cd(d,2);if($CLJS.Gl(g))return $CLJS.cd(d,2),$CLJS.cd(d,1),new $CLJS.P(null,1,5,$CLJS.Q,[d],null);throw $CLJS.Y;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof
Error){l=m;if(l===$CLJS.Y)return $CLJS.jZ(e,c,d);throw l;}throw m;}}($CLJS.Bf,a))))};
$CLJS.r2=function(a,b){for(var c=$CLJS.Td,d=new $CLJS.P(null,6,5,$CLJS.Q,[function(){return function(g){return $CLJS.XZ(g,$CLJS.mZ,$CLJS.G([$CLJS.cV,$CLJS.vk,$CLJS.v0,$CLJS.Aj]))}}(c),cha,function(){return function(g){return $CLJS.XZ(g,$CLJS.vk,$CLJS.G([$CLJS.CE,$CLJS.Hi]))}}(c),function(){return function(g){return $CLJS.XZ(g,$CLJS.vk,$CLJS.G([$CLJS.nK]))}}(c),function(){return function(g){return $CLJS.XZ(g,$CLJS.vk,$CLJS.G([$CLJS.cV]))}}(c),function(){return function(g){return $CLJS.XZ(g,$CLJS.vk,
$CLJS.G([$CLJS.$S]))}}(c)],null);;){var e=function(){var g=c.h?c.h(a):c.call(null,a);return $CLJS.CL(function(l,m,t){return function(u){return q2.g(l.h?l.h(u):l.call(null,u),t)}}(c,d,g),b)}();if($CLJS.n(e))return e;if($CLJS.A(d)){e=$CLJS.Ck.g(c,$CLJS.B(d));var f=$CLJS.Lc(d);c=e;d=f}else return null}};
$CLJS.s2=function(a,b){if($CLJS.A(b)){var c=function(e){var f=$CLJS.r2(a,function(){return function m(l){return new $CLJS.me(null,function(){for(;;){var t=$CLJS.A(l);if(t){if($CLJS.Bd(t)){var u=$CLJS.kc(t),v=$CLJS.E(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var D=$CLJS.md(u,z),J=$CLJS.H(D,0,null);D=$CLJS.H(D,1,null);J=l2(e.h?e.h(D):e.call(null,D),J);x.add(J);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),m($CLJS.lc(t))):$CLJS.te($CLJS.ve(x),null)}u=$CLJS.B(t);x=$CLJS.H(u,0,null);u=$CLJS.H(u,
1,null);return $CLJS.de(l2(e.h?e.h(u):e.call(null,u),x),m($CLJS.Lc(t)))}return null}},null,null)}($CLJS.Vl($CLJS.Gt,b))}());return $CLJS.n(f)?k2.h($CLJS.rd(f)):null},d=c($CLJS.$Z);return $CLJS.n(d)?d:$CLJS.n($CLJS.p2(a))?c(function(e){return $CLJS.$Z($CLJS.n($CLJS.Wi.h(e))?$CLJS.R.j(e,$CLJS.t_,$CLJS.w0):e)}):null}return null};dha=new $CLJS.M("metabase.lib.equality","no-right","metabase.lib.equality/no-right",2015744769);
eha=new $CLJS.M("metabase.lib.equality","no-left","metabase.lib.equality/no-left",-1403666439);t2=new $CLJS.M(null,"left","left",-399115937);k2=new $CLJS.M("metabase.lib.equality","index","metabase.lib.equality/index",1826141378);u2=new $CLJS.M("metabase.lib.equality","different-dispatch-values","metabase.lib.equality/different-dispatch-values",-2107733395);var q2=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.I.j(new $CLJS.k(null,1,[$CLJS.ej,$CLJS.CF],null),$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.equality","\x3d"),function(f,g){f=$CLJS.AF(f);g=$CLJS.AF(g);return $CLJS.ah.g(f,g)?u2:f},e,a,b,c,d)}();q2.m(null,u2,function(){return!1});
q2.m(null,$CLJS.yE,function(a,b){var c=n2(a),d=n2(b);return $CLJS.F.g(c,d)&&$CLJS.Me(function(e){return q2.g($CLJS.I.g(a,e),$CLJS.I.g(b,e))},c)});q2.m(null,$CLJS.wE,function(a,b){var c=$CLJS.F.g($CLJS.E(a),$CLJS.E(b));if(c)for(c=$CLJS.A(a),$CLJS.B(c),$CLJS.C(c),c=$CLJS.A(b),$CLJS.B(c),$CLJS.C(c),c=a,a=b;;){c=$CLJS.A(c);b=$CLJS.B(c);var d=$CLJS.C(c);c=b;b=d;a=$CLJS.A(a);d=$CLJS.B(a);a=$CLJS.C(a);c=q2.g(c,d);if($CLJS.n(c)){if(c=$CLJS.ud(b))return c;c=b}else return c}else return c});var i2=null;
q2.m(null,$CLJS.DZ,function(a,b){var c=i2;i2=new $CLJS.k(null,2,[t2,o2(a),$CLJS.Yz,o2(b)],null);try{var d=$CLJS.ei(q2,$CLJS.yE);return d.g?d.g(a,b):d.call(null,a,b)}finally{i2=c}});
q2.m(null,$CLJS.XJ,function(a,b){var c=$CLJS.H(a,0,null),d=$CLJS.H(a,1,null),e=$CLJS.H(a,2,null),f=$CLJS.H(b,0,null),g=$CLJS.H(b,1,null),l=$CLJS.H(b,2,null);return(a=$CLJS.F.l(3,$CLJS.E(a),$CLJS.G([$CLJS.E(b)])))?(c=$CLJS.F.g(c,f))?(d=q2.g(d,g),$CLJS.n(d)?$CLJS.n(i2)?$CLJS.F.g(j2(new $CLJS.P(null,2,5,$CLJS.Q,[t2,e],null),eha),j2(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yz,l],null),dha)):$CLJS.F.g(e,l):d):c:a});
q2.m(null,$CLJS.gi,function(a,b){if($CLJS.yd(a)){var c=$CLJS.ei(q2,$CLJS.yE);return c.g?c.g(a,b):c.call(null,a,b)}return $CLJS.xd(a)?(c=$CLJS.ei(q2,$CLJS.wE),c.g?c.g(a,b):c.call(null,a,b)):$CLJS.F.g(a,b)});
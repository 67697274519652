var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var PM,RM,TM,eN,iN,qN;$CLJS.OM=new $CLJS.M(null,"get-month","get-month",-369374731);PM=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.QM=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);RM=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.SM=new $CLJS.M(null,"iso","iso",-1366207543);TM=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.UM=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.VM=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.WM=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.XM=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.YM=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.ZM=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.$M=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.aN=new $CLJS.M(null,"us","us",746429226);$CLJS.bN=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.cN=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.dN=new $CLJS.M(null,"now","now",-1650525531);eN=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.fN=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.gN=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.hN=new $CLJS.M(null,"get-week","get-week",-888059349);
iN=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.jN=new $CLJS.M(null,"datetime-add","datetime-add",209603411);$CLJS.kN=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.lN=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.mN=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.nN=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);$CLJS.oN=new $CLJS.M(null,"get-hour","get-hour",622714059);$CLJS.pN=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);qN=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.rN=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);$CLJS.sN=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.tN=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.uN=new $CLJS.M(null,"target","target",253001721);$CLJS.AJ($CLJS.$M,$CLJS.G([$CLJS.qu,$CLJS.OG,$CLJS.yt,$CLJS.kL]));$CLJS.JI.m(null,$CLJS.XM,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=$CLJS.KI(a);return $CLJS.wd(a)?(a=$CLJS.Qv.g(a,new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.vG,null,$CLJS.dH,null],null),null)),$CLJS.F.g($CLJS.E(a),1)?$CLJS.B(a):a):a});$CLJS.tJ($CLJS.XM,$CLJS.eJ);
for(var vN=$CLJS.A(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jN,$CLJS.pN],null)),wN=null,xN=0,yN=0;;)if(yN<xN){var zN=wN.X(null,yN);$CLJS.AJ(zN,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VI],null),$CLJS.yt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kL],null)]));$CLJS.tJ(zN,$CLJS.XM);yN+=1}else{var AN=$CLJS.A(vN);if(AN){var BN=AN;if($CLJS.Bd(BN)){var CN=$CLJS.kc(BN),nba=$CLJS.lc(BN),oba=CN,pba=$CLJS.E(CN);vN=nba;wN=oba;xN=pba}else{var DN=$CLJS.B(BN);$CLJS.AJ(DN,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.VI],null),$CLJS.yt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kL],null)]));$CLJS.tJ(DN,$CLJS.XM);vN=$CLJS.C(BN);wN=null;xN=0}yN=0}else break}
for(var EN=$CLJS.A(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.VM,$CLJS.OM,$CLJS.gN,$CLJS.oN,$CLJS.kN,$CLJS.UM,$CLJS.rN],null)),FN=null,GN=0,HN=0;;)if(HN<GN){var qba=FN.X(null,HN);$CLJS.AJ(qba,$CLJS.G([$CLJS.qu,$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VI],null)],null)]));HN+=1}else{var IN=$CLJS.A(EN);if(IN){var JN=IN;if($CLJS.Bd(JN)){var KN=$CLJS.kc(JN),rba=$CLJS.lc(JN),sba=KN,tba=$CLJS.E(KN);EN=rba;FN=sba;GN=tba}else{var uba=$CLJS.B(JN);$CLJS.AJ(uba,$CLJS.G([$CLJS.qu,
$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VI],null)],null)]));EN=$CLJS.C(JN);FN=null;GN=0}HN=0}else break}$CLJS.AJ($CLJS.ZM,$CLJS.G([$CLJS.qu,$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hL],null)]));
for(var LN=$CLJS.A(new $CLJS.dh(null,new $CLJS.k(null,8,[$CLJS.rN,null,$CLJS.kN,null,$CLJS.oN,null,$CLJS.lN,null,$CLJS.UM,null,$CLJS.OM,null,$CLJS.VM,null,$CLJS.gN,null],null),null)),MN=null,NN=0,ON=0;;)if(ON<NN){var vba=MN.X(null,ON);$CLJS.AJ(vba,$CLJS.G([$CLJS.qu,$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VI],null)],null)]));ON+=1}else{var PN=$CLJS.A(LN);if(PN){var QN=PN;if($CLJS.Bd(QN)){var RN=$CLJS.kc(QN),wba=$CLJS.lc(QN),xba=RN,yba=$CLJS.E(RN);
LN=wba;MN=xba;NN=yba}else{var zba=$CLJS.B(QN);$CLJS.AJ(zba,$CLJS.G([$CLJS.qu,$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VI],null)],null)]));LN=$CLJS.C(QN);MN=null;NN=0}ON=0}else break}$CLJS.X(qN,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ht,$CLJS.SM,$CLJS.aN,$CLJS.fN],null));
$CLJS.CJ($CLJS.hN,$CLJS.G([$CLJS.qu,$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qN],null)],null)],null)],null)]));
$CLJS.X($CLJS.mN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,$CLJS.sI,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,2,[$CLJS.Au,"valid timezone ID",$CLJS.Cu,function(a){a=$CLJS.Ge(a);a=$CLJS.I.g(a,$CLJS.zj);return["invalid timezone ID: ",$CLJS.Ph.l($CLJS.G([a]))].join("")}],null)],null),$CLJS.bL($CLJS.Jd,$CLJS.NM.tz.names()))],null));
$CLJS.CJ($CLJS.QM,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mN],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mN],null)],null)],null)],
null)]));$CLJS.tJ($CLJS.QM,$CLJS.XM);$CLJS.AJ($CLJS.dN,$CLJS.G([$CLJS.qu,$CLJS.AG]));$CLJS.X(TM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AI],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Ne(function(b){return $CLJS.PF(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vG,$CLJS.dH],null))}],null)],null));
$CLJS.X(PM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TM],null)],null)],null)],null));
$CLJS.IK.g($CLJS.WM,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ys,new $CLJS.k(null,1,[$CLJS.Au,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.WM],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PM],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$s,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ys,new $CLJS.k(null,1,[$CLJS.Au,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.DM],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.FM],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yM],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.gi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jL],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ys,new $CLJS.k(null,1,[$CLJS.Au,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.Lx],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.HM],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.gi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eL],null)],null)],null)],null)],null)],null));
$CLJS.JI.m(null,$CLJS.WM,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);a=$CLJS.F.g(b,$CLJS.Lx)?$CLJS.F.g(a,$CLJS.gi)?$CLJS.dH:$CLJS.n($CLJS.sJ($CLJS.jL,a))?$CLJS.vG:$CLJS.dH:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.qh($CLJS.LM,b))?$CLJS.vG:$CLJS.n($CLJS.qh($CLJS.MM,b))?$CLJS.vG:null:null;if($CLJS.n(a))return a;b=$CLJS.KI(b);b=$CLJS.wd(b)?$CLJS.eg.j($CLJS.eh,$CLJS.hf(function(c){return $CLJS.PF(c,$CLJS.Qj)}),b):b;return $CLJS.wd(b)&&
$CLJS.F.g($CLJS.E(b),1)?$CLJS.B(b):b});$CLJS.X(iN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.Lx],null),$CLJS.yt],null));
$CLJS.CJ($CLJS.bN,$CLJS.G([$CLJS.qu,$CLJS.dH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bz,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iN],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kL],null)],null)],null)],null)]));
$CLJS.AJ($CLJS.pC,$CLJS.G([$CLJS.qu,$CLJS.TH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iL],null)]));$CLJS.X(RM,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.ht,$CLJS.gL,$CLJS.$k,$CLJS.dl,$CLJS.YM,$CLJS.sN,$CLJS.tN,$CLJS.cl,$CLJS.al,$CLJS.Ai,$CLJS.bl,$CLJS.dL],null));$CLJS.X(eN,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ht,$CLJS.SM,$CLJS.aN,$CLJS.fN],null));
$CLJS.CJ($CLJS.nN,$CLJS.G([$CLJS.qu,$CLJS.sj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RM],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eN],null)],null)],
null)],null)]));
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var II,NI,PI,QI,RI,bJ,gJ,hJ;
$CLJS.FI=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.Hu([$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-4",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(b),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),
$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a())].join("").toLowerCase())};$CLJS.GI=function(a,b){a=$CLJS.tv($CLJS.aE(a,/''/,"'"),/\{\d+\}/);return $CLJS.O.j($CLJS.Fu.t,$CLJS.Xk(a),$CLJS.Xk(b))};$CLJS.HI=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.GI(arguments[0],1<b.length?new $CLJS.y(b.slice(1),0,null):null)};
II=function(a){return $CLJS.Ad(a)&&$CLJS.B(a)instanceof $CLJS.M};$CLJS.KI=function(a){var b=function(){var c=II(a);return c?(c=$CLJS.yd($CLJS.jd(a)))?(c=$CLJS.Hi.h($CLJS.jd(a)),$CLJS.n(c)?c:$CLJS.CE.h($CLJS.jd(a))):c:c}();return $CLJS.n(b)?b:$CLJS.JI.h(a)};
NI=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,2,[$CLJS.Au,"valid MBQL clause",$CLJS.Cu,function(c){c=$CLJS.Ge(c);c=$CLJS.I.g(c,$CLJS.zj);return["invalid MBQL clause: ",$CLJS.Ph.l($CLJS.G([c]))].join("")}],null),$CLJS.Oe(II)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,b],null),function(c){c=$CLJS.KI(c);
return MI(c,a)}],null)],null)};$CLJS.OI=new $CLJS.M(null,"right-join","right-join",-56349359);PI=new $CLJS.M("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);QI=new $CLJS.M("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);RI=new $CLJS.M("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.SI=new $CLJS.M(null,"fields","fields",-1932066230);
$CLJS.TI=new $CLJS.M(null,"expr","expr",745722291);$CLJS.UI=new $CLJS.M("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.VI=new $CLJS.M("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.WI=new $CLJS.M(null,"alias","alias",-2039751630);$CLJS.XI=new $CLJS.M("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);
$CLJS.YI=new $CLJS.M("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.ZI=new $CLJS.M("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.$I=new $CLJS.M("lib","expression-name","lib/expression-name",-1799326590);$CLJS.aJ=new $CLJS.M("mbql.clause","field","mbql.clause/field",1497292735);bJ=new $CLJS.M("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);
$CLJS.cJ=new $CLJS.M(null,"left-join","left-join",-672831855);$CLJS.dJ=new $CLJS.M("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.eJ=new $CLJS.M("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.fJ=new $CLJS.M("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);
gJ=new $CLJS.w("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);hJ=new $CLJS.M("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.iJ=new $CLJS.M(null,"inner-join","inner-join",659431740);$CLJS.LI=new $CLJS.M("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);
$CLJS.jJ=new $CLJS.M("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.kJ=new $CLJS.M(null,"full-join","full-join",1305476385);$CLJS.lJ=new $CLJS.M("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);$CLJS.mJ=new $CLJS.M("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.JI=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.I.j(new $CLJS.k(null,1,[$CLJS.ej,$CLJS.CF],null),$CLJS.ej,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.schema.expression","type-of-method"),function(f){var g=$CLJS.AF(f);return $CLJS.F.g(g,$CLJS.BE)?$CLJS.Ya(f):g},e,a,b,c,d)}();$CLJS.X(QI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.ZI],null),$CLJS.AI],null));
$CLJS.JI.m(null,$CLJS.gi,function(a){throw $CLJS.li($CLJS.GI("{0}: Don''t know how to determine the type of {1}",$CLJS.G([gJ,$CLJS.Ph.l($CLJS.G([a]))])),new $CLJS.k(null,1,[$CLJS.TI,a],null));});$CLJS.JI.m(null,$CLJS.eJ,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.KI(a)});
var MI=function MI(a,b){return $CLJS.wd(a)?$CLJS.Ne(function(d){return MI.g?MI.g(d,b):MI.call(null,d,b)},a):$CLJS.wd(b)?$CLJS.Ne(function(d){return MI.g?MI.g(a,d):MI.call(null,a,d)},b):$CLJS.F.g(a,$CLJS.ZI)?!0:$CLJS.PF(a,b)};$CLJS.X($CLJS.XI,NI($CLJS.Fj,"expression returning a boolean"));$CLJS.X($CLJS.jJ,NI($CLJS.Xj,"expression returning a string"));$CLJS.X($CLJS.mJ,NI($CLJS.sj,"expression returning an integer"));$CLJS.X(RI,NI($CLJS.NH,"expression returning a non-integer real number"));
$CLJS.X($CLJS.lJ,NI($CLJS.Jj,"expression returning a number"));$CLJS.X(bJ,NI($CLJS.vG,"expression returning a date"));$CLJS.X(hJ,NI($CLJS.TH,"expression returning a time"));$CLJS.X(PI,NI($CLJS.dH,"expression returning a date time"));$CLJS.X($CLJS.VI,NI($CLJS.Qj,"expression returning a date, time, or date time"));$CLJS.nJ=new $CLJS.dh(null,new $CLJS.k(null,3,[$CLJS.Jj,null,$CLJS.Xj,null,$CLJS.Qj,null],null),null);$CLJS.X($CLJS.fJ,NI($CLJS.nJ,"an expression that can be compared with :\x3e or :\x3c"));
$CLJS.X($CLJS.dJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,NI(new $CLJS.dh(null,new $CLJS.k(null,4,[$CLJS.Jj,null,$CLJS.Xj,null,$CLJS.Qj,null,$CLJS.Fj,null],null),null),"an expression that can appear in :\x3d or :!\x3d")],null));$CLJS.X($CLJS.YI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,NI($CLJS.wj,"any type of expression")],null));
$CLJS.X($CLJS.UI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vj,new $CLJS.k(null,1,[$CLJS.On,1],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YI],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ys,$CLJS.Dt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$I,$CLJS.Tj],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,$CLJS.Dt],null)],null)],null)],null));
var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var N4=function(a,b,c){var d=$CLJS.q0(a,b);d=$CLJS.Ge(d);d=$CLJS.I.g(d,$CLJS.XJ);d=$CLJS.CL($CLJS.Ck.j($CLJS.fh([c]),$CLJS.yI,$CLJS.jd),d);if(!$CLJS.n(d))throw $CLJS.li($CLJS.GI("No aggregation with uuid {0}",$CLJS.G([c])),new $CLJS.k(null,3,[$CLJS.Bt,c,$CLJS.jQ,a,$CLJS.i1,b],null));return d},O4=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592),P4=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",
-21314018),Q4=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815),Mia=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.t1.m(null,$CLJS.XJ,function(a,b){var c=$CLJS.He($CLJS.XJ.h($CLJS.q0(a,b)));return $CLJS.n(c)?$CLJS.c3($CLJS.HI("and"),function(){return function f(e){return new $CLJS.me(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.Bd(g)){var l=$CLJS.kc(g),m=$CLJS.E(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.md(l,u);v=$CLJS.V0.v(a,b,v,$CLJS.W0);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.lc(g))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.B(g);return $CLJS.de($CLJS.V0.v(a,
b,t,$CLJS.W0),f($CLJS.Lc(g)))}return null}},null,null)}(c)}()):null});$CLJS.x1.m(null,$CLJS.XJ,function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null),e=$CLJS.Ge(d);d=$CLJS.I.g(e,$CLJS.CE);e=$CLJS.I.g(e,$CLJS.Hi);c=$CLJS.H(c,2,null);c=N4(a,b,c);return $CLJS.Uk.l($CLJS.G([$CLJS.U0.j(a,b,c),new $CLJS.k(null,2,[$CLJS.t_,$CLJS.A_,$CLJS.d3,$CLJS.yI.h($CLJS.jd(c))],null),$CLJS.n(d)?new $CLJS.k(null,1,[$CLJS.CE,d],null):null,$CLJS.n(e)?new $CLJS.k(null,1,[$CLJS.Hi,e],null):null]))});
$CLJS.q1.m(null,$CLJS.XJ,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);return $CLJS.V0.v(a,b,N4(a,b,c),d)});$CLJS.tJ(P4,Q4);
for(var R4=$CLJS.A(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TA,$CLJS.iK],null)),S4=null,T4=0,U4=0;;)if(U4<T4){var Nia=S4.X(null,U4);$CLJS.tJ(Nia,P4);U4+=1}else{var V4=$CLJS.A(R4);if(V4){var W4=V4;if($CLJS.Bd(W4)){var X4=$CLJS.kc(W4),Oia=$CLJS.lc(W4),Pia=X4,Qia=$CLJS.E(X4);R4=Oia;S4=Pia;T4=Qia}else{var Ria=$CLJS.B(W4);$CLJS.tJ(Ria,P4);R4=$CLJS.C(W4);S4=null;T4=0}U4=0}else break}
$CLJS.q1.m(null,P4,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);if($CLJS.n(c))switch(a=$CLJS.V0.v(a,b,c,d),e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.GI("Count of {0}",$CLJS.G([a]));case "cum-count":return $CLJS.GI("Cumulative count of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.HI("Count");case "cum-count":return $CLJS.HI("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.r1.m(null,P4,function(a,b,c){a=$CLJS.H(c,0,null);a=a instanceof $CLJS.M?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.x1.m(null,P4,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.ei($CLJS.x1,Q4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.Pj,$CLJS.yG)});$CLJS.q1.m(null,$CLJS.SJ,function(){return $CLJS.HI("Case")});
$CLJS.r1.m(null,$CLJS.SJ,function(){return"case"});$CLJS.tJ(O4,Q4);for(var Y4=$CLJS.A(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.bK,$CLJS.EK,$CLJS.OJ,$CLJS.ck,$CLJS.vK,$CLJS.On,$CLJS.yK,$CLJS.uK,$CLJS.PJ],null)),Z4=null,$4=0,a5=0;;)if(a5<$4){var Sia=Z4.X(null,a5);$CLJS.tJ(Sia,O4);a5+=1}else{var b5=$CLJS.A(Y4);if(b5){var c5=b5;if($CLJS.Bd(c5)){var d5=$CLJS.kc(c5),Tia=$CLJS.lc(c5),Uia=d5,Via=$CLJS.E(d5);Y4=Tia;Z4=Uia;$4=Via}else{var Wia=$CLJS.B(c5);$CLJS.tJ(Wia,O4);Y4=$CLJS.C(c5);Z4=null;$4=0}a5=0}else break}
$CLJS.r1.m(null,O4,function(a,b,c){a=$CLJS.H(c,0,null);$CLJS.H(c,1,null);$CLJS.H(c,2,null);c=a instanceof $CLJS.M?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.q1.m(null,O4,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);a=$CLJS.V0.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "avg":return $CLJS.GI("Average of {0}",$CLJS.G([a]));case "cum-sum":return $CLJS.GI("Cumulative sum of {0}",$CLJS.G([a]));case "distinct":return $CLJS.GI("Distinct values of {0}",$CLJS.G([a]));case "max":return $CLJS.GI("Max of {0}",$CLJS.G([a]));case "median":return $CLJS.GI("Median of {0}",$CLJS.G([a]));case "min":return $CLJS.GI("Min of {0}",
$CLJS.G([a]));case "stddev":return $CLJS.GI("Standard deviation of {0}",$CLJS.G([a]));case "sum":return $CLJS.GI("Sum of {0}",$CLJS.G([a]));case "var":return $CLJS.GI("Variance of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.q1.m(null,$CLJS.UJ,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null);c=$CLJS.H(c,3,null);return $CLJS.GI("{0}th percentile of {1}",$CLJS.G([c,$CLJS.V0.v(a,b,e,d)]))});$CLJS.r1.m(null,$CLJS.UJ,function(){return"percentile"});
$CLJS.tJ($CLJS.UJ,Q4);$CLJS.q1.m(null,$CLJS.CK,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return $CLJS.GI("Sum of {0} matching condition",$CLJS.G([$CLJS.V0.v(a,b,e,d)]))});$CLJS.r1.m(null,$CLJS.CK,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.s1.j(a,b,d))].join("")});$CLJS.tJ($CLJS.CK,Q4);$CLJS.q1.m(null,$CLJS.oK,function(){return $CLJS.HI("Share of rows matching condition")});
$CLJS.r1.m(null,$CLJS.oK,function(){return"share"});$CLJS.tJ($CLJS.oK,Q4);$CLJS.q1.m(null,$CLJS.qK,function(){return $CLJS.HI("Count of rows matching condition")});$CLJS.r1.m(null,$CLJS.qK,function(){return"count-where"});$CLJS.tJ($CLJS.qK,Q4);
$CLJS.x1.m(null,Q4,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null),e=$CLJS.Uk.l;d=$CLJS.n(d)?$CLJS.bh($CLJS.U0.j(a,b,d),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.pU],null)):null;var f=$CLJS.ei($CLJS.x1,$CLJS.gi);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.Uk,$CLJS.G([d,a]))});$CLJS.VZ.m(null,$CLJS.XJ,function(a){return a});
$CLJS.Xia=function(){function a(d,e,f){for(;;)if($CLJS.F.g($CLJS.AF(f),$CLJS.b_))f=$CLJS.$Z(f);else return $CLJS.m2(d,e,$CLJS.XJ,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Yia=function(){function a(d,e){return $CLJS.He($CLJS.XJ.h($CLJS.q0(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.e5=function(){function a(d,e){var f=$CLJS.He($CLJS.XJ.h($CLJS.q0(d,e)));return null==f?null:$CLJS.eg.j($CLJS.Bf,$CLJS.ff.h(function(g){var l=$CLJS.U0.j(d,e,g),m=$CLJS.R.l,t=$CLJS.CE.h(l);return m.call($CLJS.R,$CLJS.b3(l,$CLJS.Hi,$CLJS.n(t)?t:$CLJS.wj),$CLJS.t_,$CLJS.A_,$CLJS.G([$CLJS.d3,$CLJS.yI.h($CLJS.jd(g))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.q1.m(null,$CLJS.ZK,function(a,b,c){a=$CLJS.Ge(c);a=$CLJS.I.g(a,$CLJS.RK);return $CLJS.nI.h(a.o?a.o():a.call(null))});$CLJS.y1.m(null,$CLJS.ZK,function(a,b,c){var d=$CLJS.Ge(c);a=$CLJS.I.g(d,$CLJS.aK);b=$CLJS.I.g(d,$CLJS.RK);c=$CLJS.I.g(d,$CLJS.OK);d=$CLJS.I.g(d,$CLJS.D0);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.o1,$CLJS.jE(a),$CLJS.G([$CLJS.n1,c]));return null!=d?$CLJS.R.j(a,$CLJS.c1,d):a});
$CLJS.Zia=function(){function a(d,e){var f=function(){var m=$CLJS.Zu.h($CLJS.O0($CLJS.R0(d)));return $CLJS.n(m)?m:$CLJS.eh}(),g=$CLJS.q0(d,e),l=$CLJS.C1.j(d,e,g);return $CLJS.He($CLJS.eg.j($CLJS.Bf,$CLJS.Ck.j($CLJS.hf(function(m){m=$CLJS.XK.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.Ol(function(m){m=$CLJS.Ge(m);var t=$CLJS.I.g(m,$CLJS.OK),u=$CLJS.I.g(m,$CLJS.JK);if($CLJS.Ta(t))return m;if($CLJS.F.g(u,$CLJS.Dt))return $CLJS.R.j(m,$CLJS.n_,l);t=$CLJS.He($CLJS.aZ(function(v){return $CLJS.P2(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.n_,t):null}),$CLJS.ff.h(function(m){return $CLJS.R.j(m,$CLJS.Aj,$CLJS.ZK)})),$CLJS.$K))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.$ia=function(){function a(d,e){return $CLJS.ZZ(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aK.h(d),$CLJS.N,$CLJS.c_.h(e)],null))}function b(d){if($CLJS.Ta($CLJS.OK.h(d)))return $CLJS.ZZ(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aK.h(d),$CLJS.N],null));var e=$CLJS.aK.h(d);e=$CLJS.ha.g?$CLJS.ha.g("aggregation operator %s requires an argument",e):$CLJS.ha.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.li(e,new $CLJS.k(null,1,[Mia,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();